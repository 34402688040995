import React from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

export const ViewJob = () => {
    const jobsGridData = [
        {
            id: 1,
            title: "Magento Developer",
            location: "California"
        },
        {
            id: 2,
            title: "Product Designer",
            location: "UK"
        },
        {
            id: 3,
            title: "Marketing Director",
            location: "USA"
        },
        {
            id: 4,
            title: "Project Manager",
            location: "California"
        },
        {
            id: 5,
            title: "HTML Developer",
            location: "Canada"
        },
        {
            id: 6,
            title: "Business Associate",
            location: "UK"
        },
        {
            id: 7,
            title: "Product Sales Specialist",
            location: "USA"
        },
        {
            id: 8,
            title: "Magento Developer",
            location: "Pakistan"
        },
        {
            id: 9,
            title: "Magento Developer",
            location: "India"
        },
        {
            id: 10,
            title: "Magento Developer",
            location: "California"
        },
        {
            id: 11,
            title: "Magento Developer",
            location: "Pakistan"
        },
        {
            id: 12,
            title: "Magento Developer",
            location: "India"
        },
    ];
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Row>
                            {(jobsGridData || []).map((item, key) => (
                                <Col xl={3} md={6} key={key}>
                                    <Card>
                                        <CardBody>
                                            <div className="favorite-icon">
                                                <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                            </div>
                                            <img src={""} alt="" height="50" className="mb-3" />
                                            <h5 className="fs-17 mb-2">
                                                <Link to="#" className="text-dark">{item.title}</Link> <small className="text-muted fw-normal">(0-2 Yrs Exp.)</small></h5>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-1">Skote Technology Pvt.Ltd</p>
                                                </li>{" "}
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0"><i className="mdi mdi-map-marker"></i> {item.location}</p>
                                                </li>
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0"><i className="uil uil-wallet"></i> $250 - $800 / month</p>
                                                </li>
                                            </ul>
                                            <div className="mt-3 hstack gap-2">
                                                <span className="badge rounded-1 badge-soft-success">Full Time</span>
                                                <span className="badge rounded-1 badge-soft-warning">Urgent</span>
                                                <span className="badge rounded-1 badge-soft-info">Private</span>
                                            </div>
                                            <div className="mt-4 hstack gap-2">
                                                <Link to="/view-detail-job" className="btn btn-soft-success w-100">View Profile</Link>
                                                <Link to="#" className="btn btn-soft-primary w-100">Apply Now</Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
