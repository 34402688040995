import React from 'react';
import { Row, Card, CardBody,Col } from 'reactstrap';
import './slider_custom.css'
const Vacancy = () => {
    return (
        <React.Fragment>
            <section className="py-2 bg-dark"style={{overflowX: "hidden",}}  >
                <div className="container-fluid">
                    <div className="my-2">
                        <Row>
                            <Col md="6">
                                <Card className="card_overlay py-5 mx-3">
                                    <div className="image_container training_image"></div>
                                    <CardBody className="text_center">
                                        <h2 className="card_title">
                                            GET <span className="highlight  fs-1"><em>TRAINING</em></span> BY PROFESSIONALS
                                        </h2>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="card_overlay py-5 mx-3">
                                    <div className="image_container consult_image"></div>
                                    <CardBody className="text_center">
                                        <h2 className="card_title">
                                            GET <span className="highlight fs-1"><em>CONSULT</em>   </span> BY PROFESSIONALS
                                        </h2>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Vacancy