import React, { useState } from 'react'
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter } from "reactstrap";

function NutritionCalculator({publicdata=false}) {
    const [foodName, setFoodName] = useState('');
    const [protein, setProtein] = useState('');
    const [fiber, setFiber] = useState('');
    const [carbohydrate, setCarbohydrate] = useState('');
    const [calcium, setCalcium] = useState('');
    const [iron, setIron] = useState('');
    const [vitaminC, setVitaminC] = useState('');
    
    const [totalProtein, setTotalProtein] = useState(0);
    const [totalFiber, setTotalFiber] = useState(0);
    const [totalCarbohydrate, setTotalCarbohydrate] = useState(0);
    const [totalCalcium, setTotalCalcium] = useState(0);
    const [totalIron, setTotalIron] = useState(0);
    const [totalVitaminC, setTotalVitaminC] = useState(0);

    const calculateTotals = () => {
        setTotalProtein(parseFloat(totalProtein) + parseFloat(protein));
        setTotalFiber(parseFloat(totalFiber) + parseFloat(fiber));
        setTotalCarbohydrate(parseFloat(totalCarbohydrate) + parseFloat(carbohydrate));
        setTotalCalcium(parseFloat(totalCalcium) + parseFloat(calcium));
        setTotalIron(parseFloat(totalIron) + parseFloat(iron));
        setTotalVitaminC(parseFloat(totalVitaminC) + parseFloat(vitaminC));
    
        // Reset input fields after calculation
        setFoodName('');
        setProtein('');
        setFiber('');
        setCarbohydrate('');
        setCalcium('');
        setIron('');
        setVitaminC('');
    };
    
    return (
        <React.Fragment>
            {
                publicdata==true?
                <>
                      <Card className='my-3'>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Nutrition Calculator</h3>
                                    </CardTitle>
                                    <ul className='list-group'>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Protein</h5> </Col>
                                                <Col><h5>{totalProtein ? totalProtein :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Fiber</h5> </Col>
                                                <Col><h5>{totalFiber ? totalFiber :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Carbohydrate</h5> </Col>
                                                <Col><h5>{totalCarbohydrate ? totalCarbohydrate :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Calcium</h5> </Col>
                                                <Col><h5>{totalCalcium ? totalCalcium :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Iron</h5> </Col>
                                                <Col><h5>{totalIron ? totalIron :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Vitamin C</h5> </Col>
                                                <Col><h5>{totalVitaminC ? totalVitaminC :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                    </ul>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={12} sm={12} className='mt-4'>
                                                <label className='label-control'>Food Name :</label>
                                                <input type="text" className='form-control' value={foodName} onChange={(e) => setFoodName(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Protein (g) :</label>
                                                <input type="number" className='form-control' value={protein} onChange={(e) => setProtein(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Fiber (g) :</label>
                                                <input type="number" className='form-control' value={fiber} onChange={(e) => setFiber(e.target.value)} />
                                            </Col>
                                            
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Carbohydrate (g) :</label>
                                                <input type="number" className='form-control' value={carbohydrate} onChange={(e) => setCarbohydrate(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Calcium (mg) :</label>
                                                <input type="number" className='form-control' value={calcium} onChange={(e) => setCalcium(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Iron (mg) :</label>
                                                <input type="number" className='form-control' value={iron} onChange={(e) => setIron(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Vitamin C (mg) :</label>
                                                <input type="number" className='form-control' value={vitaminC} onChange={(e) => setVitaminC(e.target.value)} />
                                            </Col>
                                        </Row>                            
                                    </form>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex justify-content-end py-2'>
                                        <button className='me-2 btn btn-success' onClick={calculateTotals}>Add Food</button>
                                    </div>
                                </CardFooter>
                            </Card>
                </>
                :
                <>
                
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardHeader><CardTitle><h3>Total Nutritional Values</h3></CardTitle></CardHeader>
                                <CardBody>
                                    <ul className='list-group'>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Protein</h5> </Col>
                                                <Col><h5>{totalProtein ? totalProtein :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Fiber</h5> </Col>
                                                <Col><h5>{totalFiber ? totalFiber :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Carbohydrate</h5> </Col>
                                                <Col><h5>{totalCarbohydrate ? totalCarbohydrate :"-"} g</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Calcium</h5> </Col>
                                                <Col><h5>{totalCalcium ? totalCalcium :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Iron</h5> </Col>
                                                <Col><h5>{totalIron ? totalIron :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                        <li className="list-group-item">
                                           <Row>
                                                <Col><h5>Total Vitamin C</h5> </Col>
                                                <Col><h5>{totalVitaminC ? totalVitaminC :"-"} mg</h5> </Col>
                                            </Row> 
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Nutrition Calculator</h3>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={12} sm={12} className='mt-4'>
                                                <label className='label-control'>Food Name :</label>
                                                <input type="text" className='form-control' value={foodName} onChange={(e) => setFoodName(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Protein (g) :</label>
                                                <input type="number" className='form-control' value={protein} onChange={(e) => setProtein(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Fiber (g) :</label>
                                                <input type="number" className='form-control' value={fiber} onChange={(e) => setFiber(e.target.value)} />
                                            </Col>
                                            
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Carbohydrate (g) :</label>
                                                <input type="number" className='form-control' value={carbohydrate} onChange={(e) => setCarbohydrate(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Calcium (mg) :</label>
                                                <input type="number" className='form-control' value={calcium} onChange={(e) => setCalcium(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Iron (mg) :</label>
                                                <input type="number" className='form-control' value={iron} onChange={(e) => setIron(e.target.value)} />
                                            </Col>
                                            <Col lg={4} sm={6} className='mt-4'>
                                                <label className='label-control'>Vitamin C (mg) :</label>
                                                <input type="number" className='form-control' value={vitaminC} onChange={(e) => setVitaminC(e.target.value)} />
                                            </Col>
                                        </Row>                            
                                    </form>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex justify-content-end py-2'>
                                        <button className='me-2 btn btn-success' onClick={calculateTotals}>Add Food</button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment>
    )
}

export default NutritionCalculator