import React, { useState } from "react"
import SimpleBar from "simplebar-react"
import BreadCrumb from "components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { NavLink } from "react-router-dom"


import { ConcernTarget } from "./ConcernTarget"
import { Assessment } from "./Assessment"
import { MedicalHistory } from "./MedicalHistory"
import { Answer } from "./Answer"
import { PersonalHealth } from "./PersonalHealthHistory/PersonalHealth"

const index = () => {
  const [activeTab, setActiveTab] = useState(1)
  const toggleTab = tab => {
    activeTab !== tab && setActiveTab(tab)
  }
  const TabData = [
    { tabname: "participator Intake", tabcomponent: Assessment },
    { tabname: "Concern Target", tabcomponent: ConcernTarget },
    { tabname: "Medical History", tabcomponent: MedicalHistory },
    { tabname: "Personal Health", tabcomponent: PersonalHealth },
    { tabname: "Participator Reply", tabcomponent: Answer },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="ASSESSMENT LIST"
            prevPage="Dashboard"
            prevPath="/dashboard"
          />
          <Row>
            <Col lg={3} xs={12}>
              <Card style={{ padding: 0 }}>
                <CardBody>
                  <div className="wizard">
                    <div className="steps">
                      <Nav vertical>
                        {TabData.map((item, idx) => (
                          <>
                            <NavItem
                              key={idx}
                              className={classnames({
                                current: activeTab === idx + 1,
                                "mt-3": true,
                              })}
                              onClick={() => {
                                toggleTab(idx + 1)
                              }}
                            >
                              <NavLink>
                                <div className="row">
                                  <div className="col-10 text-start">
                                    <span className="number">{idx + 1}.</span>{" "}
                                    {item.tabname}{" "}
                                  </div>
                                  <div className="col-2 text-end d-lg-none">
                                    <i
                                      className={
                                        activeTab === idx + 1
                                          ? "mdi mdi-minus me-1 fs-4 fw-1"
                                          : "mdi mdi-plus me-1 fs-4 fw-1"
                                      }
                                    />
                                  </div>
                                </div>
                              </NavLink>
                            </NavItem>
                            <div className="d-lg-none d-block">
                              <Collapse isOpen={activeTab === idx + 1}>
                                {React.createElement(item.tabcomponent)}
                              </Collapse>
                            </div>
                          </>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9} xs={12}>
              <div className="d-none d-lg-block">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab} className="">
                      {TabData.map((item, idx) => (
                        <TabPane tabId={idx + 1} key={idx}>
                          {React.createElement(item.tabcomponent)}
                        </TabPane>
                      ))}
                    </TabContent>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default index
