import React from 'react'
import {  Col, Input, Label, Row } from 'reactstrap'
export const PersonalDetails = () => {  
    return (
        <React.Fragment>
                <Row className="mt-3">
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="name">Name</Label>
                        <Input type="text" name="name" id="name"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="email">Email</Label>
                        <Input type="email" name="email" id="email"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="mobile">Mobile</Label>
                        <Input type="text" name="mobile" id="mobile"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="whatsapp">WhatsApp</Label>
                        <Input type="text" name="whatsapp" id="whatsapp" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="dob">DOB</Label>
                        <Input type="date" name="dob" id="dob" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="gender" className='mt-2'>Gender</Label> <br />
                        <Input type="radio" name="gender" value="male" id="male" className='mx-2' /><Label htmlFor='male' className='px-2'>Male</Label>
                        <Input type="radio" name="gender" value="female" id="female" className='mx-2' /><Label htmlFor='female' className='px-2'>Female</Label>
                        <Input type="radio" name="gender" value="others" id="other" className='mx-2' /><Label htmlFor='other' className='px-2'>Other</Label>
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="height">Height</Label>
                        <div className="input-group mb-3">
                            <Input type="number" name="height" id="height" className="form-control" />
                                <span className="input-group-text">cm</span>
                        </div>
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="weight">Weight</Label>
                        <div className="input-group mb-3">
                            <Input type="number" name="weight" id="weight" className="form-control" />
                                <span className="input-group-text">KG</span>
                        </div>
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="pan_card">Pan Card</Label>
                        <Input type="text" name="pan_card" id="pan_card" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="aadhar_card">Aadhar Card</Label>
                        <Input type="number" name="aadhar_card" id="aadhar_card" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="father_name">Father Name</Label>
                        <Input type="text" name="father_name" id="father_name" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="mother_name">Mother Name</Label>
                        <Input type="text" name="mother_name" id="mother_name" />
                    </Col>
                </Row>
        </React.Fragment>
    )
}
