import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import AddDietSchedule from './AddDietSchedule';
import AddExerciseSchedule from './AddExerciseSchedule';
import { toast } from 'react-toastify';
import { Recipes, exercise, food, meals, preset, schedule, timetables } from 'helpers/api_url';
import { useSelector } from 'react-redux';
import { addDays, format, formatISO, startOfDay } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { isEmpty } from 'lodash';
import AddNotesModal from './AddNotesModal';
import DietCard from '../Diet/presetPlan/DietCard';
import DayCard from '../Excersises/Scheduler/DayCard';

export const Timetable = () => {
	const [events, setEvents] = useState([]);
	const [exScheduleList, setExScheduleList] = useState([]);
	const [dietPlanList, setDietPlanList] = useState([]);

	const [foodList, setFoodList] = useState([]);
	const [recipeList, setRecipeList] = useState([]);
	const [mealList, setMealList ] = useState([]);
	const [exercises,setExercises] = useState([]);

	const authUser = useSelector(state => state.Login.authUser)
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(changePreloader({ status: true, message: '' }))
		schedule.list().then(res => setExScheduleList(res.data.schedule))
			.catch(err => toast.error(err.response ? err.response.data.message : err.message))
		preset.list().then(res => setDietPlanList(res.data.preset))
			.catch(err => toast.error(err.response ? err.response.data.message : err.message))
		
		Recipes.list().then(res => setRecipeList(res.data.recipe)).catch(e => console.error(e));
		food.list().then(res => setFoodList(res.data.food)).catch(e => console.error(e));
		meals.list().then(res=>setMealList(res.data.meal)).catch(e => console.error(e));
		exercise.list().then(res=>setExercises(res.data.exercise)).catch(e=>console.error(e));

		timetables.list(authUser.id).then(res => {
			const events = [];
			res.data?.timetable?.map((each, index) => {
				events.push({
					id: index,
					title: each.particular_name,
					date: each.date_time,
					className: each.particular_type === `App\\Models\\Menu` ?'bg-warning text-white':(each.particular_type === `App\\Models\\Diet\\PresetDay` ? 'bg-info text-white' : 'bg-primary text-white'),
					type: each.particular_type === `App\\Models\\Menu` ?'notes':(each.particular_type === `App\\Models\\Diet\\PresetDay` ? 'preset' : 'schedule'),
					data: each,
				});
			})
			setEvents(events)
		}).catch(err => toast.error(err.response ? err.response.data.message : err.message))
		.finally(() => dispatch(changePreloader({ status: false, message: '' })))



	}, [])
	const handleNavigateToDownloadTimeTable = () => navigate('/downloads/timetable');

	const [modalOnDateClickStatus, setModalOnDateClickStatus] = useState(false);
	const toggleModalOnDateClick = () => setModalOnDateClickStatus(!modalOnDateClickStatus);
	const [dateClickEventsData, setDateClickEventsData] = useState({});
	const handleDetiledRoutineView = () => {
		navigate('/day-routine', { state: { user: authUser, ...dateClickEventsData } })
	}

	const [modalOnEventClickStatus, setModalOnEventClickStatus] = useState(false);
	const toggleModalOnEventClick = () => setModalOnEventClickStatus(!modalOnEventClickStatus);
	const [clickedEventData, setClickedEventData] = useState({});


	
	
	

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Card>
						<CardHeader className='d-flex justify-content-between align-items-center'>
							<CardTitle className='m-0'>Time Table</CardTitle>
							<div >
								<AddNotesModal>
									<button className='btn btn-soft-warning me-2'> 
										<i className='bx bxs-notepad fs-4'></i> 
										<span className='d-none d-md-inline ms-2'> Add Notes</span> 
									</button>
								</AddNotesModal>
								<AddDietSchedule user={authUser} dietPlanList={dietPlanList} >
									<button className='btn btn-soft-success me-2'>
										<i className='bx bx-restaurant fs-4' ></i>
										<span className='d-none d-md-inline ms-2'> Add Diets</span> 
									</button>
								</AddDietSchedule>
								<AddExerciseSchedule user={authUser} exScheduleList={exScheduleList}>
									<button className='btn btn-soft-info me-2'>
										<i className='bx bx-dumbbell fs-4'></i>
										<span className='d-none d-md-inline ms-2'> Add Exercises</span> 
									</button>
								</AddExerciseSchedule>
								{/* <button onClick={handleNavigateToDownloadTimeTable} className='btn btn-soft-primary'>
									<i className='bx bx-cloud-download fs-4'></i>
									<span className='d-none d-md-inline ms-2'> Downloads</span> 
								</button> */}
							</div>
						</CardHeader>
						<CardBody>
							<FullCalendar plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin,]}
								slotDuration={"00:15:00"}
								handleWindowResize={true}
								themeSystem="bootstrap"
								headerToolbar={{
									left: "prev,next today",
									center: "title",
									right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
								}}
								events={events}
								dateClick={function (info) {
									const events = info.view.getCurrentData().options.events
									toggleModalOnDateClick(true);
									const currentEvents = events?.length ? events?.filter(e => format(e.date, "yyyy-MM-dd") === info.dateStr) : [];
									setDateClickEventsData({
										events: currentEvents,
										dateStr: info.dateStr
									})
									console.log(currentEvents)
								}}
								eventClick={function (info) {
									setClickedEventData(info.event._def)
									toggleModalOnEventClick();
									console.log(info.event._def);
								}}
							/>
						</CardBody>
					</Card>
					<Modal centered={true} isOpen={modalOnDateClickStatus} toggle={toggleModalOnDateClick}>
						<ModalHeader toggle={toggleModalOnDateClick}>Routine for : {dateClickEventsData?.dateStr ? format(dateClickEventsData?.dateStr, "PPP") : null}</ModalHeader>
						<ModalBody>
							{dateClickEventsData?.events?.length ? (
								<ul className='list-group'>
									{dateClickEventsData?.events?.map((event, index) => (
										<li className='list-group-item d-flex justify-content-between' key={index}>
											{event.title}
										</li>
									))}
								</ul>
							) : (
								<div className='d-flex justify-content-center align-items-center py-2'>
									<h4>No Events on this day</h4>
								</div>
							)}
						</ModalBody>
						<ModalFooter>
							<Button onClick={handleDetiledRoutineView} disabled={dateClickEventsData?.events?.length ? false : true} className='btn btn-sm btn-primary'>Detailed View</Button>
						</ModalFooter>
					</Modal>


					{/* modal on event click */}
					<Modal size='lg' centered={true} isOpen={modalOnEventClickStatus} toggle={toggleModalOnEventClick}>
						<ModalHeader toggle={toggleModalOnEventClick}> {clickedEventData.title} </ModalHeader>
						<ModalBody>
							{clickedEventData.extendedProps?.type === 'preset' && (
								<DietCard 
									index={null} 
									day={clickedEventData.extendedProps?.data?.particular} 
									foodList={foodList}
									recipeList={recipeList}
									mealList={mealList}
								/>
							)}
							{clickedEventData.extendedProps?.type === 'notes' && (
								<textarea rows={4} disabled className='form-control'>
									{clickedEventData.extendedProps?.data?.description}
								</textarea>
							)}
							{clickedEventData.extendedProps?.type === 'schedule' && (
								<DayCard 
									index={null}
									day={clickedEventData.extendedProps?.data?.particular}
									exercises={exercises}
									setSchedule={()=>{}}
								/>
							)}
						</ModalBody>
					</Modal>




				</Container>
			</div>
		</>
	)
}
