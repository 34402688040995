import React from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Button
} from "reactstrap"

import Select from 'react-select'
import { toast } from "react-toastify"
import { bodyStatic, community } from "helpers/api_url"

import { useDispatch } from "react-redux"
import { changePreloader } from "store/actions"

const BodyStatic = () => {

  const dispatch = useDispatch()

  const [participator, setParticipator ] = React.useState([])
                                       
      React.useEffect( () => {
        community.participators()
        .then( (res) => {
          setParticipator(res.data.participators)
        }
      )
        .catch(err => console.log(err)) 
      }, [])

  function handleSubmit(e) {
    e.preventDefault()
    dispatch(
      changePreloader({
        status: true,
        message: "Please Wait!",
      })
    )
    const formData = new FormData(e.target)
    bodyStatic
      .add(formData)
      .then(res => {
        toast.success(res.message, { autoClose: 2000 })
        e.target.reset()
      })

      .catch(err => toast.error(err.response ? err.response.data.message : err.message))
      .finally(() =>
        dispatch(
          changePreloader({
            status: false,
            message: "Please Wait !",
          })
        )
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Body-static" breadcrumbItem="Dashboard" />
          <Row>
            <Col>
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <h3 className="text-primary">Add Body Statics</h3>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <label htmlFor="participator" className="form-label">
                        Select Participator
                      </label>
                    <Select
                      name="user_id" 
                      options = { participator?.map (i=>({label:i.first_name,value:i.id}))}
                    />
                    </div>
                  </CardBody>
                  <CardBody>
                      <h3>Body Details</h3>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="height" className="form-label">
                            Height
                          </label>
                          <input
                            type="number"
                            name="height"
                            className="form-control"
                            id="height"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Weight
                          </label>
                          <input
                            type="number"
                            name="weight"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Neck
                          </label>
                          <input
                            type="number"
                            name="neck"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Shoulder
                          </label>
                          <input
                            type="number"
                            name="shoulder"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Chest
                          </label>
                          <input
                            type="number"
                            name="chest"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Arms
                          </label>
                          <input
                            type="number"
                            name="arms"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Waist
                          </label>
                          <input
                            type="number"
                            name="waist"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Hips
                          </label>
                          <input
                            type="number"
                            name="hips"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Thigh
                          </label>
                          <input
                            type="number"
                            name="thigh"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Calf
                          </label>
                          <input
                            type="number"
                            name="calf"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Center Back
                          </label>
                          <input
                            type="number"
                            name="center_back"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Back Waist
                          </label>
                          <input
                            type="number"
                            name="back_waist"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Cross Back
                          </label>
                          <input
                            type="number"
                            name="cross_back"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Arm Length to Underarm
                          </label>
                          <input
                            type="number"
                            name="arm_length_to_underarm"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Upper Arm
                          </label>
                          <input
                            type="number"
                            name="upper_arm"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Armhole depth
                          </label>
                          <input
                            type="number"
                            name="armhole_depth"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                      </div>
                  
                  </CardBody>

                  <CardBody>
                      <h3>BMI MACHINES DATA</h3>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Visceral Fat Level
                          </label>
                          <input
                            type="number"
                            name="visceral_fat_level"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Resting Metabolism
                          </label>
                          <input
                            type="number"
                            name="resting_metabolism"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            BMI
                          </label>
                          <input
                            type="number"
                            name="bmi"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Body Age as per BMI:
                          </label>
                          <input
                            type="number"
                            name="body_age_as_per_bmi"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                      </div>
                  </CardBody>

                  <CardBody>
                      <h5>Subcutaneous Fat :</h5>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Whole Body
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="subcutaneous_fat_whole_body"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Trunk
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="subcutaneous_fat_trunk"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Arm
                          </label>
                          <input
                            type="number"
                            name="subcutaneous_fat_arm"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Leg
                          </label>
                          <input
                            type="number"
                            name="subcutaneous_fat_leg"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                      </div>
                  </CardBody>

                  <CardBody>
                      <h5>Skeletal Muscle</h5>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Whole Body
                          </label>
                          <input
                            type="number"
                            name="skeletal_muscle_whole_body"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Trunk
                          </label>
                          <input
                            type="number"
                            name="skeletal_muscle_trunk"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Arm
                          </label>
                          <input
                            type="number"
                            name="skeletal_muscle_arm"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                      </div>
                  </CardBody>

                  <CardBody>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Body Strength
                          </label>
                          <input
                            type="number"
                            name="body_strength"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="weight" className="form-label">
                            Skinfold Measurements Calipers or “The Pinch Test”
                          </label>
                          <input
                            type="number"
                            name="pinch_test"
                            className="form-control"
                            id="weight"
                          />
                        </div>
                      </div>
                  </CardBody>

                  <CardFooter>
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BodyStatic
