import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody, Container, Badge, Button, CardHeader, Label } from 'reactstrap';
import { AssessmentApi } from 'helpers/api_url';
import { toast } from 'react-toastify';
import Select from 'react-select';
export const Answer = () => {
    const [tableData, setTableData] = useState([]);
    const [typeValue, setTypeValue] = useState();
    useEffect(() => {
        AssessmentApi.allQuestion()
            .then(r => setTableData(r.data.questions))
            .catch(e => console.log(e))
    }, [])
    const handleDelete = (id) => { AssessmentApi.deleteQuestion(id).then(res => { setTableData([...tableData.filter(td => td.id != id)]) }).catch((err) => toast.error(err.response ? err.response.data.message : err.message)) }
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const handleAnswer=(ques_id,e)=>{
        setToggleSwitch(!toggleSwitch);
    }
    return (
        <React.Fragment>
            <CardHeader className="d-flex align-items-center justify-content-between">
                <h5 className="card-title mb-0">Answer</h5>
            </CardHeader>
            <CardBody>
                <div className='row'>
                <div className='col-6 mb-3'>
                    <Label htmlFor='type'>Type</Label>
                    <Select className="basic-single" classNamePrefix="select" name="usertype" id="type" options={[
                        { label: 'Concern Target', value: 'concern_target' },
                        { label: 'Medical History', value: 'medical_history' },
                        { label: 'Participator Intake', value: 'participator_intake' },
                        { label: 'Personal Health', value: 'personal_health' },
                    ]} onChange={(e) => setTypeValue(e.value)} />
                </div>
                <div className={typeValue === 'personal_health' || typeValue === 'physician_information' || typeValue === 'lifestyle_dietary' || typeValue === 'cardiovascular_information' || typeValue === 'pain_history_information'|| typeValue === 'work_environment_information'|| typeValue === 'exercise_history_information' ? 'col-6 mb-3 d-lg-block' : 'col-6 mb-3 d-none'}>
                    <Label htmlFor='type_sub'>Sub Type</Label>
                    <Select className="basic-single" classNamePrefix="select" name="usertype" id="type_sub" options={[
                        { label: 'Physician Information', value: 'physician_information' },
                        { label: 'LifeStyle And Dietary', value: 'lifestyle_dietary' },
                        { label: 'Cardiovascular Information', value: 'cardiovascular_information' },
                        { label: 'Pain History Information', value: 'pain_history_information' },
                        { label: 'Work and Environment Information', value: 'work_environment_information' },
                        { label: 'Exercise History Information', value: 'exercise_history_information' },
                    ]} onChange={(e) => setTypeValue(e.value)} />
                </div>
                </div>
                <ul className="list-group">
                    {
                        tableData.filter(i => i.type === typeValue).map((item, idx) => (
                            <li className='list-group-item' key={idx}>
                                <Row>
                                    <Col xs={1}><span className='fw-bold'>{idx + 1}.</span></Col>
                                    <Col xs={8}><span className='fw-bold'><b>{item.question}</b></span></Col>
                                    <Col className='text-start' xs={3}>
                                        <div className="form-check form-switch mb-3">
                                            <label className="form-check-label" htmlFor={`answer-${item.id}`}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`answer-${item.id}`}
                                                    name='answer'
                                                    value={toggleSwitch}
                                                    checked={toggleSwitch}
                                                    onChange={e => {handleAnswer(item.id,e) }}
                                                />
                                                {toggleSwitch ? "Yes" : "No"}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </li>
                        ))
                    }
                </ul>
            </CardBody>
        </React.Fragment>
    )
}
