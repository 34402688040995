import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Card, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import ReactSelect from 'react-select';

function OtherDetailModel(props) {
    const { setData, user, current = null } = props;
    const { genders, toggleModal, status } = useModalHandler();
    const handleSubmit = (e) => { 
        e.preventDefault();
       toast.error("Under proccesing...........")
    }
    return (
        <>
            {props.children ? (
                <span onClick={toggleModal}>
                    {props.children}
                </span>
            ) : (
                <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            )}
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Other Details</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                        <div className="mb-3">
                            <Label className="form-label">Current Earning</Label>
                            <Input className='form-control' name='title' type='text' />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Expecting Earn</Label>
                            <textarea className='form-control' name="description" rows={2}></textarea>
                        </div>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Job Preffer</Label>
                                    <Input className='form-control' name='institute' type='text' />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Notice Period</Label>
                                    <Card>
                                    <ReactSelect className="basic-single" classNamePrefix="select" name="category" id="category"options={[
                                        {label:'5 day',value:'5 day'},
                                        {label:'10 day',value:'10 day'},
                                        {label:'15 day',value:'15 day'},
                                        {label:'20 day',value:'20 day'},
                                        {label:'25 day',value:'25 day'},
                                        {label:'1 month',value:'25 month'},
                                        {label:'2 month',value:'2 month'},
                                    ]}  />
                                    </Card>
                                </div>
                            </Col>
                        </Row>

                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default OtherDetailModel