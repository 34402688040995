import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function Proffessional(props) {
    const {current,user,setData} = props;
    const {genders, toggleModal, status } = useModalHandler();
    const handleSubmit=(e)=>{
        e.preventDefault();

        toast.error("Proffessional Data not update...UnderProccessing...")
    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Proffessional Details</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' value={user?.id} name='user_id' />
                        <Row>
                        
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Company name</Label>
                                    <textarea name="" id="" className='form-control'></textarea>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Director</Label>
                                    <Input name="father_phone" className="form-control" placeholder="" type="text"  />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Contact</Label>
                                    <Input name="father_aadhaar" className="form-control" placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Address</Label>
                                    <Input name="father_income" className="form-control" placeholder="" type="text" />
                                </div>
                            </Col>
                           
                        </Row>
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Proffessional