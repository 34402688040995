import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import image from '../../../assets/images/fitnessTest/background.png'
const FitnessTest = () => {
    return (
        <React.Fragment>
            <div className="page-content mt-5">

                <div className="__scholarship-section">
                </div>
                <Container className="__mainpage">
                    <Row className=" title-section">
                        <Col>
                            <h1 className="__main-title text-center">Fitness Test</h1>
                            <p className="__sub-title">Gauge your current fitness level with a variety of fitness tests that assess endurance, strength, flexibility, and more. These tests provide valuable feedback, helping you set realistic goals and track progress as you work towards better health.</p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <img src={image} alt="Sports Scholarships" className="__banner-image" />
                        </Col>
                    </Row>
                    <h3 className='__title-section mt-3'>Understand Your Fitness, Unlock Your Potential</h3>
                    <p className='font-format fs-4 '>
                    Gauge your current fitness level with our carefully designed <b>Fitness Tests</b>, tailored to assess various aspects of your physical health:  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Endurance:</b> Measure how well your body performs over time during sustained physical activity.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Strength & Conditioning:</b>  Test your muscle power to evaluate overall physical capability.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Flexibility & Mobility:</b>Assess your range of motion to understand joint and muscle health.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Movement, Balance and Stability:</b> Ensure your body’s coordination and control for daily movements.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Cardiovascular Health: </b>Check how efficiently your heart and lungs work during exercise.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Power Strength:</b> Measure your 1RM (One-Rep Max), Personal Records (PR), and overall muscle power strength to evaluate your maximum physical potential and track progress effectively.  
                    </p>
                    <p className='font-format fs-4 '>
                    <b>Specialize According to Your Needs:</b> Our platform is designed to assess your fitness level based on your specialization, whether it’s in sports performance or achieving specific fitness goals. Tailor your fitness journey to suit your unique targets and aspirations.  
                    </p>

                   
                    <p className='font-format fs-4 '>
                    Each test offers detailed insights and valuable feedback, helping you: 
                        <ul className='fs-4'>
                            <li>Identify your strengths and areas that need improvement.  </li>
                            <li>Set achievable fitness goals based on your current level.  </li>
                            <li>Track your progress over time, keeping you motivated on your health journey.  </li>
                        </ul>
                        Whether you're a beginner or a fitness enthusiast, our tests provide the foundation for building a personalized fitness plan.<b> Start your assessment today</b> and take the first step towards a healthier, stronger you!
                    </p>
                    

                </Container>
            </div>
        </React.Fragment>
    );
};

export default FitnessTest;
