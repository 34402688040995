import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter, Input, Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"
import classNames from "classnames";
function HealthyWt() {

    const [unitSystem, setUnitSystem] = useState("metric");
    const [height, setHeight] = useState("");
    const [healthyWeight, setHealthyWeight] = useState("");
    const [activeTab, setactiveTab] = useState("1");
    const toggle = tab => { if (activeTab !== tab) { setactiveTab(tab); } };
    const calculateHealthyWeight = () => {
        const heightInMeter =
            unitSystem === "metric"
                ? parseFloat(height) / 100
                : parseFloat(height) / 39.37;

        // Calculate healthy weight using BMI formula
        const lowerHealthyBmi = 18.5;
        const upperHealthyBmi = 24.9;
        const lowerHealthyWeight =
            lowerHealthyBmi * (heightInMeter * heightInMeter);
        const upperHealthyWeight =
            upperHealthyBmi * (heightInMeter * heightInMeter);

        // Convert to kilograms and format with two decimal places
        const lowerHealthyWeightFormatted = lowerHealthyWeight.toFixed(2);
        const upperHealthyWeightFormatted = upperHealthyWeight.toFixed(2);

        return `${lowerHealthyWeightFormatted} - ${upperHealthyWeightFormatted}`;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const calculatedHealthyWeight = calculateHealthyWeight();
        setHealthyWeight(calculatedHealthyWeight);
    };

    const handleUnitSystemChange = (system) => {
        setUnitSystem(system);
        setHeight("");
        setHealthyWeight("");
    };
    return (
        <React.Fragment>
            <Breadcrumbs title="Healthy Wt Calculator" breadcrumbItem="Dashboard" />
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader>
                                <h3>Results</h3>
                        </CardHeader>
                            <CardTitle className="my-2">
                                {healthyWeight !== "" && (
                                    <div>
                                        <p>Healthy Weight Range: {healthyWeight} Kg</p>
                                    </div>
                                )}
                            </CardTitle>
                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <h3>Healthy Wt. Calculator</h3>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <h2>Healthy Weight Calculator</h2>

                                <Nav tabs>
                                    <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }}>Metric Units</NavLink></NavItem>
                                    <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }}>US Units</NavLink></NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <form onSubmit={handleSubmit}>
                                            <div className="my-2">
                                                <label>Height (cm):</label>
                                                <Input type="number" value={height} onChange={(e) => setHeight(e.target.value)} required />
                                            </div>
                                            <Button className="bg-success" type="submit">Calculate</Button>
                                        </form>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <form onSubmit={handleSubmit}>
                                            <div className="my-2">
                                                <label>Height (in):</label>
                                                <Input type="number" value={height} onChange={(e) => setHeight(e.target.value)} required />
                                            </div>
                                            <Button className="bg-success" type="submit">Calculate</Button>
                                        </form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                        <CardBody>
                                <h3>What is a Healthy Weight?</h3>
                                <p >
                                    A healthy weight is generally defined as a weight range that is associated with good health outcomes and a reduced risk of chronic diseases such as diabetes, heart disease, and certain cancers. However, the specific definition of a healthy weight can vary depending on factors such as age, gender, height, body composition, and overall health status.
                                </p>
                                <p >
                                    One way to estimate a healthy weight range is through the use of BMI (Body Mass Index), which is a calculation based on a person's weight and height. Generally, a BMI of 18.5 to 24.9 is considered within the healthy weight range for most adults. However, it's important to note that BMI doesn't take into account individual differences in body composition, such as the proportion of fat and muscle in the body.
                                </p>

                                <p>
                                    Other measures, such as waist circumference, body fat percentage, and muscle mass, can also provide important information about health status and risk for chronic disease. Ultimately, the best way to determine a healthy weight range is through consultation with a healthcare professional who can take into account all of these factors and provide personalized recommendations.
                                </p>

                                <h3>Weight Effects.</h3>
                                <p>
                                    Being underweight, overweight, or obese can have significant effects on your overall health and well-being. Here are some of the effects of each condition:
                                </p>

                                <ol>
                                    <li><h2>Effects of Being Underweight:</h2></li>
                                    <ul>
                                        <li>Nutritional deficiencies: Being underweight can be a sign of malnourishment, which can lead to deficiencies in essential nutrients like protein, vitamins, and minerals.</li>
                                        <li>Weakened immune system: A lack of proper nutrition can weaken the immune system, making it harder for your body to fight off infections and illnesses.</li>
                                        <li>Reproductive issues: Women who are underweight may experience irregular menstrual cycles or infertility.</li>
                                        <li>Muscle wasting: Being underweight can cause muscle wasting and weakness, which can make it harder to perform everyday activities.</li>
                                    </ul>

                                    <li><h2>Effects of Being Overweight:</h2></li>
                                    <ul>
                                        <li>Cardiovascular disease: Carrying excess weight can put a strain on the heart and increase the risk of heart disease, stroke, and high blood pressure.</li>
                                        <li>Type 2 diabetes: Obesity is a major risk factor for type 2 diabetes, which can lead to serious health complications if left untreated.</li>
                                        <li>Joint pain: Excess weight can put added pressure on the joints, leading to pain and inflammation.</li>
                                        <li>Sleep apnea: Obesity is a common cause of sleep apnea, a condition that causes breathing to stop and start during sleep.</li>
                                    </ul>

                                    <li><h2>Effects of Being Obese:</h2></li>
                                    <ul>
                                        <li>Increased risk of cancer: Obesity has been linked to an increased risk of several types of cancer, including breast, colon, and prostate cancer.</li>
                                        <li>Liver disease: Obesity can cause fatty liver disease, which can lead to liver damage and even liver failure.</li>
                                        <li>Depression and anxiety: Obesity has been linked to mental health issues like depression and anxiety.</li>
                                        <li>Reduced life expectancy: Obesity can shorten life expectancy by increasing the risk of several health conditions.</li>
                                    </ul>
                                </ol>

                                <h3>Healthy Weight Maintenance.</h3>
                                <p>
                                    Maintaining a healthy weight is important for overall health and well-being. Here are some tips to help you achieve and maintain a healthy weight:
                                </p>

                                <ol>
                                    <li>Eat a balanced diet: Include plenty of fruits, vegetables, lean proteins, whole grains, and healthy fats in your diet. Avoid processed and junk foods that are high in calories, sugar, and unhealthy fats.</li>
                                    <li>Portion control: Eating in moderation is key to maintaining a healthy weight. Use smaller plates, avoid eating while distracted, and listen to your body's signals of hunger and fullness.</li>
                                    <li>Stay hydrated: Drinking enough water can help keep you feeling full, boost your metabolism, and aid in digestion. Aim for at least 8 glasses of water a day.</li>
                                    <li>Exercise regularly: Regular physical activity is important for maintaining a healthy weight. Aim for at least 150 minutes of moderate-intensity exercise per week, such as brisk walking, cycling, or swimming.</li>
                                    <li>Get enough sleep: Lack of sleep can disrupt hormones that regulate appetite and metabolism, leading to weight gain. Aim for 7-9 hours of sleep per night.</li>
                                    <li>Manage stress: Chronic stress can lead to weight gain and unhealthy eating habits. Find ways to manage stress, such as meditation, yoga, or talking to a therapist.</li>
                                    <li>Be consistent: Maintaining a healthy weight is a lifelong commitment. Make healthy eating and exercise a part of your daily routine, and don't give up if you have setbacks. Consistency is key to achieving your goals.</li>
                                </ol>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default HealthyWt
