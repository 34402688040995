import React from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './Scholarship.css'; // Add custom CSS
import image from '../../../assets/images/mission/scholarship.png'
import Header from '../Header';
const Scholarship = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">

                <div className="__scholarship-section">
                </div>
                <Container className="__mainpage">
                    <Row className="text-center title-section">
                        <Col>
                            <h1 className="__main-title">Scholarship</h1>
                            <p className="__sub-title">Admissions | Updated 02 Jul, 2024</p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <img src={image} alt="Sports Scholarships" className="__banner-image" />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={6}>
                            <h3 className="__title-section mt-3">Scholarship</h3>
                        </Col>
                        <Col lg={4}>
                            <div className="d-flex">
                                <Input type="text" className="form-control me-2" placeholder="Search..." />
                                <Button>Apply</Button>
                            </div>
                        </Col>
                    </Row>
                    <p className='font-format fs-4 '>
                        <b>Corporate Social Responsibility (CSR):</b> Providing scholarships demonstrates your commitment to social good, creating positive PR and strengthening your brand’s reputation in supporting future talent.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Brand Loyalty from Rising Stars: </b> Scholarship recipients view your brand as an enabler of their success, fostering long-term loyalty and authentic connections with your brand.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Engagement with Emerging Talent:  </b>By investing in scholarships, you’re effectively nurturing the next generation of athletes and influencers, potentially discovering future brand ambassadors early in their careers.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Empowering Talent: </b>By funding scholarships, investors enable promising athletes and fitness enthusiasts to pursue their goals without the constraints of financial burdens.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Direct Impact:  </b>Scholarships provide young and aspiring talent with essential resources for training, education, and wellness, ensuring they reach their full potential.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Personalized Sponsorship: </b>Tailor your scholarship offerings to specific sports, fitness areas, or skill levels to align with your brand values and goals.
                    </p>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Scholarship;
