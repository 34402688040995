import React, { useEffect, useState } from 'react';
import Header from '../Header';
import {
    Button, Card, CardBody, CardImg, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Diet } from 'helpers/api_url';
import { toast } from 'react-toastify';
import image from '../../../assets/images/Diet/diet-plans-1.jpg';
import { Link, useNavigate } from 'react-router-dom';

export const DietPage = () => {
    const [tabledata, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [nameFilter, setNameFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [calorieFilter, setCalorieFilter] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        Diet.list()
            .then(res => {
                setTableData(res.data.food);
                setFilteredData(res.data.food); // Initially set all data
            })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
    }, []);

    const toggleAdvancedSearch = () => setAdvancedSearch(!advancedSearch);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSearch = () => {
        const data = tabledata.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(data);
    };

    const handleAdvancedSearch = () => {
        const data = tabledata.filter(item => {
            return (
                (!nameFilter || item.name.toLowerCase().includes(nameFilter.toLowerCase())) &&
                (!categoryFilter || item.category?.toLowerCase().includes(categoryFilter.toLowerCase())) &&
                (!calorieFilter || item.calories <= parseFloat(calorieFilter))
            );
        });
        setFilteredData(data);
        toggleAdvancedSearch(); // Close modal after applying filters
    };

    const handleDietView = diet_id => navigate('/view-diet/' + diet_id);

    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">
                <Container className="mt-5">
                <Row>
                        <Col lg={11} md={10} xs={10}>
                            <h1 className="text-center mb-4">Fittestwarrior Diet</h1>
                            <h3>Food & Diets</h3>
                            <p className='font-format fs-4 '>
                            Discover nutritional advice and diet plans crafted to support various health and fitness goals. Whether you’re interested in weight management, muscle gain, or simply healthier eating, our Food & Diets section offers insights to help you make informed dietary choices.
                            </p>
                        </Col>
                        <Col md="2" lg={1} xs={2} className="media-position">
                            <b>Follow Us</b>
                            <div className="justify-content-end mt-3">
                                    <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                                    <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                                    <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                                </div>
                               
                            </Col>
                    </Row>
                  
                    <div className="d-flex justify-content-center my-4 __search-bar-wrapper">
                        <Input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for Diet e.g., name"className="__search-input" />
                        <Button color="primary" onClick={handleSearch} className="ms-2">Search</Button>
                        <Button color="dark" onClick={toggleAdvancedSearch} className="ms-2">Advanced Search</Button>
                    </div>
                    <Modal isOpen={advancedSearch} toggle={toggleAdvancedSearch}>
                        <ModalHeader toggle={toggleAdvancedSearch}>Advanced Search</ModalHeader>
                        <ModalBody>
                            <Nav tabs>
                                <NavItem><NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggleTab('1')}> Name</NavLink></NavItem>
                                <NavItem><NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggleTab('2')}>Category </NavLink></NavItem>
                                <NavItem><NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => toggleTab('3')}> Calories </NavLink></NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1"><Input type="text" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} placeholder="Filter by name"/></TabPane>
                                <TabPane tabId="2"><Input type="text" value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)} placeholder="Filter by category" /></TabPane>
                                <TabPane tabId="3"><Input type="number" value={calorieFilter}onChange={(e) => setCalorieFilter(e.target.value)}placeholder="Filter by maximum calories" /> </TabPane>
                            </TabContent>
                            <Button color="success" onClick={handleAdvancedSearch} className="mt-3">Apply Filters</Button>
                        </ModalBody>
                    </Modal>
                    <Row>
                        {
                            filteredData.length ? filteredData.map((item, idx) => (
                                <Col md="3" sm="6" className="mb-4" key={idx}>
                                    <Card className="__exercise-card shadow">
                                        <div className="__image-wrapper position-relative">
                                            <CardImg top src={image} alt="Diet"  className="__exercise-image" />
                                        </div>
                                        <CardBody className="text-center" style={{ height: '100px' }}>
                                            <h6 className="__exercise-category">{item.name}</h6>
                                            <Button color="dark" onClick={() => handleDietView(item.id)} className="__show-button">Show Details</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )) : (
                                <div className="text-center">No Diet found.</div>
                            )
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
