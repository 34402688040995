import React from 'react'
import ReactPlayer from 'react-player'
export const ViewVideo = () => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <div className='d-lg-block d-none'>
                    <ReactPlayer url='https://youtu.be/yYmWtzXRpqo?si=hO0atrv35yWaq8AN' width='800px' height='400px' />
                </div>
                <div className='d-block d-lg-none'>
                    <ReactPlayer url='https://youtu.be/yYmWtzXRpqo?si=hO0atrv35yWaq8AN' width='auto' height='300px' />
                </div>
            </div>
        </React.Fragment >
    )
}
