import React from 'react';
export const generateBadgeFromTextArray = (array,badgeClass = 'bg-warning') =>(<>{array.map((el,i)=>(<span key={i} className={'me-1 badge '+badgeClass}>{el}</span>))}</>)

export const foodTypeDotGenerator = (type) => {
    const type_colors = {
        veg:'success',
        vegetrain:'success',
        nonveg:'danger',
        vegSuppliment:'success',
        nonvegSuppliment:'danger',
        egg:'warning',
        Egetrain:'warning',
    };
    return (<span className={'p-1 d-inline-block rounded badge bg-'+type_colors[type]}></span>)
}