import React from 'react'

export const Knowledege = () => {
    return (
        <React.Fragment>
            <div>
                <h3 className="__title-section mt-3">Knowledge</h3>
                <b>Educational Content for Lifelong Learning</b>
                <p className='font-format fs-4 '>
                    Knowledge-sharing is at the heart of our social media content. We post valuable information on Strength & Conditioning, Sports fitness techniques, nutrition, mental health, recovery, mobility, movement and more, providing followers with resources that are practical and easy to understand. From infographics to how-to videos, our Knowledge posts empower users with information that they can immediately apply to their wellness journey.
                </p>

            </div>
        </React.Fragment>
    )
}
