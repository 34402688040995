import React, { useState } from 'react';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Input, Button, ReactSelect, Label } from 'reactstrap';
import Select from 'react-select';
function IdealWeight() {
    const [gender, setGender] = useState("male");
    const [unitSystem, setUnitSystem] = useState("metric");
    const [heightFeet, setHeightFeet] = useState("");
    const [heightInches, setHeightInches] = useState("");
    const [heightCm, setHeightCm] = useState("");
    const [weightKg, setWeightKg] = useState("");
    const [weightLbs, setWeightLbs] = useState("");
    const [age, setAge] = useState("");
    const [result, setResult] = useState("");

    const calculateIdealWeight = () => {
        let idealWeight;
        if (unitSystem === "metric") {
            if (gender === "male") {
                idealWeight = (parseFloat(heightCm) - 100) * 0.9;
            } else {
                idealWeight = (parseFloat(heightCm) - 100) * 0.85;
            }
        } else if (unitSystem === "us") {
            const totalInches =
                parseInt(heightFeet) * 12 + parseInt(heightInches);
            if (gender === "male") {
                idealWeight = (totalInches - 60) * 2.3 + 50;
            } else {
                idealWeight = (totalInches - 60) * 2.3 + 45.5;
            }
        }

        setResult(idealWeight.toFixed(1));
    };





    return (
        <React.Fragment>
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                {result && (
                                    <div>
                                        <CardHeader>
                                            <h3>Ideal Weight:</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <p>{result} kg</p>
                                        </CardBody>
                                    </div>
                                )}
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader><h2>Ideal Weight Calculator</h2></CardHeader>
                                <CardBody>
                                    <div>
                                        <div className='my-2'>
                                            <Label>Gender:</Label>
                                            <Select className="basic-single" classNamePrefix="select" defaultValue={gender} options={[
                                                { label: 'Male', value: 'male' },
                                                { label: 'Female', value: 'female' },
                                            ]} onChange={(e) => setGender(e.value)} />
                                        </div>
                                        <div className='my-2'>
                                            <Label>Unit System:</Label>
                                            <Select className="basic-single" classNamePrefix="select" defaultValue={unitSystem} options={[
                                                { label: 'Metric (cm, kg)', value: 'metric' },
                                                { label: 'US (feet, inches, lbs)', value: 'us' },
                                            ]} onChange={(e) => setUnitSystem(e.value)} />
                                        </div>



                                        {unitSystem === "metric" && (
                                            <div className='my-2'>
                                                <Label>Height (cm):</Label>
                                                <Input type="number" value={heightCm} onChange={(e) => setHeightCm(e.target.value)} />
                                            </div>
                                        )}
                                        {unitSystem === "us" && (
                                            <div className='my-2'>
                                                <Label>Height (Feet, Inches):</Label>
                                                <Input type="number" value={heightFeet} onChange={(e) => setHeightFeet(e.target.value)} placeholder="Feet" />
                                                <Input type="number" value={heightInches} onChange={(e) => setHeightInches(e.target.value)} placeholder="Inches" />
                                            </div>
                                        )}
                                        {unitSystem === "metric" && (
                                            <div className='my-2'>
                                                <Label>Weight:</Label>
                                                <Input type="number" value={weightKg} onChange={(e) => setWeightKg(e.target.value)} placeholder="Weight (kg)" />
                                            </div>
                                        )}
                                        {unitSystem === "us" && (
                                            <div className='my-2'>
                                                <Label>Weight:</Label>
                                                <Input type="number" value={weightLbs} onChange={(e) => setWeightLbs(e.target.value)} placeholder="Weight (lbs)" />
                                            </div>
                                        )}
                                        <div className='my-2'>
                                            <Label>Age:</Label>
                                            <Input type="number" value={age} onChange={(e) => setAge(e.target.value)} placeholder="Age" />
                                        </div>
                                        <div className='my-2'>
                                            <Button className='btn btn-success' onClick={calculateIdealWeight}> Calculate Ideal Weight</Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Healthy weight range for me?</h3>
                                    <ul>
                                        <li><strong>The ideal weight for an individual varies</strong> depending on several factors such as age, gender, height, body composition, and overall health.</li>
                                        <li><strong>An Ideal Weight Calculator</strong> can provide an estimation of the healthy weight range for an individual based on their specific factors.</li>
                                        <li><strong>To determine how much you should weigh</strong> according to an Ideal Weight Calculator, you would need to Input your age, gender, height, and weight.</li>
                                        <li><strong>Based on these factors, the calculator</strong> will provide an estimation of your ideal weight range.</li>
                                        <li><strong>The estimation is usually provided in a range of weights</strong> rather than a specific number, as it takes into account individual variations in body composition and overall health.</li>
                                        <li><strong>However, it is important to note</strong> that an Ideal Weight Calculator is just an estimation, and it should not be the sole determinant of your health status.</li>
                                        <li><strong>It is recommended to consult a healthcare professional</strong> for personalized advice on maintaining a healthy weight.</li>
                                    </ul>
                                    <strong>Age:</strong>
                                    <p> In general, age doesn't have a significant impact on the ideal body weight (IBW) once girls reach 14-15 years old and boys reach 16-17 years old, as growth tends to slow down or stop at that point. However, as people age, they tend to lose some height, with men losing about 1.5 inches and women losing about 2 inches by age 70. Additionally, aging can lead to a decrease in lean muscle mass and an increase in body fat, which is a natural process but can be mitigated through healthy habits such as a balanced diet, regular exercise, good sleep habits, and stress management.</p>

                                    <strong>Gender:</strong>
                                    <p>In general, males tend to weigh more than females, despite females having a higher percentage of body fat. This is because males typically have a greater amount of muscle mass, which is denser than fat. Additionally, females often have lower bone density than males. Lastly, males are typically taller than females.</p>

                                    <strong>Height:</strong>
                                    <p>Individuals who are taller tend to have more muscle mass and body fat, which contributes to a higher overall weight. If a male and female are of similar height, the male should typically weigh around 10-20% more due to differences in body composition.</p>

                                    <strong>Body Frame Size:</strong>
                                    <p>The size of one's body frame is another element that can greatly affect the determination of an ideal weight. Body frame size is usually classified into three categories: small, medium, or large-boned. The assessment of body frame size is based on the measurement of a person's wrist circumference relative to their height, which is illustrated below.</p>

                                    <h3>For Women</h3>
                                    <ul>
                                        <li><strong>Height under 5'2"</strong>
                                            <ul>
                                                <li>Small boned = wrist size less than 5.5"</li>
                                                <li>Medium boned = wrist size 5.5" to 5.75"</li>
                                                <li>Large boned = wrist size over 5.75"</li>
                                            </ul>
                                        </li>
                                        <li><strong>Height between 5'2" and 5' 5"</strong>
                                            <ul>
                                                <li>Small boned = wrist size less than 6"</li>
                                                <li>Medium boned = wrist size 6" to 6.25"</li>
                                                <li>Large boned = wrist size over 6.25"</li>
                                            </ul>
                                        </li>
                                        <li><strong>Height over 5' 5"</strong>
                                            <ul>
                                                <li>Small boned = wrist size less than 6.25"</li>
                                                <li>Medium boned = wrist size 6.25" to 6.5"</li>
                                                <li>Large boned = wrist size over 6.5"</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <h3>For Men</h3>
                                    <ul>
                                        <li> <strong>Height over 5' 5"</strong> </li>
                                        <ul>
                                            <li>Small boned = wrist size 5.5" to 6.5"</li>
                                            <li>Medium boned = wrist size 6.5" to 7.5"</li>
                                            <li>Large boned = wrist size over 7.5"</li>
                                        </ul>
                                    </ul>

                                    <p>If someone has a large body frame, their weight will tend to be higher than someone with a small body frame, even if they are the same height. Therefore, body frame size is an important factor that can impact measurements such as Ideal Body Weight (IBW) and Body Mass Index (BMI). In other words, a person's body frame size can make a difference in how their weight is perceived and how it relates to their overall health.</p>

                                    <ul>
                                        <li><b>G. J. Hamwi Formula (1964)</b>
                                            <ul>
                                                <li>Male: 48.0 kg + 2.7 kg per inch over 5 feet</li>
                                                <li>Female: 45.5 kg + 2.2 kg per inch over 5 feet</li>
                                                <li>Invented for medicinal dosage purposes.</li>
                                            </ul>
                                        </li>
                                        <li><b>B. J. Devine Formula (1974)</b>
                                            <ul>
                                                <li>Male: 50.0 kg + 2.3 kg per inch over 5 feet</li>
                                                <li>Female: 45.5 kg + 2.3 kg per inch over 5 feet</li>
                                                <li>Similar to the Hamwi Formula, it was originally intended as a basis for medicinal dosages based on weight and height. Over time, the formula became a universal determinant of IBW.</li>
                                            </ul>
                                        </li>
                                        <li><b>J. D. Robinson Formula (1983)</b>
                                            <ul>
                                                <li>Male: 52 kg + 1.9 kg per inch over 5 feet</li>
                                                <li>Female: 49 kg + 1.7 kg per inch over 5 feet</li>
                                                <li>Modification of the Devine Formula.</li>
                                            </ul>
                                        </li>
                                        <li><b>D. R. Miller Formula (1983)</b>
                                            <ul>
                                                <li>Male: 56.2 kg + 1.41 kg per inch over 5 feet</li>
                                                <li>Female: 53.1 kg + 1.36 kg per inch over 5 feet</li>
                                                <li>Modification of the Devine Formula.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <h3>Health BMI Range</h3>
                                    <p>
                                        The recommended healthy BMI range by the World Health Organization (WHO) for both males and females is 18.5 - 25. This range can be used to determine a healthy weight for any given height.
                                        BMI is a widely used metric in the medical field to determine IBW and to quickly indicate possible health complications. A high BMI is associated with an increased risk of various health problems such as obesity, diabetes, and heart disease. Doctors often use BMI as an indicator to advise their patients on potential health risks, particularly if there is a gradual increase in their BMI. Currently, it is the official metric used to classify individuals into different obesity levels.
                                    </p>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Recommended BMI Range for Children</h3>
                                    <p>
                                        All of the formulas mentioned above are applicable only to individuals aged 18 years and above. For children and teenagers, the BMI charts published by the Centers for Disease Control and Prevention (CDC) should be consulted. The CDC suggests that children should maintain a BMI that falls between the 5th and 85th percentile based on their age to ensure healthy growth and development. These charts are widely used by pediatricians to monitor the growth of children and identify potential health problems at an early stage. It is essential to keep in mind that children's bodies undergo significant changes during growth and development, and their BMI may fluctuate accordingly.
                                    </p>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Limitations of our IBW calculator</h3>
                                    <ul>
                                        <li><b>It provides only an estimate:</b> The IBW calculator provides an estimate of the healthy weight range for an individual based on their age, gender, height, and weight. However, this is only an estimate and does not take into account other factors such as body composition, muscle mass, and overall health.</li>
                                        <li><b>It is not suitable for everyone:</b> The IBW calculator may not be suitable for athletes, bodybuilders, pregnant women, and people with certain medical conditions, such as anorexia, bulimia, and other eating disorders.</li>
                                        <li><b>It does not consider cultural differences:</b> The calculator is based on Western population norms and may not be applicable to people from different ethnic backgrounds, as body composition and bone density can vary based on genetics and lifestyle factors.</li>
                                        <li><b>It may not be accurate for older adults:</b> As people age, their muscle mass tends to decrease, and their body fat increases. This can affect their IBW, and the calculator may not provide an accurate estimate.</li>
                                        <li><b>It should not be used as the sole determinant of health status:</b> While IBW is an important factor in determining overall health, it should not be used as the sole determinant of health status. Other factors, such as diet, exercise, and medical history, should also be taken into consideration.</li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
        </React.Fragment>
    );
}

export default IdealWeight;
