import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";


//Import Countdown
import SlideMain from "../CarouselTypes/SlideMain";
import OurTeam from "../CarouselTypes/AboutUsSlider";
import Competition from "../CarouselTypes/competition";
import { LoginButton } from 'components/Common/LoginButton';
import { SignupButton } from "components/Common/SignupButton";
import Vacancy from "../CarouselTypes/Vacancy";
import LeftSlideCompetition from "../CarouselTypes/LeftSlideCompetition";
import CompetitionRightSide from "../CarouselTypes/CompetitionRightSide";
import Activity from "../CarouselTypes/Activity";
import { FitnessCalculator } from "../CarouselTypes/FitnessCalculator";
import Investors from "../CarouselTypes/Investors";
import SocialMediaSlider from "../CarouselTypes/SocialMediaSlider";
import NewsletterSubscription from "../CarouselTypes/NewsletterSubscription";
import Timetable from "../CarouselTypes/TimeTable";


const Section = () => {


  return (
    <React.Fragment>

      <div className="">
        <SlideMain />
        <OurTeam />
        <Competition />
        <Vacancy />
        <FitnessCalculator />
        <Timetable />
        <Investors />
        <SocialMediaSlider />
        <NewsletterSubscription />
      </div>

    </React.Fragment>
  );
};

export default Section;
