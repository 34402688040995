import React, { useMemo } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'

export const ProgramList = () => {
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Name",accessor: "",HeaderClass:'text-center', DataClass:'text-center'},
    ]);
  return (
    <React.Fragment>
       <TableResponsive columns={columns} data={[]} noRecord="No Record List" />
    </React.Fragment>
  )
}
