import React, { useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

import { Link} from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "assets/images/fw-logoold.png";
import authOverlay from "assets/images/crousle.jpeg"

const ForgotPassword = props => {
  //meta title
  document.title = "Login | Fittestwarrior";
  const [submitted, setSubmitted] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "itsd1@3ree6ix.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
        setSubmitted(true);
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages bg-dark"style={{ overflow: 'hidden', width: '100vw', height: '100vh' }} >
      {/* style={{backgroundImage: `url(${authOverlay})`, backgroundSize: "cover", backgroundPosition: "center", minHeight: "100vh", backgroundRepeat: 'no-repeat'}} */}
        <Container fluid>
          <Row>
            <Col lg={8} className="d-none d-lg-block"></Col>
            <Col lg={4}className="pe-2">
              <Card className="my-5 pb-5">
                <div className="bg-light mt-5">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Reset Password with Fittest Warrior. (FW)</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end mb-3"><img src={profile} alt="" className="img-fluid" /></Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                
                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
                     
                      {submitted  && <Alert color="success">Password reset email sent successfully! & check Mail</Alert>}
                 
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input name="email" className="form-control" placeholder="Enter email" type="email" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} invalid={validation.touched.email && validation.errors.email ? true : false} />
                        
                      </div>

                      <div className="mt-3 d-grid"><button className="btn btn-primary btn-block" type="submit">Reset</button></div>
                    </Form>
                  </div>
                  <div className="mt-4 text-center">
                    <p>If you have already an account ?{" "}<Link to="/login" className="fw-medium text-primary">{" "}Sign in{" "}</Link>{" "}</p>
                    <p> © {new Date().getFullYear()} Fittestwarrior . Crafted with{" "}<i className="mdi mdi-heart text-danger" /> by Our Team</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;

