import BreadCrumb from 'components/Common/Breadcrumb'
import { AssetUrl, appointments, users } from 'helpers/api_url';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { changePreloader } from 'store/actions';
function ProfViewList() {
    const [profUsers, setProfUsers] = useState([]);
    const [searchText, setSearchText] = useState();
    const authUser = useSelector(state => state.Login.authUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [appointmentModalData,setAppointmentModalData] = useState({status:false,data:{}});
	const toggleAppointmentModal = ()=>setAppointmentModalData(state=>({...state,status:!appointmentModalData.status}))
    useEffect(() => {
        dispatch(changePreloader({status:true,message:''}))
        users.proffessional().then(res => setProfUsers(res.data.users))
            .catch(e => toast.error(e.response ? e?.response?.data?.message : e?.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));	;
    }, []);
    const handleSearch = e => setSearchText(e.target.value);
    const handleMakeAppointmentSubmit = e=>{
		e.preventDefault();
		const formData = new FormData(e.target);
        dispatch(changePreloader({status:true,message:'Creating Appointment'}))
		appointments.add(formData).then(res=>{
            toast.success(res.message);
            toggleAppointmentModal();
        }).catch(e=>toast.error(e.response?e.response.data.message:e.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));		
	}
    const handleOpenAppointmentModal = user => setAppointmentModalData({status:true,data:{user}})
    const handleProfileView = user =>  navigate('/prof-profile/'+user?.id);
    console.log(profUsers)
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Professionals" prevPage="Dashboard" prevPath="/dashboard" />
                <Row>
                    <Col xs={12} className='mb-3'>
                        <Input value={searchText} onChange={handleSearch} className='form-control w-100' placeholder='Search ...' />
                    </Col>
                    {profUsers
                        ?.filter(u => searchText ? (`${u.first_name} ${u.last_name} ${u.role?.name}`.toLowerCase().includes(searchText.toLowerCase())) : true)
                        .map((user, index) => (
                            <Col xs={6} md={4} lg={3} key={index}>
                                <Card>
                                    <CardHeader>
                                        <CardTitle>{user?.role?.name}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} className='d-flex justify-content-center align-items-center flex-column'>
                                                <img
                                                    className='rounded-circle border border-primary mb-2'
                                                    src={AssetUrl + user?.other_details?.profile_image}
                                                    style={{ aspectRatio: '1/1', objectFit: 'cover', height: '64px', width: '64px' }}
                                                    alt={user?.first_name}
                                                />
                                                <h5>{user?.first_name} {user?.last_name}</h5>
                                                <p className='mb-1'>{user?.role?.name}</p>
                                            </Col>
                                        </Row>
                                        <Button onClick={e=>handleProfileView(user)} className='btn btn-soft-success d-block btn-sm w-100 mb-2'>Profile</Button>
                                        <Button onClick={e=>handleOpenAppointmentModal(user)} className='btn btn-soft-primary d-block btn-sm w-100'>Get Appointment</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Container >
            <Modal centered={true} isOpen={appointmentModalData.status} toggle={toggleAppointmentModal}>
                <ModalHeader toggle={toggleAppointmentModal}>
                    <span>New Appointment Details</span>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleMakeAppointmentSubmit}>
                        <input type='hidden' value={authUser?.id} name='user_id' />
                        <input type='hidden' value={appointmentModalData.data?.user?.id} name='prof_user_id' />
                        <div className='mb-2'>
                            <Label htmlFor='prof_user_id' className="form-label">Selected Professional</Label>
                            <Input 
                                disabled 
                                value={`${appointmentModalData.data?.user?.first_name} ${appointmentModalData.data?.user?.last_name} (${appointmentModalData.data?.user?.role?.name})`} 
                            />
                        </div>
                        <Row className='mb-2'>
                            <Col xs={6}>
                                <div>
                                    <Label htmlFor='title' className="form-label">Title</Label>
                                    <input type="text" className='form-control' name='title' id='title' />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div>
                                    <Label htmlFor='date_of_apointment' className="form-label">Date of Apointment</Label>
                                    <input
                                        defaultValue={appointmentModalData?.data?.date}
                                        type="date"
                                        className='form-control'
                                        name='date_of_apointment'
                                        id='date_of_apointment'
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="mb-3">
                            <Label htmlFor='description' className="form-label">Appointment Descriptions</Label>
                            <textarea className='form-control' name='description' id='description' rows={5}></textarea>
                        </div>
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Add</Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    )
}
export default ProfViewList