import { schedule } from 'helpers/api_url';
import React from 'react'
import { Card, CardBody, CardTitle, CardHeader, CardFooter } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import ViewExerciseModal from '../ViewExerciseModal';
import AddDayExerciseModal from './AddDayExerciseModal';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';
function DayCard({ day, setSchedule, index, exercises }) {
    const dispatch = useDispatch();
    const handleDayDelete = () => {
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        schedule.deleteDay(day.id)
            .then(res => { setSchedule(state => ({ ...state, schedule_days: state.schedule_days.filter(sd => sd.id != day.id) })); toast.success(res.message) })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }
    const handleRemoveExercise = exercise_id => {
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        schedule.removeDayExercise(day.id, exercise_id)
            .then(res => { setSchedule(state => ({ ...state, schedule_days: state.schedule_days.map(sd => sd.id == day.id ? res.data.schedule : sd) })); toast.success(res.message) })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }

    return (
        <Card>
            {index !== null && (
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <CardTitle>DAY {index + 1}  <small className='ms-2'>({day.day_title})</small></CardTitle>
                    <div>
                        <button onClick={handleDayDelete} className='me-2 btn btn-sm btn-danger' ><i className="mdi mdi-delete-outline"></i></button>
                        <button className='me-2 btn btn-sm btn-warning' ><i className="mdi mdi-pencil-outline"></i></button>
                    </div>
                </CardHeader>
            )}
            <CardBody>
                <SimpleBar style={{ height: '200px' }}>
                    <ul className="list-group list-group-flush">
                        {day?.exercisedata_id && JSON.parse(day?.exercisedata_id)?.map((ex, i) => (
                            <li key={i} className='list-group-item d-flex justify-content-between align-items-center'>
                                <div>
                                    <h5>{exercises.filter(exercise => exercise.id == ex)[0]?.exercise_name}</h5>
                                </div>
                                <div>
                                    <ViewExerciseModal exerciseData={exercises.filter(exercise => exercise.id == ex)[0]}>
                                        <button className='me-2 btn btn-sm btn-soft-info' ><i className="mdi mdi-eye-outline"></i></button>
                                    </ViewExerciseModal>
                                    {index !== null && (
                                        <button onClick={() => handleRemoveExercise(ex)} className='me-2 btn btn-sm btn-soft-danger' ><i className="mdi mdi-delete-outline"></i></button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </SimpleBar>
            </CardBody>
            <CardFooter>
                <div className='d-flex justify-content-end'>
                    <AddDayExerciseModal day_id={day.id} setCurrentSchedule={setSchedule} exercises={exercises}>
                        <button className='me-2 btn btn-sm btn-success' >+ Add Exercise</button>
                    </AddDayExerciseModal>
                </div>
            </CardFooter>
        </Card>
    )
}

export default DayCard