import React, { useEffect, useState } from 'react';
import { Table, Input, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import { proffessionals } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { changePreloader } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const initialSlots = [
    { id: 2, slot_name: 'All Day', start_time: '', end_time: '' },
];
const initialDays = [
    { is_active:false, value: 'monday', label: 'Monday', slots: initialSlots },
    { is_active:false, value: 'tuesday', label: 'Tuesday', slots: initialSlots },
    { is_active:false, value: 'wednesday', label: 'Wednesday', slots: initialSlots },
    { is_active:false, value: 'thursday', label: 'Thursday', slots: initialSlots },
    { is_active:false, value: 'friday', label: 'Friday', slots: initialSlots },
    { is_active:false, value: 'saturday', label: 'Saturday', slots: initialSlots },
    { is_active:false, value: 'sunday', label: 'Sunday', slots: initialSlots },
    { is_active:false, value: 'none', label: 'Reset / None', slots: initialSlots },
];
const Availability = () => {
    const [days, setDays] = useState(initialDays);
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.Login.authUser);
    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }))
        proffessionals.profile(authUser.id)
        .then(res =>  setDays(state=>state.map(s=>{
            let slots = res.data.profile?.availability.find(a=>a.day === s.value)?.slot_data;
            slots = slots ? JSON.parse(slots):s.slots;
            return {...s,slots:slots}
        })))
        .catch(e => toast.error(e.response ? e.response.data.message : e.message))
        .finally(() =>dispatch(changePreloader({ status: false, message: '' })));
    }, [])
    const addSlot = (dayValue) => {
        setDays(days.map(day =>
            day.value === dayValue
                ? { ...day, slots: [...day.slots, { id: Date.now(), slot_name: '', start_time: '', end_time: '' }] }
                : day
        ));
    };
    const removeSlot = (dayValue, slotId) => {
        setDays(days.map(day =>
            day.value === dayValue
                ? { ...day, slots: day.slots.filter(slot => slot.id !== slotId) }
                : day
        ));
    };
    const handleSlotChange = (dayValue, slotId, field, value) => {
        setDays(days.map(day =>
            day.value === dayValue
                ? {
                    ...day,
                    slots: day.slots.map(slot =>
                        slot.id === slotId
                            ? { ...slot, [field]: value }
                            : slot
                    )
                }
                : day
        ));
    };

    const handleSameAsChange = (e, dayValue) =>setDays(days=>days.map(day =>day.value === dayValue? {...day,slots: days.find(d=>d.value===e.value).slots}: day))
    const handleDayActiveChange = (e,day)=>setDays(state=>state.map(s=>s.value===day?({...s,is_active:e.target.checked}):s))
    const handleCheckAllClick = e => setDays(state=>state.map(s=>s.value !== 'none'?({...s,is_active:e.target.checked}):s))
    const handleSaveAvailablity = ()=>{
        dispatch(changePreloader({ status: true, message: '' }))
        proffessionals.updateAvailability({availability_days:days.filter(d=>d.is_active)})
        .then(res=> setDays(state=>state.map(s=>{
            let slots = res.data.availability.find(a=>a.day === s.value)?.slot_data;
            slots = slots ? JSON.parse(slots):s.slots;
            return {...s,slots:slots}
        })))
        .catch(e=>toast.error(e.response?e.response.data.message:e.message))
        .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }

    return (
        <Row>
            <Col xs={12}>
                <Table>
                    <thead>
                        <tr>
                            <th><Input checked={days.every(day => day.is_active)} type='checkbox' onClick={handleCheckAllClick} /></th>
                            <th>Days</th>
                            <th className='text-center'>Slots</th>
                            <th>Same As</th>
                        </tr>
                    </thead>
                    <tbody>
                        {days.map(day => day.value !== 'none' && (
                            <tr key={day.value}>
                                <td><Input type='checkbox' onChange={e=>handleDayActiveChange(e,day.value)} checked={day.is_active} /></td>
                                <td>{day.label}</td>
                                <td>
                                    {day?.slots?.map((slot,idx) => (
                                        <Row className='mb-2' key={slot.id}>
                                            {idx === day.slots.length - 1 ?(
                                            <Col xs={1}>
                                                <Button disabled={!day.is_active} className='btn-soft-primary' onClick={() => addSlot(day.value)}>+</Button>
                                            </Col>
                                            ):(
                                            <Col xs={1}>
                                                <Button disabled={!day.is_active} className='btn-soft-danger' onClick={() => removeSlot(day.value, slot.id)}>-</Button>
                                            </Col>
                                            )}
                                            <Col xs={3}>
                                                <Input disabled={!day.is_active} className='form-control' type='text' placeholder='Slot Name' value={slot.slot_name} onChange={(e) => handleSlotChange(day.value, slot.id, 'slot_name', e.target.value)} />
                                            </Col>
                                            <Col xs={4}>
                                                <Input disabled={!day.is_active} className='form-control' type='time' value={slot.start_time} onChange={(e) => handleSlotChange(day.value, slot.id, 'start_time', e.target.value)} />
                                            </Col>
                                            <Col xs={4}>
                                                <Input disabled={!day.is_active} className='form-control' type='time' value={slot.end_time} onChange={(e) => handleSlotChange(day.value, slot.id, 'end_time', e.target.value)} />
                                            </Col>
                                        </Row>
                                    ))}
                                </td>
                                <td>
                                    <Select
                                        isDisabled={!day.is_active}
                                        onChange={(selectedOption) => handleSameAsChange(selectedOption, day.value)}
                                        defaultValue={{ value: 'none', label: 'Reset / None' }}
                                        options={initialDays.filter(d => d.value !== day.value).map(d => ({ value: d.value, label: `${d.label}` }))}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
            <Col xs={12} className='d-flex justify-content-center'>
                <Button onClick={handleSaveAvailablity} className='btn btn-success'>Save Availablity Time-Table</Button>
            </Col>
        </Row>
    );
};

export default Availability;
