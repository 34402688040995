import React from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Button,
} from "reactstrap"

const UrgentIssues = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Urgent Issues" breadcrumbItem="Dashboard" />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3 className="text-primary">Urgent Issues</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="branch" className="form-label">
                        Fitness Center Branch
                      </label>
                      <select id="branch" className="form-select">
                        <option>- Select Branch -</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="client" className="form-label">
                        Client Member
                      </label>
                      <select id="client" className="form-select">
                        <option>Select</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="client" className="form-label">
                        Assign issues to staff
                      </label>
                      <select id="client" className="form-select">
                        <option>Select</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="client" className="form-label">
                        Assign issues to Trainer
                      </label>
                      <select id="client" className="form-select">
                        <option>Select</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="client" className="form-label">
                        Assign issues to Urgent
                      </label>
                      <select id="client" className="form-select">
                        <option>Select</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="client" className="form-label">
                        Comments
                      </label>
                      <textarea id="client" className="form-select"></textarea>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UrgentIssues
