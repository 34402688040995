import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
export const Address = ({setData,user,current=null}) => {
    const { genders, toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit=(e)=>{
        dispatch(changePreloader({status:true,message:'Updating Address'}));
        e.preventDefault();
        const formData = new FormData(e.target);    
        users.updateAddress(formData).then(res=>{
            // setData(state=>({...state,address:res.data.address}))
            toast.success("Update Successfully !")
            toggleModal(false);
            window.location.reload();
        }).catch(e=>toast.error(e.response.data.message))
        .finally(()=>dispatch(changePreloader({status:false,message:false})))

    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Address</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        
                        <input type='hidden' name='user_id' value={user?.id} />
                        <input type='hidden' name='country' value="india" />
                        <div className="mb-3">
                            <Label className="form-label">Address type</Label>
                            {current?(
                                <Input type='text' disabled value={current.type} name='type'/>
                            ):(

                                <Select name='type' options={[
                                    {label:"Home",value:'home'},
                                    {label:"Office",value:'office'},
                                    {label:"Current",value:'current'},
                                    {label:"Permanent",value:'permanent'}
                                ]} />
                            )}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Address</Label>
                            <textarea className='form-control' name="address" rows={2}>{current?.address}</textarea>
                        </div>
                        <Row>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                    <Input name="city" className="form-control" defaultValue={current?.city} placeholder="" type="name" />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">District</Label>
                                    <Input name="district" className="form-control" defaultValue={current?.district} placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">State</Label>
                                    <Input name="state" className="form-control" defaultValue={current?.state} placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Pin Code</Label>
                                    <Input name="pincode" className="form-control" defaultValue={current?.pincode} placeholder="" type="text"  />
                                </div>
                            </Col>
                        </Row>
                       
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
