import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { exercise, schedule } from 'helpers/api_url';
import ReactSelect from 'react-select';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';

function AddDayExerciseModal(prop) {
    const dispatch=useDispatch();
    const {day_id,setCurrentSchedule,exercises} = prop;
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status);
    const handleSubmit = e =>{
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({status:true,message:'please wait'}));
        schedule.addDayExercise(day_id,formData.get('exercise_id')).then(res=>{
            const day = res.data.schedule;
            setCurrentSchedule(state=>({...state,schedule_days:state.schedule_days.map(sd=>sd.id == day_id?day:sd)}));
            toggleModal();
            e.target.reset();
            toast.success(res.message)
        }).catch(err=>toast.error(err.response?err.response.data.message:err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));
    }
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}>Add Exercise</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Select Exercise</label>
                                    <ReactSelect name='exercise_id' options={exercises?.map(e=>({value:e.id,label:e.exercise_name}))}/>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddDayExerciseModal