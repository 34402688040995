import React, { useState } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/f-logo-light.png";
import logoLightSvg from "../../assets/images/f-logo-light.png";
import logoDark from "../../assets/images/f-logo-light.png";


// import images

import icon1 from '../../assets/images/App-icon/Activities.svg'
import icon2 from '../../assets/images/App-icon/Community.svg'
import icon3 from '../../assets/images/App-icon/Competition.svg'
import icon4 from '../../assets/images/App-icon/Connection.svg'
import icon5 from '../../assets/images/App-icon/Consultancy.svg'
import icon6 from '../../assets/images/App-icon/Courses-Certificate.svg'
import icon7 from '../../assets/images/App-icon/Diets.svg'
import icon8 from '../../assets/images/App-icon/Exercises.svg'
import icon9 from '../../assets/images/App-icon/Fitness-Calculator.svg'
import icon10 from '../../assets/images/App-icon/Fitness-Cliniq.svg'
import icon11 from '../../assets/images/App-icon/GPRRating.svg'
import icon12 from '../../assets/images/App-icon/HSXStudio.svg'
import icon13 from '../../assets/images/App-icon/HireProfessional.svg'
import icon15 from '../../assets/images/App-icon/Jobs.svg'
import icon16 from '../../assets/images/App-icon/Leaderboard.svg'
import icon17 from '../../assets/images/App-icon/Live.svg'
import icon18 from '../../assets/images/App-icon/Shop.svg'
import icon19 from '../../assets/images/App-icon/SportsEvent.svg'
import icon20 from '../../assets/images/App-icon/Training.svg'
import icon21 from '../../assets/images/App-icon/Vendors.svg'

//i18n
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

const Header = props => {
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" width="100" height="30" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <Dropdown
              className="dropdown-mega d-none d-lg-block ms-2"
              isOpen={menu}
              toggle={() => setMenu(!menu)}
            >
              <DropdownToggle
                className="btn header-item "
                caret
                tag="button"
              >
                {props.t("Mega Menu")} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  <Col sm={8} className="">
                    <Row className="mt-5">
                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("HYSPORTX")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                         
                          <li>
                            <Link to="https://hysportx.com/">{props.t("HySport-x")}</Link>
                          </li>
                         
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("Sportskutumb")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("Sportskutumb")}</Link>
                          </li>
                         
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {props.t("FWBazaar")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("FWBazaar")}</Link>
                          </li>
                         
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={6} className="mt-5">
                        <h5 className="font-size-14 mt-0">
                          {props.t("FitnessCliniq")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{props.t("FitnessCliniq")}</Link>
                          </li>
                          
                        </ul>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <img
                            src={megamenuImg}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <SimpleBar style={{ maxHeight: "300px", maxWidth: "1000px", overflowX: "hidden" }}>
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon1} alt="Activities" />
                          <span>Activities</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon2} alt="Community" />
                          <span>Community</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon4} alt="Connection" />
                          <span>Connection</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/professional">
                          <img src={icon5} alt="Consultancy" />
                          <span>Consultancy</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="//lock">
                          <img src={icon6} alt="Course" />
                          <span>Course</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/createDiet">
                          <img src={icon7} alt="Diets" />
                          <span>Diets</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon3} alt="Competition" />
                          <span>Competition</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon8} alt="Exercises" />
                          <span>Exercises</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/calculator/bmibmrbac">
                          <img src={icon9} alt="Fitness-Calculator" />
                          <span>Calculator</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon10} alt="Fitness-Clinic" />
                          <span>Fitness-Clinic</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon11} alt="GPRRating" />
                          <span>GPRRating</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon12} alt="HSX Studio" />
                          <span>HSX Studio</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon13} alt="Hire Professional" />
                          <span>Hire Professional</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/job">
                          <img src={icon3} alt="Jobs" />
                          <span>Jobs</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon15} alt="slack" />
                          <span>Slack</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon16} alt="Leader Board" />
                          <span>Leader Board</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon17} alt="Live" />
                          <span>Live</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon18} alt="Shop" />
                          <span>Shop</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon19} alt="Sports Event" />
                          <span>Sports Event</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon20} alt="Training" />
                          <span>Training</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="/lock">
                          <img src={icon21} alt="Vendors" />
                          <span>Vendors</span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </SimpleBar>
              </DropdownMenu>
            </Dropdown>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />

            <ProfileMenu />

            <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar);
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
