import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle,DropdownMenu,DropdownItem,} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import user1 from "../../../assets/images/user-dummy-img.jpg";
import { logoutUser } from "store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AssetUrl } from "helpers/api_url";
const ProfileMenu = props => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const userData = useSelector(state=>state.Login.authUser);
  const logout = () =>{ dispatch(logoutUser());}
  return (
    <React.Fragment>
      <Dropdown isOpen={menu}toggle={() => setMenu(!menu)}className="d-inline-block" >
        <DropdownToggle className="btn header-item "id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user"src={userData?.other_details?.profile_image===""?user1: AssetUrl+userData?.other_details?.profile_image}alt="Header Avatar" style={{aspectRatio:1/1,objectFit:'cover'}}/>
          <span className="d-none d-xl-inline-block ms-2 me-1">{userData?.first_name} {userData?.last_name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>{" "}<span className="font-size-16 align-middle me-1">{userData?.role?.role_name}</span> <span className='badge badge-soft-success px-1 ms-1'>{userData?.role?.role_type}</span></DropdownItem>
          <Link  to="/profile" >
            <DropdownItem tag="a">{" "}<i className="bx bx-user font-size-16 align-middle me-1" />{props.t("Profile")}{" "}</DropdownItem>
          </Link>
          <div className="dropdown-divider" />
          <Link to="" onClick={logout} className="dropdown-item"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /><span>{props.t("Logout")}</span></Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
ProfileMenu.propTypes = { success: PropTypes.any, t: PropTypes.any};
export default withRouter((withTranslation()(ProfileMenu)));