import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardTitle, CardHeader, Col, Row } from 'reactstrap';
import { TableResponsive } from 'components/Common/TableResponsive';
import { schedule } from 'helpers/api_url';
import AddScheduleModal from './Scheduler/AddScheduleModal';
import Scheduler from './Scheduler/Scheduler';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function ExerciseSchedule({tabNumber}) {
    const dispatch=useDispatch();
    const [tabledata,setTableData] = useState([]);
    const [scheduleForView,setScheduleForView] = useState(null);
    useEffect(()=>{
        if(tabNumber==4 && scheduleForView === null){
            dispatch(changePreloader({status:true,message:'please wait'}));
            schedule.list().then(res=>setTableData(res.data.schedule))
            .catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));
        }
    },[tabNumber,scheduleForView])
    const handleScheduleDelete = id => schedule.delete(id).then(res=>setTableData(state=>state.filter(ex=>ex.id !== id))).catch(e=>console.error(e));
    const handleScheduleView = row => setScheduleForView(row);
    const columns = useMemo(()=>[
        {Header: "Name",accessor: "name"},
        {Header: "Description",accessor: "description",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "created By",HeaderClass:'text-center', DataClass:'text-center',Cell:cell=>`${cell.row.original.creator?.first_name} ${cell.row.original.creator?.first_name}`},
        {
            Header: "Action",HeaderClass:'text-center', DataClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return (
                    <div>
                        <button onClick={e=>handleScheduleView(row)} className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
                        <button onClick={e=>handleScheduleDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                        <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button>
                    </div>
                );
            }
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.name} </a> | {row.creator?.first_name}
                        </h5>
                        <p className="text-muted mb-0">
                        <span>{row.description} </span> </p>
                    </div>
                    <div className="flex-shrink-0">
                    <button onClick={e=>handleScheduleView(row)} className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
                    <button onClick={e=>handleScheduleDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <Row className={scheduleForView?'d-none':''}>
                <Col xs={12} >
                    <Card>
                        <CardHeader className='d-flex justify-content-between align-items-center'>
                            <CardTitle>Schedule List</CardTitle>
                            <div>
                                <AddScheduleModal setSchedule={setTableData}>
                                    <button className='btn btn-sm btn-success me-2'>+ Add New</button>
                                </AddScheduleModal>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <TableResponsive columns={columns} data={tabledata} noRecord="No Record List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {scheduleForView != null? <Scheduler setScheduleData={setScheduleForView} scheduleData={scheduleForView} />:null}
        </>
    )
}

export default ExerciseSchedule