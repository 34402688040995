import React, { useState } from "react"

import { Row, Col, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, Form, Label, Input, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/fw-logoold.png"
import { useSelector } from "react-redux"
import { AssetUrl, dashboard, kyc, validate } from "helpers/api_url"
import { useDispatch } from "react-redux"
import { changePreloader as setPreloader } from 'store/layout/actions'
import { toast } from "react-toastify"
import { loginSuccess } from "store/actions"
import ActivityModel from "pages/DefaultPage/ActivityModel"

const WelcomeComp = () => {
  const userData = useSelector(state => state.Login.authUser);
  const activityDataDashboard = [
    {
      id: 1,
      active: false,
      date: "22 Nov",
      activity: "Responded to need “Volunteer Activities”"
    },
    {
      id: 2,
      active: false,
      date: "17 Nov",
      activity: "Everyone realizes why a new common language would be desirable... Read More"
    },
    {
      id: 3,
      active: true,
      date: "15 Nov",
      activity: "Joined the group “Boardsmanship Forum”"
    },
    {
      id: 4,
      active: false,
      date: "22 Nov",
      activity: "Responded to need “In-Kind Opportunity”"
    }
  ]
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false)
  const handleSubmit = (e) => {
    dispatch(setPreloader({ status: true, message: "Please wait ..." }));
    e.preventDefault();
    const formData = new FormData(e.target);
    dashboard.profile(formData).then(res => {
      dispatch(setPreloader({ loader: false, message: '' }))
      toast.success(res.message, { autoClose: 2000 });
      setEditModal(false);

    })
      .then(() => {
        validate().then((res) => dispatch(loginSuccess(res)))
      })
      .catch(err => {
        dispatch(setPreloader({ loader: false, message: '' }))
        toast.error(err.response ? err.response.data.message : err.message, { autoClose: 2000 })
      })
  }
  return (
    <React.Fragment>
      <Card className="overflow-hidden position-relative">
        <div className="" style={{ backgroundImage: `url(${AssetUrl + userData?.other_details?.background_image})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}>
          <Row>
            <Col xs="7">
              <div className="text-light p-3">
                <h5 className="text-light">Welcome Back !</h5>
                <p>Fittest Warrior-Dashboard (FW)</p>
              </div>
            </Col>
            <Col xs="5" className="d-flex justify-content-end my-2">
              <img src={userData?.other_details?.logo === "" ? profileImg : AssetUrl + userData?.other_details?.logo} alt="" className="img-fluid me-2"
                style={{
                  aspectRatio: 1 / 1, height: "124px", objectFit: 'contain',
                  background: 'rgba(0, 0, 0, 0.3)',
                  backdropFilter: 'blur(6px)',
                  padding: '4px',
                  borderRadius: '50%'
                }
                }
              />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userData?.other_details?.profile_image === "" ? avatar1 : AssetUrl + userData?.other_details?.profile_image}
                  alt=""
                  className="img-thumbnail rounded-circle" style={{ aspectRatio: 1 / 1, objectFit: 'cover' }}
                />
              </div>
              <h6>Hello, <span>{userData?.first_name} {userData?.last_name}</span></h6>
              <h5 className="font-size-15 text-truncate">{userData?.role?.role_type}</h5>
              <p className="text-muted mb-0 text-truncate">{userData?.role?.role_name}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="5">
                    <h5 className="font-size-15">0</h5>
                    <p className="text-muted mb-0">Total Members</p>
                  </Col>
                  <Col xs="5">
                    <h5 className="font-size-15">0</h5>
                    <p className="text-muted mb-0">Total Events</p>
                  </Col>
                  <Col xs="2" className="text-end">
                    <h5 onClick={(e) => { e.preventDefault(); setEditModal(true) }} className="font-size-15 dripicons-document-edit" style={{ cursor: 'pointer' }}></h5>
                    <p className="text-muted mb-0"></p>
                  </Col>
                </Row>
                <div className="mt-4 mr-4">
                  <Link
                    to="/profile"
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">

          <div className='d-flex justify-content-between align-items-center'>
            <span>Activity</span>
            <ActivityModel/>
          </div>
          </CardTitle>
          <ul className="verti-timeline list-unstyled">
            {
              (activityDataDashboard || []).map((item, index) => (
                <li className={`event-list ${item.active && "active"}`} key={index}>
                  <div className="event-timeline-dot">
                    <i className={`bx bx-right-arrow-circle font-size-18 ${item.active && "bx-fade-right"}`} />
                  </div>
                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3">
                      <h5 className="font-size-14">
                        {item.date}
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                      </h5>
                    </div>
                    <div className="flex-grow-1">
                      <div>{item.activity}</div>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
          <div className="text-center mt-4">
            <Link
              to="/lock"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={editModal} toggle={() => { setEditModal(); }}>
        <ModalHeader toggle={() => setEditModal(false)}> Edit Profile </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Input type="hidden" name="user_id" value={userData?.id} defaultChecked={userData?.id} />
            <Row>
              <Col lg={12} xs={12} className="mb-3">
                <Label htmlFor='upload_logo'>Upload Logo</Label>
                <Input type='file' name='logo' id='upload_logo' />
              </Col>
              <Col lg={12} xs={12} className="mb-3">
                <Label htmlFor='upload_bg'>Upload Background Image</Label>
                <Input type='file' name='background_image' id='upload_bg' />
              </Col>
              <Col lg={12} xs={12} className="mb-3">
                <Label htmlFor='upload_profile'>Upload Profile</Label>
                <Input type='file' name='profile_image' id='upload_profile' />
              </Col>
              <Col lg={12} className='mt-2'>
                <div className="text-end">
                  <button type='submit' className="btn btn-success me-1">Update <i className="bx bx-send align-middle"></i></button>
                  <button className="btn btn-outline-secondary" onClick={(e) => { e.preventDefault(); setEditModal(false) }}>Cancel</button>
                </div>
              </Col>
            </Row>

          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default WelcomeComp
