import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './SelfAssessment.css';
import image from '../../../assets/images/selfAssessment/background.png'
import ReactPlayer from 'react-player';
const SelfAssessment = () => {
  return (
    <React.Fragment>

      <div className="__assessment-wrapper">
        <div className="__assessment-image">
          <img
            src={image}
            alt="Movement Assessment"
            className="__assessment-bg-image"
          />
        </div>

        <div className="__gradient-overlay"></div>

        <Container className="__assessment-content">
          <Row>
            <Col className="__assessment-text-container">
              <h1 className="__assessment-title">
                Self-Assessment: Empower Yourself with Personalized Insights
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Container className='px-5'>
          <b className="font-format fs-4">
            Self Assessment
          </b>
          <p className="font-format fs-4">
            Our self-assessment tools allow users to understand their physical and mental wellness better. By evaluating various health metrics, users can gain a clearer picture of their fitness journey and identify areas for improvement.
          </p>


          
          <h3>Empower Yourself with Personalized Insights</h3>
          <p className="font-format fs-4">
          Our <b>Self-Assessment tools</b> are designed to help users take control of their health and fitness journey by gaining a deeper understanding of their physical and mental wellness. These tools go beyond surface-level evaluations, providing a holistic view of your overall well-being and helping you make informed decisions for a healthier lifestyle. Here’s what our Self-Assessment tools cover:  
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <div className='d-lg-block d-none'>
              <ReactPlayer url='https://youtube.com/shorts/YcmTJQS04VE?si=fheDYL7u2yTQNMoo' width='800px' height='400px' />
            </div>
            <div className='d-block d-lg-none'>
              <ReactPlayer url='https://youtube.com/shorts/YcmTJQS04VE?si=fheDYL7u2yTQNMoo' width='auto' height='300px' />
            </div>
          </div>
          <p className="font-format fs-4">
          <b>Medical History:</b> Document and review your past medical conditions, surgeries, allergies, and family medical history to identify risk factors and prioritize your health needs.
          </p>
          <p className="font-format fs-4">
          <b>Physical Information:</b> Evaluate key metrics such as weight, height, body mass index (BMI), body fat percentage, and more to create a foundation for your fitness goals.  
          </p>
          <p className="font-format fs-4">
          <b>Lifestyle and Dietary Habits:</b> Analyse your daily routines, eating patterns, and hydration levels to identify areas for improvement in maintaining a balanced lifestyle.  
          </p>
          <p className="font-format fs-4">
          <b>Cardiovascular Health:</b> Assess your heart health through indicators such as resting heart rate, blood pressure, and activity levels to ensure long-term wellness.  
          </p>
          <p className="font-format fs-4">
          <b>Pain History and Mobility:</b> Track and document any recurring pain, stiffness, or injuries to create tailored plans that promote recovery and prevent further issues.  
          </p>
          <p className="font-format fs-4">
          <b>Work and Environmental Impact:</b> Understand how your work schedule, posture, and environment affect your health, and discover ways to stay active and stress-free in daily life.  
          </p>
          <p className="font-format fs-4">
          <b>Exercise History:</b> Record your past workout routines, fitness levels, and areas of focus to craft a personalized training plan that aligns with your goals.  
          </p>
          <p className="font-format fs-4">
          <b>Stress and Mental Wellness:</b> Identify stress levels, sleep patterns, and emotional well-being to ensure a balanced mind-body connection.  
          </p>
          <p className="font-format fs-4">
          <b>Nutritional Assessment:</b> Evaluate your current nutrient intake and discover deficiencies or excesses to fine-tune your diet for better energy and health.  
          </p>
          <p className="font-format fs-4">
          <b>Posture and Flexibility Analysis:</b> Examine your body’s alignment and flexibility to prevent injuries and enhance performance in physical activities.  
          </p>
          <p className="font-format fs-4">
          <b>Health Goals and Progress Tracking: </b>Set achievable goals and monitor your improvements over time to stay motivated and focused.  
          </p>
          
          <ul className='fs-4'>
          <p className="font-format fs-4"><b>Why Use Self-Assessment Tools?</b></p>
            <li>Get a <b>comprehensive understanding </b>of your health and fitness.  </li>
            <li>Identify <b>key areas of improvement</b> for targeted action.  </li>
            <li>Develop a <b>personalized fitness or health plan</b> tailored to your needs.</li>
            <li>Monitor your progress and celebrate milestones on your journey.  </li>
          </ul>
          <p className="font-format fs-4">
          Whether you're a beginner, a professional athlete, or someone aiming for a healthier lifestyle, our self-assessment tools offer a <b>user-friendly, step-by-step approach</b> to better health. Start your journey with FittestWarrior today and take the first step toward unlocking your full potential !
          </p>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default SelfAssessment;
