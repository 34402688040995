import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Button,
} from "reactstrap"
import ReactSelect from "react-select"



function BodyType() {
  const [measurements, setMeasurements] = useState({
    bust: "",
    waist: "",
    highHip: "",
    hip: "",
  });
  const [bodyType, setBodyType] = useState("");
  const [whr, setWhr] = useState("");

  const calculateBodyType = (e) => {
    e.preventDefault();
    const { bust, waist, highHip, hip } = measurements;

    // Calculate WHR
    const waistInches = parseFloat(waist);
    const hipInches = parseFloat(hip);
    const calculatedWHR = waistInches / hipInches;

    setWhr(calculatedWHR.toFixed(2)); // Round WHR to 2 decimal places

    // Body Type Calculation
    if (bust - hip < 3.6 && bust - waist < 9 && hip - waist < 10) {
      setBodyType("Rectangle");
    } else if (bust - hip >= 3.6 && bust - waist < 9) {
      setBodyType("Inverted triangle");
    } else if (hip - bust >= 3.6 && hip - waist < 9) {
      setBodyType("Triangle");
    } else if (hip - bust > 2 && hip - waist >= 7 && highHip / waist >= 1.193) {
      setBodyType("Spoon");
    } else if (
      bust - hip <= 1 &&
      hip - bust < 3.6 &&
      bust - waist >= 9 &&
      hip - waist >= 10
    ) {
      setBodyType("Hourglass");
    } else if (bust - hip > 1 && bust - hip < 10 && bust - waist >= 9) {
      setBodyType("Top hourglass");
    } else if (
      hip - bust >= 3.6 &&
      hip - bust < 10 &&
      hip - waist >= 9 &&
      highHip / waist < 1.193
    ) {
      setBodyType("Bottom hourglass");
    } else {
      setBodyType("Unknown");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMeasurements({ ...measurements, [name]: value });
  };

  return (
    <React.Fragment>
      <Breadcrumbs title="BodyType Calculator" breadcrumbItem="Dashboard" />
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader>
                <h3>Results</h3>
            </CardHeader>
              <CardTitle className="my-2">
                <h5>Waist-Hip Ratio (WHR): {whr}</h5>
                <h5>Body Type: {bodyType}</h5>
              </CardTitle>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <CardHeader>
              <CardTitle>
                <h3>Body Type Calculator</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <form onSubmit={calculateBodyType}>
                <div className="row my-2">
                  <label>Bust Size</label>
                  <div className="col-6">
                    <Input type="number" name="bust" value={measurements.bust}onChange={handleChange} placeholder="Enter bust size"/>
                  </div>
                  <div className="col-6">
                    <ReactSelect type="text" name="bust_size_measure" defaultValue={{ value: 'cm', label: 'CM' }} options={[
                      { value: 'cm', label: 'CM' },
                      { value: 'inches', label: 'inches' },
                    ]} />
                  </div>
                </div>
                <div className="row my-2">
                  <label>Waist Size</label>
                  <div className="col-6">
                    <Input type="number"name="waist" value={measurements.waist} onChange={handleChange} placeholder="Enter waist size"/>
                  </div>
                  <div className="col-6">
                    <ReactSelect type="text" name="waist_size_measure" defaultValue={{ value: 'cm', label: 'CM' }} options={[
                      { value: 'cm', label: 'CM' },
                      { value: 'inches', label: 'inches' },
                    ]} />
                  </div>
                </div>
                <div className="row my-2">
                  <label>High Hip Size</label>
                  <div className="col-6">
                    <Input  type="number" name="highHip" value={measurements.highHip} onChange={handleChange} placeholder="Enter high hip size" />
                  </div>
                  <div className="col-6">
                    <ReactSelect type="text" name="high_size_measure" defaultValue={{ value: 'cm', label: 'CM' }} options={[
                      { value: 'cm', label: 'CM' },
                      { value: 'inches', label: 'inches' },
                    ]} />
                  </div>
                </div>
                <div className="row my-2">
                  <label>Hip Size</label>
                  <div className="col-6">
                    <Input type="number" name="hip" value={measurements.hip} onChange={handleChange} placeholder="Enter hip size"/>
                  </div>
                  <div className="col-6">
                    <ReactSelect type="text" name="hip_size_measure" defaultValue={{ value: 'cm', label: 'CM' }} options={[
                      { value: 'cm', label: 'CM' },
                      { value: 'inches', label: 'inches' },
                    ]} />
                  </div>
                </div>
                <Button className="btn btn-success" type="submit"> Calculate</Button>
              </form>
           
            </CardBody>


            <CardBody>
              <h3>Measurements</h3>
              <p >
                Measuring these body parts can be subjective and may vary depending on the individual and the measuring
                technique used. However, here are some general guidelines on how to measure bust size, waist size, high hip
                size, and hip size:<br />
              </p>

              <ol>
                <li><b>Bust size:</b>Wear a properly fitted bra and measure around the fullest part of your bust. Make sure
                  the measuring tape is parallel to the ground and not too tight or too loose.</li>
                <li><b>Waist size:</b>Measure around the smallest part of your waist, which is typically just above your
                  belly button. Again, make sure the measuring tape is parallel to the ground and not too tight or too
                  loose.</li>
                <li><b>High hip size:</b>Measure around the upper swell of your hip over the pelvic region, which is around
                  7 inches (18 cm) below your natural waist. The measuring tape should be parallel to the ground and not
                  too tight or too loose.</li>
                <li><b>Hip size:</b>Measure around the largest part of your hips, which is typically over your buttocks.
                  Make sure the measuring tape is parallel to the ground and not too tight or too loose.</li>
              </ol>

              <h3>Fashion Body Shape Categories.</h3>
              <p>
                These four body shape categories are not a perfect representation of all body types and can be limiting in
                terms of inclusivity. It's important to recognize that bodies come in all shapes and sizes, and fashion
                should be accessible to everyone, regardless of their body shape. In recent years, the fashion industry has
                made strides towards greater body positivity and inclusivity, with more brands featuring diverse models and
                offering a wider range of sizes.
              </p>

              <ol >
                <li><b>Rectangle or Straight Body Shape:</b>The body shape you are referring to is called a "rectangle" or
                  "straight" body shape. This means that the person's waist measurement is relatively similar to their hip
                  and bust measurements, creating a straight or rectangular shape. This body shape is also sometimes
                  referred to as an "athletic" or "boyish" shape. People with this body shape tend to have a slim and
                  straight silhouette with little to no waist definition.</li>

                <li><b>Inverted triangle or Apple Shape Defined:</b>The body shape commonly known as an "inverted triangle"
                  or "apple" shape typically describes individuals who have a larger upper body, with broader shoulders,
                  chest, and back, and a smaller lower body, with narrower hips and thighs. This body shape is
                  characterized by a V-shaped torso, where the shoulders are wider than the hips.</li>

                <li><b>Pear-shaped body:</b>The body shape that describes a person who has hip measurements greater than
                  their bust measurements is commonly referred to as a "pear-shaped" body.</li>

                <li><b>Hourglass Body Shape:</b> The hourglass shape is characterized by a well-defined waistline that is
                  narrower than the hips and bust, creating an hourglass-like silhouette. This body shape can be found in
                  people of any size or weight, and it is often achieved through a combination of genetics and lifestyle
                  factors such as diet and exercise.</li>
              </ol>

              <img
                src="https://media.istockphoto.com/id/510615356/vector/chalked-female-body-types.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=hXJkjI1D2JcWqfq10O4qAxmvxOUpcFythKlWCTdpuOw="
                alt=""
                width="400"
                height="300"
              />
              <br /><br />
              <h3>Body Shape Determination</h3>
              <p>
                Body shape is typically determined by an individual's skeletal structure and body fat distribution. You can
                determine your body shape by measuring your body at specific points and calculating your waist-to-hip ratio.
                There are also online tools available that can help you determine your body shape based on your
                measurements.
              </p>
              <ol>
                <li><b>Rectangle:</b></li>

                <p>
                  If (hips - bust) &lt; 3.6" AND (bust - hips) &lt; 3.6" AND (bust - waist) &lt; 9" AND (hips - waist) &lt; 10" </p>
                This body shape has a similar measurement between the bust, waist, and hips, with the difference
                between the bust and hips and the hips and waist being less than 3.6 and 10 inches, respectively.
                The waist may not be very defined

                <li><b>Inverted triangle:</b></li>

                <p >
                  If (bust - hips) ≥ 3.6" AND (bust - waist) &lt; 9" </p>
                This body shape has a defined waist, but the bust is significantly larger than the hips. The
                difference between the bust and hips is at least 3.6 inches, and the difference between the
                bust and waist is less than 9 inches.

                <li><b>Triangle:</b></li>

                <p>
                  If (hips - bust) ≥ 3.6" AND (hips - waist) &lt; 9" </p>
                This body shape has a defined waist, but the hips are significantly larger than the
                bust. The difference between the hips and bust is at least 3.6 inches, and the
                difference between the hips and waist is less than 9 inches.

                <li><b>Spoon:</b></li>

                <p>
                  If (hips - bust) &gt; 2" AND (hips - waist) ≥ 7" AND (high hip/waist) ≥ 1.193
                </p>
                This body shape has a defined waist, but the hips are significantly larger than the
                bust. The difference between the hips and bust is at least 2 inches, and the high
                hip-to-waist ratio is 1.193 or higher.

                <li><b>Hourglass:</b></li>

                <p>
                  If (bust - hips) ≤ 1" AND (hips - bust) &lt; 3.6" AND (bust - waist) ≥ 9" OR (hips
                  - waist) ≥ 10" </p>
                This body shape is characterized by a well-defined waist and balanced
                proportions between the bust and hips. The difference between the bust and
                hips is less than or equal to 1 inch, and the difference between the hips
                and waist is at least 10 inches or more.

                <li><b>Top hourglass:</b></li>

                <p>
                  If (bust - hips) &gt; 1" AND (bust - hips) &lt; 10" AND (bust - waist) ≥ 9"
                </p>
                This body shape has similar measurements to the hourglass, but the
                bust is slightly larger than the hips.

                <li><b>Bottom hourglass:</b></li>

                <p>
                  If (hips - bust) ≥ 3.6" AND (hips - bust) &lt; 10" AND (hips -
                  waist) ≥ 9" AND (high hip/waist) &lt; 1.193 </p>
                This body shape has similar measurements to the hourglass,
                but the hips are slightly larger than the bust.
                Additionally, the high hip (the widest part of the hips) is
                less than 1.193 times the waist measurement.

              </ol>

              <h3>WHR and Health Risks.</h3>
              <p>
                Waist-hip ratio (WHR) is a measure of body fat distribution. It is calculated by dividing the measurement of
                the waist circumference by the measurement of the hip circumference. The waist circumference is measured at
                the narrowest point of the waist, usually just above the belly button, and the hip circumference is measured
                at the widest point of the hips.
              </p>
              <p>
                A high waist-hip ratio indicates that a person carries more fat around their waist, which is often referred
                to as "apple-shaped" body, while a low waist-hip ratio indicates that a person carries more fat around their
                hips and thighs, which is often referred to as "pear-shaped" body.
              </p>
              <p >
                Research has shown that a high waist-hip ratio is associated with an increased risk of many health problems,
                including cardiovascular disease, diabetes, and certain cancers. Therefore, it is recommended that
                individuals maintain a healthy waist-hip ratio as part of a healthy lifestyle. The ideal waist-hip ratio
                varies by gender, with a ratio of less than 0.90 for men and less than 0.85 for women considered healthy.

              </p>



            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BodyType
