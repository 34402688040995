import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
export const PersonalInfo = (props) => {
    const { current, user, setData } = props;
    const { genders, toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit = e => {
        e.preventDefault();
        dispatch(changePreloader({ status: true, message: 'Updating Additinal Details' }));
        const formData = new FormData(e.target);
        users.updateAdditionalDetails(formData).then(res => {
            setData(state => ({ ...state, additional_details: res.data.additional_details }))
            toast.success("Updated Successfully !")
            toggleModal(false);
        }).catch(e => toast.error(e.response.data.message))
            .finally(() => dispatch(changePreloader({ status: false, message: false })))
    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Personal Info</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' value={user?.id} name='user_id' />
                        <Row>
                        <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Whatsapp</Label>
                                    <Input name="whatsapp" className="form-control" placeholder="" type="name" defaultValue={current?.whatsapp} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> DOB</Label>
                                    <Input name="dob" className="form-control" placeholder="" type="date" defaultValue={current?.dob} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> Gender</Label>
                                    <Select className="basic-single" classNamePrefix="select" name="gender" options={genders} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Height</Label>
                                    <Input name="height" className="form-control" placeholder="" type="number" step={0.01} defaultValue={current?.height} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Weight</Label>
                                    <Input name="weight" className="form-control" placeholder="" type="number" step={0.01} defaultValue={current?.weight} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Aadhar</Label>
                                    <Input name="aadhaar" className="form-control" placeholder="" type="text" defaultValue={current?.aadhaar} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">PAN</Label>
                                    <Input name="pan" className="form-control" placeholder="" type="text" defaultValue={current?.pan} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Father name</Label>
                                    <Input name="father_name" className="form-control" placeholder="" type="text" defaultValue={current?.father_name} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Father phone</Label>
                                    <Input name="father_phone" className="form-control" placeholder="" type="text" defaultValue={current?.father_phone} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Father Aadhaar</Label>
                                    <Input name="father_aadhaar" className="form-control" placeholder="" type="text" defaultValue={current?.father_aadhaar} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Father Income (Yearly)</Label>
                                    <Input name="father_income" className="form-control" placeholder="" type="text" defaultValue={current?.father_income} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> Mother name</Label>
                                    <Input name="mother_name" className="form-control" placeholder="" type="text" defaultValue={current?.mother_name} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> Mother Phone</Label>
                                    <Input name="mother_phone" className="form-control" placeholder="" type="text" defaultValue={current?.mother_phone} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> Mother Aadhaar</Label>
                                    <Input name="mother_aadhaar" className="form-control" placeholder="" type="text" defaultValue={current?.mother_aadhaar} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label"> Mother Income (Yearly)</Label>
                                    <Input name="mother_income" className="form-control" placeholder="" type="text" defaultValue={current?.mother_icome} />
                                </div>
                            </Col>
                          
                        </Row>
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}
