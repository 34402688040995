import React from 'react';
import { Container, Row, Col, Form, Input, Button, Card, CardBody } from 'reactstrap';
import './slider_custom.css';

const NewsletterSubscription = () => {
  return (
    <div className="subscription-section" >
      <div className="overlay">
        <Container>
          <Card className='bg-dark '>
            <Row className='my-3'>
              <Col md="6" className="text-center text-md-left">
                <h3 className="mb-4 text-light fs-2">FOLLOW US</h3>
                <p className="text-light">
                  <b> Stay Fit and Stay Updated!</b>
                  Sign up for our newsletter to get the latest tips, fitness trends, and exclusive offers right in your inbox. Don’t forget to follow us on social media to join our fitness community and stay motivated every step of the way!
                </p>
                <div>
                  <Button color="primary" className="mx-2" outline>
                    <i className="fab fa-instagram" />
                  </Button>
                  <Button color="primary" className="mx-2" outline>
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button color="primary" className="mx-2" outline>
                    <i className="fab fa-youtube" />
                  </Button>
                </div>
              </Col>

              <Col md="6" className="mt-4 mt-md-0">
                <h3 className="mb-4 text-light text-center fs-2">Subscription</h3>
                <Form>
                  <div className='row'>

                    <div className='col-lg-8 col-10 mx-auto'>
                      <Input
                        type="email"
                        placeholder="Enter Your Email Address"
                        className="form-control  mb-3 "
                        style={{ borderRadius: '8px' }}
                      />
                      <div className="mb-3 ">
                        <Input
                          type="checkbox"
                          id="recaptcha"
                          className=" me-2 fs-5"
                        />
                        <label htmlFor="recaptcha" className="text-light fs-5">I'm not a robot</label>
                      </div>
                    </div>
                  </div>
                  <div className='row'>

                    <div className='text-center'>
                      <div className='col-lg-8 col-10 mx-auto'>
                        <Button type='button' color="primary" size="lg" block style={{ borderRadius: '8px' }}>
                          Subscribe
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>

            </Row>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default NewsletterSubscription;
