import { format } from 'date-fns'
import { AssetUrl, users } from 'helpers/api_url'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { Button, Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

function MakeAppointmentModal(appointmentModalData, toggleAppointmentModal, handleMakeAppointmentSubmit) {
    const authUser = useSelector(state => state.Login.authUser);
    const [profUsers, setProfUsers] = useState([]);
    useEffect(() => {
        if (appointmentModalData.status)
            users.proffessional().then(res => setProfUsers(res.data.users)).catch(error => console.warn(error));
    }, [appointmentModalData.status])
    return (
        <Modal centered={true} isOpen={appointmentModalData.status} toggle={toggleAppointmentModal}>
            <ModalHeader toggle={toggleAppointmentModal}>
                <span>New Appointment Details</span>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleMakeAppointmentSubmit}>
                    <input type='hidden' value={authUser?.id} name='user_id' />
                    <div className='mb-2'>
                        <Label htmlFor='prof_user_id' className="form-label">Select A Professional</Label>
                        <Card>
                            <ReactSelect
                                isSearchable
                                id='prof_user_id'
                                name='prof_user_id'
                                options={profUsers?.map(u => ({ value: u.id, label: `${u.first_name} ${u.last_name} (${u?.role?.name})` }))}
                            />
                        </Card>
                    </div>
                    <Row className='mb-2'>
                        <Col xs={6}>
                            <div>
                                <Label htmlFor='title' className="form-label">Title</Label>
                                <input type="text" className='form-control' name='title' id='title' />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div>
                                <Label htmlFor='date_of_apointment' className="form-label">Date of Apointment</Label>
                                <input
                                    defaultValue={appointmentModalData?.data?.date}
                                    type="date"
                                    className='form-control'
                                    name='date_of_apointment'
                                    id='date_of_apointment'
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="mb-3">
                        <Label htmlFor='description' className="form-label">Appointment Descriptions</Label>
                        <textarea className='form-control' name='description' id='description' rows={5}></textarea>
                    </div>
                    <div className="mb-3 center">
                        <Button type='submit' className='form-control bg-primary' >Add</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default MakeAppointmentModal