import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './slider_custom.css';

const ViewTimetable = () => {
    const schedule = [
        { time: "06.00 - 08.00", monday: "BOXING", instructorMon: "Professor Smith", tuesday: "BOXING", instructorTue: "Robert Cage", wednesday: "CARDIO", thursday: "BOXING", friday: "BOXING", saturday: "CRAZE", sunday: "BOXING", instructorSun: "Ms. Garcia" },
        { time: "08.00 - 10.00", monday: "", tuesday: "BOXING", instructorTue: "Robert Cage", wednesday: "", thursday: "BOXING", friday: "", saturday: "BOOTCAMP", sunday: "" },
        { time: "10.00 - 12.00", monday: "YOGA", instructorMon: "Robert Cage", tuesday: "", wednesday: "ZUMBA", instructorWed: "Mrs. Johnson", thursday: "BOXING", friday: "SCULPT", saturday: "", sunday: "" },
        { time: "12.00 - 02.00", monday: "", tuesday: "BOXING", instructorTue: "Robert Cage", wednesday: "", thursday: "BOXING", friday: "", saturday: "", sunday: "" },
        { time: "02.00 - 04.00", monday: "CARDIO", instructorMon: "Robert Cage", tuesday: "", wednesday: "", thursday: "", friday: "", saturday: "", sunday: "" },
    ];

    return (
        <React.Fragment>
            <section className="py-2 my-3 bg-dark px-5">
                <Container  fluid className="timetable_schedule bg-dark">
                <div className="text-center pt-4 mt-5">
                    <h3 className="small-title fs-1">Schedule Time Table</h3>
                </div>
                    <div className='d-none d-lg-block'>

                    <Row className="header_schedule d-flex align-items-stretch">
                        <Col className="time-col_schedule col">Time</Col>
                        <Col className="col">Monday</Col>
                        <Col className="col">Tuesday</Col>
                        <Col className="col">Wednesday</Col>
                        <Col className="col">Thursday</Col>
                        <Col className="col">Friday</Col>
                        <Col className="col">Saturday</Col>
                        <Col className="col">Sunday</Col>
                    </Row>

                    {schedule.map((slot, idx) => (
                        <Row className="schedule-row_schedule d-flex align-items-stretch" key={idx}>
                            <Col className="time-col_schedule col">{slot.time}</Col>
                            <Col className={`col ${slot.monday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.monday}<br /><span>{slot.instructorMon}</span></Col>
                            <Col className={`col ${slot.tuesday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.tuesday}<br /><span>{slot.instructorTue}</span></Col>
                            <Col className={`col ${slot.wednesday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.wednesday}<br /><span>{slot.instructorWed}</span></Col>
                            <Col className={`col ${slot.thursday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.thursday}<br /><span>{slot.instructorThu}</span></Col>
                            <Col className={`col ${slot.friday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.friday}<br /><span>{slot.instructorFri}</span></Col>
                            <Col className={`col ${slot.saturday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.saturday}<br /><span>{slot.instructorSat}</span></Col>
                            <Col className={`col ${slot.sunday ? 'active_schedule mx-2 text-center' : 'mx-2 text-center'}`}>{slot.sunday}<br /><span>{slot.instructorSun}</span></Col>
                        </Row>
                    ))}
                    </div>

                    <div className='d-block d-lg-none'>
                        {schedule.map((slot, idx) => (
                            <div key={idx} className="mb-3">
                                <Row className="header_schedule d-flex align-items-stretch text-white">
                                    <Col xs={4} className="time-col_schedule">Time</Col>
                                    <Col xs={8} className="time-value_schedule">{slot.time}</Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Monday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.monday ? 'active_schedule' : ''}`}>{slot.monday}<br /><span>{slot.instructorMon}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Tuesday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.tuesday ? 'active_schedule' : ''}`}>{slot.tuesday}<br /><span>{slot.instructorTue}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Wednesday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.wednesday ? 'active_schedule' : ''}`}>{slot.wednesday}<br /><span>{slot.instructorWed}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Thursday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.thursday ? 'active_schedule' : ''}`}>{slot.thursday}<br /><span>{slot.instructorThu}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Friday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.friday ? 'active_schedule' : ''}`}>{slot.friday}<br /><span>{slot.instructorFri}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Saturday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.saturday ? 'active_schedule' : ''}`}>{slot.saturday}<br /><span>{slot.instructorSat}</span></Col>
                                </Row>
                                <Row className="schedule-row_schedule text-white">
                                    <Col xs={4} className="day-label_schedule">Sunday</Col>
                                    <Col xs={8} className={`day-value_schedule text-center ${slot.sunday ? 'active_schedule' : ''}`}>{slot.sunday}<br /><span>{slot.instructorSun}</span></Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>  
        </React.Fragment>
    );
};

export default ViewTimetable;
