import React, { useEffect, useMemo, useState } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'
import { Card, CardBody, Container,Badge,Button, CardHeader } from 'reactstrap';
import BreadCrumb from 'components/Common/Breadcrumb';
import { users } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { UserCreate } from './UserCreate';
export const Community = () => {
    const [tableData,setTableData] = useState([]);
    useEffect(()=>{
        users.community()
        .then(r=>setTableData(r.data.users))
        .catch(e=>console.log(e))
    },[])
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Name", accessor: "first_name", HeaderClass: 'text-center', DataClass: 'text-center',Cell:cell=>`${cell.row.original.first_name } ${cell.row.original.last_name }` },
        { Header: "phone", accessor: "phone"},
        { Header: "email", accessor: "email"},
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                const row=cell.row.original;
              return ( 
                <div className="">
                    <Button onClick={()=>{toast.error('You are not Authorized to delete this user')}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.first_name} {row.last_name}</a> |    
                        </h5>
                        <p className="text-muted mb-0">
                        <span>{row.email}</span> | <Badge color="success" outline className="p-2 ms-1">{row.role.type} </Badge> </p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <Button onClick={()=>{toast.error('You are not Authorized to delete this user')}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Community List" prevPage="Community" prevPath="/Community" />
                    <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Community User</h5>
                            <UserCreate setTableData={setTableData} />
                        </CardHeader>
                        <CardBody>
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}
