import { format, parse } from 'date-fns';
import React, { useState } from 'react'
import { Col, Input, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap'

function ViewAvailabilityModal({ availability = [] }) {
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status)
    return (
        <>
            <button onClick={toggleModal} className='btn btn-outline-primary'>Availability</button>
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    <span>Availability</span>
                </ModalHeader>
                <ModalBody>
                    {availability?.length ? (
                        <Row>
                            <Col xs={12}>
                                <Table>
                                    <tbody>
                                        {availability?.map(day => (
                                            <tr key={day?.day}>
                                                <td className='fw-bold'>{day?.day.toUpperCase()}</td>
                                                <td>
                                                    {day.slot_data && JSON.parse(day?.slot_data)?.map(slot => (
                                                        <Row className='mb-2' key={slot.id}>
                                                            <Col xs={4}>
                                                                <Input disabled={true} className='form-control' type='text' placeholder='Slot Name' value={slot.slot_name?.toUpperCase()} />
                                                            </Col>
                                                            <Col xs={4}>
                                                                <Input disabled={true} className='form-control' type='text' value={slot.start_time && format(parse(slot.start_time,'HH:mm',new Date()),'hh:mm a')} />
                                                            </Col>
                                                            <Col xs={4}>
                                                                <Input disabled={true} className='form-control' type='text' value={slot.end_time && format(parse(slot.end_time,'HH:mm',new Date()),'hh:mm a')} />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    ):null}
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewAvailabilityModal