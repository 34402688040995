import React, { useEffect, useMemo, useState } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { exercise } from 'helpers/api_url';
import ViewExerciseModal from './ViewExerciseModal';
import { generateBadgeFromTextArray } from 'helpers/badgeGenerator';
import { useDispatch } from 'react-redux';
import {changePreloader} from 'store/layout/actions'
import { toast } from 'react-toastify';

function ExerciseList({tabNumber}) {
    const dispatch =useDispatch();
    const [tabledata,setTableData] = useState([]);
    useEffect(()=>{
        if(tabNumber==3){
            dispatch(changePreloader({status:true,message:'please wait'}));
            exercise.list().then(res=>setTableData(res.data.exercise)).catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=> dispatch(changePreloader({status:false,message:''})) );
        }
    },[tabNumber]);
    const handleDeleteExercise = id => exercise.delete(id).then(res=>setTableData(state=>state.filter(ex=>ex.id != id))).catch(err=>toast.error(err.response?err.response.data.message:err.message));
    const columns = useMemo(()=>[
        {Header: "Name",accessor: "exercise_name",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Area of Body Part",HeaderClass:'text-center', DataClass:'text-center',Cell:cell=>{
            const row = cell.row.original;
            return generateBadgeFromTextArray(JSON.parse(row.area_of_body_part),'bg-warning px-2 py-1');
        }},
        {Header: "Gravity",HeaderClass:'text-center', DataClass:'text-center',Cell:cell=>{
            const row = cell.row.original;
            return generateBadgeFromTextArray(JSON.parse(row.gravity),'bg-info px-2 py-1');
        }},
        {Header: "Muscle Size",accessor: "muscle_size",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Intensity / Mobility",accessor: "intensity_mobility",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Action",HeaderClass:'text-center', DataClass:'text-center', Cell:cell=>{
            const row = cell.row.original;
            return (
                <div>
                    <ViewExerciseModal exerciseData={row}>
                        <button className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
                    </ViewExerciseModal>
                    <button onClick={()=>handleDeleteExercise(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                    <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button>
                </div>
            );
            
        }},
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.exercise_name} </a> |    {generateBadgeFromTextArray(JSON.parse(row.area_of_body_part),'bg-warning px-2 py-1')}
                        </h5>
                        {/* <p className="text-muted mb-0">
                        <span>{row.email}</span> | <Badge color="success" outline className="p-2 ms-1">{row.role.type} </Badge> </p> */}
                    </div>
                    <div className="flex-shrink-0">
                    <ViewExerciseModal exerciseData={row}>
                        <button className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
                    </ViewExerciseModal>
                    <button onClick={()=>handleDeleteExercise(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                    {/* <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button> */}
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <Card>
            <CardHeader className="d-flex justify-content-between">
                <CardTitle>Exercise List</CardTitle>
            </CardHeader>
            <CardBody>
                <TableResponsive columns={columns} data={tabledata} noRecord="No Record List" />
            </CardBody>
        </Card>
    )
}

export default ExerciseList