import React from 'react'

export const Podcast = () => {
    return (
        <React.Fragment>
            <div>
                <h3 className="__title-section mt-3">Podcast</h3>
                <b>In-Depth Conversations with Experts</b>
                <p className='font-format fs-4 '>
                    Our podcast series, available on social media and major streaming platforms, features in-depth conversations with fitness experts, athletes, nutritionists, and wellness professionals. Each episode dives into topics ranging from personal fitness journeys to expert advice on training and health. Through these conversations, we offer our followers the chance to learn from industry leaders and get inspired by real-life stories of resilience and dedication.
                </p>
                <u><i><b><q>Best Users/Influencers Stories will invite to Our Podcast</q></b></i></u>
            </div>
        </React.Fragment>
    )
}
