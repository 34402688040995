import { timetables } from 'helpers/api_url';
import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, Label, Form } from 'reactstrap'
import { changePreloader } from 'store/actions';

function AddExerciseSchedule(props) {
    const {exScheduleList=[],user} = props;
    const { toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({status:true,message:'please wait'}));
        timetables.addSchedule(formData.get('schedule_id'),formData.get('user_id'))
        .then(res=>console.log(res))
        .catch(err=>toast.error(err.response?err.response.data.message:err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));
    }
    return (
        <>
            {props.children?(
                <span onClick={toggleModal}>
                    {props.children}
                </span>
            ):(
                <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            )}
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Schedule to  Time Table</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                        <div className="mb-3">
                            <Label className="form-label">Exercise Schedule</Label>
                            <ReactSelect name='schedule_id' options={exScheduleList.map(sch=>({label:sch.name,value:sch.id}))} />
                        </div>
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Add</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddExerciseSchedule