import React, { useState } from 'react'
import { Nav, NavItem, NavLink, Container, TabContent, TabPane, Card, CardBody, CardHeader, CardFooter, Row, Col } from 'reactstrap'
import BreadCrumb from "components/Common/Breadcrumb"
import Pills from './Pills'

function index() {
    const [activeStep,setActiveStep] = useState(1);
    const handleStepChange = (e) => setActiveStep(e);
    const gamesData = [
        { "name": "Cricket", "label": "cricket" },
        { "name": "Kabaddi", "label": "kabaddi" },
        { "name": "Hockey", "label": "hockey" },
        { "name": "Badminton", "label": "badminton" },
        { "name": "Chess", "label": "chess" },
        { "name": "Snooker", "label": "snooker" },
        { "name": "Kho Kho", "label": "kho_kho" },
        { "name": "Polo", "label": "polo" },
        { "name": "Pachisi", "label": "pachisi" },
        { "name": "Gilli Danda", "label": "gilli_danda" },
        { "name": "Carrom", "label": "carrom" },
        { "name": "Ludo", "label": "ludo" },
        { "name": "Langdi", "label": "langdi" },
        { "name": "Dhopkhel", "label": "dhopkhel" },
        { "name": "Kite Fighting", "label": "kite_fighting" },
        { "name": "Silambam", "label": "silambam" },
        { "name": "Thoda", "label": "thoda" },
        { "name": "Pulikkali", "label": "pulikkali" },
        { "name": "Thudangum Kali", "label": "thudangum_kali" },
        { "name": "Kalaripayattu", "label": "kalaripayattu" }
      ];      
    return (
        <>
        
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title="PARTICIPATOR GAMES"
                        prevPage="Dashboard"
                        prevPath="/dashboard"
                    />
                    <Card>
                        <CardHeader className='d-flex justify-content-center'>
                            <Nav variant="pills" className="">
                                <NavItem >
                                    <NavLink className='' id="activeStep1">
                                        <button disabled className={`btn ${activeStep === 1 ? 'btn-primary' : 'btn-dark'}`}>Intrested Sports</button>
                                    </NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink className='' id="activeStep2">
                                        <button disabled className={`btn ${activeStep === 2 ? 'btn-primary' : 'btn-dark'}`}>Intrested Sports</button>
                                    </NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink className='' id="activeStep2">
                                        <button disabled className={`btn ${activeStep === 3 ? 'btn-primary' : 'btn-dark'}`}>Sports Data</button>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={activeStep} className="">
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col xs={12} className='p-4 my-4 justify-content-center d-flex'>
                                            <div className='text-center' style={{maxWidth:'800px'}}>
                                                {gamesData.map((game,index)=>(<Pills key={index} text={game.name} />))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardFooter className='d-flex justify-content-between px-4'>
                                        <button disabled className='btn btn-info' onClick={()=>handleStepChange(1)}>Prev</button>
                                        <button className='btn btn-success' onClick={()=>handleStepChange(2)}>Next</button>
                                    </CardFooter>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row>
                                        <Col xs={12} className='p-4 my-4 justify-content-center d-flex'>
                                            <div className='text-center' style={{maxWidth:'800px'}}>
                                                {gamesData.map((game,index)=>(<Pills key={index} text={game.name} />))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardFooter className='d-flex justify-content-between px-4'>
                                        <button className='btn btn-info' onClick={()=>handleStepChange(1)}>Prev</button>
                                        <button className='btn btn-success' onClick={()=>handleStepChange(3)}>Next</button>
                                    </CardFooter>                                    
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Row>
                                        <Col xs={12}>
                                            Tab Three
                                        </Col>
                                    </Row>
                                    <CardFooter className='d-flex justify-content-between px-4'>
                                        <button className='btn btn-info' onClick={()=>handleStepChange(2)}>Prev</button>
                                        <button className='btn btn-success' onClick={()=>alert('fire handle save function')}>Save</button>
                                    </CardFooter>   
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                    
                </Container>

            </div>
        </>
    )
}

export default index