import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, CardHeader, CardFooter } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { changePreloader as setPreloader } from 'store/layout/actions'
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "assets/images/fw-logoold.png";
import logo from "assets/images/logo.svg";
import authOverlay from "assets/images/crousle.jpeg"
import Select from 'react-select';
import { register } from "helpers/api_url";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { loginUser } from "store/actions";

const Register = () => {
  //meta title
  document.titleallUserType = "Login | Fittestwarrior";
  const dispatch = useDispatch();
  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    role: '',
    user_category: '',
    password: '',
    c_password: '',
  }
  const validation = useFormik({
    initialValues,
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your first name"),
      last_name: Yup.string().required("Please Enter Your Last name"),
      phone: Yup.string().required("Please Enter your mobile number").matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      email: Yup.string().email('Invalid email address').required('Please Enter Your Email'),
      role: Yup.string().required("Please Select User Role"),
      user_category: Yup.string().required("Please Select User Category"),
      // password: Yup.number().required("Please Enter Your Password").min(8, "Password must be at least 8 characters long"),
      // c_password: Yup.string().required("Please Enter Your confirm Password").oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
  });
  const [allrole, setallrole] = useState([]);
  const [allUserType,setAllUserType]=useState([]);
  useEffect(() => {
    register.role().then(res =>
      setallrole(res.data.roles.reduce((acc, item) => {
        acc[item.role_type] = acc[item.role_type] ? [...acc[item.role_type], item] : [item];
        return acc;
      }, {}))
    ).catch(e => console.log(e));
  }, [])
  const handleUserType=(e)=>{
      setAllUserType(allrole[e.value])
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setPreloader({ status: true, message: "Please wait ..." }));
    const formdata = new FormData(e.target);
    register.add(formdata)
      .then((res) => {
        localStorage.setItem("authUser", JSON.stringify(res))
        localStorage.setItem("authToken", res._token)
        toast.success(res.message, { autoClose: 2000 });
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response ? err.response.data.message : err.message, { autoClose: 2000 })
      })
      .finally(()=>dispatch(setPreloader({ status: false, message: "" })));
  }
  console.log(allrole)
  return (
    <React.Fragment>
      <div className="account-pages bg-dark" style={{ overflow: 'hidden', width: '100vw', height: '100vh' }}>
        <Container fluid>
          <Row>
            <Col lg={8} className="d-none d-lg-block"></Col>
            <Col lg={4} className="pe-0 mt-5">
              <Card className="my-0" style={{ minHeight: "100vh" }}>
                <div className="bg-light mt-5">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Fittest Warrior-Sign up (FW)</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end py-1"><Link to="/"><img src={profile} alt="" className="" style={{ aspectRatio: 16 / 9, objectFit: 'content', height: '100px' }} /></Link></Col>
                  </Row>
                </div>
                <Form className="form-horizontal" autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  <CardBody className="pt-0 d-flex flex-column justify-content-center">
                    {/* <SimpleBar id="scrollbar" style={{ maxHeight: "calc(90vh - 240px)" }} > */}
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light"><img src={logo} alt="" className="rounded-circle" height="34" /></span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <div className="mb-3">
                          <Row>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">First Name</Label>
                              <Input name="first_name" className="form-control" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.first_name || ""} invalid={validation.touched.first_name && validation.errors.first_name ? true : false} />
                              {validation.touched.first_name && validation.errors.first_name ? (<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>) : null}
                            </Col>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">Last Name</Label>
                              <Input name="last_name" className="form-control" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.last_name || ""} invalid={validation.touched.last_name && validation.errors.last_name ? true : false} />
                              {validation.touched.last_name && validation.errors.last_name ? (<FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>) : null}
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">phone</Label>
                              <Input name="phone" className="form-control" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.phone || ""} invalid={validation.touched.phone && validation.errors.phone ? true : false} />
                              {validation.touched.phone && validation.errors.phone ? (<FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>) : null}
                            </Col>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">Email</Label>
                              <Input name="email" className="form-control" placeholder="Enter email" type="email" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} invalid={validation.touched.email && validation.errors.email ? true : false} />
                              {validation.touched.email && validation.errors.email ? (<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>) : null}
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col lg={6} xs={12}>
                              <Label className="form-label" htmlFor="">Role Category</Label>
                              <Select className="basic-single" classNamePrefix="select" name="" id="" options={Object.keys(allrole).map(item=>({label:item,value:item}))} onChange={(e)=>handleUserType(e)} />
                            </Col>
                            <Col lg={6} xs={12}>
                              <Label className="form-label" htmlFor="role">Role</Label>
                              <Select className="basic-single" classNamePrefix="select" name="role_id" id="role" options={allUserType.map(item=>({label:item.role_name,value:item.role_id}))} />
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">Password</Label>
                              <Input name="password" value={validation.values.password || ""} type="password" placeholder="Enter Password" onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.password && validation.errors.password ? true : false} />
                              {/* {validation.touched.password && validation.errors.password ? (<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>) : null} */}
                            </Col>
                            <Col lg={6} xs={12}>
                              <Label className="form-label">Confirm Password</Label>
                              <Input name="c_password" value={validation.values.c_password || ""} type="password" placeholder="" onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.c_password && validation.errors.c_password ? true : false} />
                              {/* {validation.touched.c_password && validation.errors.c_password ? (<FormFeedback type="invalid">{validation.errors.c_password}</FormFeedback>) : null} */}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    {/* </SimpleBar> */}
                  </CardBody>
                  <CardFooter>
                    <div className="mt-0 text-center">
                      <div className="mb-2 d-grid"><button className="btn btn-primary btn-block" type="submit">Register</button></div>
                      <p> Don&#39;t have an account ?{" "}<Link to="/login" className="fw-medium text-primary">{" "}SignIn now{" "}</Link>{" "}</p>
                      <p> © {new Date().getFullYear()} Fittestwarrior . Crafted with{" "}<i className="mdi mdi-heart text-danger" /> by Our Team</p>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;


