import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: null,
  authUser:null,
  token:null,
  success:null,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        error:"",
        loading: false,
        isUserLogout: false,
        success:true,
        authUser:action.payload.data.user,
        token:action.payload._token
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = {
        error: "",
        loading: false,
        authUser:null,
        token:null,
        isUserLogout: true,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false,isUserLogout: false, }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
