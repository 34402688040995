import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import './Partner.css'
export const Partner = () => {
    return (
        <React.Fragment>
            <div className='page-content mt-5'>
                <Container className='mt-5'>
                    <Row className="mt-5">
                    <Row className='mt-3'>
                        <Col lg={6}>
                            <h3 className='__title-section '>Partner & Barter</h3>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex">
                                <Input type="text" className="form-control me-2" placeholder="Search..." />
                                <Button>Apply</Button>
                            </div>
                        </Col>
                    </Row>
                        <p className='font-format fs-3'>
                            <b>Cost-Efficient Resource Sharing: </b>Barter arrangements allow for exchange of services or products without heavy financial investment, maximizing ROI on resources and reducing marketing spend.
                        </p>
                        <p className='font-format fs-3'>
                            <b>Strategic Alliances for Expansion:  </b>By partnering with FittestWarrior, your brand gains direct access to our extensive network, fostering collaborations that can open new markets and customer segments.
                        </p>
                        <p className='font-format fs-3'>
                            <b>Enhanced Brand Equity through Collaboration:   </b>Partnerships create shared value, associating your brand with Fittest Warrior’s mission, which can improve customer trust and brand loyalty among health-conscious consumers.
                        </p>
                        <p className='font-format fs-3'>
                            <b>Collaborative Opportunities: </b>Partnering with FittestWarrior offers access to a wide-reaching community and mutually beneficial arrangements, including resource sharing and event collaboration.
                        </p>
                        <p className='font-format fs-3'>
                            <b>Barter Arrangements: </b>Leverage barter options to exchange services, products, or marketing resources, fostering partnerships that benefit both FittestWarrior and your brand.
                        </p>
                        <p className='font-format fs-3'>
                            <b>Community Synergy: </b>With our platform as a partner, your brand can access new markets, enhance its presence in the sports and fitness industry, and engage with a passionate community.
                        </p>
                    </Row>
                    <hr/>
                    <p className='font-format fs-3'>
                     We highlights the profit potential and strategic advantages for investors, positioning each investment pathway as a valuable opportunity for growth, visibility, and long-term success with FittestWarrior. Let me know if there are any additional points you’d like included!
                    <Button color='primary' className='mx-3'>contact Us</Button>
                    </p>
                </Container>
            </div>
        </React.Fragment>
    )
}
