import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import classname from "classnames";
const MenuBar = (props) => {
  const [activeMenu, setActiveMenu] = useState(null);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll("#navigation a");
    navLinks.forEach((link) => {link.classList.remove("active");link.closest("li")?.classList.remove("active");});
    const activeLink = Array.from(navLinks).find((link) => link.pathname === currentPath);
    if (activeLink) {
      activeLink.classList.add("active");
      let parent = activeLink.closest("li");
      while (parent) { parent.classList.add("active"); parent = parent.parentElement.closest("li");}
    }
  }, []);
  const toggleMenu = (menu) => {setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));};
  return (
    <React.Fragment>
      <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle arrow-none"onClick={(e) => {e.preventDefault();toggleMenu("dashboard");}}to="/">
          <i className="bx bx-home-circle me-2"></i>{props.t("Know Us")}
          <div className="arrow-down"></div>
        </Link>
        <div className={classname("dropdown-menu", { show: activeMenu === "dashboard" })}>
          <Link to="/aboutus" className="dropdown-item">{props.t("Who We Are")}</Link>
          <Link to="/teams" className="dropdown-item">{props.t("Our Team")}</Link>
          <Link to="/mission" className="dropdown-item">{props.t("Mission & Vision")}</Link>
          <Link to="/ecosystem" className="dropdown-item">{props.t("Eco System")}</Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle arrow-none" onClick={(e) => {e.preventDefault();toggleMenu("investors");}} to="/">
          <i className="bx bx-customize me-2"></i>{props.t("Investors")}
          <div className="arrow-down"></div>
        </Link>
        <div className={classname("dropdown-menu", { show: activeMenu === "investors" })}>
          <Link to="/investor" className="dropdown-item">{props.t("Invest With Us")}</Link>
          <Link to="/scholarship" className="dropdown-item">{props.t("Scholarship")}</Link>
          <Link to="/sponsorship" className="dropdown-item">{props.t("Sponsorship")}</Link>
          <Link to="/promotion" className="dropdown-item">{props.t("Promotion & Branding")}</Link>
          <Link to="/partner" className="dropdown-item">{props.t("Partner & Barter")}</Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle arrow-none"onClick={(e) => {e.preventDefault();toggleMenu("health");}}to="/">
          <i className="bx bx-customize me-2"></i>{props.t("Health & Fitness")}
          <div className="arrow-down"></div>
        </Link>
        <div className={classname("dropdown-menu", { show: activeMenu === "health" })}>
          <Link to="/exercise" className="dropdown-item">{props.t("Exercises")}</Link>
          <Link to="/diet" className="dropdown-item">{props.t("Diets")}</Link>
          <Link to="/time-table" className="dropdown-item"> {props.t("Time Table")}</Link>
          <Link to="/calculator-page" className="dropdown-item">{props.t("Fitness Calculator")}</Link>
          <Link to="/self-assessement" className="dropdown-item" >{props.t("Self-Assessment")}</Link>
          <Link to="/Fitness-Test" className="dropdown-item" >{props.t("Fitness Test")}</Link>
          <Link to="/program-trainer" className="dropdown-item" > {props.t("Training/Coaching")}</Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link className="nav-link dropdown-toggle arrow-none"onClick={(e) => {e.preventDefault();toggleMenu("Resources");}}to="/">
          <i className="bx bx-customize me-2"></i>{props.t("Resources")}
          <div className="arrow-down"></div>
        </Link>
        <div className={classname("dropdown-menu", { show: activeMenu === "Resources" })}>
        <Link to="https://jobs.fittestwarrior.com/" target="__blank" className="dropdown-item" >{props.t("Jobs")}</Link>
          <Link to="https://blogs.fittestwarrior.com/" target="__blank" className="dropdown-item" >{props.t("Blogs")} </Link>
          <Link to="/consultancy-trainer" className="dropdown-item" >{props.t("Consultancy")} </Link>
          <Link to="https://Community.fittestwarrior.com/" target="__blank" className="dropdown-item" >{props.t("Community Networking")}</Link>
          <Link to="/promotion" className="dropdown-item" > {props.t("Promotion & Branding")}</Link>
          <Link to="/scholarship" className="dropdown-item" > {props.t("Scholarship & Sponsorship")}</Link>
          <Link to="/dt-lock" className="dropdown-item" > {props.t("Gym Management")}</Link>
          <Link to="/dt-lock" className="dropdown-item" > {props.t("Live Broadcast")}</Link>
          <Link to="/dt-lock" className="dropdown-item" > {props.t("Education")}</Link>
          <Link to="https://Events.fittestwarrior.com/" target="__blank" className="dropdown-item" > {props.t("Events")}</Link>
          <Link to="/dt-lock" className="dropdown-item" > {props.t("Competition")}</Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        <Link to="/stories-view" className="nav-link">
          <i className="bx bx-customize me-2"></i>{props.t("Social & Media")}
        </Link>
      </li>
      <li className="nav-item dropdown">
        <Link to="/dt-lock" className="nav-link">
          <i className="bx bx-customize me-2"></i>{props.t("Hysportx")}
        </Link>
      </li>
      <li className="nav-item dropdown"><Link to="https://Events.fittestwarrior.com/" target="__blank" className="nav-link dropdown-togglez" ><i className="bx bx-customize me-2"></i>{props.t("Events")}</Link></li>
      <li className="nav-item dropdown"><Link to="https://blogs.fittestwarrior.com/" target="__blank" className="nav-link dropdown-togglez" ><i className="bx bx-customize me-2"></i>{props.t("Blogs")}</Link></li>  
      <li className="nav-item dropdown"><Link to="https://gym.fittestwarrior.com/" target="__blank" className="nav-link dropdown-togglez" ><i className="bx bx-customize me-2"></i>{props.t("Gym Managemant")}</Link></li>  
    </React.Fragment>
  );
};
MenuBar.propTypes = {t: PropTypes.func.isRequired,};
const mapStateToProps = (state) => ({leftMenu: state.Layout.leftMenu,});
export default withRouter(connect(mapStateToProps)(withTranslation()(MenuBar)));
