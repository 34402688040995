import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter, Input, Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import classNames from "classnames";



function BodySurfaceArea() {

  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [unitSystem, setUnitSystem] = useState("metric");
  const [bsa, setBsa] = useState("");

  const [activeTab, setactiveTab] = useState("1");
  const toggle = tab => { if (activeTab !== tab) { setactiveTab(tab); } };
  const calculateBsa = () => {
    let weightInKg, heightInCm;

    if (unitSystem === "metric") {
      weightInKg = parseFloat(weight);
      heightInCm = parseFloat(height);
    } else {
      weightInKg = parseFloat(weight) / 2.20462; // Convert lbs to kg
      heightInCm = parseFloat(height) * 2.54; // Convert inches to cm
    }

    // Calculate BSA using the Dubois formula
    const bsaValue = Math.sqrt((weightInKg * heightInCm) / 3600);
    return bsaValue.toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const calculatedBsa = calculateBsa();
    setBsa(calculatedBsa);
  };

  const handleUnitSystemChange = (system) => {
    setUnitSystem(system);
    setWeight("");
    setHeight("");
    setBsa("");
  };

  return (
    <React.Fragment>
      <Breadcrumbs title="Body Surface Area Calculator" breadcrumbItem="Dashboard" />
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader>
                <h3>Result</h3>
                </CardHeader>
              <CardTitle className="my-2">
                {bsa !== "" && (
                  <div>
                    <p>
                      Body Surface Area: {bsa}{" "}
                      {unitSystem === "metric" ? "m²" : "ft²"}
                    </p>
                  </div>
                )}
              </CardTitle>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <CardHeader>
              <CardTitle>
                <h3>Body Surface Area Calculator</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <h2>Body Surface Area Calculator</h2>
               
                <Nav tabs>
                  <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }}>Metric Units</NavLink></NavItem>
                  <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }}>US Units</NavLink></NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <label> Weight (kg)</label>
                    <Input type="number"value={weight} onChange={(e) => setWeight(e.target.value)}required/>
                  </div>
                  <div className="my-2">
                    <label>Height (cm):</label>
                    <Input type="number"value={height} onChange={(e) => setHeight(e.target.value)}required />
                  </div>
                  <Button className="bg-success" type="submit">Calculate</Button>
                </form>
                </TabPane>
                <TabPane tabId="2">
                <form onSubmit={handleSubmit}>
                  <div className="my-2">
                    <label>Weight (lbs):</label>
                    <Input type="number"value={weight} onChange={(e) => setWeight(e.target.value)}required/>
                  </div>
                  <div className="my-2">
                    <label> Height (in)</label>
                    <Input type="number" value={height} onChange={(e) => setHeight(e.target.value)} required />
                  </div>
                  <Button className="bg-success" type="submit">Calculate</Button>
                </form>
                </TabPane>
                </TabContent>
              </div>
            </CardBody>
            <CardBody>
              <Card>
                <p>
                  BSA (m<sup>²</sup>) = 0.007184 x (height in cm)<sup>0.725</sup> x (weight in kg)<sup>0.425</sup>
                  <br /><br />
                  Here's an example of how to use the formula:
                  <br />
                  <br />
                  <p>Let's say you are 170 cm tall and weigh 70 kg.</p>
                  BSA = 0.007184 x (170)<sup>0.725</sup> x (70)<sup>0.425</sup>

                  = 1.8 m² (rounded to one decimal place)
                </p>

                <h3>Average BSA Table</h3>
                <p>
                  A table of average body surface areas (BSAs) for different age groups and genders.<br /><br />
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Age Group/Gender</th>
                      <th>Average BSA (ft<sup>2</sup>)</th>
                      <th>Average BSA (m<sup>2</sup>)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Newborn child</td>
                      <td>2.69</td>
                      <td>0.25</td>
                    </tr>
                    <tr>
                      <td>Two-year-old child</td>
                      <td>5.38</td>
                      <td>0.5</td>
                    </tr>
                    <tr>
                      <td>Ten-year-old child</td>
                      <td>12.27</td>
                      <td>1.14</td>
                    </tr>
                    <tr>
                      <td>Adult female</td>
                      <td>17.22</td>
                      <td>1.6</td>
                    </tr>
                    <tr>
                      <td>Adult male</td>
                      <td>20.45</td>
                      <td>1.9</td>
                    </tr>
                  </tbody>
                </table>

                <br /><br />

                <h3>Du Bois formula</h3>
                The Du Bois formula is a method used to estimate the body surface area (BSA) of an individual based on their weight (W) and height (H). The formula is:

                <br /><br />
                <h3>Haycock formula for BSA</h3>
                <p>
                  The Haycock formula is a mathematical equation used to estimate the body surface area (BSA) of a human being based on their weight (W) and height (H). The formula is as follows:
                </p>

                <h3>Boyd formula for BSA</h3>
                <p>
                  The Boyd formula is used to estimate the body surface area (BSA) of an individual based on their weight (W) and height (H).
                </p>
                The formula is as follows:
                <p>
                  BSA = 0.03330 × W(0.6157) × H(0.3 - 0.0188 × log10(W))
                </p>

                <h3>Fujimoto formula for BSA.</h3>
                <p>
                  The Fujimoto formula is a mathematical formula used to estimate the body surface area (BSA) of an individual based on their weight (W) and height (H).
                </p>
                <p >
                  BSA = 0.008883 × W<sup>0.444</sup> × H<sup>0.663</sup>
                </p>


                <h3>Takahira formula for BSA</h3>
                The Takahira formula is a mathematical formula used to estimate the body surface area (BSA) of an individual based on their weight (W) and height (H).

                The formula is as follows:
                <p>
                  BSA = 0.007241 × W<sup>0.425</sup> × H<sup>0.725</sup>
                </p>



                <h3>Schlich formula for BSA</h3>
                The Schlich formula is a method for calculating the body surface area (BSA) of a person based on their weight (W) in kilograms and height (H) in centimeters. <br /><br />

                The formula is as follows:

                <ul>
                  <li>Women BSA = 0.000975482 × W<sup>0.46</sup> × H<sup>1.08</sup></li>
                  <li>Men BSA = 0.000579479 × W<sup>0.38</sup> × H<sup>1.24</sup></li>
                </ul>













              </Card>
            </CardBody>


          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BodySurfaceArea
