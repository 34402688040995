import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function BankAccountModel({user,current=null,setData}) {
    const { genders, toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit=(e)=>{
        dispatch(changePreloader({status:true,message:'Updating Address'}));
        e.preventDefault();
        const formData = new FormData(e.target);    
        users.updateBankAccount(formData).then(res=>{
            setData(state=>({...state,bank_account:[res.data.bankaccount]}))
            toast.success("Update Successfully !")
            toggleModal(false);
        }).catch(e=>toast.error(e.response.data.message))
        .finally(()=>dispatch(changePreloader({status:false,message:false})))

    }
    return (
        <>
             <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Bank Account</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                        
                        <div className="mb-3">
                            <Label className="form-label">Account Holder</Label>
                            <Input className='form-control' name="account_name" rows={2} defaultValue={current?.account_name} />
                        </div>
                        <Row>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Bank Name</Label>
                                    <Input name="bank_name" className="form-control" defaultValue={current?.bank_name} placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">IFSC</Label>
                                    <Input name="ifsc_code" className="form-control" defaultValue={current?.ifsc_code} placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Account Number</Label>
                                    <Input name="account_number" className="form-control" defaultValue={current?.account_number} placeholder="" type="text" />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">UPI Account Holder</Label>
                                    <Input name="upi_account_name" className="form-control" defaultValue={current?.upi_account_name} placeholder="" type="text"  />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">UPI ID</Label>
                                    <Input name="upi_id" className="form-control" defaultValue={current?.upi_id} placeholder="" type="text"  />
                                </div>
                            </Col>
                        </Row>
                       
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BankAccountModel