import React from 'react'

export const Networking = () => {
  return (
    <React.Fragment>
            <div>
                <h3 className="__title-section mt-3">Networking</h3>
                <b>Connecting the Sports, Health and Fitness Community</b>
                <p className='font-format fs-4 '>
                Our social media platforms serve as a hub for users to connect with like-minded fitness enthusiasts, trainers, and experts. Through networking events, group discussions, and follower shout-outs, we encourage users to engage, share, and build relationships. This aspect of our social media strategy fosters a strong sense of community, offering opportunities to find workout partners, mentors, and even potential collaborators. “Register”
                </p>
               
            </div>
    </React.Fragment>
  )
}
