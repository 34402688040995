import React from 'react'
import {  Col, Input, Label, Row } from 'reactstrap'
export const CompanyRegstration = () => {  
    return (
        <React.Fragment>
                <Row className="mt-3">
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="registration_date">Register Date</Label>
                        <Input type="date" name="registration_date" id="registration_date"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="registration_place">Register Place</Label>
                        <textarea className='form-control' rows={2} type="text" name='registration_place' id='registration_place'></textarea>
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="gross_annual_income">Annual Income</Label>
                        <div className="input-group mb-3">
                            <Input type="number" name="gross_annual_income" id="gross_annual_income" className="form-control" />
                                <span className="input-group-text">$</span>
                        </div>
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="latest_worth_rupees">Latest Income</Label>
                        <div className="input-group mb-3">
                            <Input type="number" name="latest_worth_rupees" id="latest_worth_rupees" className="form-control" />
                                <span className="input-group-text">Rs.</span>
                        </div>
                    </Col>
                    
                </Row>
        </React.Fragment>
    )
}
