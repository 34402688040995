import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Mission.css'; // Custom CSS file
import Header from '../Header';
import image1 from '../../../assets/images/mission/image1.png'
import image2 from '../../../assets/images/mission/vision.png'
const Mission = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="page-content mt-5">
        <section className="__mission-section">
          <Container>
            {/* Heading */}
            <Row className="text-center mb-4">
              <Col>
                <h2 className="__mission-title">
                  We are on a <span className="__mission-highlight">mission</span>
                </h2>
                <h4><b>At Fittest Warrior,</b></h4>
                <p className="font-format __mission-subtitle">
                We’re redefining the sports, health, and fitness landscape by building a dynamic, inclusive Sport Ecosystem. Our mission is to provide a platform that welcomes participants from every background—whether they are budding enthusiasts or seasoned professionals. We aim to foster talent, drive participation, and create opportunities for meaningful connections that inspire and elevate all involved. <br /><br />

                We don’t just promote physical strength and agility; we nurture a community where athletes, coaches, enthusiasts, and organizations come together to share knowledge, resources, and support. Through this ecosystem, we’re committed to helping India unlock the hidden potential within the unorganized sports and fitness sectors, with the vision of preparing athletes for excellence on international platforms.

                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="10">
                <div className="__mission-img-wrapper">
                  <img
                    src={image1}
                    alt="Fit Buds Mission"
                    className="img-fluid rounded"

                  />
                </div>
              </Col>
            </Row>
            <Row className="text-center my-4">
              <Col>
                <h2 className="__mission-title">
                  We are on a <span className="__mission-highlight">Our Vision</span>
                </h2>
                <p className="font-format __mission-subtitle">
                Our vision is bold and transformative: to establish a new era in sports, health and fitness through <b>“HYSPORTX” </b>(Hybrid Sports Exercises) — an innovative fusion of hybrid sports exercises, challenges, and competitions. HYSPORTX will bring an exciting new category to the sports world, featuring national and international games, leagues inspired by popular events like Pro Kabaddi, IPL, HIL and ISL. <br /> <br />

                Through <b>HYSPORTX,</b> we aim to build a comprehensive, web-based platform that integrates all aspects of native and unorganized sports. This unique approach will reach every corner of India, helping to discover and develop talented athletes ready to compete at the highest levels. Our goal is to not only elevate the profile of hybrid sports exercises but also to foster community engagement and enthusiasm across diverse regions.

                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="10">
                <div className="__mission-img-wrapper">
                  <img
                    src={image2}
                    alt="Fit Buds Mission"
                    className="img-fluid rounded"

                  />
                </div>
              </Col>
            </Row>
          </Container>

        </section>
      </div>
    </React.Fragment>
  );
};

export default Mission;
