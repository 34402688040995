import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';
import ReactSelect from "react-select";
import { exercise } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';

function AddCategoryModal({ setCategories }) {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    const handleAddCategory = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        exercise.addCategory(formData)
            .then(res => { setCategories(state => state.filter(i => i.id == res.data.category.id)?.length ? state : [res.data.category, ...state]); toggleModal(); toast.success(res.message) })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message))
            .finally(() =>
                dispatch(changePreloader({ status: false, message: '' })));
    }
    const categoryTitles = [
        { value: "Muscle Group", label: "Muscle Group" },
        { value: "Experience Level", label: "Experience Level" },
        { value: "Excercise Type", label: "Excercise Type" },
        { value: "Equipments", label: "Equipments" },
    ]
    return (
        <>
            <button onClick={() => toggleModal()} className="btn btn-sm btn-info me-2"><i className="mdi mdi-plus"> Add Category</i></button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Add Category</h5></ModalHeader>
                <form onSubmit={handleAddCategory}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                               
                                    <div className="mb-2">
                                        <label className="control-label"> Category Title </label>
                                        <Card>
                                            <ReactSelect id="specialty" name="category_title" options={categoryTitles} />
                                        </Card>
                                    </div>
                              
                            </Col>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Category</label>
                                    <Input className="form-control" name="category_name" />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddCategoryModal