import classNames from 'classnames'
import BmiBmrBac from 'pages/Public/Calculators/BmiBmrBac'
import BodyFatCalculator from 'pages/Public/Calculators/BodyFatCalculator'
import CalorieCalculator from 'pages/Public/Calculators/CalorieCalculator'
import DueDate from 'pages/Public/Calculators/DueDate'
import GfrCalculator from 'pages/Public/Calculators/GfrCalculator'
import NutritionCalculator from 'pages/Public/Calculators/NutritionCalculator'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Card, CardBody, Col, Collapse, Form, Input, Nav, NavItem, Row } from 'reactstrap'
import calimg from '../../../assets/images/calculator/TG-Fitness-Age-Calculator.png'
export const FitnessCalculator = () => {
    const [activeTab, setActiveTab] = useState(1);
    const toggleTab = (tab) => { activeTab !== tab && setActiveTab(tab); }
    const TabData = [
        { tabname: 'BMI / BMR / BAC', tabcomponent: BmiBmrBac, publicdata: true },
        { tabname: 'Calorie Goal / TDEE', tabcomponent: CalorieCalculator, publicdata: true },
        { tabname: 'GFR', tabcomponent: GfrCalculator, publicdata: true },
        { tabname: 'Body Fat', tabcomponent: BodyFatCalculator, publicdata: true },
        { tabname: 'Nutrition', tabcomponent: NutritionCalculator, publicdata: true },
    ]
    return (
        <React.Fragment>
            <div className="text-center mt-3" style={{ overflowX: "hidden", }}>
                <h3 className="small-title fs-1">Calculators</h3>
                <Row className='bg-light' >

                    <Col lg={4} className='ms-lg-5 d-none d-lg-block'>
                        {React.createElement(TabData[activeTab - 1].tabcomponent, TabData[activeTab - 1].publicdata ? { publicdata: TabData[activeTab - 1].publicdata } : {})}
                    </Col>
                    <Col lg={3} className=''>
                        <Card className='my-3' style={{ height: '450px' }}>
                            <CardBody>
                                <div className="wizard">
                                    <div className="steps">
                                        <Nav vertical>
                                            {TabData.map((item, idx) => (
                                                <>
                                                    <NavItem key={idx} className={classNames({ current: activeTab === idx + 1, 'mt-3': true })} onClick={() => { toggleTab(idx + 1) }}>
                                                        <NavLink  >
                                                            <div className="row" >
                                                                <div className="col-10 text-start"><span className="number">{idx + 1}.</span> {item.tabname} </div>
                                                                <div className="col-2 text-end d-lg-none"><i className={activeTab === idx + 1 ? "mdi mdi-minus me-1 fs-4 fw-1" : "mdi mdi-plus me-1 fs-4 fw-1"} /></div>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <div className='d-lg-none d-block'>
                                                        <Collapse isOpen={activeTab === idx + 1}>{React.createElement(item.tabcomponent, TabData[activeTab - 1].publicdata ? { publicdata: TabData[activeTab - 1].publicdata } : {})}</Collapse>
                                                    </div>
                                                </>
                                            ))}
                                        </Nav>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} className='d-none d-lg-block'>
                        <Card className='mt-3' style={{ height: '450px', }}>
                            <CardBody>
                                <h3 className='text-center'>Contact Us</h3>
                                <Form>
                                    <div className='row'>
                                        <div className='col-lg-6 mx-auto'>
                                            <Input type="text" name='name' placeholder="Enter Your Name" className="form-control  mb-3" />
                                        </div>
                                        <div className='col-lg-6 mx-auto'>
                                            <Input type="number" name='mobile' placeholder="Enter Your Mobile" className="form-control  mb-3" />
                                        </div>
                                    </div>
                                    <div className='mx-auto'>
                                        <Input type="email" name='email' placeholder="Enter Your Email Address" className="form-control  mb-3" />
                                    </div>
                                    <div className='mx-auto'>
                                        <textarea className='form-control mb-3' name='message' rows={5} placeholder='Type your any Enquiry...'></textarea>
                                    </div>
                                    <div className='text-center'>
                                        <div className='mx-auto'>
                                            <Button type='button' color="primary" size="lg" block style={{ borderRadius: '8px' }}>Submit</Button>
                                        </div>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
              
            </div>
              <Row>
              <Col lg={4} className='d-block d-lg-none'>
                        <Card className='mt-3' >
                            <CardBody>
                                <h3 className='text-center'>Contact Us</h3>
                                <Form>
                                    <div className='row'>
                                        <div className='col-lg-6 mx-auto'>
                                            <Input type="text" name='name' placeholder="Enter Your Name" className="form-control  mb-3" />
                                        </div>
                                        <div className='col-lg-6 mx-auto'>
                                            <Input type="number" name='mobile' placeholder="Enter Your Mobile" className="form-control  mb-3" />
                                        </div>
                                    </div>
                                    <div className='mx-auto'>
                                        <Input type="email" name='email' placeholder="Enter Your Email Address" className="form-control  mb-3" />
                                    </div>
                                    <div className='mx-auto'>
                                        <textarea className='form-control mb-3' name='message' rows={5} placeholder='Type your any Enquiry...'></textarea>
                                    </div>
                                    <div className='text-center'>
                                        <div className='mx-auto'>
                                            <Button type='button' color="primary" size="lg" block style={{ borderRadius: '8px' }}>Submit</Button>
                                        </div>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
              </Row>
        </React.Fragment>
    )
}
