import { AssessmentApi } from 'helpers/api_url';
import React, { useEffect} from 'react'
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { UpdateQuestion } from '../UpdateQuestion';

export const ExerciseHistory = (props) => {
    useEffect(() => {
        AssessmentApi.allQuestion()
            .then(r => props.setTableData(r.data.questions))
            .catch(e => console.log(e))
    }, [])
    const handleDelete = (id) => {AssessmentApi.deleteQuestion(id).then(res => { props.setTableData([...props.tableData.filter(td => td.id != id)]) }).catch((err) => toast.error(err.response ? err.response.data.message : err.message))}
  return (
    <React.Fragment>
         <ul className="list-group">
                    {
                        props.tableData.filter(i => i.type === props.type).map((item, idx) => (
                            <li className='list-group-item' key={idx}>
                                <Row>
                                    <Col xs={1}><span className='fw-bold'>{idx + 1}.</span></Col>
                                    <Col xs={8}><span className='fw-bold'><b>{item.question}</b></span></Col>
                                    <Col className='text-start' xs={3}>
                                    <div>
                                    <UpdateQuestion type={props.type} item={item.question} ques_id={item.id} tableData={props.tableData} setTableData={props.setTableData}/>
                                    <Button onClick={()=>{handleDelete(item.id)}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                                    </div>
                                    </Col>
                                </Row>
                            </li>
                        ))
                    }
                </ul>
    </React.Fragment>
  )
}
