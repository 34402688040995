import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

export const ViewMeal = ({meals}) => {
    const {status, toggleModal}=useModalHandler();
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                <i className="mdi mdi-eye-outline" />
            </button>

            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>View Meal/Diet</ModalHeader>
                <ModalBody>
                    <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            {/* <ul className="list-group">
                                <li className='list-group-item bg-light' >
                                    <Row>
                                        <Col xs={4}><span className='fw-bold'>Diet Name </span> <br /> {meals.name}</Col>
                                        <Col className='' xs={4}><span className='fw-bold'>Receipe Type</span><br />{meals.type}</Col>
                                        <Col xs={4}><span className='fw-bold text-start'> Total Quantity <br />{meals.quantity}</span></Col>
                                    </Row>
                                </li>
                            </ul> */}
                            <table className='table table-bordered mt-3'>
                                <thead>
                                    <tr>
                                        
                                        <th>Name</th>
                                        <th>Carbohydrate</th>
                                        <th>Protein</th>
                                        <th>Fat</th>
                                        <th>Calorie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                            <tr > 
                                                <td>{meals.name}</td>
                                                <td>{meals.carbohydrate}</td>
                                                <td>{meals.protein}</td>
                                                <td>{meals.fat}</td>
                                                <td>{meals.carbohydrate*4+meals.protein*4+meals.fat*9}</td>
                                            </tr>
                                    

                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}
