import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, CardImg} from 'reactstrap';
export const KycProfile = () => {
  const [profileImage, setProfileImage] = useState(null); 
  const fileInputRef = useRef(null); 
  const handleImageChange = (e) => {
    const file = e.target.files[0]; 
    if (file) {const imageUrl = URL.createObjectURL(file);setProfileImage(imageUrl);}};
    const handleButtonClick = () => {fileInputRef.current.click(); };
  return (
    <React.Fragment>
      <Card className="text-center mt-4" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <CardBody>
          <div className="position-relative">
            <CardImg top src={profileImage || 'https://via.placeholder.com/150'}  alt="Profile" className="rounded-circle mb-3"style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
            <input type="file" name="passport_photo" id="passport_photo" accept="image/*"  ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageChange} />
          </div>
          <Button color="info" outline onClick={handleButtonClick}> Upload Photo </Button>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
