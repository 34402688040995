import React, { useState } from 'react'
import { Card, CardBody, Col, Collapse, Container, Nav, NavItem, Row, TabContent, TabPane, Toast, ToastBody, ToastHeader } from 'reactstrap'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { StoriesData } from './StoriesData';
import { Networking } from './Networking';
import { Knowledege } from './Knowledege';
import { Podcast } from './Podcast';
import { Media } from './Media';

const MultiStories = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [toast, setToast] = useState(true);
    const toggleToast = () => {setToast(!toast);};
  
    const toggleTab = (tab) => { activeTab !== tab && setActiveTab(tab); }
    const TabData = [
        { tabname: 'Stories', tabcomponent: StoriesData },
        { tabname: 'Networking', tabcomponent: Networking },
        { tabname: 'Knowledge', tabcomponent: Knowledege },
        { tabname: 'Podcast', tabcomponent: Podcast },
        { tabname: 'Media', tabcomponent: Media },
    ]
    return (
        <React.Fragment>
            <style>
                {`
                .blinking-text {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0;
                    }
                }
                `}
            </style>
            <Container>
                <Row className='my-3'>
                    <Col lg={3} xs={12}>
                        <Card>
                            <CardBody>
                                <div className="wizard">
                                    <div className="steps">
                                        <Nav vertical>
                                            {TabData.map((item, idx) => (
                                                <>
                                                    <NavItem key={idx} className={classnames({ current: activeTab === idx + 1, 'mt-3': true })} onClick={() => { toggleTab(idx + 1) }}>
                                                        <NavLink  >
                                                            <div className="row" >
                                                                <div className="col-10 text-start"><span className="number">{idx + 1}.</span> {item.tabname} </div>
                                                                <div className="col-2 text-end d-lg-none"><i className={activeTab === idx + 1 ? "mdi mdi-minus me-1 fs-4 fw-1" : "mdi mdi-plus me-1 fs-4 fw-1"} /></div>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <div className='d-lg-none d-block'>
                                                        <Collapse isOpen={activeTab === idx + 1}>{React.createElement(item.tabcomponent)}</Collapse>
                                                    </div>
                                                </>
                                            ))}
                                        </Nav>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={7} xs={8}>
                        <div className='d-none d-lg-block'>
                            <Card>
                                <CardBody>
                                    <TabContent activeTab={activeTab} className="">
                                        {TabData.map((item, idx) => (<TabPane tabId={idx + 1} key={idx}>{React.createElement(item.tabcomponent)}</TabPane>))}
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col lg={2} xs={4}>
                        <Toast isOpen={toast} className="w-100 bg-info-subtle text-primary" style={{ height: '600px' }}>
                            <ToastHeader toggle={toggleToast} className="bg-info-subtle text-primary">Offer</ToastHeader>
                            <ToastBody><h3 className="text-center blinking-text" style={{ writingMode: 'vertical-rl', textOrientation: 'upright' }}>Advertisment</h3></ToastBody>
                        </Toast>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default MultiStories