import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { Col, Container, Row } from 'reactstrap';
import image from '../../../assets/images/Diet/diet-plans-1.jpg';
import bgimage from '../../../assets/images/Diet/blog-img-v1-2.jpg';
import { useParams } from 'react-router-dom';
import { changePreloader } from 'store/actions';
import { Diet } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { formatDate } from 'helpers/formatDate';

export const DietDetails = () => {
    const { Diet_id } = useParams();
    const dispatch = useDispatch();
    const [getDiet, setGetDiet] = useState();

    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }));
        Diet.singleList(Diet_id)
            .then(res => setGetDiet(res.data.food))
            .catch(e => toast.error(e.response ? e.response.data.message : e.message))
            .finally(() => {
                dispatch(changePreloader({ status: false, message: '' }));
            });
    }, [Diet_id]);

    return (
        <React.Fragment>
            <Header />
            <div className="page-content">
                <div className="mx-0 px-0">
                    <div className="__scholarship-section" style={{
                        backgroundImage: `url(${bgimage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}>
                        <h6 className='ms-4'>Follow Us</h6>
                        <div className="__social-icons ms-3 mt-4">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-facebook"></i>
                            </a>
                            <br />
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-instagram"></i>
                            </a>
                            <br />
                            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                <i className="bx bxl-youtube"></i>
                            </a>
                            <br />
                        </div>
                    </div>
                    <Container className="__mainpage">
                        <Row className="text-center title-section">
                            <Col>
                                <h1 className="__main-title">Diet</h1>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <img src={image} alt="Sports Scholarships" className="__banner-image" width={'600px'} />
                            </Col>
                        </Row>
                        <h1 className="__main-title pt-4">{getDiet?.name.toUpperCase()}</h1>
                        <div className="table-responsive">

                            <table className="table align-middle nowrap mt-2">
                                <thead className='text-center'>
                                    <tr>
                                        <th colSpan={4} className='bg-dark text-white fs-4'>
                                            Nutrients Content of {getDiet?.name.toUpperCase()} Varieties
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Component</th>
                                        <th>Quantity</th>
                                        <th>Unit</th>
                                        <th>mesasurement</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        getDiet?.nutrients.map((item, idx) => (
                                            <tr key={idx}>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.unit}</td>
                                                <td>G</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        <p className='font-format fs-3'>
                            <strong> Nutritional Benefits of {getDiet?.name.toUpperCase()}:</strong>
                            <ul>
                                {
                                    getDiet?.nutrients?.map((item, idx) => (

                                        <li key={idx}>
                                            <b>{item.name} : </b>  {item.name} are an excellent source of potassium, which helps maintain proper heart function, regulates blood pressure, and prevents muscle cramps, making them great for students involved in physical activities.
                                        </li>
                                    ))
                                }
                            </ul>


                        </p>

                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};
