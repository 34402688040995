import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap"

import GFR1 from "../../../assets/images/calculator/gfr1.png"
import GFR2 from "../../../assets/images/calculator/gfr2.png"

function GfrCalculator({publicdata=false}) {
  const [age, setAge] = useState("")
  const [gender, setGender] = useState("male")
  const [creatinine, setCreatinine] = useState("")
  const [race, setRace] = useState("nonAfricanAmerican")
  const [gfr, setGFR] = useState(null)

  const calculateGFR = () => {
    // CKD-EPI equation for estimating GFR
    let gfrResult
    if (gender === "male")
      gfrResult =
        141 *
        Math.min(creatinine / 0.9, 1) ** -0.411 *
        Math.max(creatinine / 0.9, 1) ** -1.209 *
        0.993 ** age
    else
      gfrResult =
        144 *
        Math.min(creatinine / 0.7, 1) ** -0.329 *
        Math.max(creatinine / 0.7, 1) ** -1.209 *
        0.993 ** age
    // Adjust for race
    if (race === "AfricanAmerican") gfrResult *= 1.159
    if (race === "IndianAsian") gfrResult *= 1.05
    setGFR(gfrResult.toFixed(2))
  }

  return (
    <React.Fragment>

      {
        publicdata==true?
        <>
         <Card className="my-3">
         <ul>
          <h3>Results</h3>
                    <li>
                      <Row>
                        <Col>
                          <h4>GFR</h4>{" "}
                        </Col>
                        <Col>
                          <h4>{gfr !== null ? gfr : "-"}</h4>{" "}
                        </Col>
                      </Row>
                    </li>
                  </ul>
                <CardHeader>
                  <CardTitle>
                    <h3>GFR Calculator</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Age :</label>
                        <input
                          type="number"
                          value={age}
                          className="form-control"
                          onChange={e => setAge(e.target.value)}
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Gender :</label>
                        <select
                          value={gender}
                          className="form-control"
                          onChange={e => setGender(e.target.value)}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">
                          Serum Creatinine (mg/dL) :
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={creatinine}
                          onChange={e => setCreatinine(e.target.value)}
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Race :</label>
                        <select
                          value={race}
                          className="form-control"
                          onChange={e => setRace(e.target.value)}
                        >
                          <option value="IndianAsian">Indian/Asian</option>
                          <option value="nonAfricanAmerican">
                            Non-African American
                          </option>
                          <option value="AfricanAmerican">
                            African American
                          </option>
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end py-2">
                    <button
                      className="me-2 btn btn-success"
                      onClick={calculateGFR}
                    >
                      Calculate GFR
                    </button>
                  </div>
                </CardFooter>
                </Card>
        </>
        :
        <>
          <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
          <Row>
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3>Results</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ul>
                    <li>
                      <Row>
                        <Col>
                          <h4>GFR</h4>{" "}
                        </Col>
                        <Col>
                          <h4>{gfr !== null ? gfr : "-"}</h4>{" "}
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <h3>GFR Calculator</h3>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Age :</label>
                        <input
                          type="number"
                          value={age}
                          className="form-control"
                          onChange={e => setAge(e.target.value)}
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Gender :</label>
                        <select
                          value={gender}
                          className="form-control"
                          onChange={e => setGender(e.target.value)}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">
                          Serum Creatinine (mg/dL) :
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={creatinine}
                          onChange={e => setCreatinine(e.target.value)}
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <label className="label-control">Race :</label>
                        <select
                          value={race}
                          className="form-control"
                          onChange={e => setRace(e.target.value)}
                        >
                          <option value="IndianAsian">Indian/Asian</option>
                          <option value="nonAfricanAmerican">
                            Non-African American
                          </option>
                          <option value="AfricanAmerican">
                            African American
                          </option>
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end py-2">
                    <button
                      className="me-2 btn btn-success"
                      onClick={calculateGFR}
                    >
                      Calculate GFR
                    </button>
                  </div>
                </CardFooter>
                <CardBody>
                  <Card>
                    <h3>GFR - Kidney Function</h3>
                    <p>
                      Glomerular filtration rate (GFR) is a measure of how well
                      the kidneys are filtering waste products from the blood.
                      It is the rate at which blood is filtered by the glomeruli
                      in the kidneys, which are tiny structures responsible for
                      filtering blood and removing waste products.
                    </p>
                    <br />
                    <p>
                      GFR is considered the best indicator of kidney function,
                      and is used to diagnose and monitor kidney disease. A GFR
                      test measures the amount of creatinine in the blood and
                      urine, which is a waste product produced by muscle
                      metabolism. The amount of creatinine filtered by the
                      kidneys is used to calculate the GFR.
                    </p>
                    <br></br>
                    <p>
                      The normal GFR for healthy adults is about 90-120
                      milliliters per minute, but this can vary based on age,
                      sex, and other factors. A GFR below 60 mL/min/1.73m2 for
                      three or more months indicates chronic kidney disease, and
                      a GFR below 15 mL/min/1.73m2 indicates kidney failure.
                    </p>
                  </Card>
                </CardBody>
                <CardBody>
                  <Card>
                    <h3>Estimated GFR by Age</h3>
                    <p>
                      Based on the data you provided, the population mean
                      estimated GFR (glomerular filtration rate) for different
                      age groups is:
                    </p>
                    <img src={GFR1} />
                  </Card>
                </CardBody>

                <CardBody>
                  <Card>
                    <h3>CKD Stages by GFR</h3>
                    <p>
                      It's important to note that the stage of CKD is not solely
                      based on GFR, but also on other factors such as the
                      presence of proteinuria (abnormal amounts of protein in
                      the urine), abnormal imaging studies, or a history of
                      kidney transplant. It's also important to note that the
                      stages of CKD are not necessarily linear and can vary
                      depending on the individual case.
                    </p>
                    <img src={GFR2} />
                  </Card>
                </CardBody>

                <CardBody>
                  <Card>
                    <p>
                      GFR, or glomerular filtration rate, is a measure of how
                      well the kidneys are functioning. It is an important
                      indicator of kidney health and is often used to diagnose
                      and monitor kidney disease.
                    </p>
                    <ol>
                      <li>
                        Serum creatinine level: This is the most common method
                        for estimating GFR. Creatinine is a waste product that
                        is produced by muscle metabolism and excreted by the
                        kidneys. As kidney function declines, the creatinine
                        level in the blood increases. A formula can then be used
                        to estimate GFR based on the serum creatinine level,
                        age, sex, and race.
                      </li>
                      <li>
                        24-hour urine collection: This involves collecting all
                        of the urine produced over a 24-hour period and
                        measuring the amount of creatinine in the urine. GFR can
                        then be calculated based on the amount of creatinine
                        excreted and the amount that remains in the blood.
                      </li>
                      <li>
                        Cystatin C level: Cystatin C is a protein produced by
                        all cells in the body, including the kidneys. As kidney
                        function declines, the level of cystatin C in the blood
                        increases. GFR can then be estimated based on the
                        cystatin C level.
                      </li>
                      <li>
                        Radiology imaging: There are several radiology imaging
                        techniques, such as MRI and CT scans, that can be used
                        to measure GFR. These techniques require the injection
                        of a contrast agent that is filtered by the kidneys. The
                        rate at which the contrast agent is cleared from the
                        blood can then be used to estimate GFR.
                      </li>
                    </ol>
                  </Card>
                </CardBody>

                <CardBody>
                  <Card>
                    <h3>MDRD Equation Explanation</h3>
                    <p>
                      The MDRD (Modification of Diet in Renal Disease) Study
                      equation is used to estimate the glomerular filtration
                      rate (GFR) of a patient, which is a measure of kidney
                      function. The equation is as follows:
                    </p>
                    <br></br>
                    <Card>
                      GFR = 175 x (SCr)-1.154 x (age)-0.203 x (0.742 if female)
                      x (1.212 if African American)
                      <br></br>
                      <p>Where: </p>
                      <ul>
                        <li>SCr is the serum creatinine level in mg/dL</li>
                        <li>Age is the age of the patient in years</li>
                        <li>
                          Female patients have a multiplication factor of 0.742
                          to adjust for differences in muscle mass compared to
                          male patients
                        </li>
                        <li>
                          African American patients have a multiplication factor
                          of 1.212 to adjust for differences in creatinine
                          production compared to non-African American patients
                        </li>
                      </ul>
                    </Card>
                  </Card>
                </CardBody>

                <CardBody>
                  <Card>
                    <h3>Mayo quadratic formula does not exist.</h3>
                    <p>
                      I apologize for the confusion earlier. The "Mayo Quadratic
                      Formula" you are referring to is not a standard
                      mathematical formula, but rather a clinical formula used
                      to estimate the glomerular filtration rate (GFR), which is
                      a measure of kidney function. This formula was developed
                      by Mayo Clinic researchers. Here:
                    </p>
                    <Card>
                      <p>
                        GFR = 141 x min(SCr/k,1)a x max(SCr/k,1)-1.209 x
                        0.993Age x [1.018 if female] x 1.159 [if black]
                      </p>
                      <br />
                      <p>Where: </p>
                      <ul>
                        <li>SCr is the serum creatinine level in mg/dL</li>
                        <li>κ is 0.7 for females and 0.9 for males</li>
                        <li>a is -0.329 for females and -0.411 for males</li>
                        <li>Age is in years</li>
                      </ul>
                      for example:
                      <br />
                      GFR = 141 x (1.2/0.9)<sup>(-0.411)</sup>x 0.993
                      <span>
                        <sup>50</sup>x 1.159 = 61.4 mL/min/1.73m<sup>2</sup>
                      </span>
                    </Card>
                  </Card>
                </CardBody>

                <CardBody>
                  <h3>Schwartz formula for eGFR.</h3>
                  <p>
                    The estimated glomerular filtration rate (eGFR) for children
                    using the Schwartz formula is:
                  </p>
                  <Card>
                    <strong>eGFR = 0.413 x height (in cm) / SC</strong>
                    <p>where:</p>
                    <ol>
                      <li>
                        eGFR is the estimated glomerular filtration rate in
                        mL/min/1.73m<sup>²</sup>
                      </li>
                      <li>height is the child's height in centimeters</li>
                      <li>SCr is the serum creatinine level in mg/dL</li>
                    </ol>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      }
    </React.Fragment>
  )
}

export default GfrCalculator
