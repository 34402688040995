import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function EducationModel(props) {
    const {setData,user,current=null} = props;
    const { genders, toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit=(e)=>{
        dispatch(changePreloader({status:true,message:'Updating Address'}));
        e.preventDefault();
        const formData = new FormData(e.target);    
        users.updateEducation(formData).then(res=>{
            setData(state=>({...state,education:res.data.education}))
            toast.success("Update Successfully !")
            toggleModal(false);
        }).catch(e=>toast.error(e.response.data.message))
        .finally(()=>dispatch(changePreloader({status:false,message:false})))

    }
    return (
        <>
            {props.children?(
                <span onClick={toggleModal}>
                    {props.children}
                </span>
            ):(
                <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            )}
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update Education</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                        {current && (
                            <input type='hidden' name='edu_id' value={current.id} />
                        )}
                        <div className="mb-3">
                            <Label className="form-label">Title</Label>
                            <Input className='form-control' name='title' defaultValue={current?.title} type='text'/>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Description</Label>
                            <textarea className='form-control' name="description" rows={2}>{current?.description}</textarea>
                        </div>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Institute / Board name</Label>
                                    <Input className='form-control' name='institute' defaultValue={current?.institute} type='text'/>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">Start Year</Label>
                                    <Input className='form-control' name='start_year' defaultValue={current?.start_year} type='text'/>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3">
                                    <Label className="form-label">End Year</Label>
                                    <Input className='form-control' name='end_year' defaultValue={current?.end_year} type='text'/>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-3">
                                    <Label className="form-label">Grade</Label>
                                    <Input className='form-control' name='grade' defaultValue={current?.grade} type='text'/>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-3">
                                    <Label className="form-label">Full Marks</Label>
                                    <Input className='form-control' name='full_marks' defaultValue={current?.full_marks} type='text'/>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-3">
                                    <Label className="form-label">Obtained Marks</Label>
                                    <Input className='form-control' name='marks_obtained' defaultValue={current?.marks_obtained} type='text'/>
                                </div>
                            </Col>
                            
                        </Row>
                       
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EducationModel