import React, { useEffect, useMemo, useState } from "react"
import { Container, Card, CardBody, CardTitle, CardHeader, Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import ReactSelect from "react-select"
import { exercise } from "helpers/api_url"
import { TableResponsive } from 'components/Common/TableResponsive'
import AddSubCategoryModal from "./AddSubCategoryModal";
import AddCategoryModal from "./AddCategoryModal";
import SubCategoryAllInputModal from "./SubCategoryAllInputModal";
import { changePreloader } from "store/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import UpdateSubCategoryModal from "./UpdateSubCategoryModal";
import ViewCategoryModal from "./ViewCategoryModal";
import UpdateCategoryModal from "./UpdateCategoryModal";

const ExerciseCategory = ({ tabNumber }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const columns = useMemo(() => [
    { Header: "Title", accessor: "category_title", HeaderClass: 'text-center', DataClass: 'text-center' },
    { Header: "Category", accessor: "category_name", HeaderClass: 'text-center', DataClass: 'text-center' },
    {
      Header: "Sub Categories", HeaderClass: 'text-center', DataClass: 'text-center',
      accessor: 'sub_category_name',
      Cell: cell => {
        const row = cell.row.original;
        return (
          <>
            {row?.sub_category_name?.split(',').map((name, index) => name != '' ? (<span key={index} className="me-2 badge bg-success p-2">{name}</span>) : null)}
            <UpdateSubCategoryModal setCategories={setTableData} data={row} />
          </>
        )
      }
    },
    {
      Header: 'Action',
      HeaderClass: 'text-center',
      DataClass: 'text-center',
      Cell: cell => {
        const row = cell.row.original;
        return (
          <div>
            <button onClick={() => handleCategoryDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
            <UpdateCategoryModal setCategories={setTableData} data={row} />
            <ViewCategoryModal data={row} />
          </div>
        )
      }
    },
    {
      Header: 'List',
      HeaderClass: 'd-none',
      DataClass: 'd-none',
      list:(row)=>{
        return (
          <>
          <div className="d-flex">
            <div className="flex-grow-1" data-id="1">
              <h5 className="fs-13 mb-1">
                <a href="#" className="link text-dark"></a>
                <a href="#"><b>{row.category_title}</b></a>| <span>{row.category_name}</span>
              </h5>
              <p className="text-muted mb-0 no-wrap">
                {row?.sub_category_name?.split(',').map((name, index) => name != '' ? (<span key={index} className="me-2 badge bg-success p-2">{name}</span>) : null)}
                <AddSubCategoryModal setCategories={setTableData} data={row} />
              </p>
            </div>
            <div className="flex-shrink-0">
              <div>
                <Button onClick={() => handleCategoryDelete(row.id)} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
              </div>
            </div>
          </div>
          </>
        )
      }
    }
  ]);
  const handleCategoryDelete = id => {
    dispatch(changePreloader({ status: true, message: 'please wait' }));
    exercise.deleteCategory(id)
      .then(res => { setTableData(state => state.filter(i => i.id != id)); toast.success(res.message) })
      .catch(err => toast.error(err.response ? err.response.data.message : err.message))
      .finally(() =>
        dispatch(changePreloader({ status: false, message: '' })));

  }
  useEffect(() => {
    if (tabNumber == 1) {
      dispatch(changePreloader({ status: true, message: 'please wait' }));
      exercise.getAllCategory().then(res => { setTableData(res.data.category); }).catch(err => toast.error(err.response ? err.response.data.message : err.message)).finally(() =>
        dispatch(changePreloader({ status: false, message: '' })));
    }
  }, [tabNumber])
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle>Categories And Subcategories</CardTitle>
        <div>
          <Row>
            <Col xs={6} lg={12}>
              <AddCategoryModal setCategories={setTableData} />
              <SubCategoryAllInputModal setCategories={setTableData} categories={tableData} />
            </Col>
          </Row>
        </div>
      </CardHeader>
      <CardBody>
        <TableResponsive columns={columns} data={tableData} noRecord="No Records in List" />
      </CardBody>
    </Card>
  )
}

export default ExerciseCategory
