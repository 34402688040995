import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardTitle, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Collapse, Container, Label, Row, UncontrolledCarousel } from 'reactstrap'
import img1 from '../../../assets/images/Execise/ex1.jpeg'
import img2 from '../../../assets/images/Execise/ex2.jpeg'
import img3 from '../../../assets/images/Execise/ex3.jpeg'
import ReactPlayer from 'react-player'
import { AssetUrl, exercise } from 'helpers/api_url'
import { json, Link, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import classNames from 'classnames'
import { TableResponsive } from 'components/Common/TableResponsive'
export const ExcersiseView = () => {
    const navigate=useNavigate();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategory] = useState([]);
    const [excercise, setExercise] = useState([]);
    const [gravity, setGravity] = useState([]);
    useEffect(() => {
        exercise.getAllCategory().then(res => setCategories(res.data.category)).catch(e => console.error(e));
        exercise.list().then(res => {
            setExercise(res.data.exercise);
            const gravitydata = JSON.parse(res.data.exercise.gravity);
            console.log("gravity: ", gravitydata)
            const gravityoption = gravitydata.map(item => ({ label: item, value: item, }));
            setGravity(gravityoption);
        }).catch(e => console.error(e));
    }, [])

    const handleExcercise = (data) => {
        const exercise = data;
        navigate("/exercise-detail", { state: { data:exercise} });
    };
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Excercise Name",accessor: "exercise_name",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                const row=cell.row.original;
              return ( 
                <div className="">
                    <Button onClick={()=> handleExcercise(row)} className="btn btn-sm btn-soft-primary me-1" ><i className="mdi mdi-eye-outline" /></Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.exercise_name}</a> 
                        </h5>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                        <Button onClick={()=> handleExcercise(row)} className="btn btn-sm btn-soft-primary me-1" ><i className="mdi mdi-eye-outline" /></Button>
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
    const videos = ['https://youtu.be/yYmWtzXRpqo?si=hO0atrv35yWaq8AN', 'https://youtu.be/kGvzBAaD1CQ?si=4H3O8YMZnwZRRObo', 'https://youtube.com/shorts/fVcxz-Pcqd0?si=Gv-uA6q9mjrmD3cS'];
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const next = () => { if (animating) return; const nextIndex = activeIndex === videos.length - 1 ? 0 : activeIndex + 1; setActiveIndex(nextIndex); };
    const previous = () => { if (animating) return; const nextIndex = activeIndex === 0 ? videos.length - 1 : activeIndex - 1; setActiveIndex(nextIndex); };
    const goToIndex = (newIndex) => { if (animating) return; setActiveIndex(newIndex); };
    const slides = videos.map((url, index) => { return (<CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}><ReactPlayer url={url} width='100%' height='100%' /></div></CarouselItem>); });
    const [openIndices, setOpenIndices] = useState({});
    const toggleCollapse = (idx) => { setOpenIndices(prevState => ({ ...prevState, [idx]: !prevState[idx] })); };
    const handleSubCategory = (e, delimiter = ',') => {
        if (!e.value) {
            setSubCategory([]);
            return;
        }
        const items = e.value.split(delimiter);
        const subCategoryArray = items.map(item => ({
            label: item.trim(),
            value: item.trim(),
        }));
        setSubCategory(subCategoryArray);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <div className='row'>
                                    <div className='col-lg-4 text-start'>
                                        <CardTitle className='p-2 fs-3'>
                                            Page Scroll Down
                                        </CardTitle>

                                    </div>
                                    <div className='col-lg-2 text-start'> </div>
                                    <div className='col-lg-6 text-end p-2'>
                                        <div className="d-flex justify-content-center ">
                                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link>
                                            <Link to="#" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link>
                                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link>
                                        </div>
                                    </div>

                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {
                        excercise.map((item, idx) => (
                            <Row key={idx}>
                                <Col lg={3} xs={12}>

                                    <Card>
                                        <CardTitle className='fs-4 px-2 text-center bg-light mb-3'>Recent Message</CardTitle>
                                        <CardTitle className='fs-6 px-2 bg-light'>Excercise</CardTitle>
                                        <CardBody >
                                            <UncontrolledCarousel interval={4000} indicators={false} items={[{ altText: " ", caption: " ", key: 1, src: AssetUrl + item.image, }, { altText: " ", caption: " ", key: 1, src: img2, }, { altText: " ", caption: " ", key: 1, src: img3, }]} />
                                        </CardBody>
                                   
                                        <CardTitle className='fs-6 px-2 mt-3 bg-light'> Videos</CardTitle>
                                        <CardBody>
                                            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                                                <CarouselIndicators interval={5000} items={item.link_youTube != 'none' ? item.link_youTube : videos} activeIndex={activeIndex} onClickHandler={goToIndex} />
                                                {slides}
                                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                                                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                                            </Carousel>
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/* <Col lg={3} xs={12}></Col> */}
                                <Col lg={8} xs={12}>
                                    <Card>
                                        <CardTitle className='fs-4 px-2 text-center bg-light'>Filter
                                           
                                        </CardTitle>
                                        <CardBody>
                                            <div className="mb-3">
                                                <Row>
                                                    <Col lg={6} xs={12}>
                                                        <Label className="form-label" htmlFor="">Category</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="" id="" options={categories.map(item => ({ label: `${item.category_name} (${item.category_title})`, value: item.sub_category_name }))} onChange={(e) => handleSubCategory(e)} />
                                                    </Col>
                                                    <Col lg={6} xs={12}>
                                                        <Label className="form-label" htmlFor="sub_categories">Sub Category</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="sub_categories" id="sub_categories" options={subCategories} />
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="gravity">Gravity</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="gravity" id="gravity" options={gravity}/>
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="mechanics">Mechanics</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="mechanics" id="mechanics" options={[]} />
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="area_of_body_part">Area of body part</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="area_of_body_part" id="area_of_body_part" options={[]} />
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="techniques">Techniques</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="techniques" id="techniques" options={[]} />
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="muscle_size">Muscle Size</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="muscle_size" id="muscle_size" options={[]} />
                                                    </Col>
                                                    <Col lg={6} xs={12} className='mt-2'>
                                                        <Label className="form-label" htmlFor="intensity_mobility">Intensity Mobility</Label>
                                                        <Select className="basic-single" classNamePrefix="select" name="intensity_mobility" id="intensity_mobility" options={[]} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                        <TableResponsive columns={columns} data={excercise} noRecord="No Record List"  />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ))
                    }
                
                </Container>
            </div>
        </React.Fragment>
    )
}
