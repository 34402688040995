import { differenceInYears, format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Container, Table } from 'reactstrap';
import { useReactToPrint } from "react-to-print";


const DayRoutine = () => {
    const location = useLocation();
    const data = location.state;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    if (!data) {
        return <Container className="text-center"><h1>No data passed</h1></Container>;
    }

    const { dateStr, events, user } = data;
    const [timeTableSchedule, setTimeTableSchedule] = useState([]);
    useEffect(() => {
        const schedule = [];
        if (events && events.length) {
            console.log(user);
            events.map(e => {
                if (e.data.particular_type === `App\\Models\\Diet\\PresetDay`) {
                    const foods = JSON.parse(e.data.particular.foods);
                    const meals = JSON.parse(e.data.particular.meals);
                    const recipes = JSON.parse(e.data.particular.recipes);
                    foods.map(f => schedule.push({ time: f.time, activity: `Have ${f.quantity} gm of Food id ${f.id}`, type: 'food' }))
                    recipes.map(r => schedule.push({ time: r.time, activity: `Recipe  Id ${r.id}`, type: 'recipe' }))
                    meals.map(m => schedule.push({ time: m.time, activity: `Meal  Id ${m.id}`, type: 'meal' }))
                }
                if (e.data.particular_type === `App\\Models\\Exercise\\DaySchedule`) {
                    const exercises = JSON.parse(e.data.particular.exercisedata_id);
                    exercises.map(e => schedule.push({ time: "--", activity: `Exercise ID:${e}`, type: 'exercise' }))
                }
                if (e.data.particular_type === `App\\Models\\Menu`) {
                    const note = e.data.description;
                    schedule.push({ time: "NOTE", activity: note, type: 'note' })
                }
            })
            setTimeTableSchedule(schedule);
        };

    }, [events])
    const timetableData = {
        title: `${user?.first_name} ${user?.last_name} (${format(dateStr, "PPP")}) `,
        subtitle: `Time Table for ${format(dateStr, "EEEE")} (${format(dateStr, "PPP")})`,
        notes: "If any imported Match or any work inform me before a day, schedule will customise accordingly",
        schedule: timeTableSchedule
    };
    return (
        <div className="page-content">
            <Container fluid >
                <Card ref={componentRef}>
                    <CardHeader className='d-flex justify-content-between align-items-center'>
                        <div>
                            <h4 className="mb-3">{timetableData.title}</h4>
                            <p className="mb-2">{timetableData.subtitle}</p>
                        </div>
                        <button onClick={handlePrint} disabled={false} className='btn btn-soft-danger'>Print</button>
                    </CardHeader>
                    <CardBody ref={componentRef}>
                        <h5>CLIENT DETAILS</h5>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td>{user?.first_name}</td>
                                    <td>{user?.last_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{user?.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{user?.phone}</td>
                                </tr>
                                {/* <tr>
                                    <td>AGE</td>
                                    <td>{differenceInYears(new Date(user?.dob),new Date())}</td>
                                </tr>
                                <tr>
                                    <td>PACKAGE</td>
                                    <td>3 Months Sports Nutrition (Cricket)</td>
                                </tr> */}
                            </tbody>
                        </Table>
                        <h5>BODY ASSESSMENT</h5>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td>HEIGHT (CM)</td>
                                    <td>162</td>
                                    <td>FAT%</td>
                                    <td>24.3</td>
                                </tr>
                                <tr>
                                    <td>WEIGHT (KG)</td>
                                    <td>59.3</td>
                                    <td>BMR (KCAL)</td>
                                    <td>1295</td>
                                </tr>
                                <tr>
                                    <td>Neck</td>
                                    <td>59.3</td>
                                    <td>Shoulder</td>
                                    <td>1295</td>
                                </tr>

                                <tr>
                                    <td>Chest</td>
                                    <td>59.3</td>
                                    <td>Arm</td>
                                    <td>1295</td>
                                </tr>


                                <tr>
                                    <td>Waist</td>
                                    <td>59.3</td>
                                    <td>Hips</td>
                                    <td>1295</td>
                                </tr>

                                <tr>
                                    <td>thigh</td>
                                    <td>59.3</td>
                                    <td>calf</td>
                                    <td>1295</td>
                                </tr>




                                <tr>
                                    <td>BMI</td>
                                    <td>59.3</td>
                                    <td>Boday Age as Per BMI</td>
                                    <td>1295</td>
                                </tr>



                                <tr>
                                    <td>Skeletal Muscle Whole Body</td>
                                    <td>59.3</td>
                                    <td>Skeletal Muscle Trunk</td>
                                    <td>1295</td>
                                </tr>

                            </tbody>
                        </Table>
                        <h5>Time Table</h5>
                        <Table bordered>
                            <tbody>
                                {timetableData.schedule.map((item, index) => (
                                    <tr key={index}>
                                        <td
                                        // className={` text-white ${item.type==='exercise'?'bg-info':item.type==='food'?'bg-warning':'bg-primary'}`}
                                        >{item.time}</td>
                                        <td
                                            className={` text-white ${item.type === 'exercise' ? 'bg-info' : item.type === 'food' ? 'bg-warning' : 'text-muted'}`}
                                        >{item.activity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <p className="mt-3"><em>{timetableData.notes}</em></p>
                    </CardFooter>
                </Card>
            </Container>
        </div>
    );
};

export default DayRoutine;
