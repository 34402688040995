import { community, register } from 'helpers/api_url';
import { useModalHandler } from 'helpers/customHook';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { Row, Col, Modal, ModalBody, ModalHeader, Form, Label, Input, Card } from 'reactstrap'
import {changePreloader as setPreloader} from 'store/layout/actions'
export const UserCreate = ({setTableData,userType=false}) => {
    const [allrole, setallrole] = useState([]);
    const [allUserType, setAllUserType] = useState([]);
    const { status, toggleModal } = useModalHandler();
    const dispatch=useDispatch();
    useEffect(() => {
        register.role().then(res =>
            setallrole(res.data.roles.reduce((acc, item) => {
                acc[item.role_type] = acc[item.role_type] ? [...acc[item.role_type], item] : [item];
                return acc;
            }, {}))
        ).catch(e => console.log(e));
    }, [])
    const handleUserType = (e) => {
        setAllUserType(allrole[e.value])
    }
    const handleSubmit =(e)=>{
        dispatch(setPreloader({loader:true,message:'please wait'}));
        e.preventDefault();
        let formData = new FormData(e.target);
        community.Createparticipator(formData)
        .then((res) => {
            setTableData(state=>[res.data.participator,...state]);
            toast.success(res.message, { autoClose: 2000 });e.target.reset();
        })
        .catch((err) => {
          toast.error(err.response ? err.response.data.message : err.message);
        })
        .finally(()=>
            dispatch(setPreloader({loader:false,message:''}))
        );
        
    }
    return (
        <React.Fragment>
            <button onClick={()=>toggleModal(true)} className='btn btn-soft-success add-btn waves-effect'>
                <i className="bx bx-user-plus align-bottom me-1 mb-1"></i>
                <span>Create User</span>
            </button>
            <Modal isOpen={status} toggle={() =>toggleModal()}>
                <ModalHeader toggle={() => toggleModal(false)}>New User Create</ModalHeader>
                <ModalBody>
                    <Card>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='name'>First Name</Label>
                                <Input type='text' name='first_name' id='name' />
                            </Col>
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='lname'>Last Name</Label>
                                <Input type='text' name='last_name' id='lname' />
                            </Col>
                            {/* <Col lg={6} xs={12} className='mt-2'>
                                <Label className="form-label" htmlFor="">Role Category</Label> */}
                                {/* {(userType)?
                                <Input type='text' value={allUserType.length ? Object.keys(allrole).find(r => r.label === userType).label : 'NoRole'} />
                                :<Input type='text' />
                                } */}
                                {/* <Select className="basic-single" classNamePrefix="select" defaultValue={{label:'Participator',value:'Participator'}} name="" id="" 
                                options={[{ label: 'Participator', value: 'Participator' }]} onChange={(e) => handleUserType(e)} />
                            </Col> */}
                            {/* <Col lg={6} xs={12} className='mt-2'>
                                <Label className="form-label" htmlFor="role">Role</Label>
                                <Select className="basic-single" classNamePrefix="select" name="role_id" id="role" options={allUserType.map(item => ({ label: item.role_name, value: item.role_id }))} />
                            </Col> */}
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='email'>Email</Label>
                                <Input type='email' name='email' id='email' />
                            </Col>
                            <Col lg={6} xs={12} className='mt-2'>
                                <Label htmlFor='phone'>phone</Label>
                                <Input type='number' name='phone' id='phone' />
                            </Col>
                            <Col lg={12} xs={12} className='mt-2'>
                                <Label htmlFor='password'>Password</Label>
                                <Input type='password' name='password' id='password' />
                            </Col>

                            <div className="mt-4 text-center">
                                <div className="mb-2 d-grid"><button className="btn btn-primary btn-block" type="submit">submit</button></div>

                            </div>
                        </Row>
                    </Form>
                    </Card>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}
