import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter, Input, Button
} from "reactstrap"
import ReactSelect from "react-select";



function Micro() {

    const [age, setAge] = useState("");
    const [gender, setGender] = useState("male");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [activityLevel, setActivityLevel] = useState("sedentary");
    const [goal, setGoal] = useState("maintain");
    const [unitSystem, setUnitSystem] = useState("us");
    const [macros, setMacros] = useState(null);

    const calculateMacros = () => {
        // Convert height from inches to centimeters if metric
        const heightInCentimeters =
            unitSystem === "metric" ? (height * 2.54).toFixed(2) : height;

        // Convert weight from pounds to kilograms if metric
        const weightInKilograms =
            unitSystem === "metric" ? (weight * 0.453592).toFixed(2) : weight;

        // Calculate BMR (Basal Metabolic Rate)
        let bmr = 0;
        if (gender === "male") {
            bmr =
                10 * weightInKilograms +
                6.25 * heightInCentimeters -
                5 * age +
                5;
        } else {
            bmr =
                10 * weightInKilograms +
                6.25 * heightInCentimeters -
                5 * age -
                161;
        }

        // Adjust BMR based on activity level
        let tdee = 0;
        switch (activityLevel) {
            case "sedentary":
                tdee = bmr * 1.2;
                break;
            case "lightlyActive":
                tdee = bmr * 1.375;
                break;
            case "moderatelyActive":
                tdee = bmr * 1.55;
                break;
            case "veryActive":
                tdee = bmr * 1.725;
                break;
            case "extraActive":
                tdee = bmr * 1.9;
                break;
            default:
                tdee = bmr * 1.2;
        }

        // Adjust TDEE based on goal
        let calories = 0;
        switch (goal) {
            case "lose":
                calories = tdee - 500;
                break;
            case "gain":
                calories = tdee + 500;
                break;
            default:
                calories = tdee;
        }

        // Calculate macronutrients (Protein, Fat, Carbs)
        const protein = (weightInKilograms * 2.20462 * 0.8).toFixed(2); // 0.8 grams per pound of body weight
        const fat = ((calories * 0.25) / 9).toFixed(2); // 25% of calories from fat
        const carbs = ((calories - protein * 4 - fat * 9) / 4).toFixed(2); // Remaining calories from carbs

        // Sample values for sugar, saturated fat, and food energy (calories)
        const sugar = ((calories * 0.1) / 4).toFixed(2); // 10% of calories from sugar
        const saturatedFat = ((calories * 0.15) / 9).toFixed(2); // 15% of calories from saturated fat
        const foodEnergy = calories; // Total calories

        return {
            protein,
            fat,
            carbs,
            sugar,
            saturatedFat,
            foodEnergy,
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const calculatedMacros = calculateMacros();
        setMacros(calculatedMacros);
    };

    const toggleUnitSystem = () => {
        setUnitSystem(unitSystem === "us" ? "metric" : "us");
    };


    return (
        <React.Fragment>
            <Breadcrumbs title="Micro calculator" breadcrumbItem="Dashboard" />
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader>
                                <h3>Calculated Macros</h3>
                        </CardHeader>
                            <CardTitle className="my-2">
                                {macros && (
                                    <div>
                                        <p>Protein: {macros.protein} g</p>
                                        <p>Fat: {macros.fat} g</p>
                                        <p>Carbs: {macros.carbs} g</p>
                                        <p>Sugar: {macros.sugar} g</p>
                                        <p>Saturated Fat: {macros.saturatedFat} g</p>
                                        <p>Food Energy: {macros.foodEnergy} kcal</p>
                                    </div>
                                )}
                            </CardTitle>
                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <h3>Micro calculator</h3>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <h2>Macro Calculator</h2>
                                <div>
                                    <Button className="bg-primary" onClick={toggleUnitSystem}>Toggle Unit System</Button>
                                    <h3 className="my-2">{unitSystem === "us" ? "US Units" : "Metric Units"}</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <label>Age:</label>
                                            <Input type="number" value={age} onChange={(e) => setAge(e.target.value)} required />
                                        </div>
                                        <div className="my-2">
                                            <label>Gender:</label>
                                            <ReactSelect Value={gender} options={[
                                                { value: 'male', label: 'Male' },
                                                { value: 'female', label: 'Female' },
                                            ]} onChange={(e) => setGender(e.value)} />

                                        </div>
                                        <div className="my-2">
                                            <label>Height ({unitSystem === "metric" ? "cm" : "in"}):</label>
                                            <Input type="number" value={height} onChange={(e) => setHeight(e.target.value)} required />
                                        </div>
                                        <div className="my-2">
                                            <label> Weight ({unitSystem === "metric" ? "kg" : "lbs"}): </label>
                                            <Input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} required />
                                        </div>
                                        <div className="my-2">
                                            <label>Activity Level:</label>
                                            <ReactSelect Value={activityLevel} options={[
                                                { value: 'sedentary', label: 'Sedentary (little or no exercise)' },
                                                { value: 'lightlyActive', label: 'Lightly active (light exercise/sports 1-3 days/week)' },
                                                { value: 'moderatelyActive', label: 'Moderately active (moderate exercise/sports 3-5 days/week)' },
                                                { value: 'veryActive', label: ' Very active (hard exercise/sports 6-7 days a week)' },
                                                { value: 'extraActive', label: 'Extra active (very hard exercise/sports & physical job or 2x training)' },
                                            ]} onChange={(e) => setActivityLevel(e.value)} />
                                        </div>
                                        <div className="my-2">
                                            <label>Your Goal:</label>
                                            <ReactSelect Value={goal} options={[
                                                { value: 'maintain', label: 'Maintain Weight' },
                                                { value: 'lose', label: 'Lose Weight' },
                                                { value: 'gain', label: 'Gain Weight' },
                                            ]} onChange={(e) => setGoal(e.value)} />
                                        </div>
                                        <Button className="btn btn-success" type="submit">Calculate</Button>
                                    </form>
                                </div>

                            </div>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                        <CardBody>
                            <Card>
                                <h3>What are Macronutrients (Macros)?</h3>
                                <p>
                                    Macronutrients, or macros for short, are nutrients required by the body in large quantities to maintain
                                    normal bodily functions and provide energy. The three main macronutrients are:

                                    Carbohydrates: Carbohydrates are the primary source of energy for the body. They are found in foods such as
                                    bread, pasta, fruits, and vegetables.

                                    Proteins: Proteins are essential for growth and repair of tissues in the body, as well as for the production
                                    of enzymes and hormones. They are found in foods such as meat, fish, eggs, and beans.

                                    Fats: Fats are also a source of energy for the body, and they play a role in the absorption of certain
                                    vitamins. They are found in foods such as butter, oils, and nuts.
                                </p>
                                <br></br>
                                <h3>Protein</h3>
                                <p>
                                    Proteins play a crucial role in the human body, serving a variety of functions such as:
                                </p>

                                <ol>
                                    <li>Structural support: Proteins provide structural support to various tissues and organs in the body, such
                                        as
                                        muscles, bones, and skin.
                                    </li><li>

                                    </li><li>Enzymatic reactions: Many enzymes are made up of proteins and play a crucial role in facilitating
                                        chemical
                                        reactions in the body.</li>

                                    <li>Transport: Some proteins help transport molecules across cell membranes, such as oxygen-carrying
                                        hemoglobin
                                        in red blood cells.</li>

                                    <li>Hormones: Some proteins, such as insulin, act as hormones that regulate various bodily functions.</li>

                                    <li>Immune function: Antibodies, which are proteins, help the immune system identify and fight off foreign
                                        invaders like bacteria and viruses.</li>
                                </ol>
                                Protein needs can vary depending on a person's age, gender, activity level, and overall health. Generally,
                                the recommended daily intake of protein for adults is about 0.8 grams per kilogram of body weight. However,
                                athletes and people engaged in intense physical activity may require more protein.
                                <br /><br />
                                <h3>Carbohydrates</h3>
                                <p>
                                    Carbohydrates are one of the three macronutrients that make up the human diet, along with proteins and fats.
                                    They are an important source of energy for the body and can be found in a wide range of foods, including
                                    fruits, vegetables, grains, dairy products, and sugars.
                                    <br />
                                    <br />


                                    When carbohydrates are consumed, they are broken down by enzymes in the digestive system into glucose, which
                                    can be used by the body for energy. Glucose is then transported through the bloodstream to cells where it is
                                    used for various metabolic processes, including the production of ATP (adenosine triphosphate), the primary
                                    source of energy for cells.
                                    <br />
                                    <br />

                                    Carbohydrates can be divided into two main categories: simple and complex. Simple carbohydrates are made up
                                    of one or two sugar molecules and are typically found in foods such as fruit, milk, and candy. Complex
                                    carbohydrates, on the other hand, are made up of long chains of sugar molecules and are found in foods such
                                    as whole grains, beans, and vegetables.
                                    <br />
                                    <br />

                                    It is generally recommended that carbohydrates should make up around 45-65% of the total caloric intake for
                                    adults. However, the quality and quantity of carbohydrate intake can have significant impacts on health.
                                    Diets high in simple carbohydrates and added sugars have been linked to an increased risk of obesity, type 2
                                    diabetes, and heart disease. In contrast, diets high in complex carbohydrates, particularly those from whole
                                    grains and vegetables, have been associated with lower risks of chronic diseases.
                                </p>
                                <br />

                                <h3>Food Macro Nutrients Summary</h3>
                                <p>
                                    Food	Serving Size	Protein	Carbs	Fat
                                </p>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Food</th>
                                            <th>Protein</th>
                                            <th>Carbs</th>
                                            <th>Fat</th>
                                        </tr>
                                    </thead>
                                    <br />
                                    <tbody>
                                        <tr>
                                            <th>Fruit:</th>
                                        </tr>
                                        <tr>
                                            <td>Apple</td>
                                            <td>0.27g</td>
                                            <td>14.36g  </td>
                                            <td>0.18g</td>
                                        </tr>
                                        <tr>
                                            <td>Banana</td>
                                            <td>1.85g</td>
                                            <td>38.85g  </td>
                                            <td>0.56g</td>
                                        </tr>
                                        <tr>
                                            <td>Grapes</td>
                                            <td>1.15g</td>
                                            <td>28.96g  </td>
                                            <td>0.26g</td>
                                        </tr>
                                        <tr>
                                            <td>Orange</td>
                                            <td>0.79g</td>
                                            <td>11.79g  </td>
                                            <td>0.23g</td>
                                        </tr>
                                        <tr>
                                            <td>Pear</td>
                                            <td>0.54g</td>
                                            <td>21.91g  </td>
                                            <td>0.17g</td>
                                        </tr>
                                        <tr>
                                            <td>Peach</td>
                                            <td>1.2g</td>
                                            <td>12.59g   </td>
                                            <td>0.33g</td>
                                        </tr>
                                        <tr>
                                            <td>Pineapple</td>
                                            <td>0.84g</td>
                                            <td>19.58g  </td>
                                            <td>0.19g</td>
                                        </tr>
                                        <tr>
                                            <td>Strawberry</td>
                                            <td>1.11g</td>
                                            <td>12.75g   </td>
                                            <td>0.5g</td>
                                        </tr>
                                        <tr>
                                            <td>Watermelon</td>
                                            <td>0.93g</td>
                                            <td>11.48g  </td>
                                            <td>0.23g</td>
                                        </tr>
                                        <tr>
                                            <th>Vegetables:</th>
                                        </tr>
                                        <tr>
                                            <td>Asparagus</td>
                                            <td>2.95g </td>
                                            <td> 5.2g  </td>
                                            <td>0.16g</td>
                                        </tr>
                                        <tr>
                                            <td>Broccoli</td>
                                            <td>2.57g</td>
                                            <td>6.04g </td>
                                            <td>0.34g</td>
                                        </tr>
                                        <tr>
                                            <td>Carrots</td>
                                            <td>1.19g</td>
                                            <td>12.26g </td>
                                            <td>0.31g </td>
                                        </tr>
                                        <tr>
                                            <td>Cucumber</td>
                                            <td>0.67g </td>
                                            <td>2.45g   </td>
                                            <td>0.18g</td>
                                        </tr>
                                        <tr>
                                            <td>Eggplant</td>
                                            <td>0.98g </td>
                                            <td>5.88g</td>
                                            <td>0.18g</td>
                                        </tr>
                                        <tr>
                                            <td>Lettuce</td>
                                            <td>0.5g </td>
                                            <td>1.63g </td>
                                            <td>0.08g </td>
                                        </tr>
                                        <tr>
                                            <td>Tomato</td>
                                            <td>1.58g </td>
                                            <td>7.06g </td>
                                            <td>0.36g </td>
                                        </tr>

                                        <tr>
                                            <th>Proteins:</th>
                                        </tr>
                                        <tr>
                                            <td>Beef</td>
                                            <td>14.2g</td>
                                            <td>0g carbs</td>
                                            <td>10.4g </td>
                                        </tr>
                                        <tr>
                                            <td>Chicken</td>
                                            <td>16g </td>
                                            <td>0g carbs</td>
                                            <td>1.84g</td>
                                        </tr>
                                        <tr>
                                            <td>Tofu</td>
                                            <td>7.82g </td>
                                            <td>2.72g  </td>
                                            <td>3.06g </td>
                                        </tr>
                                        <tr>
                                            <td>Egg</td>
                                            <td>6.29g</td>
                                            <td>0.38g </td>
                                            <td>4.97g</td>
                                        </tr>
                                        <tr>
                                            <td>Fish</td>
                                            <td>9.96g </td>
                                            <td>4.84g  </td>
                                            <td>8.24g</td>
                                        </tr>
                                        <tr>
                                            <td>Pork</td>
                                            <td>5.82g</td>
                                            <td>0g carbs  </td>
                                            <td>8.26g</td>
                                        </tr>
                                        <tr>
                                            <td>Shrimp</td>
                                            <td>15.45g </td>
                                            <td> 0.69g   </td>
                                            <td>1.32g</td>
                                        </tr>

                                        <tr>
                                            <th>Common Meals/Snacks:</th>
                                        </tr>
                                        <tr>
                                            <td>Bread</td>
                                            <td>1.91g</td>
                                            <td>12.65g  </td>
                                            <td>0.82g</td>
                                        </tr>
                                        <tr>
                                            <td>Butter</td>
                                            <td>0.12g</td>
                                            <td>0.01g </td>
                                            <td>11.52g</td>
                                        </tr>
                                        <tr>
                                            <td>Caesar salad</td>
                                            <td>16.3g</td>
                                            <td>21.12g</td>
                                            <td>45.91g</td>
                                        </tr>
                                        <tr>
                                            <td>Cheeseburger</td>
                                            <td>14.77g</td>
                                            <td>31.75g </td>
                                            <td>15.15g</td>
                                        </tr>
                                        <tr>
                                            <td>Hamburger</td>
                                            <td>14.61g</td>
                                            <td>26.81g</td>
                                            <td>10.97g</td>
                                        </tr>
                                        <tr>
                                            <td>Dark Chocolate</td>
                                            <td>1.57g</td>
                                            <td>16.84g</td>
                                            <td>9.19g</td>
                                        </tr>
                                        <tr>
                                            <td>Corn</td>
                                            <td>4.3g  </td>
                                            <td>30 </td>
                                            <td></td>
                                        </tr>
                                    </tbody>

                                </table>

                            </Card>
                        </CardBody>


                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Micro
