import React from 'react';
import { Container, Row, Col, Card, CardBody, Input, Button } from 'reactstrap';
import image from '../../../assets/images/socialMedia/sport.png'
import MultiStories from './MultiStories';
import { Link } from 'react-router-dom';
export const Stories = () => {
    return (
        <React.Fragment>
            <div className="page-content mt-5">
                <div className="hero-section text-center py-5" style={{ backgroundColor: '#fff' }}>
                    <Container>
                        <Row className="align-items-center">
                            <Col md="3" lg={3} xs={12}>
                                <img
                                    src={image}
                                    alt="Microphone"
                                    className="img-fluid"
                                />
                            </Col>
                            <Col md="7" lg={7} xs={6}>
                                <h1 className="display-5">Engage, Learn, and Connect with FittestWarrior’s Social Media</h1>
                               
                            </Col>
                            <Col md="2" lg={2} xs={6}>
                            <div className="justify-content-end">
                                    <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                                    <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                                    <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                                </div>
                               
                            </Col>
                        </Row>
                    </Container>
                </div>
                <MultiStories />
            </div>
        </React.Fragment>
    );
};
