import React, { useEffect } from "react"
import { Spinner } from "reactstrap";
const Preloader = () => {
    return (
        <React.Fragment>
            {/* d-flex position-fixed top-0 start-0 end-0 bottom-0  justify-content-center align-items-center bg-dark bg-opacity-10 */}
            <div className="d-flex position-fixed top-0 start-0 end-0 bottom-0  justify-content-center align-items-center bg-dark bg-opacity-10" style={{zIndex:9999,backdropFilter:'blur(2px)'}}>
                <div className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center">
                    <Spinner className="me-2" color="primary" type="grow" size="sm" />
                    <Spinner className="me-2" color="secondary" type="grow" size="sm" />
                    <Spinner className="me-2" color="success"type="grow" size="sm" /> 
                    <Spinner className="me-2" color="danger" type="grow" size="sm" />
                    <Spinner className="me-2" color="warning" type="grow" size="sm" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Preloader;