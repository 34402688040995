import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MenuBar from "./MenuBar";
const Navbar = props => {
  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {if (window.location.pathname === items[i].pathname) {matchingMenuItem = items[i];break;}}
    if (matchingMenuItem) {activateParentDropdown(matchingMenuItem);}
  });
  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {item.classList.remove("active");}
      if (parent) {if (parent.classList.contains("active")) {parent.classList.remove("active");}}
    }
  };
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); 
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); 
      const parent3 = parent2.parentElement;
      if (parent3) {parent3.classList.add("active");const parent4 = parent3.parentElement;if (parent4) {parent4.classList.add("active");const parent5 = parent4.parentElement; if (parent5) {parent5.classList.add("active");const parent6 = parent5.parentElement; if (parent6) {parent6.classList.add("active");}}}}}
    return false;
  }
  return (
    <React.Fragment>
      <div className="topnav mt-0">
        <div className="container-fluid ms-5">
          <div className='d-none d-lg-block'>
          <nav className="navbar navbar-light navbar-expand topnav-menu"id="navigation">
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" ><ul className="navbar-nav"><MenuBar /></ul></Collapse>
          </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
Navbar.propTypes = {leftMenu: PropTypes.any,location: PropTypes.any,menuOpen: PropTypes.any,t: PropTypes.any,};
const mapStatetoProps = state => {const { leftMenu } = state.Layout;return { leftMenu };};
export default withRouter(connect(mapStatetoProps, {})(withTranslation()(Navbar)));
