import React from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './Scholarship.css'; // Add custom CSS
import image from '../../../assets/images/mission/promotion.png'
import Header from '../Header';
const Promotion = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">

                <div className="__scholarship-section">
                </div>
                <Container className="__mainpage">
                    <Row className="text-center title-section">
                        <Col lg={6}>
                            <h3 className="__title-section mt-3">Promotion & Branding</h3>
                        </Col>

                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <img src={image} alt="Sports Scholarships" className="__banner-image" />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col lg={6}>
                            <h3 className='__title-section '>Promotion & Branding</h3>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex">
                                <Input type="text" className="form-control me-2" placeholder="Search..." />
                                <Button>Apply</Button>
                            </div>
                        </Col>
                    </Row>
                    <p className='font-format fs-4 '>
                        <b> Targeted Advertising:</b> Fittest Warrior’s platform enables precise, audience-focused advertising for maximum impact, connecting your brand with health-conscious, active individuals.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Increased Engagement and Conversions: </b> With exclusive promotions and product placements on a platform frequented by fitness enthusiasts, you can increase brand engagement and conversion rates.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Access to Influencer Partnerships: </b>Connect with potential brand ambassadors who are influential in the sports and fitness space, helping you expand your brand’s reach through credible endorsements.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Amplify Your Brand:  </b>Fittest Warrior’s platform provides a perfect space for investors to promote their brands to a highly engaged and niche audience in sports, fitness, and wellness.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Strategic Advertising: </b>Use targeted advertising options, product placements, and influencer partnerships within the community to reach new customers.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Build Brand Ambassadorships:  </b>Identify potential brand ambassadors from our community of dedicated athletes and enthusiasts to represent and promote your brand.
                    </p>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Promotion;
