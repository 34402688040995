import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Row, Button, Card } from 'reactstrap';
import ReactSelect from 'react-select';
import { meals } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function AddMealModal(prop) {
    const { foodList, recipeList, userData, setMeal } = prop;
    const [status,setStatus] = useState(false);
    const dispatch = useDispatch();
    
    const [selectedFoods,setSelectedFoods] = useState([]);
    const [selectedSuppliments,setSelectedSuppliments] = useState([]);
    const [selectedRecipes,setSelectedRecipes] = useState([]);
    const toggleModal = ()=> {
        setStatus(!status);
        setSelectedFoods([]);
        setSelectedSuppliments([]);
        setSelectedRecipes([]);
    };
    const handleSubmit = e =>{
        e.preventDefault();
        dispatch(changePreloader({status:true,message:'Saving Meal Please wait....'}));
        const formData = new FormData(e.target);
        const data = {
            type: formData.get('type'),
            name: formData.get('name'),
            description: formData.get('description'),
            foods: [...selectedFoods,...selectedSuppliments].map(food=>({id:food.value,quantity:document.getElementById('food_input_'+food.value).value,remark:'none'})),
            recipes:selectedRecipes.map(recipe=>({id:recipe.value,quantity:document.getElementById('recipe_input_'+recipe.value).value,remark:'none'})),
        }
        meals.add(data).then(res=>{
            setMeal(state=>[res.data.meal,...state])
            e.target.reset();
            toggleModal();
        }).catch(e=>toast.error(e.response ? e.response.data.message : e.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})))
    }
    const handleFoodChange = (e,type) => type == 'foods'?setSelectedFoods(e):(type == 'suppliments'?setSelectedSuppliments(e):setSelectedRecipes(e));
    const handleAmountChange = (e,food_id) => {
        const nutrients = calculate_nutrients(e.target.value,food_id);
        document.getElementById('carb_'+food_id).innerText = nutrients.carb.toFixed(2);
        document.getElementById('prtn_'+food_id).innerText = nutrients.prot.toFixed(2);
        document.getElementById('fat_'+food_id).innerText = nutrients.fat.toFixed(2);
        document.getElementById('kcal_'+food_id).innerText = nutrients.calorie.toFixed(2);
        calculate_total();
    }
    const calculate_nutrients = (amount,food_id)=>{
        const foodData = foodList.filter(fd=>fd.id==food_id)[0];
        const unitMultiplier = parseFloat(amount || foodData.quantity )/parseFloat(foodData.quantity);
        const carb = parseFloat(foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity) * unitMultiplier;
        const prot = parseFloat(foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity) * unitMultiplier;
        const fat = parseFloat(foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity) * unitMultiplier;
        const calorie = ((carb*4) + (prot*4) + (fat*9));
        return {carb,prot,fat,calorie};

    }
    const calculate_total = () => {
        if(selectedFoods.length || selectedSuppliments.length || selectedRecipes.length){
            const total_carb = Array.from(document.getElementsByClassName('carb'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_prot = Array.from(document.getElementsByClassName('prot'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_fat = Array.from(document.getElementsByClassName('fat'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_kcal = Array.from(document.getElementsByClassName('kcal'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            document.getElementById('total_carb').innerText =  total_carb.toFixed(2);
            document.getElementById('total_prot').innerText = total_prot.toFixed(2);
            document.getElementById('total_fat').innerText = total_fat.toFixed(2);
            document.getElementById('total_kcal').innerText = total_kcal.toFixed(2);
        }
    }
    useEffect(()=>{calculate_total()},[selectedFoods,selectedSuppliments,selectedRecipes])
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"> Add Meal</i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>Add Meal</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} className='mb-2'>
                                <label className='control-label' htmlFor='name'>Meal Name</label>
                                <input type='text' className='form-control' name='name' id='name' />
                            </Col>
                            <Col xs={6} className='mb-2'>
                                <label className='control-label' htmlFor='food_type'>Type</label>
                                <Card>

                                    <ReactSelect id={'food_type'} name={'type'} options={[
                                        { label: 'Vegan', value: 'Vegan' },
                                        { label: 'vegetrain', value: 'veg' },
                                        { label: 'Egetrain', value: 'egg' },
                                        { label: 'Non-vegetrain', value: 'nonveg' },
                                        ]} />
                                    </Card>

                            </Col>
                            <Col xs={12} className='mb-2'>
                                <label htmlFor='description'>Description</label>
                                <textarea rows={2} className='form-control' id='description' name='description'></textarea>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Select Food</label>
                                    <Card>

                                    <ReactSelect onChange={e=>handleFoodChange(e,'foods')} isMulti name='food_ids[]' options={foodList?.filter(item => item.type !== "nonvegSuppliment" && item.type !== "vegSuppliment").map(f=>({value:f.id,label:f.name}))}/>
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Select Suppliment</label>
                                    <Card>

                                    <ReactSelect onChange={e=>handleFoodChange(e,'suppliments')} isMulti name='suppliment_ids[]' options={foodList?.filter(item => item.type === "nonvegSuppliment" || item.type === "vegSuppliment")?.map(f=>({value:f.id,label:f.name}))}/>
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Select Recipe</label>
                                    <Card>

                                    <ReactSelect onChange={e=>handleFoodChange(e,'recipes')} name='recipe_ids[]' isMulti isClearable options={recipeList.map(r=>({value:r.id,label:r.name}))}/>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        <hr className='mt-3'/>
                            <Col xs={12} className='table-responsive'>
                                <table className='table align-middle nowrap mt-2 table'>
                                    <thead>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Carbs</th>
                                        <th>Protien</th>
                                        <th>Fat</th>
                                        <th>Kcal</th>
                                    </thead>
                                    <tbody>
                                        {[...selectedFoods,...selectedSuppliments].map((f,k)=>{
                                            const foodData = foodList.filter(fd=>fd.id==f.value)[0];
                                            return (<tr key={k}>
                                                <td>{foodData?.name}</td>
                                                <td>
                                                    <div className="input-group" style={{width:'150px'}}>
                                                        <input defaultValue={foodData?.quantity} onChange={e=>handleAmountChange(e,f.value)} className='form-control' type='text' id={'food_input_'+f.value} />
                                                        <span className="input-group-text" id="basic-addon2"> <span className='px-1'>Gram</span></span>
                                                    </div>
                                                </td>
                                                <td className='carb' id={'carb_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity}</td>
                                                <td className='prot' id={'prtn_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity}</td>
                                                <td className='fat' id={'fat_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity}</td>
                                                <td className='kcal' id={'kcal_'+f.value}>{foodData?.nutrients.reduce((total,n)=>{
                                                    const multiplier = n.name == 'Carbohydrate' || n.name=='Protein' ? 4 : (n.name=='FAT'?9:0);
                                                    return total + (parseFloat(n.quantity) * multiplier);
                                                },0)}</td>
                                            </tr>)
                                        })}
                                        {selectedRecipes.map((f,k)=>{
                                            const recipeData = recipeList.filter(ld=>ld.id==f.value)[0];
                                            const nutrients = recipeData.foods.reduce((total, food) => {
                                                const nutrients = calculate_nutrients(food.pivot.food_quantity, food.id);
                                                total.carb += nutrients.carb;
                                                total.prot += nutrients.prot;
                                                total.fat += nutrients.fat;
                                                total.calorie += nutrients.calorie;
                                                return total;
                                            }, {carb:0, fat:0, prot:0, calorie:0});
                                            return (<tr key={k}>
                                                <td>{recipeData?.name}</td>
                                                <td>
                                                    <div className="input-group" style={{width:'150px'}}>
                                                        <input defaultValue={0} onChange={e=>{}} className='form-control' type='text' id={'recipe_input_'+f.value} />
                                                        <span className="input-group-text" id="basic-addon2"> <span className='px-1'>Gram</span></span>
                                                    </div>
                                                </td>
                                                <td className='carb' id={'carb_'+f.value+'_recipe'}>{nutrients.carb.toFixed(2)}</td>
                                                <td className='prot' id={'prtn_'+f.value+'_recipe'}>{nutrients.prot.toFixed(2)}</td>
                                                <td className='fat' id={'fat_'+f.value+'_recipe'}>{nutrients.fat.toFixed(2)}</td>
                                                <td className='kcal' id={'kcal_'+f.value+'_recipe'}>{nutrients.calorie.toFixed(2)}</td>
                                            </tr>)
                                        })}
                                        {[...selectedFoods,...selectedSuppliments,...selectedRecipes].length?(<tr>
                                            <td className='text-right' colSpan={2}>Total</td>
                                            <td id='total_carb'>0</td>
                                            <td id='total_prot'>0</td>
                                            <td id='total_fat'>0</td>
                                            <td id='total_kcal'>0</td>
                                        </tr>):null}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Save </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddMealModal