import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';

function ViewCategoryModal({ data }) {
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    return (
        <>
            <button onClick={() => toggleModal()} className="btn btn-sm btn-info me-2"><i className="mdi mdi-eye-outline"></i></button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Category Details</h5></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={6}>
                            <div className="mb-2">
                                <label className="control-label"> Category Title </label>
                                <Input disabled value={data?.category_title} className="form-control" name="category_title" />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="mb-2">
                                <label className="control-label">Category</label>
                                <Input disabled value={data?.category_name} className="form-control" name="category_name" />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <label className=''>Sub categories</label>
                        </Col>
                        <Col xs={12} className='my-1 justify-content-center d-flex'>
                            <div className='text-center' style={{ maxWidth: '800px' }}>
                                {data?.sub_category_name?.split(',')
                                    .map(sc => sc !== '' && (
                                        <div
                                            key={sc}
                                            className={'d-inline-flex justify-content-between align-items-center border border-secondary px-3 py-2 m-2'}
                                            style={{ borderRadius: '24px', whiteSpace: 'nowrap', position: 'relative', overflow: 'hidden' }}>
                                            <span className=''>{sc}</span>
                                        </div>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button onClick={toggleModal} className='btn btn-secondary'>Close</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ViewCategoryModal