import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';
import { Recipes } from 'helpers/api_url';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function NewRecipeModal(prop) {
    const {foodList,setRecipeData} = prop;
    const [status,setStatus] = useState(false);
    const [selectedFoods,setSelectedFoods] = useState([]);
    const [selectedSuppliments,setSelectedSuppliments] = useState([]);
    const dispatch = useDispatch();
    const toggleModal = ()=> {
        setStatus(!status);
        setSelectedFoods([]);
        setSelectedSuppliments([]);
    };
    const handleSubmit = e =>{
        e.preventDefault();
        dispatch(changePreloader({status:true,message:'please wait .....'}));
        const formData = new FormData(e.target);
        const food_ids = [...formData.getAll('food_ids[]'),...formData.getAll('suppliment_ids[]')];
        if(!food_ids.length){
            toast.error('Select Foods or Suppliments');
            return
        }
        const recipeData = {
            recipe_name:formData.get('recipe_name'),
            recipe_type:formData.get('recipe_type'),
            description:formData.get('description'),
            foods:food_ids?.map(id=>({id:id,quantity:document.getElementById('food_input_'+id)?.value,remark:'none'}))
        }
        Recipes.add_recipe(recipeData)
        .then((res) => {
            setRecipeData(state => [res.data.recipe,...state]);
            toast.success(res.message, { autoClose: 2000 });
            e.target.reset();
            toggleModal();
        })
        .catch((err) => toast.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})))
    }
    const handleFoodChange = (e,type) => type == 'foods'?setSelectedFoods(e):setSelectedSuppliments(e);
    const handleAmountChange = (e,food_id) => {
        const foodData = foodList.filter(fd=>fd.id==food_id)[0];
        const unitMultiplier = parseFloat(e.target.value || foodData.quantity )/parseFloat(foodData.quantity);
        const carb = parseFloat(foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity) * unitMultiplier;
        const prot = parseFloat(foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity) * unitMultiplier;
        const fat = parseFloat(foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity) * unitMultiplier;
        document.getElementById('carb_'+food_id).innerText = carb;
        document.getElementById('prtn_'+food_id).innerText = prot;
        document.getElementById('fat_'+food_id).innerText = fat;
        document.getElementById('kcal_'+food_id).innerText = (carb*4) + (prot*4) + (fat*9);
        calculate_total();
    }
    const calculate_total = () => {
        if(selectedFoods.length || selectedSuppliments.length){
            const total_carb = Array.from(document.getElementsByClassName('carb'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_prot = Array.from(document.getElementsByClassName('prot'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_fat = Array.from(document.getElementsByClassName('fat'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_kcal = Array.from(document.getElementsByClassName('kcal'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            document.getElementById('total_carb').innerText =  total_carb;
            document.getElementById('total_prot').innerText = total_prot;
            document.getElementById('total_fat').innerText = total_fat;
            document.getElementById('total_kcal').innerText = total_kcal;
        }
    }
    useEffect(()=>{calculate_total()},[selectedFoods,selectedSuppliments])
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>Add Recipe</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs={4} className='mb-2'>
                                <label htmlFor='name'>Receipe Name</label>
                                <Input type='text' name='recipe_name' id='name' />
                            </Col>
                            <Col xs={4} className='mb-2'>
                                <label htmlFor='recipe_type'>Type</label>
                                <Card>
                                <ReactSelect id={`recipe_type`} name={`recipe_type`} options={[
                                    { label: 'vegetrain', value: 'vegetrain' },
                                    { label: 'Egetrain', value: 'Egetrain' },
                                    { label: 'Non-vegetrain', value: 'Non-vegetrain' },
                                    { label: 'Vegan', value: 'Vegan' },
                                ]} />
                                </Card>
                            </Col>
                            <Col xs={4} className='mb-2'>
                                <label htmlFor='description'>Description</label>
                                <textarea className='form-control' id='description' name='description' rows={1}></textarea>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-2">
                                    <label className="control-label">Select Food</label>
                                    <Card>

                                    <ReactSelect onChange={e=>handleFoodChange(e,'foods')} isMulti name='food_ids[]' options={foodList?.filter(item => item.type !== "nonvegSuppliment" && item.type !== "vegSuppliment").map(f=>({value:f.id,label:f.name}))}/>
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-2">
                                    <label className="control-label">Select Suppliment</label>
                                    <Card>

                                    <ReactSelect onChange={e=>handleFoodChange(e,'suppliments')} isMulti name='suppliment_ids[]' options={foodList?.filter(item => item.type === "nonvegSuppliment" || item.type === "vegSuppliment")?.map(f=>({value:f.id,label:f.name}))}/>
                                    </Card>
                                </div>
                            </Col>
                            <hr className='mt-3'/>
                            <Col xs={12} className='table-responsive'>
                                <table className='table align-middle nowrap mt-2 table'>
                                    <thead>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Carbs</th>
                                        <th>Protien</th>
                                        <th>Fat</th>
                                        <th>Kcal</th>
                                    </thead>
                                    <tbody>
                                        {[...selectedFoods,...selectedSuppliments].map((f,k)=>{
                                            const foodData = foodList.filter(fd=>fd.id==f.value)[0];
                                            return (<tr key={k}>
                                                <td>{foodData?.name}</td>
                                                <td>
                                                    <div className="input-group" style={{width:'150px'}}>
                                                        <input defaultValue={foodData?.quantity} onChange={e=>handleAmountChange(e,f.value)} className='form-control' type='text' id={'food_input_'+f.value} />
                                                        <span className="input-group-text" id="basic-addon2"> <span className='px-1'>Gram</span></span>
                                                    </div>
                                                </td>
                                                <td className='carb' id={'carb_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity}</td>
                                                <td className='prot' id={'prtn_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity}</td>
                                                <td className='fat' id={'fat_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity}</td>
                                                <td className='kcal' id={'kcal_'+f.value}>{foodData?.nutrients.reduce((total,n)=>{
                                                    const multiplier = n.name == 'Carbohydrate' || n.name=='Protein' ? 4 : (n.name=='FAT'?9:0);
                                                    return total + (parseFloat(n.quantity) * multiplier);
                                                },0)}</td>
                                            </tr>)
                                        })}
                                        {[...selectedFoods,...selectedSuppliments].length?(<tr>
                                            <td className='text-right' colSpan={2}>Total</td>
                                            <td id='total_carb'>0</td>
                                            <td id='total_prot'>0</td>
                                            <td id='total_fat'>0</td>
                                            <td id='total_kcal'>0</td>
                                        </tr>):null}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Save </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default NewRecipeModal