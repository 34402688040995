import React, { useState } from 'react';
import Header from '../Header';
import { Card, CardBody, Row, Col, Carousel, CarouselIndicators, CarouselControl, CarouselItem } from 'reactstrap';
import './AboutUsPage.css';
import image1 from '../../../assets/images/slider/img1.jpg';
import image2 from '../../../assets/images/slider/img2.jpg';
import image3 from '../../../assets/images/slider/img3.jpg';
import img1 from '../../../assets/images/about/img1.png';
import img2 from '../../../assets/images/about/img2.png';
import img3 from '../../../assets/images/about/img3.png';
import img4 from '../../../assets/images/about/img4.png';
import img5 from '../../../assets/images/about/img5.png';
import img6 from '../../../assets/images/about/img6.png';
import img7 from '../../../assets/images/about/img7.png';
import img8 from '../../../assets/images/about/img8.png';
import img9 from '../../../assets/images/about/img9.png';
import img10 from '../../../assets/images/about/img10.png';
import img11 from '../../../assets/images/about/img11.png';
import img12 from '../../../assets/images/about/img12.png';
import img13 from '../../../assets/images/about/img13.png';
import img14 from '../../../assets/images/about/img14.png';
import SlideMain from '../CarouselTypes/SlideMain';


export const AboutUsPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const aboutUsData = [
        { image: image1, effect: "fade-effect", },
        { image: image2, effect: "zoom-effect", },
        { image: image3, effect: "slide-effect", }
    ];
    const whoWeAreData = [
        { image: img2, effect: "zoom-effect", },
        { image: img1, effect: "fade-effect", },
        { image: img3, effect: "slide-effect", }
    ];
    const participatorData = [
        { image: img4, effect: "fade-effect", },
        { image: img6, effect: "slide-effect", },
        { image: img5, effect: "zoom-effect", },
    ];
    const professionalData = [
        { image: img6, effect: "zoom-effect", },
        { image: img5, effect: "fade-effect", },
        { image: img7, effect: "slide-effect", }
    ];
    const clientData = [
        { image: img8, effect: "fade-effect", },
        { image: img10, effect: "slide-effect", },
        { image: img9, effect: "zoom-effect", },
    ];
    const visitorData = [
        { image: img12, effect: "zoom-effect", },
        { image: img11, effect: "fade-effect", },
        { image: img13, effect: "slide-effect", }
    ];
    const communityData = [
        { image: img14, effect: "fade-effect", },
        { image: img1, effect: "slide-effect", },
        { image: img4, effect: "zoom-effect", },
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === aboutUsData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? aboutUsData.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = aboutUsData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides2 = whoWeAreData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides3 = participatorData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides4 = professionalData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides5 = visitorData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides6 = communityData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });
    const slides7 = clientData.map((item, index) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={index} className={item.effect}>
                <img src={item.image} alt={item.title} className="d-block w-100 luxury-image" />
            </CarouselItem>
        );
    });

    return (
        <React.Fragment>
            <Header />
            <SlideMain />
            <div className="page-content " style={{ background: '#f5f5f5',overflow:'hidden' }}>
                <h2 className='text-center' style={{ color: '#805e18' }}>Know us Information</h2>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card py-5' >
                                    <h2 className=''>Who We Are</h2>
                                    <h4 className=''>Welcome to FittestWarrior</h4>
                                    <b className='fs-5'>Creating a Comprehensive Sports Ecosystem, Competitions and Thriving Community</b>
                                    <p className='font-format'>
                                    At FittestWarrior, we are building a dynamic platform designed to connect people passionate about sports, health, and fitness with a network of communities and resources nearby. Whether you're looking for training partners, health advice, or networking opportunities, FittestWarrior is your go-to hub. Our goal is to create a mutually beneficial environment where each user gains value, fostering collaboration and growth across the board. <br />
                                    We organize local, regional, and national events that cater to a wide range of sports, offering everyone a chance to compete and improve.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides2}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >
                        <div className=' ps-4 py-4'>
                            <h2 className=''>For Community</h2>
                            <h4><b>Uniting Sports, Health, and Fitness Communities</b></h4>
                            <p className='font-format '>
                            Our platform brings together a wide range of groups and organizations: educational institutions, gyms, fitness centres, corporate mnc’s entities, housing societies, government sectors, forces, non-profits, influencers, and sports federations. This unique gathering creates an ecosystem where opportunities flourish, and growth is encouraged through competitive events, sports competition, skill development, and wellness resources.<br />
                            By connecting these diverse groups, we support participants in sports, health, and fitness, allowing them to leverage shared resources, improve access to quality training, and elevate the overall standard of health and fitness within the community.

                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>
                                    <h2 className=''>For Investors</h2>
                                    <h4><b>A Valuable Opportunity for Growth and Sponsorship</b></h4>
                                    <p className='font-format'>
                                    FittestWarrior offers investors an opportunity to connect with a rapidly growing sports and fitness community. Here, investors can sponsor top talent, discover potential brand ambassadors, and directly engage with competitions and events that align with their brand vision. The platform also allows for direct interaction with companies and brands, streamlining partnerships and sponsorships.<br />
                                    Investors are invited to support our mission by investing in Fittest Warrior’s start-up, where their contributions fuel growth while promising strong returns as the community continues to expand.

                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides3}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides4}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >

                        <div className='ps-4 py-4'>
                            <h2 className=''>For Participator</h2>
                            <h4><b>Empowering Athletes and Fitness Enthusiasts with Resources</b></h4>
                            <p className='font-format'>
                            FittestWarrior is committed to supporting sports players, athletes and fitness enthusiasts, especially during the critical training stages that often lack financial backing. Through our platform, participants can access sponsorships, scholarships, and brand partnerships, reducing their financial burdens and allowing them to focus on training.<br />
                            Additionally, the platform offers access to part-time jobs, personalized training locations, and connections to top-tier coaches. By facilitating more practice matches, FittestWarrior also provides participants with ample opportunity to hone their skills and track their progress on national and international leaderboards.

                            </p>
                        </div>

                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>
                                    <h2 className=''>For proffessional</h2>
                                    <h4><b>A Hub for Fitness and Health Experts to Connect and Grow</b></h4>
                                    <p className='font-format'>
                                    FittestWarrior creates a space for professionals in the sports, health, and fitness industries to share their expertise and offer guidance to users. Whether providing consultations, organizing events, or sharing specialized knowledge, professionals can enhance their reach and impact within the community. With options to manage their own schedules and connect with clients, this platform enables professionals to flourish and make meaningful contributions to the fitness community.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel  my-5" fade={true}>
                            {slides5}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel" fade={true}>
                            {slides6}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                    <Col lg="5" className="about-text p-0" >
                        <div className=' ps-4 py-4'>
                            <h2 className=''>For Visitor</h2>
                            <h4><b>Accessible Wellness Resources for All</b></h4>
                            <p className='font-format'>
                            For those who may not wish to pursue sports or fitness professionally but are passionate about maintaining an active lifestyle, FittestWarrior provides a wealth of information and resources. Visitors can explore content on sports, health, and fitness without the need to sign up, allowing them to increase their knowledge and connect with like-minded individuals nearby if they wish. We believe that fitness should be inclusive, and FittestWarrior is here to support everyone on their journey to wellness.</p>
                        </div>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col lg="5" className="about-text p-0" >
                        <Card >
                            <CardBody className='side-card' >
                                <div className='__text-card ps-4 py-5'>

                                    <h2 className=''>For Client</h2>
                                    <h4><b>Comprehensive Resources for Health and Fitness Enthusiasts</b></h4>
                                    <p className='font-format'>
                                    FittestWarrior offers clients access to an array of resources tailored to their fitness journey, including diet plans, workout routines, time management guides, self-assessment tools, fitness calculators, and exclusive subscriptions. This platform serves as a one-stop destination for clients seeking personalized tools and insights to enhance their health and fitness experience.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="7" className="about-image p-0">
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} className="luxury-carousel my-5" fade={true}>
                            {slides7}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
                <p className='font-format px-5'>
                FittestWarrior aims to elevate each section with clear goals, encouraging tone, and a focus on the benefits of each user group joining the FittestWarrior platform. Let us know if there are specific details you’d like to know!
                </p>
            </div>
        </React.Fragment>
    );
};
export default AboutUsPage;
