import React, { useEffect, useState } from "react"
import {
  Container, Row, Col,
  Card, CardBody, CardHeader, CardTitle, CardFooter,
  Form, Input, Button,
  Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap"
import ReactSelect from "react-select"
import { exercise } from "helpers/api_url"
import AddSubCategoryModal from "./AddSubCategoryModal"
import { changePreloader } from "store/actions"
import { useDispatch } from "react-redux"

const AddEx = ({tabNumber}) => {
  const dispatch=useDispatch();
  const [categoryName, setCategoryName] = useState("")
  const [categories,setCategories] = useState([]);
  const [modalStatus,setModalStatus] = useState(false);
  const toggleModal = () => setModalStatus(!modalStatus);
  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    exercise.addExercise(formData)
    .then(res=>console.log(res))
    .catch(e=>console.warn(e))
  }
  useEffect(()=>{
    if (tabNumber == 2) {
      dispatch(changePreloader({ status: true, message: 'please wait' }));
    exercise.getAllCategory().then(res=>setCategories(res.data.category)).catch(e=>console.error(e));
    dispatch(changePreloader({ status: false, message: '' }))
    }
  },[tabNumber])

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleCheckboxChange = (id, checked) => {
    setSelectedOptions({ ...selectedOptions, [id]: checked });
  };
  const handleAddCategory = e => {
    e.preventDefault();
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Card>
          <CardHeader> <CardTitle>Add Exercise</CardTitle> </CardHeader>
          <CardBody>
            <Row>
                <Col xs={12}>
                    <div className="mb-2">
                      <label className="control-label">Exercise Name</label>
                      <input name="exercise_name" placeholder="Exercise Name" className="form-control"/>
                    </div>
                </Col>


                <hr className="my-4" />
                <h5 className="mt-2">Muscle Group</h5>
                {categories?.filter(i => i.category_title === 'Muscle Group')?.map((c, index) => (
                  <Row key={index} className="mb-2">
                      <Col xs={6} md={4} lg={3} className="d-flex align-items-center justify-content-between">    
                        <div className="form-check me-3 d-flex align-items-center justify-content-between w-100"> 
                          <label className="form-check-label" htmlFor={'checkbox_' + c.id}>{c.category_name}</label>
                          <input
                            className="form-check-input mx-3"
                            type="checkbox"
                            id={'checkbox_' + c.id}
                            checked={selectedOptions[c.id] || false}
                            onChange={e => handleCheckboxChange(c.id, e.target.checked)}
                            style={{ height: '20px', width: '20px' }}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={8} lg={9} className="d-flex align-items-center">
                        <ReactSelect
                          id={'select_id_' + c.id}
                          name={"muscle_group[]"}
                          isMulti
                          // options={c?.sub_category_name?.split(',')?.map(i => ({ label: i, value: i }))}
                          options={c?.sub_category_name?.split(',')?.filter(scn=>scn!='')?.map(i => ({ label: i, value: `{"category":"${c.category_name}","sub_category":"${i}"}` }))}
                          isDisabled={!selectedOptions[c.id]}
                          className="flex-grow-1 me-2"
                        />
                        <AddSubCategoryModal setCategories={setCategories} data={c} >
                          <button disabled={!selectedOptions[c.id]} className="btn btn-soft-success">+</button>
                        </AddSubCategoryModal>
                        
                      </Col>
                  </Row>
                ))}
                <hr className="my-4" />
                <h5 className="mt-2" >Experience Level</h5>
                {categories?.filter(i => i.category_title === 'Experience Level')?.map((c, index) => (
                  <Row key={index} className="mb-2">
                      <Col xs={3} className="d-flex align-items-center justify-content-between">    
                        <div className="form-check me-3 d-flex align-items-center justify-content-between w-100"> 
                          <label className="form-check-label" htmlFor={'checkbox_' + c.id}>{c.category_name}</label>
                          <input
                            className="form-check-input mx-3"
                            type="checkbox"
                            id={'checkbox_' + c.id}
                            checked={selectedOptions[c.id] || false}
                            onChange={e => handleCheckboxChange(c.id, e.target.checked)}
                            style={{ height: '20px', width: '20px' }}
                          />
                        </div>
                      </Col>
                      <Col xs={9} className="d-flex align-items-center">
                        <ReactSelect
                          id={'select_id_' + c.id}
                          name={"experience_level[]"}
                          isMulti
                          // options={c?.sub_category_name?.split(',')?.map(i => ({ label: i, value: i }))}
                          options={c?.sub_category_name?.split(',')?.filter(scn=>scn!='')?.map(i => ({ label: i, value: `{"category":"${c.category_name}","sub_category":"${i}"}` }))}
                          isDisabled={!selectedOptions[c.id]}
                          className="flex-grow-1 me-2"
                        />
                        <AddSubCategoryModal setCategories={setCategories} data={c} >
                          <button disabled={!selectedOptions[c.id]} className="btn btn-soft-success">+</button>
                        </AddSubCategoryModal>
                      </Col>
                  </Row>
                ))}
                <hr className="my-4" />
                <h5 className="mt-2" >Excercise Type</h5>
                {categories?.filter(i => i.category_title === 'Excercise Type')?.map((c, index) => (
                  <Row key={index} className="mb-2">
                      <Col xs={3} className="d-flex align-items-center justify-content-between">    
                        <div className="form-check me-3 d-flex align-items-center justify-content-between w-100"> 
                          <label className="form-check-label" htmlFor={'checkbox_' + c.id}>{c.category_name}</label>
                          <input
                            className="form-check-input mx-3"
                            type="checkbox"
                            id={'checkbox_' + c.id}
                            checked={selectedOptions[c.id] || false}
                            onChange={e => handleCheckboxChange(c.id, e.target.checked)}
                            style={{ height: '20px', width: '20px' }}
                          />
                        </div>
                      </Col>
                      <Col xs={9} className="d-flex align-items-center">
                        <ReactSelect
                          id={'select_id_' + c.id}
                          name={"exercise_type[]"}
                          isMulti
                          // options={c?.sub_category_name?.split(',')?.map(i => ({ label: i, value: i }))}
                          options={c?.sub_category_name?.split(',')?.filter(scn=>scn!='')?.map(i => ({ label: i, value: `{"category":"${c.category_name}","sub_category":"${i}"}` }))}
                          isDisabled={!selectedOptions[c.id]}
                          className="flex-grow-1 me-2"
                        />
                        <AddSubCategoryModal setCategories={setCategories} data={c} >
                          <button disabled={!selectedOptions[c.id]} className="btn btn-soft-success">+</button>
                        </AddSubCategoryModal>
                      </Col>
                  </Row>
                ))}
                <hr className="my-4" />
                <h5 className="mt-2" >Equipments</h5>
                {categories?.filter(i => i.category_title === 'Equipments')?.map((c, index) => (
                  <Row key={index} className="mb-2">
                      <Col xs={3} className="d-flex align-items-center justify-content-between">    
                        <div className="form-check me-3 d-flex align-items-center justify-content-between w-100"> 
                          <label className="form-check-label" htmlFor={'checkbox_' + c.id}>{c.category_name}</label>
                          <input
                            className="form-check-input mx-3"
                            type="checkbox"
                            id={'checkbox_' + c.id}
                            checked={selectedOptions[c.id] || false}
                            onChange={e => handleCheckboxChange(c.id, e.target.checked)}
                            style={{ height: '20px', width: '20px' }}
                          />
                        </div>
                      </Col>
                      <Col xs={9} className="d-flex align-items-center">
                        <ReactSelect
                          id={'select_id_' + c.id}
                          name={"equipments[]"}
                          isMulti
                          // options={c?.sub_category_name?.split(',')?.map(i => ({ label: i, value: i }))}
                          options={c?.sub_category_name?.split(',')?.filter(scn=>scn!='')?.map(i => ({ label: i, value: `{"category":"${c.category_name}","sub_category":"${i}"}` }))}
                          isDisabled={!selectedOptions[c.id]}
                          className="flex-grow-1 me-2"
                        />
                        <AddSubCategoryModal setCategories={setCategories} data={c} >
                          <button disabled={!selectedOptions[c.id]} className="btn btn-soft-success">+</button>
                        </AddSubCategoryModal>
                      </Col>
                  </Row>
                ))}
                <hr className="my-4" />
                <h4 className="mt-2">Specification</h4>
                <hr className="my-4" />
                <Col xs={12} className="mb-2">
                    <div className="mb-2 row">
                      <Col xs={4}><label className="control-label">Area of Body Part</label></Col>
                      <Col xs={8}>
                        <input name="area_of_body_part[]" value='trunk_upper' type="checkbox" /> <span className="mx-2">Trunk/upper</span>
                        <input name="area_of_body_part[]" value="arms" type="checkbox" /> <span className="mx-2">Arms</span>
                        <input name="area_of_body_part[]" value="leg_lower" type="checkbox" /> <span className="mx-2">Leg/Lower</span>
                      </Col>
                    </div>
                </Col>
                <Col xs={12} className="mb-2">
                    <div className="mb-2 row">
                      <Col xs={4}><label className="control-label">Gravity</label></Col>
                      <Col xs={8}>
                        <input name="gravity[]" value="pull" type="checkbox" /> <span className="mx-2">Pull</span>
                        <input name="gravity[]" value="push" type="checkbox" /> <span className="mx-2">Push</span>
                      </Col>
                    </div>
                </Col>
                <Col xs={12} className="mb-2">
                    <div className="mb-2 row">
                      <Col xs={4}><label className="control-label">Techniques</label></Col>
                      <Col xs={8}>
                        <input name="techniques[]" value="static" type="checkbox" /> <span className="mx-2">Static</span>
                        <input name="techniques[]" value="dynamic" type="checkbox" /> <span className="mx-2">Dynamic</span>
                        <input name="techniques[]" value="polymatric" type="checkbox" /> <span className="mx-2">Polymatric</span>
                        <input name="techniques[]" value="explosive" type="checkbox" /> <span className="mx-2">Explosive</span>
                      </Col>
                    </div>
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <div>
                    <label className="control-label">Muscle Size</label>
                    <ReactSelect
                        id="muscle_size"
                        name="muscle_size"
                        options={[
                          {label:'Small',value:'small'},
                          {label:'Medium',value:'medium'},
                          {label:'Big',value:'big'},
                        ]}
                      />
                  </div>
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <div>
                    <label className="control-label">Intensity / Mobility</label>
                    <ReactSelect
                        id="intensity_mobility"
                        name="intensity_mobility"
                        options={[
                          {label:'Normal',value:'normal'},
                          {label:'Medium',value:'medium'},
                          {label:'High',value:'high'},
                        ]}
                      />
                  </div>
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <div>
                    <label className="control-label">Mechanics</label>
                    <ReactSelect
                        id="mechanics"
                        name="mechanics"
                        options={[
                          {label:'Bilateral/compound',value:'bilateral_compound'},
                          {label:'Unilateral/Isolateral',value:'unilateral_isolateral'},
                        ]}
                      />
                  </div>
                </Col>
                <Col xs={12} className="mb-2">
                  <div>
                    <label className="control-label">Secondary Muscles</label>
                    <ReactSelect
                        id="secondary_muscles"
                        isMulti
                        name="secondary_muscles[]"
                        options={categories?.filter(i => i.category_title === 'Muscle Group')?.map(c =>({label:c.category_name,value:c.category_name}))}
                      />
                  </div>
                </Col>
                <Row>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">Do's</label>
                      <textarea className="form-control" name="dos"></textarea>
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">Don'ts</label>
                      <textarea className="form-control" name="donts"></textarea>
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">Image</label>
                      <input type="file" accept="image/jpeg, image/png"  className="form-control" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">YouTube Link</label>
                      <input type="text" name="link_youTube"  className="form-control" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">InstaGram Link</label>
                      <input type="text" name="link_instaGram"  className="form-control" />
                    </div>
                  </Col>
                  <Col xs={12} md={6} className="mb-2">
                    <div>
                      <label className="control-label">Facebook Link</label>
                      <input type="text" name="link_facebook"  className="form-control" />
                    </div>
                  </Col>
                </Row>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-end">
            <button className="btn btn-success px-5">Save</button>
          </CardFooter>
        </Card>
      </Form>
      <Modal isOpen={modalStatus} toggle={() => toggleModal()}>
        <ModalHeader toggle={() => toggleModal()}><h5>Add Sub Category</h5></ModalHeader>
        <form onSubmit={handleAddCategory}>
            <ModalBody>
                <Row>
                <Col xs={12}>
                    <div className="mb-2">
                    <label className="control-label"> Category Title </label>
                    <input className="form-control" value={'null'} name="category_title" readOnly />
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="mb-2">
                        <label className="control-label"> Category </label>
                        <input className="form-control" value={'null'}  name="category_name" readOnly />
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="mb-2">
                        <label className="control-label"> Sub Category </label>
                        <Input name="sub_category_name" className="form-control"/>
                    </div>
                </Col>
                </Row>
            </ModalBody>
            <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default AddEx
