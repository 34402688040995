import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './Sponsorship.css'; 
import Header from '../Header';
import image from '../../../assets/images/SportEcoSystem/eco.png'
const SportEcoSystem = () => {
  return (
    <React.Fragment>
    <Header />
    <div className="page-content mt-5">
            
                <div className="__scholarship-section">
                </div>
                    <Container className="__mainpage">
                        <Row className="text-center title-section">
                            <Col>
                                <h1 className="__main-title">Sport Eco System</h1>
                                <p className="__sub-title">Admissions | Updated 02 Jul, 2024</p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                <img src={image} alt="Sports Scholarships" className="__banner-image" />
                            </Col>
                        </Row>
                        <h3 className='__title-section mt-3'>Sports Eco System</h3>
                        <p className='font-format fs-3 '>
                          Our ecosystem represents a powerful network where resources, individuals, investors, institutions, and businesses converge to support the growth of sports, health, and fitness in India. This interconnected network will create an environment—both physical and virtual—where sports and fitness can thrive. By harnessing the power of our community, we’ll drive opportunities, promote innovation, and bring together all who share a passion for elevating Indian sports and fitness.
                        </p>
                    </Container>
            </div>
    </React.Fragment>
  );
};

export default SportEcoSystem;
