import { AssessmentApi, community, register } from 'helpers/api_url';
import { useModalHandler } from 'helpers/customHook';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {changePreloader as setPreloader} from 'store/layout/actions'
import { Row, Col, Modal, ModalBody, ModalHeader, Form, Label, Input, Card } from 'reactstrap'
import { useSelector } from "react-redux";
export const AssessmentForm = (props) => {
    const { status, toggleModal } = useModalHandler();
    const userData = useSelector(state=>state.Login.authUser);
    const dispatch=useDispatch();
    const handleSubmit=(e)=>{
        e.preventDefault();
        dispatch(setPreloader({loader:true,message:'please wait'}));
        let formData = new FormData(e.target);
        AssessmentApi.CreateAssessment(formData)
        .then((res) => {
            props.setTableData(state=>[res.data.questions,...state]);
            toast.success(res.message, { autoClose: 2000 });e.target.reset();
        })
        .catch((err) => {
          toast.error(err.response ? err.response.data.message : err.message);
        })
        .finally(()=>
            dispatch(setPreloader({loader:false,message:''}))
        );
    }
    
    return (
        <React.Fragment>
            <button onClick={() => toggleModal(true)} className='btn btn-soft-success add-btn waves-effect'>
                <i className="bx bx-question-mark align-bottom me-1 mb-1"></i>
                <span>Add Question</span>
            </button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal(false)}>Add New Question </ModalHeader>
                <ModalBody>
                    <Card>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <input type='hidden' name='type' value={props.type} />
                                <input type='hidden' name='created_by' value={userData?.role?.role_type}/>
                                <Col lg={12}>
                                    <div className='text-center'>Enter Your Question here <br /> (supports "yes" or "no" answers only)</div>
                                </Col>
                                <Col lg={12} xs={12} className='mt-2'>
                                    <div className="form-floating">
                                        <textarea className="form-control" name='question'  id="floatingTextarea"></textarea>
                                        <label htmlFor="floatingTextarea">Question ?</label>
                                    </div>
                                </Col>
                                <div className="mt-4 text-center">
                                    <div className="mb-2 d-grid"><button className="btn btn-primary btn-block" type="submit">Save</button></div>
                                </div>
                            </Row>
                        </Form>
                    </Card>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}
