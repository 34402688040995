import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import './slider_custom.css';  
import img1 from "assets/images/slider/img1.jpg";
import img2 from "assets/images/slider/img2.jpg";
import img3 from "assets/images/slider/img3.jpg";
const Investors = () => {
  return (
    <Container fluid className="investors-section text-center" style={{overflowX: "hidden",}}>
      <h2 className="investors-title">INVESTORS</h2>
      <Row className="justify-content-center mt-4">
        <Col lg="3" md="6" className="mb-4">
          <Card className="investor-card" style={{ backgroundImage: `url(${img3})`, height:'300px' }}>
            <CardBody className='investor-body'>
              <div className="overlay">
                <h4>INVEST IN OUR</h4>
                <h4 className="highlight-text fs-1"><em>STARTUP</em></h4>
                <h4>PROJECT</h4>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg="1" md="1" className="vertical-divider justify-content-center">
        </Col>

        <Col lg="3" md="6" className="mb-0">
          <Card className="investor-card" style={{ backgroundImage: `url(${img1})`,height:'300px' }}>
            <CardBody className='investor-body'>
              <div className="overlay">
                <h4>INVEST IN</h4>
                <h4 className="highlight-text fs-1"><em>BRANDING</em></h4>
                <h4>AND</h4>
                <h4 className='highlight-text fs-1'><em>PROMOTION</em></h4>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg="1" md="1" className="vertical-divider justify-content-center"></Col>

        <Col lg="3" md="6" className="mb-0">
          <Card className="investor-card" style={{ backgroundImage: `url(${img2})`,height:'300px' }}>
            <CardBody className='investor-body'>
              <div className="overlay">
                <h4>INVEST IN</h4>
                <h4 className="highlight-text fs-1"><em>SPONSORSHIP</em></h4>
                <h4>AND</h4>
                <h4 className='highlight-text fs-1'><em>SCHOLARSHIP</em></h4>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Investors;
