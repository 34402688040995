import React from 'react'

export const Media = () => {
    return (
        <div>
            <h3 className="__title-section mt-3">Media</h3>
            <b>Visual and Engaging Content</b>
            <p className='font-format fs-4 '>
                Our Media section includes high-quality visuals, engaging videos, and highlights from FittestWarrior events, competitions, and live training sessions. This content provides users with a glimpse into the vibrant FittestWarrior community and showcases the achievements of our users. From transformation stories to event recaps, the media content is designed to inspire, entertain, and celebrate the accomplishments of our followers.
            </p>
        </div>
    )
}
