import React, { useState } from 'react';
import { Container, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import './Sponsorship.css';
import Header from '../Header';
import image from '../../../assets/images/investor/investor.png';

const Investor = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);

  const toggleApplyModal = () => setIsApplyModalOpen(!isApplyModalOpen);

  return (
    <React.Fragment>
      <Header />
      <div className="page-content mt-5">
        <Container className="__sponsorship-container">
        <Row className="my-4 px-3">
            <Col md="6" className="d-flex align-items-center">
              <Label for="searchBox" className="me-2"></Label>
              <Input
                type="text"
                id="searchBox"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for opportunities"
              />
            </Col>
            <Col md="6" className="d-flex justify-content-end align-items-center">
              <p className='__meta-info mb-0 me-3'>Interested in investing with us?</p>
              <Button color="success" onClick={toggleApplyModal}>Apply Now</Button>
            </Col>

          </Row>
          <Row className="__title-section px-3">
            <Col md="8">
              <h1 className="">Investors</h1>
              <h4 className="__main-title">Maximize Your Returns and Brand Presence with FittestWarrior</h4>
              <p>
                FittestWarrior offers investors a unique opportunity to benefit from a rapidly growing sports, health, and fitness ecosystem.
                Each of our investment options provides distinct advantages, helping you achieve visibility, brand loyalty, and substantial returns.
                Here’s why each pathway could be valuable for you.
              </p>
              <h4 className="__main-title">Your Opportunity to Grow with FittestWarrior</h4>
              <p>
                At FittestWarrior, we’re committed to creating value for our investors by building a unique sports, health, and fitness ecosystem.
                Through targeted options such as scholarships, sponsorships, brand promotion, and partnerships, investors have multiple avenues to make impactful contributions and see meaningful returns.
                Here’s how you can invest with us:
              </p>
            </Col>
            <Col md="4" className="__image-section">
              <img
                src={image}
                alt="Sponsorship in push"
                className="__sponsorship-image"
              />
            </Col>
          </Row>
          <hr />
          <Row className='px-3'>
            <Col lg={8}>
              <h4 className="__main-title">Invest With Us</h4>
            </Col>
            <Col lg={4}>
              <Button color="primary">Download-Pitch Deck</Button>
            </Col>
          </Row>
         
          <p className='font-format fs-5 px-3'>
            <b>Strong ROI Potential:</b> Investing in FittestWarrior as an early-stage investor positions you to benefit from the platform’s exponential growth.
            As we expand our user base and service offerings, your investment grows in value, delivering solid returns.
          </p>
          <p className='font-format fs-5 px-3'>
            <b>Market Entry Advantage:</b> Our platform is positioned in the high-demand sectors of sports, fitness, and wellness,
            giving you an early advantage in a competitive, fast-evolving industry.
          </p>
          <p className='font-format fs-5 px-3'>
            <b>Portfolio Diversification:</b> With FittestWarrior, you add a high-potential, mission-driven start-up to your portfolio, ensuring both financial and social returns.
          </p>
          <p className='font-format fs-5 px-3'>
            <b>Platform Growth:</b> Support our mission by investing directly in FittestWarrior’s expansion. Your investment fuels the growth of our user base,
            community offerings, and resource library, enhancing the experience for all participants and ensuring a robust, self-sustaining ecosystem.
          </p>
        </Container>
      </div>

      {/* Apply Modal */}
      <Modal isOpen={isApplyModalOpen} toggle={toggleApplyModal}>
        <ModalHeader toggle={toggleApplyModal}>Apply for Investment</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" id="name" placeholder="Enter your name" />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" id="email" placeholder="Enter your email" />
            </FormGroup>
            <FormGroup>
              <Label for="investmentAmount">Investment Amount</Label>
              <Input type="number" id="investmentAmount" placeholder="Enter the amount you want to invest" />
            </FormGroup>
            <Button color="primary" type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Investor;
