import { useModalHandler } from 'helpers/customHook'
import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

export const ViewFood = ({food}) => {
    const {status, toggleModal}=useModalHandler();
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                <i className="mdi mdi-eye-outline" />
            </button>
            
            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>View Food</ModalHeader>
                <ModalBody>
                <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            <ul className="list-group">
                                <li className='list-group-item bg-light' >
                                    <Row>
                                        <Col xs={4}><span className='fw-bold'>Food Name </span> <br /> {food.name}</Col>
                                        <Col className='' xs={4}><span className='fw-bold'>Food Type</span><br />{food.type}</Col>
                                        <Col xs={4}><span className='fw-bold text-start'> Total  <br />{food.quantity}</span></Col>
                                    </Row>
                                </li>

                                {
                                    food?.nutrients.map((item,idx)=>(
                                        (item.name=="Calorie")?'':
                                <li className='list-group-item' key={idx}>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>{item.name}</span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}><span className=''>{item.quantity}</span> Gram</Col>
                                    </Row>
                                </li>
                                ))
                                }
                                
                                 <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Calorie</span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}><span className=''>{food?.nutrients[0].quantity*4+food?.nutrients[1].quantity*4+food?.nutrients[2].quantity*9}</span> Kcal</Col>
                                    </Row>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}
