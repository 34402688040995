import React from 'react'
import { Row } from 'reactstrap'

export const CompanySocialMedia = () => {
    return (
        <React.Fragment>
            <Row>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="instagram_link" className="form-label">Instagram</label>
                        <input type='text' name='instagram_link' id='instagram_link' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="facebook_link" className="form-label">FaceBook</label>
                        <input type='text' name='facebook_link' id='facebook_link' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="youtube_link" className="form-label">Youtube</label>
                        <input type='text' name='youtube_link' id='youtube_link' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="twitter_link" className="form-label">Twitter</label>
                        <input type='text' name='twitter_link' id='twitter_link' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="linkedin_link" className="form-label">Linkedin</label>
                        <input type='text' name='linkedin_link' id='linkedin_link' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="skype_link" className="form-label">Skype</label>
                        <input type='text' name='skype_link' id='skype_link' className='form-control' />
                    </div>
                </div>
            </Row>
        </React.Fragment>
    )
}
