import React, { useState } from 'react';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Input, Button, Nav, NavItem, NavLink, TabContent, TabPane, CardText } from 'reactstrap';
import classNames from 'classnames';

function Pace() {

    const [calculationType, setCalculationType] = useState("pace");
    const [distance, setDistance] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [paceHours, setPaceHours] = useState("");
    const [paceMinutes, setPaceMinutes] = useState("");
    const [paceSeconds, setPaceSeconds] = useState("");
    const [result, setResult] = useState("");
    const [unitSystem, setUnitSystem] = useState("miles");
    const [metricSystem, setMetricSystem] = useState("minutesMile");

    const [activeTab, setactiveTab] = useState("1");
    const toggle = tab => { if (activeTab !== tab) { setactiveTab(tab); } };

    const calculatePace = () => {
        const totalSeconds =
            parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
        const distanceFloat = parseFloat(distance);

        if (totalSeconds && distanceFloat) {
            let pace = totalSeconds / distanceFloat;

            if (unitSystem === "kilometers") {
                pace *= 0.621371; // Convert pace to minutes per mile
            }

            const paceMinutes = Math.floor(pace / 60);
            const paceSeconds = Math.floor(pace % 60);
            const paceHours = Math.floor(paceMinutes / 60);

            setPaceHours(paceHours);
            setPaceMinutes(paceMinutes % 60);
            setPaceSeconds(paceSeconds);

            if (metricSystem === "minutesKilometer") {
                setResult(`${paceMinutes}m ${paceSeconds}s per kilometer`);
            } else {
                setResult(
                    `${paceHours}h ${paceMinutes}m ${paceSeconds}s per mile`
                );
            }
        } else {
            setResult("Please fill in all fields correctly.");
        }
    };

    const calculateDistance = () => {
        const totalSeconds =
            parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
        const timeFloat = totalSeconds / 3600;

        if (timeFloat && distance) {
            let distanceFloat = parseFloat(distance);

            if (unitSystem === "kilometers") {
                distanceFloat *= 0.621371; // Convert distance to miles
            }

            const distanceResult = timeFloat * distanceFloat;

            if (unitSystem === "kilometers") {
                setResult(`${distanceResult.toFixed(2)} kilometers`);
            } else {
                setResult(`${distanceResult.toFixed(2)} miles`);
            }
        } else {
            setResult("Please fill in all fields correctly.");
        }
    };

    const calculateTime = () => {
        const distanceFloat = parseFloat(distance);

        if (distanceFloat && paceHours && paceMinutes && paceSeconds) {
            let paceTotalSeconds =
                parseInt(paceHours) * 3600 +
                parseInt(paceMinutes) * 60 +
                parseInt(paceSeconds);
            let totalTimeSeconds = paceTotalSeconds * distanceFloat;

            if (unitSystem === "kilometers") {
                totalTimeSeconds *= 0.621371; // Convert total time to minutes per mile
            }

            const timeHours = Math.floor(totalTimeSeconds / 3600);
            const timeMinutes = Math.floor((totalTimeSeconds % 3600) / 60);
            const timeSeconds = Math.floor(totalTimeSeconds % 60);

            setResult(`${timeHours}h ${timeMinutes}m ${timeSeconds}s`);
        } else {
            setResult("Please fill in all fields correctly.");
        }
    };
    return (
        <React.Fragment>
            <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader>
                            <h3>Result:</h3>
                        </CardHeader>
                        {result && (
                            <div>
                                <CardBody>
                                    <p>{result}</p>
                                </CardBody>
                            </div>
                        )}

                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <CardBody>
                            <div>
                                <h2>Pace Calculator</h2>
                                <Nav tabs>
                                    <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }}>Pace</NavLink></NavItem>
                                    <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }}>Distance</NavLink></NavItem>
                                    <NavItem><NavLink style={{ cursor: "pointer" }} className={classNames({ active: activeTab === "3", })} onClick={() => { toggle("3"); }}>Time</NavLink></NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12">
                                                <CardText className="mb-0">
                                                    <h3>Pace Calculation</h3>
                                                    <div className='my-2'>
                                                        <label> Distance ({unitSystem === "miles" ? "miles" : "kilometers"})</label>
                                                        <Input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
                                                    </div>
                                                    <div className='row my-2'>
                                                        <label>Time (hours, minutes, seconds)</label>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={hours} onChange={(e) => setHours(e.target.value)} placeholder="Hours" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={minutes} onChange={(e) => setMinutes(e.target.value)} placeholder="Minutes" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input value={seconds} onChange={(e) => setSeconds(e.target.value)} placeholder="Seconds" />
                                                        </div>
                                                    </div>
                                                    <Button className='btn btn-success' onClick={calculatePace}>Calculate Pace</Button>

                                                    <div className='my-2'>
                                                        <label>Unit System:</label>
                                                        <select className="form-control" value={unitSystem} onChange={(e) => setUnitSystem(e.target.value)}>
                                                            <option value="miles">Miles</option>
                                                            <option value="kilometers">Kilometers</option>
                                                        </select>
                                                    </div>
                                                    <div className='my-2'>
                                                        <label>Metric System:</label>
                                                        <select className="form-control" value={metricSystem} onChange={(e) => setMetricSystem(e.target.value)}>
                                                            <option value="minutesMile">Minutes per Mile</option>
                                                            <option value="minutesKilometer">
                                                                Minutes per Kilometer
                                                            </option>
                                                        </select>
                                                    </div>
                                                </CardText>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col sm="12">
                                                <CardText className="mb-0">

                                                    <h3>Distance Calculation</h3>
                                                    <div className='row my-2'>
                                                        <label>Time (hours, minutes, seconds)</label>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={hours} onChange={(e) => setHours(e.target.value)} placeholder="Hours" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={minutes} onChange={(e) => setMinutes(e.target.value)} placeholder="Minutes" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input value={seconds} onChange={(e) => setSeconds(e.target.value)} placeholder="Seconds" />
                                                        </div>
                                                    </div>
                                                    <Button className='btn btn-success' onClick={calculatePace}>Calculate Pace</Button>

                                                    <div className='my-2'>
                                                        <label>Unit System:</label>
                                                        <select className="form-control" value={unitSystem} onChange={(e) => setUnitSystem(e.target.value)}>
                                                            <option value="miles">Miles</option>
                                                            <option value="kilometers">Kilometers</option>
                                                        </select>
                                                    </div>
                                                    <div className='my-2'>
                                                        <label>Metric System:</label>
                                                        <select className="form-control" value={metricSystem} onChange={(e) => setMetricSystem(e.target.value)}>
                                                            <option value="minutesMile">Minutes per Mile</option>
                                                            <option value="minutesKilometer">
                                                                Minutes per Kilometer
                                                            </option>
                                                        </select>
                                                    </div>
                                                </CardText>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col sm="12">
                                                <CardText className="mb-0">

                                                    <h3>Time Calculation</h3>
                                                    <div className='row my-2'>
                                                        <label>Pace (hours, minutes, seconds)</label>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={paceHours} onChange={(e) => setPaceHours(e.target.value)} placeholder="Hours" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input type="number" value={paceMinutes} onChange={(e) => setPaceMinutes(e.target.value)} placeholder="Minutes" />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12">
                                                            <Input value={paceSeconds} onChange={(e) => setPaceSeconds(e.target.value)} placeholder="Seconds" />
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <label>Distance ({unitSystem === "miles" ? "miles" : "kilometers"}):</label>
                                                        <Input type="number" value={distance} onChange={(e) => setDistance(e.target.value)} />
                                                    </div>

                                                    <Button className='btn btn-success my-2' onClick={calculateTime}>Calculate Pace</Button>

                                                    <div className='my-2'>
                                                        <label>Unit System:</label>
                                                        <select className="form-control" value={unitSystem} onChange={(e) => setUnitSystem(e.target.value)}>
                                                            <option value="miles">Miles</option>
                                                            <option value="kilometers">Kilometers</option>
                                                        </select>
                                                    </div>
                                                    <div className='my-2'>
                                                        <label>Metric System:</label>
                                                        <select className="form-control" value={metricSystem} onChange={(e) => setMetricSystem(e.target.value)}>
                                                            <option value="minutesMile">Minutes per Mile</option>
                                                            <option value="minutesKilometer">Minutes per Kilometer</option>
                                                        </select>
                                                    </div>
                                                </CardText>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                    <br></br>
                    <Card>
                        <CardBody>
                            <h3>PACE EMPTY</h3>
                            <ul>
                                <li>The due date is an estimated date when a pregnant woman is expected to deliver her baby, also referred to as the estimated date of confinement.</li>
                                <li>It's important to note that while a single date is often given, it's useful to consider a range of due dates, as only 4% of births actually occur on the estimated due date.</li>
                                <li>There are several methods that can be used to estimate due dates, such as the last menstrual period, ultrasound, conception date, and IVF transfer date.</li>
                                <li>Each of these methods takes different factors into account to provide an estimate for when the baby is likely to be born.</li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Pace;
