import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { preset, schedule } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';

function AddDietPresetModal(prop) {
    const dispatch=useDispatch();
    const {setPreset} = prop;
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status);
    const handleSubmit = e =>{
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({status:true,message:'please wait'}));
        preset.add(formData).then(res=>{
            setPreset(state=>[res.data.preset,...state]);
            toggleModal();
            e.target.reset();
            toast.success(res.message)
        })
        .catch(err=>toast.error(err.response?err.response.data.message:err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));
    }
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}>Add Diet Preset</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Preset Name </label>
                                    <Input className="form-control" name="name" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Description</label>
                                    <textarea className='form-control' name='description'></textarea>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddDietPresetModal