import React, { useMemo } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'
import { Card, CardBody } from 'reactstrap';

export const ListLandingPage = () => {
  const columns = useMemo(() => [
    { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
    { Header: "Name", accessor: "", HeaderClass: 'text-center', DataClass: 'text-center' },
  ]);
  return (
    <React.Fragment>
      <Card className='landing-page-card mt-3'>
        <CardBody>
          <TableResponsive columns={columns} data={[]} noRecord="No Record List" />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
