import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Input,
  Button
} from "reactstrap"
import ReactSelect from "react-select";

function PregnancyConception() {

  const [method, setMethod] = useState("dueDate");
  const [date, setDate] = useState("");
  const [estimatedDueDate, setEstimatedDueDate] = useState("");
  const [weeksPregnant, setWeeksPregnant] = useState("");

  const calculateDueDate = (e) => {
    e.preventDefault()
    if (date) {
      const selectedDate = new Date(date);

      if (method === "dueDate") {
        const dueDate = new Date(
          selectedDate.setDate(selectedDate.getDate() - 266)
        );
        setEstimatedDueDate(dueDate.toDateString());
      } else if (method === "lastPeriod") {
        const dueDate = new Date(
          selectedDate.setDate(selectedDate.getDate() + 280)
        );
        setEstimatedDueDate(dueDate.toDateString());
      } else if (method === "ultrasound") {
        const dueDate = new Date(
          selectedDate.setDate(selectedDate.getDate() + 266)
        );
        setEstimatedDueDate(dueDate.toDateString());
      }

      // Calculate weeks pregnant based on current date and estimated due date
      const currentDate = new Date();
      const weeks = Math.floor(
        (currentDate - selectedDate) / (1000 * 60 * 60 * 24 * 7)
      );
      setWeeksPregnant(weeks);
    } else {
      setEstimatedDueDate("");
      setWeeksPregnant("");
    }
  };


  return (
    <React.Fragment>
      <Breadcrumbs title="Pregnancy Conception Calculator" breadcrumbItem="Dashboard" />
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader>
                <h3>Estimated Due Date:</h3>
            </CardHeader>
              <CardTitle className="my-2">
                {estimatedDueDate && (
                  <div>
                    <p>{estimatedDueDate}</p>
                    <h3>Weeks Pregnant:</h3>
                    <p>{weeksPregnant}</p>
                  </div>
                )}
              </CardTitle>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
           
            <CardBody>
              <form>
                <div>
                  <h2>Pregnancy Conception Calculator</h2>
                  <label>Calculation Method:</label>
                  <ReactSelect Value={method} onChange={(e) => setMethod(e.value)} options={[
                    { value: 'dueDate', label: 'Due Date' },
                    { value: 'lastPeriod', label: 'Last Menstrual Period (LMP)' },
                    { value: 'ultrasound', label: 'Ultrasound Date' },
                  ]} />
                  <div className="my-2">
                    <label>Date:</label>
                    <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                  </div>
                  <Button className="btn btn-success" onClick={calculateDueDate}>Calculate Due Date</Button>
                </div>
              </form>
            </CardBody>
            <CardFooter>

            </CardFooter>

            <CardBody>
              <Card>
                <h3>When Did I Conceive?</h3>
                <p>
                  Determining the exact date of conception can be challenging, as it depends on various factors such as the length of your menstrual cycle, the regularity of your cycles, and the timing of ovulation. However, if you know the first day of your last menstrual period (LMP), you can estimate the date of conception using a pregnancy due date calculator or pregnancy conception calculator.
                </p>
                <p>
                  Typically, conception occurs around 14 days after the start of your last menstrual period, when ovulation occurs and an egg is fertilized by sperm. Therefore, if you have a regular menstrual cycle of 28 days, you likely ovulated around day 14 and conceived around that time. However, if you have an irregular menstrual cycle or are unsure of the date of your last period, it may be more difficult to estimate the date of conception.
                </p>
                <p>
                  Another way to determine the date of conception is through ultrasound. An early ultrasound can provide an estimate of gestational age and help determine when conception likely occurred. It's important to note that conception does not always occur exactly on day 14 or during the first ultrasound, and there can be a variation of a few days to a week.
                </p>

                <h3>Last Menstrual Period</h3>
                <p>
                  Conception typically happens 11-21 days after the first day of a woman's last menstrual period, assuming she has a regular cycle. However, determining the exact date of conception is challenging since it's hard to know when ovulation occurred. This estimation is even more difficult for women with irregular periods or those who can't recall the first day of their last period. Ultrasound is a more accurate method of estimating gestational age in such cases.
                </p>

                <h3>Due Date</h3>
                <p>
                  During a prenatal check-up, a healthcare provider usually estimates the due date for delivery based on a sonogram. From this estimated due date, a range of possible conception dates can be calculated.
                </p>

                <h3>Fertilization, Conception, and Pregnancy</h3>
                <ul>
                  <li>Fertilization and conception have different meanings in medical and scientific terms.</li>
                  <li>Fertilization occurs when the sperm and egg combine in the fallopian tube to form a fertilized egg.</li>
                  <li>Conception is the process of becoming pregnant, which involves both fertilization and implantation of the fertilized egg into the uterine wall.</li>
                  <li>On the state level in the U.S., there is some confusion regarding the use of these terms.</li>
                  <li>However, according to medical and scientific definitions, a woman is considered pregnant only when the fertilized egg has implanted in the uterus.</li>
                  <li>Conception requires both fertilization and implantation, and a woman is not considered pregnant until both have occurred.</li>
                </ul>


              </Card>
            </CardBody>


          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PregnancyConception
