import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useSelector } from "react-redux";
import { Recipes, food, meals } from 'helpers/api_url';
import { useHandleApiCall, usePageInitialor } from 'helpers/customHook';
import { TableResponsive } from 'components/Common/TableResponsive';
import { ViewMeal } from './ViewMeal';
import AddMealModal from './Meal/AddMealModal';

export const Meal = ({tabNumber}) => {
    const [foodList, setFoodList] = useState([]);
    const [recipeList, setRecipeList] = useState([]);
    const [tableData, setTableData ] = useState([]);
    const userData = useSelector(state => state.Login.authUser);
    const {handleDelete} = useHandleApiCall(tableData,setTableData,meals)
    useEffect(() => {
        if(tabNumber == 4){
            Recipes.list().then(res => setRecipeList(res.data.recipe)).catch(e => console.error(e));
            food.list().then(res => setFoodList(res.data.food)).catch(e => console.error(e));
            meals.list().then(res=>setTableData(res.data.meal)).catch(e => console.error(e));
        }
    }, [tabNumber])    
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Meal Name",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            accessor:'name'
        },
        {
            Header: "Carbs",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            accessor:'carbohydrate'
        },
        {
            Header: "Protein",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            accessor:'protein'
        },
        {
            Header: "Fat",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            accessor:'fat'
        },
        {
            Header: "Total Calorie",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return (parseFloat(row.fat) *9 + parseFloat(row.protein)*4 + parseFloat(row.carbohydrate)*4).toFixed(2)
            }
        },
        {
            Header: 'Action',
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <div>
                        <button onClick={() => handleDelete({id:row.id})} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                        <ViewMeal meals={row} />
                        {/* <button className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button> */}
                    </div>
                )
            }
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#">{row.name} </a> 
                        </h5> 
                    </div>
                    <div className="flex-shrink-0">
                    <button onClick={() => handleDelete({id:row.id})} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                    <ViewMeal meals={row} />
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <Card>
                <CardHeader className='d-flex justify-content-between'>
                    <CardTitle>Create Meal/Diet</CardTitle>
                    <AddMealModal foodList={foodList} recipeList={recipeList} userData={userData} setMeal={setTableData} />
                </CardHeader>
                <CardBody>
                    <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
                </CardBody>
            </Card>
        </>
    )
}
