import React from 'react'
import ReactSelect from 'react-select'
import { Col, Input, Label, Form, Row, Button } from 'reactstrap'

export const Program = () => {
    const foodtype = [{ value: 'vegetrain', label: 'Vegetrain' }, { value: 'Egetrain', label: 'Egetrain' }]
    return (
        <React.Fragment>
            <Form>
                <Row>
                    <div className='col-6'>
                        <Label htmlFor='type'>User Type</Label>
                        <ReactSelect className="basic-single" classNamePrefix="select" name="type" id="type" options={[]} />
                    </div>
                 
                    <div className='col-6'>
                        <Label htmlFor='name'>Trainer Name</Label>
                        <Input type="text" name="name" id='name' />
                    </div>
                    <div className='col-6 mt-2'>
                        <Label htmlFor='programname'>Program Name</Label>
                        <Input type="text" name="programname" id='programname' />
                    </div>
                    <div className='col-3 mt-2'>
                        <Label htmlFor='programlink'>Program Link</Label>
                        <Input type="text" name="programlink" id='programlink' />
                    </div>
                    <div className='col-3 mt-2'>
                        <Label htmlFor='video'>Program video</Label>
                        <Input type="file" name="video" id='video' />
                    </div>
                    <div className='col-6 mt-2'>
                        <Label htmlFor='day'>Work Day</Label>
                        <ReactSelect className="basic-single" classNamePrefix="select" name="day" id="day" options={[]} />
                    </div>
                    <div className='col-6 mt-2'>
                        <div className='mb-3'>
                            <Label htmlFor='date'> Date</Label>
                            <Input type="date" name="date" id='date' />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div className='mb-3'>
                            <Label htmlFor='start_time'>Start Time</Label>
                            <Input type="time" name="start_time" id='start_time' />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div className='mb-3'>
                            <Label htmlFor='end_time'>End Time</Label>
                            <Input type="time" name="end_time" id='end_time' />
                        </div>
                    </div>
                  
                </Row>
                <Row className="mt-4 mb-3">
                    <Col lg={3} className="mx-auto">
                        <div className="hstack gap-2 justify-content-center">
                            <Button type="submit" color='primary' className='px-5'>Submit</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}
