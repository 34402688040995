import React, { useEffect, useMemo, useState } from 'react'
import { TableResponsive } from 'components/Common/TableResponsive'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import AddDietPresetModal from './AddDietPresetModal';
import { Recipes, food, meals, preset } from 'helpers/api_url';
import { toast } from 'react-toastify';
import { useHandleApiCall } from 'helpers/customHook';
import { DietPlan } from './DietPlan';

export const DietPlanList = ({tabNumber}) => {
  const [tableData,setTableData] = useState([]); 
  const [foodList, setFoodList] = useState([]);
  const [recipeList, setRecipeList] = useState([]);
  const [mealList, setMealList ] = useState([]);
  const [presetInView,setPresetInView] = useState(null);
  const {handleDelete} = useHandleApiCall(tableData,setTableData,preset);
  useEffect(()=>{
    if(tabNumber == 5 && presetInView == null){
      preset.list().then(res=>setTableData(res.data.preset)).catch(err=>toast.error(err.response?err.response.data.message:err.message))
      Recipes.list().then(res => setRecipeList(res.data.recipe)).catch(e => console.error(e));
      food.list().then(res => setFoodList(res.data.food)).catch(e => console.error(e));
      meals.list().then(res=>setMealList(res.data.meal)).catch(e => console.error(e));
    }
  },[tabNumber,presetInView])
  const handleDeletePreset = id => handleDelete({id:id})
  const handlePresetView = preset => setPresetInView(preset);
  const columns = useMemo(() => [
    { Header: "SrNo",Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
    { Header: "Name", accessor: "name", HeaderClass: 'text-center', DataClass: 'text-center' },
    { Header: "Description", accessor: "description", HeaderClass: 'text-center', DataClass: 'text-center' },
    { 
      Header: "Created By",HeaderClass: 'text-center', DataClass: 'text-center',
      Cell:cell=>cell.row.original.creator?.first_name + " " + cell.row.original.creator?.last_name
    },
    {
      Header: "Action",HeaderClass:'text-center', DataClass:'text-center', Cell:cell=>{
      const row = cell.row.original;
      return (
          <div>
              <button onClick={e=>handlePresetView(row)} className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
              <button onClick={e=>handleDeletePreset(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
              <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button>
          </div>
      )}
    },
    {
      Header:'List',
      HeaderClass:'d-none',
      DataClass:'d-none',
      list:(row)=>{
          return (
          <div className="d-flex">
              <div className="flex-grow-1" data-id="1">
                  <h5 className="fs-13 mb-1">
                      <a href="#">{row.name} </a> | {row.creator?.first_name}
                  </h5> 
                  <p className="text-muted mb-0">
                  <span>{row.description} </span> </p>
              </div>
              <div className="flex-shrink-0">
              <button onClick={e=>handlePresetView(row)} className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button>
              <button onClick={e=>handleDeletePreset(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
              {/* <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button> */}
              </div>
          </div>
          )
      }
  }
  ]);
  return (
    <React.Fragment>
      {presetInView == null ? (
        <Card >
          <CardHeader className='d-flex justify-content-between align-items-center py-2'>
            <CardTitle className='m-0'>Diet Presets</CardTitle>
            <div>
              <AddDietPresetModal setPreset={setTableData}>
                <button className='btn btn-success'>+ New Preset</button>
              </AddDietPresetModal>
            </div>
          </CardHeader>
          <CardBody>
            <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
          </CardBody>
        </Card>
      ):<DietPlan preset={presetInView} foodList={foodList} recipeList={recipeList} mealList={mealList}  setPresetInView={setPresetInView} />}
    </React.Fragment>
  )
}
