import React, { useEffect, useMemo, useState } from 'react';
import { TableResponsive } from 'components/Common/TableResponsive';
import { Diet, Recipes } from 'helpers/api_url';
import { useHandleApiCall, usePageInitialor } from 'helpers/customHook';
import { Button, Card, CardBody, CardHeader, CardTitle, UncontrolledTooltip } from 'reactstrap'
import { ViewRecipe } from './ViewRecipe';
import NewRecipeModal from './Recipe/NewRecipeModal';
import ViewRecipeModal from './Recipe/ViewRecipeModal';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';
import { foodTypeDotGenerator } from 'helpers/badgeGenerator';

export const Recipe = ({tabNumber}) => {
    const dispatch=useDispatch();
    const [tableData, setTableData] = useState([]);
    // const {handleDelete} = useHandleApiCall(tableData,setTableData,Recipes);
    const type_colors = {
        veg:'success',
        vegetrain:'success',
        nonveg:'danger',
        vegSuppliment:'success',
        nonvegSuppliment:'danger',
        egg:'warning',
        Egetrain:'warning',
    };
    const [foodList, setFoodList] = useState([]);   
    useEffect(() => {
        if(tabNumber==3){
            dispatch(changePreloader({status:true,message:'please wait'}));
            Recipes.list().then(res=>{setTableData(res.data.recipe);})
            .catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));
            Diet.list().then(res => setFoodList(res.data.food))
            .catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));
        }
    }, [tabNumber]);
    const handleRecipeDelete = (id) => console.log({ id: id });
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Reciepe Name",
            Cell: (cell) => {
                const row = cell.row.original;
                return (<>
                    <div className='p-2'  id={"reciepename_"+row.id}>
                        <span style={{ cursor: 'pointer' }} >{row.name} </span> {foodTypeDotGenerator(row.type)}
                    </div>
                    <UncontrolledTooltip placement="top" target={"reciepename_"+row.id}>
                        Type |  {row.type}
                    </UncontrolledTooltip>
                </>)
            }
        },
        {
            Header: "Total Food",
            HeaderClass: 'text-center',
            colSpanSize: '3',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<>

                    {row?.foods?.map((item, idx) => item != '' ? (<><span key={idx} id={`nutrient` + idx} style={{ cursor: 'pointer' }} className={`me-2 badge bg-success p-2`}>
                        {item.name} 
                        {/* | {item.quantity}   {item.unit == "g" ? 'gm' : ''} */}
                    </span>
                    </>
                    ) : null)}
                </>
                )
            }
        },
      
        {
            Header: "Total Calorie",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<>
                    <span id="name" style={{ cursor: 'pointer' }} >{row.total_calorie}</span>
                </>)
            }
        },
        {
            Header: 'Action',
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <div>
                        <button onClick={() => handleRecipeDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                        {/* <ViewRecipe Recipe={row} /> */}
                        <ViewRecipeModal Recipe={row} foodList={foodList} />
                        {/* <button className="btn btn-sm btn-success me-1"><i className="mdi mdi-eye-outline"></i></button> */}
                        {/* <button className="btn btn-sm btn-warning me-1"><i className="mdi mdi-pencil-outline"></i></button> */}
                    </div>
                )
            }
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#">{row.name} </a> |   {foodTypeDotGenerator(row.type)} 
                        </h5> 
                    </div>
                    <div className="flex-shrink-0">
                    <button onClick={() => handleRecipeDelete(row.id)} className="btn btn-sm btn-danger me-1"><i className="mdi mdi-delete-outline"></i></button>
                      
                        <ViewRecipeModal Recipe={row} foodList={foodList} />
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <Card>
                <CardHeader className='d-flex justify-content-between'>
                    <CardTitle>Receipe</CardTitle>
                    <NewRecipeModal foodList={foodList} setRecipeData={setTableData}>
                        <Button className='bg-primary' >+ Add Receipe</Button>
                    </NewRecipeModal>
                </CardHeader>
                <CardBody>
                    <TableResponsive columns={columns} data={tableData} noRecord="No Record List" />
                </CardBody>
            </Card>
        </>
    )
}
