import React from 'react'
import ReactPlayer from 'react-player';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from 'reactstrap'
import img3 from '../../../assets/images/Execise/ex1.jpeg'
import { AssetUrl } from 'helpers/api_url';
export const SingleExcercise = () => {
    const { state } = useLocation();
    const item = state && state.data;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {
                        item == null ? 'not record' :
                            <Row>
                                <Col lg={12} xs={12}>
                                    <Card>
                                        <div className='row'>
                                            <div className='col-lg-4 text-start'>
                                                <CardTitle className='p-2 fs-3'>
                                                    Page Scroll Down
                                                </CardTitle>

                                            </div>
                                            <div className='col-lg-2 text-start'> </div>
                                            <div className='col-lg-6 text-end p-2'>
                                                <div className="d-flex justify-content-center ">
                                                    <Link to={item.link_facebook} ><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link>
                                                    <Link to={item.link_instaGram} > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link>
                                                    <Link to={item.link_youTube} ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link>
                                                </div>
                                            </div>

                                        </div>
                                    </Card>
                                </Col>

                                <Col lg={6} xs={12}>
                                    <Card>
                                        <CardTitle className='fs-4 px-2 text-center bg-light'>Specification</CardTitle>

                                        <CardBody>

                                            <ul className="list-group">
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Exercise Name</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.exercise_name}</span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Muscle Group</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{
                                                            JSON.parse(item.muscle_group).map((musuleItem, idxs) => {
                                                                const item = JSON.parse(musuleItem);
                                                                return (
                                                                    <div key={idxs}>
                                                                        <span>{item?.category} ( {item?.sub_category})</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Experience Level</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{
                                                            JSON.parse(item.experience_level).map((experienceItem, idxs) => {
                                                                const item = JSON.parse(experienceItem);
                                                                return (
                                                                    <div key={idxs}>
                                                                        <span>{item?.category} ( {item?.sub_category})</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Excercise Type</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{
                                                            JSON.parse(item.exercise_type).map((exerciseItem, idxs) => {
                                                                const item = JSON.parse(exerciseItem);
                                                                return (
                                                                    <div key={idxs}>
                                                                        <span>{item?.category} ( {item?.sub_category})</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Equipment</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{
                                                            // JSON.parse(item.equipments)
                                                            JSON.parse(item.equipments).map((equipmentItem, idxs) => {
                                                                const item = JSON.parse(equipmentItem);
                                                                return (
                                                                    <div key={idxs}>
                                                                        <span>{item?.category} ( {item?.sub_category})</span>
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                        </span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Area Of Body Part</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{JSON.parse(item.area_of_body_part)}</span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Gravity</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{JSON.parse(item.gravity)}</span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Muscle Size</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.muscle_size}</span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Intensity / Mobility</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.intensity_mobility}</span></Col>
                                                    </Row>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Row>
                                                        <Col xs={5}><span className='fw-bold'>Techniques</span></Col>
                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.techniques}</span></Col>
                                                    </Row>
                                                </li>

                                                {/* <li className='list-group-item'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Sets | Reps | Weight</span></Col>
                                                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                                                    </Row>
                                                                </li> */}
                                            </ul>
                                        </CardBody>


                                    </Card>
                                </Col>
                                <Col lg={4} xs={12}>

                                    <Card>
                                        <CardTitle className='fs-4 px-2 text-center bg-light'>Video</CardTitle>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                                            <div className='d-lg-block d-none'>
                                                <ReactPlayer url='https://youtu.be/yYmWtzXRpqo?si=hO0atrv35yWaq8AN' width='300px' height='150px' />
                                            </div>
                                            <div className='d-block d-lg-none'>
                                                <ReactPlayer url='https://youtu.be/yYmWtzXRpqo?si=hO0atrv35yWaq8AN' width='auto' height='300px' />
                                            </div>
                                        </div>
                                    </Card>

                                    <Card>
                                        <CardTitle className='fs-4 px-2 text-center bg-light'>Image</CardTitle>
                                        <CardBody className="d-flex justify-content-center align-items-center">
                                            <img src={AssetUrl + item.image} className="rounded" alt="..." />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Row>

                                    <Col lg={6} xs={12} className='mt-2'>
                                        <Card>
                                            <CardTitle className='fs-4 px-2 text-center bg-light'>Do's</CardTitle>
                                            <CardBody>
                                                {item.dos}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={6} xs={12} className='mt-2'>
                                        <Card>
                                            <CardTitle className='fs-4 px-2 text-center bg-light'>Don't</CardTitle>
                                            <CardBody>
                                                {item.donts}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>


                            </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}
