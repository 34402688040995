import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ExerciseLike = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <React.Fragment>
            <i
                className="bx bx-heart text-dark __heart-icon"
                onClick={toggle}
            />
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Like - Exercise</ModalHeader>
                <ModalBody>
                    <p>
                        To make either guest_id or exercise id and user_id required in Laravel's validation rules, you can use the required_without rule. This rule ensures that one of the fields is required if the other is not present.

                    </p>
                     <p>Please login to Like an Exercise</p>   
                </ModalBody>
                <Link to={'/login'} className='btn btn-dark mx-3 mb-3'> Login</Link>
               
            </Modal>
        </React.Fragment>
    );
};
