import React, { useEffect, useState } from 'react'
import { Container, Card, CardBody, Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import avatar2 from "assets/images/users/avatar-2.jpg";
import { PersonalInfo } from './PersonalInfo';
import { Address } from './Address';
import { useSelector } from 'react-redux';
import { AssetUrl, users, kyc } from "helpers/api_url";
import BankAccountModel from './BankAccountModel';
import EducationModel from './EducationModel';
import AboutModel from './AboutModel';
import { toast } from 'react-toastify';
import ParentDetails from './ParentDetails';
import Proffessional from './Proffessional';
import OtherDetailModel from './OtherDetailModel';
import SkillModel from './SkillModel';
import SocialMediaModel from './SocialMediaModel';
import { formatDate } from 'helpers/formatDate';
// import { KycProfile } from './kycUpdate';
const Profile = () => {
    const userData = useSelector(state => state.Login.authUser);
    const [profileData, setProfileData] = useState(null);
    const [kycData, setKycData] = useState(null);
    useEffect(() => {
        users.profile().then(res => setProfileData(res.data.user))
            .catch(e => toast(e.response ? e.response.data.message : e.message));
        kyc.list().then(res => setKycData(res.data.user))
            .catch(e => toast(e.response ? e.response.data.message : e.message));
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Card>
                                <CardBody>
                                    <Row className='mb-2'>
                                        <div className=' text-center'>
                                            <div className="avatar-group-item ">
                                                <Link to="#" className="d-inline-block">
                                                    <img src={
                                                        userData?.other_details?.profile_image === "" ? avatar2 : AssetUrl + userData?.other_details?.profile_image
                                                    } alt="" className="avatar-md rounded-circle" style={{ aspectRatio: 1 / 1, objectFit: 'cover' }} />
                                                </Link>
                                            </div>
                                            <h4>{userData.first_name} {userData.last_name} </h4>
                                        </div>
                                    </Row>
                                    {/* <div className='text-end mt-2'>
                                        <button type="button" className="btn btn-primary btn-sm ">Download CV <i className='bx bx-download  ms-1'></i></button>
                                    </div> */}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} lg={10} className='text-start'>Personal Infomation</Col>
                                        <Col xs={12} lg={2} className='text-end'>
                                            <PersonalInfo current={profileData?.additional_details} user={userData} setData={setProfileData} />
                                        </Col>
                                    </Row>
                                    <hr />
                                    {/* for company */}

                                    {userData?.role?.role_type === ("community" || "admin") ?
                                        <ul className="list-group">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-phone-call font-size-18 text-success me-2' /> Mobile: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>+91 {userData.phone}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-mail-send font-size-18 text-success me-2' /> Email: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{userData.email}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bxl-whatsapp font-size-18 text-success me-2' /> Whatsapp: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.whatsapp}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        </ul>
                                        :
                                        <ul className="list-group">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-phone-call font-size-18 text-success me-2' /> Mobile: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>+91 {userData?.phone}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-mail-send font-size-18 text-success me-2' /> Email: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{userData?.email}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bxl-whatsapp font-size-18 text-success me-2' /> Whatsapp: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.whatsapp}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-calendar-event font-size-18 text-success me-2' /> DOB: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.dob}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-body font-size-18 text-success me-2' /> Gender: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.gender}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-vertical-center font-size-18 text-success me-2' /> Height: </span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.height} cm</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-horizontal-center font-size-18 text-success me-2' />Weight:</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.weight} kg</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-user-circle font-size-18 text-success me-2' />Aadhar:</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.aadhaar}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'><i className='bx bx-user-circle font-size-18 text-success me-2' />PAN:</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        <span className='text-wrap'>{profileData?.additional_details?.pan}</span>
                                                    </Col>
                                                </Row>
                                            </li>
                                        </ul>
                                    }

                                </CardBody>
                            </Card>


                            {/* for company */}


                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>Account Details</span>
                                        <BankAccountModel user={userData} setData={setProfileData} current={profileData?.bank_account?.[0]} />
                                    </div>
                                    <hr />

                                    <ul className="list-unstyled vstack gap-3 mb-0">
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Account Holder:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.account_name}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Bank Name:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.bank_name}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-radio-circle-marked font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">IFSC code:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.ifsc_code}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-money font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">Account Number:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.account_number}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bx-map-pin font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">UPI Account Holder:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.upi_account_name}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className='bx bxl-paypal font-size-18 text-primary'></i>
                                                <div className="ms-3">
                                                    <h6 className="mb-1 fw-semibold">UPI ID:</h6>
                                                    <span className="text-primary">{profileData?.bank_account?.[0]?.upi_id}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>

                            </Card>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h5 className="mb-0">About Us</h5>
                                        <AboutModel user={userData} current={profileData?.about?.content} setData={setProfileData} />
                                    </div>
                                    {profileData?.about && (<p className="text-muted" >{profileData.about.content}</p>)}
                                    {/* <p ></p>
                                    <p className="text-muted mb-0">As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience lies in successfully conceptualizing, designing, and modifying consumer products specific to interior design and home furnishings.</p> */}
                                </CardBody>
                            </Card>
                            <Row>


                                {userData?.role?.role_type === ("community" || "admin") ?
                                    <>
                                        <Col lg={6} xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={12} lg={10} className='text-start'>Proffessional Detail</Col>
                                                        <Col xs={12} lg={2} className='text-end'>
                                                            <Proffessional user={userData} current={profileData?.additional_details} setData={setProfileData} />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <ul className="list-group">
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'>Company Detail: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_name}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'>Director</span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_income}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'>Mobile </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_phone}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'> Address </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_aadhaar}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>

                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col lg={6} xs={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={12} lg={10} className='text-start'>Parent Detail</Col>
                                                        <Col xs={12} lg={2} className='text-end'>
                                                            <ParentDetails user={userData} current={profileData?.additional_details} setData={setProfileData} />
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <ul className="list-group">
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-phone-call font-size-18 text-success me-2' /> Father Name: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_name}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bxl-whatsapp font-size-18 text-success me-2' /> Mobile </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_phone}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold ps-4'> Aadhar </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_aadhaar}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-dollar-circle font-size-18 text-success me-2' />Yearly Earn </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.father_income}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <hr />
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-phone-call font-size-18 text-success me-2' />Mother Name </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.mother_name}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bxl-whatsapp font-size-18 text-success me-2' /> Mobile </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.mother_phone}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold ps-4'> Aadhar </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap '>{profileData?.additional_details?.mother_aadhaar}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-dollar-circle font-size-18 text-success me-2' />Yearly Earn </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{profileData?.additional_details?.mother_icome}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                                }
                                <Col lg={6} xs={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs={12} lg={10} className='text-start'>Address Details</Col>
                                                <Col xs={12} lg={2} className='text-end'>
                                                    <Address setData={setProfileData} user={userData} />
                                                </Col>
                                            </Row>
                                            <hr />
                                            {profileData?.address?.length ? (
                                                profileData?.address?.map((addr, index) => (
                                                    <ul key={index} className="list-group">

                                                        <li className='list-group-item'>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h6 className='text-center'>{addr.type} Address</h6>
                                                                <Address user={userData} current={addr} />
                                                            </div>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-radio-circle-marked font-size-18 text-primary me-2' />Address: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{addr.address}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-radio-circle-marked font-size-18 text-primary me-2' />city District: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{addr.city} {addr.district}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-map-pin font-size-18 text-primary me-2' />State: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{addr.state}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <Row>
                                                                <Col xs={5}><span className='fw-bold'><i className='bx bx-map-pin font-size-18 text-primary me-2' />Pin Code: </span></Col>
                                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                <Col className='text-start' xs={5}>
                                                                    <span className='text-wrap'>{addr.pincode}</span>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                ))
                                            ) : (<Address setData={setProfileData} user={userData} />)}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={6} xs={12}>
                                    {userData?.role?.role_type === ("community" || "admin") ?
                                        '' :
                                        <Card>
                                            <CardBody>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <h5 className="mb-0">Education</h5>
                                                    <EducationModel user={userData} current={null} setData={setProfileData} />
                                                </div>
                                                <ul className="verti-timeline list-unstyled">
                                                    {profileData?.education?.length ? profileData?.education?.map((edu, index) => (
                                                        <li key={index} className="event-list">
                                                            <div className="event-timeline-dot">
                                                                <i className="bx bx-right-arrow-circle"></i>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <div>
                                                                        <h6 className="font-size-14 mb-1">{edu.title}</h6>
                                                                        <p className="text-muted">{edu.institute} - ({edu.start_year}-{edu.end_year})</p>
                                                                        <p className="text-muted">{edu.grade} - ({edu.marks_obtained}/{edu.full_marks})</p>
                                                                        <p className="text-muted mb-0">{edu.description}</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <EducationModel user={userData} current={edu} setData={setProfileData} >
                                                                    <Button className='btn btn-soft-success w-100'>Update</Button>
                                                                </EducationModel>
                                                            </div>
                                                        </li>
                                                    )) : (<EducationModel user={userData} current={null} setData={setProfileData} />)}
                                                </ul>
                                            </CardBody>

                                            <CardBody className="border-bottom">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className="mb-0">Key Skill</h5>
                                                    <SkillModel user={userData} current={null} setData={setProfileData} />
                                                </div>
                                                <div className="hstack gap-2">
                                                    <p className="text-muted mb-0">There are many variations of passages of available, but the majority alteration in some form. As a highly skilled and successfull product development and design specialist with more than 4 Years of My experience.</p>
                                                </div>
                                            </CardBody>
                                            <CardBody className="border-bottom">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className="mb-0">Other Details</h5>
                                                    <OtherDetailModel user={userData} current={null} setData={setProfileData} />
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-lg-6'>
                                                        <span className='btn btn-sm btn-outline-info'>Current Earning : 5,00,000 Annually</span>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span className='btn btn-sm btn-outline-danger'>Expecting Earning : 6,00,000 Annually</span>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-lg-6'>
                                                        <span className='btn btn-sm btn-outline-info'>Comfirtable: Work Form Home</span>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span className='btn btn-sm btn-outline-danger'>Notice Period : 1 week</span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    }
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h5 className="mb-0">Social Media</h5>
                                                    <SocialMediaModel user={userData} current={null} setData={setProfileData} />
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="d-flex">
                                                        <i className='bx bxl-instagram-alt font-size-18 text-primary'></i>
                                                        <div className="ms-3">
                                                            <h6 className="mb-1 fw-semibold">Instagram:</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="d-flex">
                                                        <i className='bx bxl-facebook font-size-18 text-primary'></i>
                                                        <div className="ms-3">
                                                            <h6 className="mb-1 fw-semibold">Facebook:</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="d-flex">
                                                        <i className='bx bxl-twitter font-size-18 text-primary'></i>
                                                        <div className="ms-3">
                                                            <h6 className="mb-1 fw-semibold">Twitter:</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                </Col>

                                <Col lg={6} xs={12}>
                                    {
                                        kycData?.kyc?.length ?
                                            <Card>
                                                <CardBody>                              
                                                    <Row>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className="mb-0">KYC Profile</h5>
                                                            {/* <KycProfile current={kycData?.kyc?.length?kycData?.kyc[0]:''} user={kycData?.kyc?.length?kycData?.kyc[0].id:''} setData={setKycData} /> */}
                                                        </div>
                                                        <div className=' text-center mb-2'>
                                                            <div className="avatar-group-item ">
                                                                <Link to="#" className="d-inline-block">
                                                                    <img src={
                                                                         kycData?.kyc[0].passport_photo === "" ? avatar2 : AssetUrl + kycData?.kyc[0].passport_photo 
                                                                    } alt="" className="avatar-md rounded-circle" style={{ aspectRatio: 1 / 1, objectFit: 'cover' }} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <ul className="list-group">
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Name </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].name : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Father Name </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].father_name : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Mother Name </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].mother_name : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>DOB </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? formatDate(kycData?.kyc[0].dob) : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Gender </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].gender : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Type </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].type.toUpperCase() : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Contact </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].mobile : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Whatsapp </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].whatsapp : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Email </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].email : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Aadhar Number </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].aadhar_card : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='list-group-item'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>PANCARD  </span></Col>
                                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{kycData?.kyc?.length ? kycData?.kyc[0].pan_card : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            :                   ''

                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Profile;