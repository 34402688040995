import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';
import ReactSelect from "react-select";
import { exercise } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';

function SubCategoryAllInputModal({categories,setCategories}) {
    const dispatch=useDispatch();
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status);
    const [currentCategoryTitle,setCurrentCategoryTitle] = useState('');
    const handleAddCategory = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get('sub_category_name') != ''){
        dispatch(changePreloader({status:true,message:'please wait'}));
        exercise.addCategory(formData)
        .then(res=>{setCategories(state=>state.map(s=>s.id==res.data.category.id?res.data.category:s));toggleModal();toast.success(res.message)})
        .catch(err=>toast.error(err.response?err.response.data.message:err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));
        }
        else toast.error('Sub Category name is required');
    } 
    const categoryTitles = [
        { value: "Muscle Group", label: "Muscle Group" },
        { value: "Experience Level", label: "Experience Level" },
        { value: "Excercise Type", label: "Excercise Type" },
        { value: "Equipments", label: "Equipments" },
      ]

    return (
        <>
            <button onClick={()=>toggleModal()} className="btn btn-sm btn-success me-2"><i className="mdi mdi-plus"> Add Sub Category</i></button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Add Sub Category</h5></ModalHeader>
                <form onSubmit={handleAddCategory}>
                <ModalBody>
                    <Row>
                    <Col xs={12}>
                        <div className="mb-2">
                        <label className="control-label"> Category Title </label>
                        <Card>
                        <ReactSelect id="specialty" name="category_title" options={categoryTitles} onChange={e=>{
                            document.getElementById('category_name_select').value = null;
                            setCurrentCategoryTitle(e.value)
                            }} />
                        </Card>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-2">
                            <label className="control-label"> Category </label>
                            <Card>
                            <ReactSelect id="category_name_select" name="category_name" 
                                options={categories.filter(i=>i.category_title === currentCategoryTitle).map(c=>({label:c.category_name,value:c.category_name}))}/>
                            </Card>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-2">
                            <label className="control-label"> Sub Category </label>
                            <Input name="sub_category_name" className="form-control"/>
                        </div>
                    </Col>
                    </Row>
                </ModalBody>
                <ModalFooter><Button color="primary" type="submit" className="mt-2"> Submit </Button></ModalFooter>
                </form>
            </Modal>        
        </>
    )
}

export default SubCategoryAllInputModal