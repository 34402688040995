import { TableResponsive } from 'components/Common/TableResponsive'
import React, { useEffect, useMemo, useState } from 'react'
import ReactSelect from 'react-select'
import { Col, Input, Label, Form, Row, Button, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { meals, users } from 'helpers/api_url';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import DietCard from './DietCard';
import AddPresetDayModal from './AddPresetDayModal';

export const DietPlan = ({preset,setPresetInView,mealList,recipeList,foodList}) => {
    
    const handleCloseView = e => setPresetInView(null);
    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between py-3">
                        <CardTitle className='m-0'>{preset?.name}</CardTitle>
                        <div>
                            <button className='btn btn-soft-warning me-2'>share</button>
                            <AddPresetDayModal presetInView={preset} setPreset={setPresetInView} mealList={mealList} recipeList={recipeList} foodList={foodList}>
                                <button className='btn btn-soft-success me-2'>Add Day</button>
                            </AddPresetDayModal>
                            <button onClick={e=>handleCloseView()} className='btn btn-soft-danger'>Close</button>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
            {preset?.days?.map((day,index)=>(
                <Col key={index} xs={12}>
                    <DietCard index={index} day={day} mealList={mealList} recipeList={recipeList} foodList={foodList} />
                </Col>
            ))}
        </Row>
    )
}
