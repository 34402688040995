import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {changePreloader as setPreloader} from 'store/layout/actions'
export const useModalHandler = () => {
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    const genders=[{value:'male',label:'male'},{value:'female',label:'female'},{value:'other',label:'other'}]
    return {toggleModal,status,genders};
  }
export const usePageInitialor=(apiCallerObject)=>{
    const dispatch =useDispatch();
    const [tableData,setTableData]=useState([]);
    useEffect(()=>{
      dispatch(setPreloader({status:true,message:'please wait'}));
      apiCallerObject.list().then(r=>setTableData(r.data[Object.keys(r.data)[0]])).catch(err=>toast.error(err.response?err.response.data.message:err.message))
      .finally(()=> dispatch(setPreloader({status:false,message:''})));
    },[setTableData]);  
    const apiHandler = useHandleApiCall(tableData,setTableData,apiCallerObject);
    return {tableData,setTableData,apiHandler}
}

export const useHandleApiCall=(tableData,setTableData,apiCallerObject)=>{
    const dispatch =useDispatch();

    const handleDelete=(data)=>{
      dispatch(setPreloader({ status: true, message: "Please wait ..." }));
        apiCallerObject.delete(data.id)
              .then(res=>{setTableData([...tableData.filter(td=>td.id!=data.id)]);dispatch(setPreloader({ status: false, message: "" }));toast.success(res.message, { autoClose: 2000 });})
              .catch(err=>{dispatch(setPreloader({ status: false, message: "" }));toast.error(err.response ? err.response.data.message : err.message, { autoClose: 2000 });})
    }

    const handleSubmit=(e,textData)=>{
      dispatch(setPreloader({ status: true, message: "Please wait ..." }));
        e.preventDefault();
        const formdata = new FormData(e.target);
        textData&& formdata.append('description',textData);
        apiCallerObject.add(formdata)
          .then((res) => {
            dispatch(setPreloader({ status: false, message: "" }));
            setTableData([res.data[Object.keys(res.data)[0]], ...tableData]);
            toast.success(res.message, { autoClose: 2000 });e.target.reset();
          })
          .catch((err) => {
            dispatch(setPreloader({ status: false, message: "" }));
            toast.error(err.response ? err.response.data.message : err.message, { autoClose: 2000 })
          });
    }

    return {handleDelete,handleSubmit}
}