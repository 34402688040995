import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import './calculatorpage.css';
import Header from '../Header';
import { Link } from 'react-router-dom';

const CalculatorPage = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="page-content mt-5">
        <Container className="text-center">
          <h1 className="text">Tools</h1>
          <p className="font-format text-center fs-5">
            Want to know how many calories you need to be eating to lose weight?
          </p>
          <p className="font-format fs-5 text-center">
            How many grams of protein you need to maximize muscle growth?
          </p>
          <p className="font-format fs-5 text-center">
            What your genetic potential is for muscle growth before using steroids?
          </p>
          <h1>Don’t worry, we’ve got you covered.</h1>
          <p className="font-format fs-5 text-center">
            What your genetic potential is for muscle growth before using steroids?
          </p>

          <Row className="__header-row mt-5">
            <Col>
              <h2 className="__header-title">
                <span className="p-3 border border-secondary">
                  <span className="__arrow">↓</span> FREE CALCULATORS <span className="__arrow">↓</span>
                </span>
              </h2>
            </Col>
          </Row>
          <div className="calculator-card-container">
            <Card className="__calculator-card">
              <CardBody>
                <Link to="/login">
                <i className="bx bx-calculator __calculator-icon" style={{ color: '#f7c531' }}></i>
                <h5 className="__calculator-text">PROTEIN CALCULATOR</h5>
                </Link>
              </CardBody>
            </Card>
            <Card className="__calculator-card">
              <CardBody>
              <Link to="/login">
                <i className="bx bxs-bar-chart-square __calculator-icon" style={{ color: '#f7c531' }}></i>
                <h5 className="__calculator-text">CALORIE (TDEE) CALCULATOR</h5>
              </Link>
              </CardBody>
            </Card>
            <Card className="__calculator-card">
              <CardBody>
              <Link to="/login">
                <i className="bx bx-dumbbell __calculator-icon" style={{ color: '#f7c531' }}></i>
                <h5 className="__calculator-text">ONE-REP MAX CALCULATOR</h5>
                </Link>
              </CardBody>
            </Card>
            <Card className="__calculator-card">
              <CardBody>
              <Link to="/login">
                <i className="bx bx-calculator __calculator-icon" style={{ color: '#f7c531' }}></i>
                <h5 className="__calculator-text">PROTEIN CALCULATOR</h5>
                </Link>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CalculatorPage;
