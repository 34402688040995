
import React, { useEffect, useState } from "react";

import Section from "./HeroSection/Section"
import { Container } from "reactstrap";
import { AboutUsPage } from "./AboutUsPage";
const LandingPage = (props) => {
  document.title = "Fittest Warrior | FW";
  const [imglight, setimglight] = useState(true);
  const [navClass, setnavClass] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  }, [])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }
  return (
    <React.Fragment>

      <Section />

    </React.Fragment>
  )
}

export default LandingPage
