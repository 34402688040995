import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import ReactSelect from 'react-select';
import './CreateLandingPage.css';

export const CreateLandingPage = () => {
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const currentImages = [...selectedImages];

    if (currentImages.length + files.length > 5) {
      alert("You can only upload up to 5 images.");
      return;
    }

    files.forEach((file) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width === 800 && img.height === 533) {
          const newImage = URL.createObjectURL(file);
          setSelectedImages((prevImages) => [...prevImages, newImage]);
        } else {
          alert(`Image "${file.name}" is not 800x533 pixels.`);
        }
      };
    });
  };

  const removeImage = (indexToRemove) => {
    const newImages = selectedImages.filter((_, index) => index !== indexToRemove);
    setSelectedImages(newImages);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Card className="landing-page-card mt-3">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <div className="col-6">
                <Label htmlFor="main_page">Select page</Label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  name="main_page"
                  id="main_page"
                  options={[
                    { label: 'Main Page', value: 'main_page' },
                    { label: 'About Us', value: 'about_us' },
                    { label: 'Investors', value: 'investors' },
                    { label: 'Social Media', value: 'social_media' },
                    { label: 'Follow Us', value: 'follow_us' },
                  ]}
                />
              </div>
              <div className="col-6">
                <Label htmlFor="button_type">Choose Button</Label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  name="button_type"
                  id="button_type"
                  options={[
                    { label: 'Read More', value: 'read_more' },
                    { label: 'More', value: 'more' },
                    { label: 'View More', value: 'view_more' },
                  ]}
                />
              </div>

              <div className="col-6 mt-3">
                <Label htmlFor="title">Title</Label>
                <Input type="text" name="title" id="title" />
              </div>

              <div className="col-6 mt-3">
                <Label htmlFor="tag_line">Tag Line</Label>
                <Input type="text" name="tag_line" id="tag_line" />
              </div>

              <div className="col-6 mt-3">
                <Label htmlFor="desc">Description</Label>
                <textarea rows={4} className="form-control" name="desc" id="desc"></textarea>
              </div>

              <div className="col-6 mt-3">
                <Label htmlFor="image">
                  Upload Images (<span className="text-danger">Max: 5</span>, <span className="text-success">Size: 800x533</span>)
                </Label>
                <Input
                  type="file"
                  name="image"
                  id="image"
                  multiple
                  onChange={handleImageChange}
                  disabled={selectedImages.length >= 5}
                />
              <div className="col-6 mt-3">
                {selectedImages.length > 0 && (
                  <div className="uploaded-images">
                    {selectedImages.map((image, index) => (
                      <div key={index} className="image-container">
                        <img src={image} alt={`uploaded ${index}`} className="uploaded-image" />
                        <button type="button" className="remove-image-btn" onClick={() => removeImage(index)}>✖</button>
                      </div>
                    ))}
                  </div>
                )}
                {selectedImages.length >= 5 && (
                  <p className="limit-text">You have reached the upload limit of 5 images.</p>
                )}
              </div>
              </div>

            </Row>
            <Row className="mt-4 mb-3">
              <Col lg={3} className="mx-auto">
                <div className="hstack gap-2 justify-content-center">
                  <Button type="submit" color="primary" className="px-5">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
