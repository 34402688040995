import React from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './Sponsorship.css';
import Header from '../Header';
import image from '../../../assets/images/mission/sponsorship.png'
const Sponsorship = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="page-content mt-5">

        <Container className="__sponsorship-container">
          <Row className="__title-section mx-3">
            <Col md="8">
              <Row className='mt-3'>
                <Col lg={6}>
                  <h3 className="__title-section mt-3">Sponsorship</h3>
                </Col>
                <Col lg={6}>
                  <div className="d-flex">
                    <Input type="text" className="form-control me-2" placeholder="Search..." />
                    <Button>Apply</Button>
                  </div>
                </Col>
              </Row>
              <p className='font-format fs-4 '>
                <b>Increased Brand Visibility: </b> Sponsorship opportunities allow your brand to gain exposure at events and competitions within our growing community, expanding your reach to a targeted audience.
              </p>
              <p className='font-format fs-4 '>
                <b>Direct Association with High Performance:  </b> Sponsoring athletes or events associates your brand with success, dedication, and peak performance, enhancing your brand’s positioning in the market.
              </p>
              <p className='font-format fs-4 '>
                <b>Flexible Sponsorship Options:  </b> Customize your sponsorship involvement based on your brand’s specific needs, whether you’re looking to support entire events, individual athletes, or particular initiatives, allowing for targeted brand impact.
              </p>
              <p className='font-format fs-4 '>
                <b>Support the Community: </b> Sponsorships allow investors to support events, competitions, and individual athletes within our community, gaining visibility and enhancing community development.
              </p>
              <p className='font-format fs-4 '>
                <b>Customized Sponsorship Plans:  </b> Sponsor community events or specific teams and individuals, allowing for personalized brand exposure and engagement.
              </p>
              <p className='font-format fs-4 '>
                <b>Brand Recognition: </b>With every sponsored event and athlete, your brand gains valuable recognition and goodwill across our platform’s audience.
              </p>

            </Col>
            <Col md="4" className="__image-section">
              <img
                src={image}
                alt="Sponsorship in push"
                className="__sponsorship-image"
              />
            </Col>
          </Row>
          <hr />
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Sponsorship;
