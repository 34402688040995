import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"


import { login, logout } from "helpers/api_url"
import { useDispatch } from "react-redux"
import { CHANGE_PRELOADER } from "store/layout/actionTypes"
import Cookies from "js-cookie"
import { removeAuthToken, setAuthToken } from "helpers/cookie_helper"

function* loginUser(action) {
  try {
    yield put({type: CHANGE_PRELOADER,payload: { status: true, message: "" },})
    const response = yield call(login, action.payload)
    setAuthToken(response._token);
    localStorage.setItem("authUser", JSON.stringify(response))
    localStorage.setItem("authToken", response._token)
    localStorage.setItem("myitem",JSON.stringify(action.payload.redirect));// working
    yield put(loginSuccess(response))
    if(action.payload.redirect){
      window.location.href = action.payload.redirect;
    }else window.location.reload();
  } catch (error) {
    yield put({type: CHANGE_PRELOADER,payload: { status: false, message: "" },})
    yield put(apiError(error.response?error.response.data.message:error.message))
  }
}

function* logoutUser() {
  try {
    const response = yield call(logout);
    localStorage.removeItem('authUser');
    removeAuthToken()
    window.location.href = "/";
  } catch (error) {
    yield put(apiError(error.response?error.response.data.message:error.message))
  }
}



function* authSaga() {
  
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
