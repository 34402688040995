import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter, Input, Button
} from "reactstrap"
import ReactSelect from "react-select";



function FatIntake() {

    const [weight, setWeight] = useState("");
    const [goal, setGoal] = useState("lose");
    const [unitSystem, setUnitSystem] = useState("us");
    const [activityLevel, setActivityLevel] = useState("sedentary");
    const [dailyFatAllowance, setDailyFatAllowance] = useState("");
    const [saturatedFatAllowance, setSaturatedFatAllowance] = useState("");
    const [satFatReducedAllowance, setSatFatReducedAllowance] = useState("");

    const calculateCalories = () => {
        const weightInKg = unitSystem === "metric" ? weight : weight / 2.205; // Convert lbs to kg if us

        let bmr = 0;
        if (activityLevel === "sedentary") {
            bmr = 1.2 * (weightInKg * 24);
        } else if (activityLevel === "lightlyActive") {
            bmr = 1.375 * (weightInKg * 24);
        } else if (activityLevel === "moderatelyActive") {
            bmr = 1.55 * (weightInKg * 24);
        } else if (activityLevel === "veryActive") {
            bmr = 1.725 * (weightInKg * 24);
        } else if (activityLevel === "extraActive") {
            bmr = 1.9 * (weightInKg * 24);
        }

        const fatGoalPercentage = goal === "lose" ? 0.2 : 0.35;
        const dailyCalories = bmr + 500 * (goal === "gain" ? 1 : -1);
        const dailyFatAllowanceValue = dailyCalories * fatGoalPercentage;
        const saturatedFatAllowanceValue = dailyCalories * 0.1;
        const satFatReducedAllowanceValue = dailyCalories * 0.07;

        return {
            dailyCalories: dailyCalories.toFixed(2),
            dailyFatAllowance: dailyFatAllowanceValue.toFixed(2),
            saturatedFatAllowance: saturatedFatAllowanceValue.toFixed(2),
            satFatReducedAllowance: satFatReducedAllowanceValue.toFixed(2),
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const calculatedCalories = calculateCalories();
        setDailyFatAllowance(calculatedCalories.dailyFatAllowance);
        setSaturatedFatAllowance(calculatedCalories.saturatedFatAllowance);
        setSatFatReducedAllowance(calculatedCalories.satFatReducedAllowance);
    };

    const toggleUnitSystem = () => {
        setUnitSystem(unitSystem === "us" ? "metric" : "us");
    };


    return (
        <React.Fragment>
            <Breadcrumbs title="Fat Intake" breadcrumbItem="Dashboard" />
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader>
                            <h3>Results:</h3>
                        </CardHeader>
                            <CardTitle className="my-2">
                                {dailyFatAllowance && (
                                    <div>
                                        <h3>Calculated Daily Fat Intake Allowance</h3>
                                        <p>Daily Calorie Allowance: {dailyFatAllowance} kcal</p>
                                        <p>Daily Fat Allowance: {dailyFatAllowance} kcal</p>
                                        <p>Saturated Fat Allowance: {saturatedFatAllowance} kcal</p>
                                        <p>
                                            Saturated Fat Allowance to Help Reduce Heart Disease:{" "}
                                            {satFatReducedAllowance} kcal
                                        </p>
                                    </div>
                                )}
                            </CardTitle>
                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                <h3>Fat Intake Calculator</h3>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <h2>Fat Intake Calculator</h2>
                                <div>
                                    <Button className="bg-primary" onClick={toggleUnitSystem}>Unit System</Button>
                                    <h3>{unitSystem === "us" ? "US Units" : "Metric Units"}</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <label>Weight ({unitSystem === "metric" ? "kg" : "lbs"}):</label>
                                            <Input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} required />
                                        </div>
                                        <div className="my-2">
                                            <label>Goal:</label>
                                            <ReactSelect Value={goal} options={[
                                                { value: 'lose', label: 'Lose Weight' },
                                                { value: 'maintain', label: 'Maintain Weight' },
                                                { value: 'gain', label: 'Gain Weight' },
                                            ]} onChange={(e) => setGoal(e.target.value)} required />
                                        </div>
                                        <div className="my-2">
                                            <label>Activity Level:</label>
                                            <ReactSelect Value={activityLevel} options={[
                                                { value: 'sedentary', label: 'Sedentary (little or no exercise)' },
                                                { value: 'lightlyActive', label: 'Lightly active (light exercise/sports 1-3 days/week)' },
                                                { value: 'moderatelyActive', label: 'Moderately active (moderate exercise/sports 3-5 days/week)' },
                                                { value: 'veryActive', label: 'Very active (hard exercise/sports 6-7 days a week)' },
                                                { value: 'extraActive', label: 'Extra active (very hard exercise/sports & physical job or 2x training)' },
                                            ]} onChange={(e) => setActivityLevel(e.target.value)} required />
                                        </div>
                                        <Button className="bg-success" type="submit">Calculate</Button>
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>

                        </CardFooter>

                        <CardBody>
                            <h3>Different Types of Fat</h3>
                            <ol>
                                <li><b> Saturated fat:</b> This type of fat is found in animal products such as meat, butter, cheese, and
                                    whole milk, as
                                    well as some plant oils such as coconut and palm oil. Eating too much saturated fat can raise LDL (bad)
                                    cholesterol levels and increase the risk of heart disease.</li>

                                <li><b> Trans fat:</b> This type of fat is found in partially hydrogenated oils, which are often used in
                                    processed foods
                                    such as fried foods, baked goods, and snack foods. Trans fat can also raise LDL (bad) cholesterol levels
                                    and
                                    increase the risk of heart disease.</li>

                                <li><b>Monounsaturated fat:</b> This type of fat is found in foods such as nuts, seeds, avocados, and olive
                                    oil. Eating
                                    foods that are high in monounsaturated fat may help improve cholesterol levels and reduce the risk of
                                    heart
                                    disease.</li>

                                <li><b>Polyunsaturated fat:</b> This type of fat is found in foods such as fatty fish (salmon, tuna, and
                                    mackerel),
                                    flaxseeds, and soybeans. Polyunsaturated fats include omega-3 and omega-6 fatty acids, which are
                                    essential
                                    for good health.</li>

                                <li><b>Omega-3 fatty acids:</b> These are a type of polyunsaturated fat that is found in fatty fish (salmon,
                                    tuna, and
                                    mackerel), flaxseeds, and walnuts. Omega-3 fatty acids are important for brain function and may help
                                    reduce
                                    inflammation in the body.</li>
                            </ol>
                            It's important to note that while fat is an important part of a healthy diet, consuming too much of any type
                            of fat can lead to weight gain and other health problems. It's recommended to focus on consuming healthy
                            fats, such as monounsaturated and polyunsaturated fats, in moderation as part of a well-balanced diet.
                            <br />
                            <br />
                            <h3>Fat Intake Guidelines</h3>
                            <p>
                                The daily recommended fat intake varies based on factors such as age, gender, weight, and level of physical
                                activity. The American Heart Association suggests that adults should aim for a diet that consists of 20-35%
                                of calories from fat, with no more than 10% of calories coming from saturated fat. For example, if you
                                consume 2000 calories per day, 400-700 calories should come from fat. <br /><br />

                                It's important to note that not all fats are created equal. Monounsaturated and polyunsaturated fats, found
                                in foods such as nuts, seeds, and fish, are considered "good" fats because they can help improve cholesterol
                                levels and decrease the risk of heart disease. Saturated fats, found in foods such as red meat and dairy
                                products, and trans fats, found in many processed foods, are considered "bad" fats because they can increase
                                cholesterol levels and increase the risk of heart disease.

                                It's important to maintain a balanced and varied diet that includes healthy fats in moderation while
                                avoiding excessive amounts of saturated and trans fats.

                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Age</th>
                                        <th>total calories</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td> 2-3</td>
                                        <td>30% to 40%</td>

                                    </tr>
                                    <tr>
                                        <td>4-18</td>
                                        <td>25% to 35%</td>

                                    </tr>
                                    <tr>
                                        <td> 19 and above</td>
                                        <td> 20% to 35%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default FatIntake
