import React, { useState } from 'react'
import { Container, Card, CardBody, Collapse, CardTitle, CardHeader, Col, Row, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import { NavLink } from 'react-router-dom'
import BreadCrumb from 'components/Common/Breadcrumb';
import classnames from 'classnames';
import { CreateCompany } from './CreateCompany';
import { Companylist } from './companylist';
import { CreateKyc } from '../kyc/CreateKyc';

function CompanyKyc() {
    const [activeTab, setActiveTab] = useState(1);
    const toggleTab = (tab) => { activeTab !== tab && setActiveTab(tab); }
    const TabData = [
        { tabname: 'Profile', tabcomponent: CreateKyc },
        { tabname: 'Create Company', tabcomponent: CreateCompany },
        { tabname: 'Company List', tabcomponent: Companylist },
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Company" prevPage="Dashboard" prevPath="/dashboard" />
                    <Row>
                        <Col lg={3} xs={12}>
                            <div className="wizard">
                                <div className="steps">
                                    <Nav vertical>
                                        {TabData.map((item, idx) => (
                                            <>
                                                <NavItem key={idx} className={classnames({ current: activeTab === idx + 1, 'mt-3': true })} onClick={() => { toggleTab(idx + 1) }}>
                                                    <NavLink  >
                                                        <div className="row" >
                                                            <div className="col-10 text-start"><span className="number">{idx + 1}.</span> {item.tabname} </div>
                                                            <div className="col-2 text-end d-lg-none"><i className={activeTab === idx + 1 ? "mdi mdi-minus me-1 fs-4 fw-1" : "mdi mdi-plus me-1 fs-4 fw-1"} /></div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                                <div className='d-lg-none d-block'>
                                                    <Collapse isOpen={activeTab === idx + 1}>{React.createElement(item.tabcomponent, { tabNumber: activeTab })}</Collapse>
                                                </div>
                                            </>
                                        ))}
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9} xs={12}>
                            <div className='d-none d-lg-block'>
                                <TabContent activeTab={activeTab} className="">
                                    {TabData.map((item, idx) => (<TabPane tabId={idx + 1} key={idx}>{React.createElement(item.tabcomponent, { tabNumber: activeTab })}</TabPane>))}
                                </TabContent>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CompanyKyc