import React from 'react'

export const StoriesData = () => {
    return (
        <React.Fragment>
            <div>
                <h3 className="__title-section mt-3">Stories</h3>
                <b>Real-Time Updates and Insights</b>
                <p className='font-format fs-4 '>
                    Our Stories feature provides daily, real-time glimpses into the world of fitness, health tips, quick workouts, and motivational content. Through YouTube, Instagram and Facebook Stories, we share behind-the-scenes moments, community spotlights, quick tips, and flash sales on products and services. Stories are a great way to keep up with FittestWarrior’s latest updates in a quick and engaging format. “Submit Request”
                </p>
                <u><b>Share Your Story with FittestWarrior</b> </u>
                <p className='font-format fs-4 '>
                    At FittestWarrior, we celebrate the incredible journeys of individuals or organisation from the sports, health, and fitness industries. Whether you're an athlete, trainer, coach, or someone who has overcome challenges through fitness, “your story matters”.
                </p>
                <p className='font-format fs-4 '>
                    If you have a story of resilience, determination, and inspiration, we’d love to hear it. “Submit your story through our platform”, and if your journey aligns with the spirit of FittestWarrior—be it through your life, profession, or challenges—we’ll feature you across our social media platforms, inspiring our global community.
                </p>
                <p className='font-format fs-4 '>
                    <u className='fs-3'><b><q>Why Collaborate with Us?</q></b></u>
                    <ul>
                        <li>Gain recognition for your efforts and achievements.</li>
                        <li>Inspire others to embark on their own sports, health and fitness journeys.</li>
                        <li>Connect with a supportive and motivated community of like-minded individuals.</li>
                        <li>Be celebrated as one of the trailblazers in the sports, health and fitness world.</li>
                    </ul>
                </p>
                <p className='font-format fs-4 '>
                    <u className='fs-3'><b><q>Tag System: Celebrating Your Identity</q></b></u>
                    <p>When we feature your story, we’ll give you a unique tag that defines your essence and journey. Here are some of the inspiring tags we use to honor you:  </p>
                    <ul>
                        <li>Super Human</li>
                        <li>Real Fighter</li>
                        <li>Fittest Warrior </li>
                        <li>Dedicated Life </li>
                        <li>Champion Spirit</li>
                        <li>Trailblazer</li>
                        <li>Resilience Hero</li>
                        <li>Empowering Voice</li>
                        <li>Unstoppable Soul </li>
                    </ul>
                    <p>These tags are our way of showing the world how extraordinary you are, highlighting your unique journey and the impact you’ve made.  </p>
                </p>
                <p className='font-format fs-4 '>
                    <u className='fs-3'><b><q>Ready to Inspire the World?</q></b></u>
                    Submit your story today and let FittestWarrior help you share it with the world. Together, we can motivate and uplift a global community dedicated to Sports, health, fitness, and resilience.
                </p>
                    <u className='fs-3'><b><i><q>Best Users/Influencers Stories will invite to Our Podcast</q></i></b></u>

            </div>
        </React.Fragment>
    )
}
