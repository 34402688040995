import React from 'react'
import {  Col, Input, Label, Row } from 'reactstrap'
export const CompanyDetails = () => {  
    return (
        <React.Fragment>
                <Row className="mt-3">
                    <Col lg={12} className='mt-2'>
                        <Label htmlFor="representator_designation">Representator Designation</Label>
                        <Input type="text" name="representator_designation" id="representator_designation"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="business_type">Business Type</Label>
                        <Input type="text" name="business_type" id="business_type"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="organization_name">Company Organization Name</Label>
                        <Input type="text" name="organization_name" id="organization_name"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="brand_name">Brand Name</Label>
                        <Input type="text" name="brand_name" id="brand_name"  />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="org_mobile">Mobile Number</Label>
                        <Input type="text" name="org_mobile" id="org_mobile" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="org_whatsapp">Whatsapp Number</Label>
                        <Input type="text" name="org_whatsapp" id="org_whatsapp" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="org_email">Email</Label>
                        <Input type="email" name="org_email" id="org_email" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="org_desk">Desk</Label>
                        <Input type="text" name="org_desk" id="org_desk" />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor="about_your_company">Description</Label>
                        <textarea className='form-control' rows={2} type="text" name='about_your_company' id='about_your_company'></textarea>
                    </Col>
                </Row>
        </React.Fragment>
    )
}
