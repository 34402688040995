import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { preset } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';

function AddPresetDayModal(prop) {
    const dispatch=useDispatch();
    const {presetInView,setPreset,foodList=[],recipeList=[],mealList=[]} = prop;
    const [selectedFoods,setSelectedFoods] = useState([]);
    const [selectedSuppliments,setSelectedSuppliments] = useState([]);
    const [selectedRecipes,setSelectedRecipes] = useState([]);
    const [selectedMeals,setSelectedMeals] = useState([]);
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status);
    const handleSubmit = e =>{
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            preset_id : formData.get('preset_id'),
            day_title : formData.get('day_title'),
            day_name  : formData.get('name'),
            description : formData.get('description'),
            foods : [...selectedFoods,...selectedSuppliments].map(food=>({
                id:food.value,
                quantity:document.getElementById('food_input_'+food.value).value,
                time:document.getElementById('food_time_input_'+food.value).value,
            })),
            recipes : selectedRecipes.map(recipe=>({id:recipe.value,time:document.getElementById('recipe_time_input_'+recipe.value).value})),
            meals : selectedMeals.map(meal=>({id:meal.value,time:document.getElementById('meal_time_input_'+meal.value).value}))
        }
        // console.log(data);
        dispatch(changePreloader({status:true,message:'please wait'}));
        preset.addDay(data).then(res=>{
            setPreset(state=>({...state,days:[...state.days,res.data.presetday]}));
            toggleModal();
            e.target.reset();
            toast.success(res.message)
        })
        .catch(err=>toast.error(err.response?err.response.data.message:err.message))
        .finally(()=>dispatch(changePreloader({status:false,message:''})));
    }
    const handleItemChange = (e,type) => {
        if(type == 'foods') setSelectedFoods(e)
        if(type == 'suppliments') setSelectedSuppliments(e)
        if(type == 'recipes') setSelectedRecipes(e)
        if(type == 'meals') setSelectedMeals(e)
    }
    const handleAmountChange = (e,food_id) => {
        const nutrients = calculate_nutrients(e.target.value,food_id);
        document.getElementById('carb_'+food_id).innerText = nutrients.carb.toFixed(2);
        document.getElementById('prtn_'+food_id).innerText = nutrients.prot.toFixed(2);
        document.getElementById('fat_'+food_id).innerText = nutrients.fat.toFixed(2);
        document.getElementById('kcal_'+food_id).innerText = nutrients.calorie.toFixed(2);
        calculate_total();
    }
    const calculate_nutrients = (amount,food_id)=>{
        const foodData = foodList.filter(fd=>fd.id==food_id)[0];
        const unitMultiplier = parseFloat(amount || foodData.quantity )/parseFloat(foodData.quantity);
        const carb = parseFloat(foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity) * unitMultiplier;
        const prot = parseFloat(foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity) * unitMultiplier;
        const fat = parseFloat(foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity) * unitMultiplier;
        const calorie = ((carb*4) + (prot*4) + (fat*9));
        return {carb,prot,fat,calorie};
    }
    const calculate_total = () => {
        if(selectedFoods.length || selectedSuppliments.length || selectedRecipes.length || selectedMeals.length){
            const total_carb = Array.from(document.getElementsByClassName('carb'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_prot = Array.from(document.getElementsByClassName('prot'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_fat  = Array.from(document.getElementsByClassName('fat'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            const total_kcal = Array.from(document.getElementsByClassName('kcal'))?.reduce((acc,el)=>acc+parseFloat(el.innerText || 0),0);
            document.getElementById('total_carb').innerText =  total_carb.toFixed(2);
            document.getElementById('total_prot').innerText = total_prot.toFixed(2);
            document.getElementById('total_fat').innerText = total_fat.toFixed(2);
            document.getElementById('total_kcal').innerText = total_kcal.toFixed(2);
        }
    }
    useEffect(()=>{calculate_total()},[selectedFoods,selectedSuppliments,selectedRecipes,selectedMeals])
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()} size='lg' >
                <ModalHeader toggle={() => toggleModal()}>Add Day</ModalHeader>
                <form onSubmit={e=>handleSubmit(e)}>
                    <input type='hidden' name='preset_id' value={presetInView?.id} />
                    <ModalBody>
                        <Row>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Day Name </label>
                                    <Input className="form-control" name="name" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Title</label>
                                    <Input className="form-control" name="day_title" />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="mb-2">
                                    <label className="control-label">Description</label>
                                    <textarea className='form-control' name='description' rows={1}></textarea>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mb-2">
                                    <label className="control-label">Foods</label>
                                    <ReactSelect 
                                        isMulti 
                                        isClearable 
                                        options={
                                            foodList
                                            ?.filter(item => item.type !== "nonvegSuppliment" && item.type !== "vegSuppliment")
                                            ?.map(f=>({value:f.id,label:f.name}))
                                        } 
                                        onChange={e=>handleItemChange(e,'foods')}
                                        name='foods[]' 
                                    />
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mb-2">
                                    <label className="control-label">Suppliments</label>
                                    <ReactSelect 
                                        isMulti 
                                        isClearable 
                                        options={
                                            foodList
                                            ?.filter(item => item.type === "nonvegSuppliment" || item.type === "vegSuppliment")
                                            ?.map(f=>({value:f.id,label:f.name}))
                                        } 
                                        onChange={e=>handleItemChange(e,'suppliments')}
                                        name='foods[]' />
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mb-2">
                                    <label className="control-label">Recipes</label>
                                    <ReactSelect 
                                        isMulti 
                                        isClearable 
                                        options={recipeList.map(r=>({label:r.name,value:r.id}))} 
                                        onChange={e=>handleItemChange(e,'recipes')}
                                        name='recipes[]' 
                                    />
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="mb-2">
                                    <label className="control-label">Meals</label>
                                    <ReactSelect 
                                        isMulti 
                                        isClearable 
                                        options={mealList?.map(m=>({label:m.name,value:m.id}))} 
                                        onChange={e=>handleItemChange(e,'meals')}
                                        name='meals[]'
                                    />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12}>
                                <table className='align-middle nowrap mt-2 table'>
                                    <thead>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Time</th>
                                        <th>Carbs</th>
                                        <th>Protien</th>
                                        <th>Fat</th>
                                        <th>Kcal</th>
                                    </thead>
                                    <tbody>
                                        {[...selectedFoods,...selectedSuppliments].map((f,k)=>{
                                            const foodData = foodList.filter(fd=>fd.id==f.value)[0];
                                            return (<tr key={k}>
                                                <td>{foodData?.name}</td>
                                                <td>
                                                    <div className="input-group" style={{width:'150px'}}>
                                                        <input defaultValue={foodData?.quantity} onChange={e=>handleAmountChange(e,f.value)} className='form-control' type='text' id={'food_input_'+f.value} />
                                                        <span className="input-group-text" id="basic-addon2"> <span className='px-1'>Gram</span></span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input className='form-control' style={{width:'150px'}} type='time' id={'food_time_input_'+f.value} />
                                                </td>
                                                <td className='carb' id={'carb_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity.toFixed(2)}</td>
                                                <td className='prot' id={'prtn_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity.toFixed(2)}</td>
                                                <td className='fat' id={'fat_'+f.value}>{foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity.toFixed(2)}</td>
                                                <td className='kcal' id={'kcal_'+f.value}>{foodData?.nutrients.reduce((total,n)=>{
                                                    const multiplier = n.name == 'Carbohydrate' || n.name=='Protein' ? 4 : (n.name=='FAT'?9:0);
                                                    return total + (parseFloat(n.quantity) * multiplier);
                                                },0).toFixed(2)}</td>
                                            </tr>)
                                        })}

                                        {selectedRecipes.map((f,k)=>{
                                            const recipeData = recipeList.filter(ld=>ld.id==f.value)[0];
                                            const nutrients = recipeData.foods.reduce((total, food) => {
                                                const nutrients = calculate_nutrients(food.pivot.food_quantity, food.id);
                                                total.carb += nutrients.carb;
                                                total.prot += nutrients.prot;
                                                total.fat += nutrients.fat;
                                                total.calorie += nutrients.calorie;
                                                return total;
                                            }, {carb:0, fat:0, prot:0, calorie:0});
                                            return (<tr key={k}>
                                                <td colSpan={2}>{recipeData?.name}</td>
                                                <td><input className='form-control' style={{width:'150px'}} type='time' id={'recipe_time_input_'+f.value} /></td>
                                                <td className='carb' id={'carb_'+f.value+'_recipe'}>{nutrients.carb.toFixed(2)}</td>
                                                <td className='prot' id={'prtn_'+f.value+'_recipe'}>{nutrients.prot.toFixed(2)}</td>
                                                <td className='fat' id={'fat_'+f.value+'_recipe'}>{nutrients.fat.toFixed(2)}</td>
                                                <td className='kcal' id={'kcal_'+f.value+'_recipe'}>{nutrients.calorie.toFixed(2)}</td>
                                            </tr>)
                                        })}

                                        {selectedMeals?.map((f,k)=>{
                                            const mealData = mealList?.filter(m=>m.id==f.value)[0];
                                            return (<tr key={k}>
                                                <td colSpan={2}>{mealData?.name}</td>
                                                <td><input className='form-control' style={{width:'150px'}} type='time' id={'meal_time_input_'+f.value} /></td>
                                                <td className='carb' id={'carb_'+f.value+'_meal'}>{mealData.carbohydrate.toFixed(2)}</td>
                                                <td className='prot' id={'prtn_'+f.value+'_meal'}>{mealData.protein.toFixed(2)}</td>
                                                <td className='fat' id={'fat_'+f.value+'_meal'}>{mealData.fat.toFixed(2)}</td>
                                                <td className='kcal' id={'kcal_'+f.value+'_meal'}>{((mealData.fat *9) + (mealData.carbohydrate *4) + (mealData.protein * 4)).toFixed(2)}</td>
                                            </tr>)
                                        })}


                                        {[...selectedFoods,...selectedSuppliments,...selectedRecipes,...selectedMeals].length?(<tr>
                                            <td className='text-right' colSpan={3}>Total</td>
                                            <td id='total_carb'>0</td>
                                            <td id='total_prot'>0</td>
                                            <td id='total_fat'>0</td>
                                            <td id='total_kcal'>0</td>
                                        </tr>):null}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddPresetDayModal