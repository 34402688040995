import React from 'react'
import { Col, Container, Input, Label, Row, Table } from 'reactstrap'

function TimeTableDownloads() {
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={5}>
                        <div className="mb-2">
                            <label className="control-label"> From Date </label>
                            <Input type="date" className="form-control" name="from_date" />
                        </div>
                    </Col>
                    <Col xs={5}>
                        <div className="mb-2">
                            <label className="control-label"> To Date </label>
                            <Input type="date" className="form-control" name="to_date" />
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className="mb-2">
                            <label className="control-label"> Click to Apply </label>
                            <Input type="submit" className="form-control" value="Apply" />
                        </div>
                    </Col>
                </Row>
                <Row className="my-4">
        <Col>
          <h2 className="text-center">NUTRITION ASSESSMENT</h2>
          <h5>CLIENT DETAILS</h5>
          <Table bordered>
            <tbody>
              <tr>
                <td>NAME</td>
                <td>Tejashwani</td>
              </tr>
              <tr>
                <td>AGE</td>
                <td>17 Years</td>
              </tr>
              <tr>
                <td>PACKAGE</td>
                <td>3 Months Sports Nutrition (Cricket)</td>
              </tr>
            </tbody>
          </Table>
          <h5>BODY ASSESSMENT</h5>
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="2">DATE</th>
                <th colSpan="2">21 JUNE 24</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>HEIGHT (CM)</td>
                <td>162</td>
                <td>FAT%</td>
                <td>24.3</td>
              </tr>
              <tr>
                <td>WEIGHT (KG)</td>
                <td>59.3</td>
                <td>BMR (KCAL)</td>
                <td>1295</td>
              </tr>
              <tr>
                <td>BMI (KG/M²)</td>
                <td>22.6</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <h5>FAT DISTRIBUTION</h5>
          <Table bordered>
            <tbody>
              <tr>
                <td>WHOLE BODY (%)</td>
                <td>23</td>
              </tr>
              <tr>
                <td>TRUNK (%)</td>
                <td>17.9</td>
              </tr>
              <tr>
                <td>ARMS (%)</td>
                <td>35</td>
              </tr>
              <tr>
                <td>LEGS (%)</td>
                <td>32.3</td>
              </tr>
            </tbody>
          </Table>
          <h5>MUSCLE DISTRIBUTION</h5>
          <Table bordered>
            <tbody>
              <tr>
                <td>WHOLE BODY (%)</td>
                <td>29.7</td>
              </tr>
              <tr>
                <td>TRUNK (%)</td>
                <td>24</td>
              </tr>
              <tr>
                <td>ARMS (%)</td>
                <td>32.3</td>
              </tr>
              <tr>
                <td>LEGS (%)</td>
                <td>43.3</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
            </Container>
        </div>
    )
}

export default TimeTableDownloads