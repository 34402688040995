import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { exercise } from 'helpers/api_url';
import { Link } from 'react-router-dom';

function ViewExerciseModal(prop) {
    const { exerciseData } = prop;
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    const generateBadge = (array, badgeClass = 'bg-warning') => (<>{array.map((el, i) => (<span key={i} className={'me-1 badge ' + badgeClass}>{el}</span>))}</>)
    return (
        <>
            {prop.children ? <span onClick={toggleModal}>{prop.children}</span> : (
                <button onClick={() => toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}>Add Sub Category</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col className='py-2 border-bottom' xs={6}> Exercise Name</Col>        <Col className='py-2 border-bottom' xs={6}> {exerciseData?.exercise_name} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Muscle Group</Col>         <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.muscle_group).map((muscleItem, idxs) => { const item = JSON.parse(muscleItem); return (<div key={idxs}><span>{item?.category} ( {item?.sub_category})</span></div>) }), 'bg-danger')}</Col>
                        <Col className='py-2 border-bottom' xs={6}> Experience Level</Col>     <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.experience_level).map((muscleItem, idxs) => { const item = JSON.parse(muscleItem); return (<div key={idxs}><span>{item?.category} ( {item?.sub_category})</span></div>) }), 'bg-warning')}</Col>
                        <Col className='py-2 border-bottom' xs={6}> Exercise Type</Col>        <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.exercise_type).map((muscleItem, idxs) => { const item = JSON.parse(muscleItem); return (<div key={idxs}><span>{item?.category} ( {item?.sub_category})</span></div>) }), 'bg-success')}</Col>
                        <Col className='py-2 border-bottom' xs={6}> Equipment</Col>            <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.equipments).map((muscleItem, idxs) => { const item = JSON.parse(muscleItem); return (<div key={idxs}><span>{item?.category} ( {item?.sub_category})</span></div>) }), 'bg-success')}</Col>
                        <Col className='py-2 border-bottom' xs={6}> Area of Body Part</Col>    <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.area_of_body_part || '[]'), 'bg-success')} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Gravity</Col>              <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.gravity || '[]'), 'bg-success')} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Muscle Size</Col>          <Col className='py-2 border-bottom' xs={6}> {exerciseData?.muscle_size} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Intensity / Mobility</Col> <Col className='py-2 border-bottom' xs={6}> {exerciseData?.intensity_mobility} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Mechanics</Col>            <Col className='py-2 border-bottom' xs={6}> {exerciseData?.mechanics} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Secondary Muscles</Col>    <Col className='py-2 border-bottom' xs={6}> {generateBadge(JSON.parse(exerciseData?.secondary_muscles || '[]'))} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Do's</Col>                 <Col className='py-2 border-bottom' xs={6}> {exerciseData?.dos} </Col>
                        <Col className='py-2 border-bottom' xs={6}> Dont's</Col>               <Col className='py-2 border-bottom' xs={6}> {exerciseData?.donts} </Col>
                        <Col className='pt-3 text-center' xs={4}> <Link to={exerciseData?.link_youTube} className='fs-3 text-danger'><i className='bx bxl-youtube'></i></Link> </Col>
                        <Col className='pt-3 text-center' xs={4}> <Link to={exerciseData?.link_instaGram} className='fs-3 text-danger'><i className='bx bxl-instagram'></i></Link> </Col>
                        <Col className='pt-3 text-center' xs={4}> <Link to={exerciseData?.link_facebook} className='fs-3 text-primary'><i className='bx bxl-facebook'></i></Link> </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewExerciseModal