import React, { useState } from 'react'
import { Card, CardBody, CardTitle, CardHeader, CardFooter, Col, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { ViewFood } from '../create/ViewFood';
import ViewRecipeModal from '../create/Recipe/ViewRecipeModal';

function DietCard({index,day,foodList,recipeList,mealList}) {
       
    const calculate_nutrients = (amount,food_id)=>{
        const foodData = foodList.filter(fd=>fd.id==food_id)[0];
        const unitMultiplier = parseFloat(amount || foodData.quantity )/parseFloat(foodData.quantity);
        const carb = parseFloat(foodData?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity) * unitMultiplier;
        const prot = parseFloat(foodData?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity) * unitMultiplier;
        const fat = parseFloat(foodData?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity) * unitMultiplier;
        const calorie = ((carb*4) + (prot*4) + (fat*9));
        return {carb,prot,fat,calorie};

    }
    const handleTotal = () => {
        const totalNutrients = {
            carbs:0,
            protein:0,
            fat:0,
            calorie:0
        }
        JSON.parse(day?.foods)?.map(f=>{
            const food = foodList.filter(f=>f.id == f.id)[0];
            totalNutrients.carbs += food?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity;
            totalNutrients.protein += food?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity;
            totalNutrients.fat += food?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity;  
        })
        JSON.parse(day?.recipes)?.map(r=>{
            const recipe = recipeList.filter(f=>f.id == r.id)[0];
            const nutrients = recipe.foods.reduce((total, food) => {
                const nutrients = calculate_nutrients(food.pivot.food_quantity, food.id);
                total.carb += nutrients.carb;
                total.prot += nutrients.prot;
                total.fat += nutrients.fat;
                total.calorie += nutrients.calorie;
                return total;
            }, {carb:0, fat:0, prot:0, calorie:0});
            totalNutrients.carbs += nutrients.carb;
            totalNutrients.protein += nutrients.prot;
            totalNutrients.fat += nutrients.fat;
        })
        JSON.parse(day?.meals)?.map(m=>{
            const meal = mealList.filter(f=>f.id == m.id)[0];
            totalNutrients.carbs += meal.carbohydrate;
            totalNutrients.protein += meal.protein;
            totalNutrients.fat += meal.fat;
        })
        return totalNutrients;
    }
    const [totals,setTotals] = useState(handleTotal());
    return (
        <Card>
            {index !== null && (
                <CardHeader className='d-flex justify-content-between align-items-center'>
                    <CardTitle> {day?.day_name || 'DAY '+ (+index+1)} <small className='ms-2'>({day?.day_title})</small></CardTitle>
                    <div>
                        <button className='me-2 btn btn-sm btn-danger' ><i className="mdi mdi-delete-outline"></i></button>
                        <button className='me-2 btn btn-sm btn-warning' ><i className="mdi mdi-pencil-outline"></i></button>
                        <button className='me-2 btn btn-sm btn-success' ><i className="mdi mdi-plus"></i></button>
                    </div>
                </CardHeader>
            )}
            <CardBody>
                <SimpleBar style={{height:'200px'}}>
                    <ul className="list-group list-group-flush">
                        {JSON.parse(day?.foods)?.map((f,i)=>{
                            const food = foodList.filter(f=>f.id == f.id)[0];
                            const carbs = food?.nutrients.filter(n=>n.name=='Carbohydrate')[0]?.quantity;
                            const protein = food?.nutrients.filter(n=>n.name=='Protein')[0]?.quantity;
                            const fat = food?.nutrients.filter(n=>n.name=='FAT')[0]?.quantity;
                            return food &&  (
                                <li key={i}  className='list-group-item d-flex justify-content-between align-items-center'>
                                    <p> <span className='p-2 me-2 badge bg-success'>{f?.time}</span> {food.name}  ({f?.quantity} gram)</p>
                                    <div>
                                        <ViewFood food={food} />
                                        {index !== null && (
                                            <button className='me-2 btn btn-sm btn-soft-danger' ><i className="mdi mdi-delete-outline"></i></button>
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                        {JSON.parse(day?.recipes)?.map((r,i)=>{
                            const recipe = recipeList.filter(f=>f.id == r.id)[0];
                            return recipe && (
                                <li key={i}  className='list-group-item d-flex justify-content-between align-items-center'>
                                    <div>
                                        <p> <span className='p-2 me-2 badge bg-success'>{r?.time}</span> {recipe.name}</p>
                                    </div>
                                    <div>
                                        <ViewRecipeModal Recipe={recipe} foodList={foodList} />
                                        {index !== null && (
                                            <button className='me-2 btn btn-sm btn-soft-danger' ><i className="mdi mdi-delete-outline"></i></button>
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                        {JSON.parse(day?.meals)?.map((m,i)=>{
                            const meal = mealList.filter(f=>f.id == m.id)[0];
                            return meal && (
                                <li key={i}  className='list-group-item d-flex justify-content-between align-items-center'>
                                    <div>
                                        <p><span className='p-2 me-2 badge bg-success'>{m?.time}</span>{meal.name}</p>
                                    </div>
                                    <div>
                                        <button className='me-2 btn btn-sm btn-soft-info' ><i className="mdi mdi-eye-outline"></i></button>
                                        {index !== null && (
                                            <button className='me-2 btn btn-sm btn-soft-danger' ><i className="mdi mdi-delete-outline"></i></button>
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </SimpleBar>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col xs={12} className='d-flex justify-content-between align-items-center py-2'>
                        <p className='fw-bold m-0 text-info'>Carbs : {totals.carbs.toFixed(2)} gm</p>
                        <p className='fw-bold m-0 text-warning'>Protein : {totals.protein.toFixed(2)} gm</p>
                        <p className='fw-bold m-0 text-success'>Fat : {totals.fat.toFixed(2)} gm</p>
                        <p className='fw-bold m-0 text-danger'>Calorie : {((totals.carbs*4)+(totals.protein*4)+(totals.fat*9)).toFixed(2)} kcal</p>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default DietCard