import { exercise } from 'helpers/api_url';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader,ModalFooter, Col, Input, Row, Button } from 'reactstrap';
import { changePreloader } from 'store/actions';

function AddSubCategoryModal(prop) {
    const dispatch=useDispatch();
    const {data,setCategories} = prop;
    const [status,setStatus] = useState(false);
    const toggleModal = ()=> setStatus(!status); 
    
    
    const handleAddCategory = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if(formData.get('sub_category_name') != ''){
            dispatch(changePreloader({status:true,message:'please wait'}));
            exercise.addCategory(formData)
            .then(res=>{setCategories(state=>state.map(s=>s.id==res.data.category.id?res.data.category:s));toast.success(res.message)})
            .catch(err=>toast.error(err.response?err.response.data.message:err.message))
            .finally(()=>dispatch(changePreloader({status:false,message:''})));
        }
        else toast.error('Sub Category name is required');
    } 
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-info ms-2"><i className="mdi mdi-plus"></i></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Add Sub Category</h5></ModalHeader>
                <form onSubmit={handleAddCategory}>
                    <ModalBody>
                        <Row>
                        <Col xs={12}>
                            <div className="mb-2">
                            <label className="control-label"> Category Title </label>
                            <input className="form-control" value={data?.category_title} name="category_title" readOnly />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-2">
                                <label className="control-label"> Category </label>
                                <input className="form-control" value={data?.category_name}  name="category_name" readOnly />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-2">
                                <label className="control-label"> Sub Category </label>
                                <Input name="sub_category_name" className="form-control"/>
                            </div>
                        </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter><Button color="primary" type="submit" className="mt-2"> Add </Button></ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default AddSubCategoryModal