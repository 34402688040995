import PropTypes from "prop-types";
import React, { useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { Container, Row, Col, CardGroup, Card, CardTitle, CardText, CardBody, CardFooter, Toast, ToastHeader, ToastBody, } from "reactstrap"
import WelcomeComp from "components/Common/WelcomeComp";
import { Link } from "react-router-dom";
const Dashboard = props => {
  document.title = "Dashboard | Fittestwarrior";
  const [toast, setToast] = useState(true);
  const [toast1, setToast1] = useState(true);
  const toggleToast = () => {
    setToast(!toast);
  };
  const toggleToast1 = () => {
    setToast1(!toast1);
  };

  const reports = [
    { title: "Jobs", iconClass: "bx-copy-alt", description: "$1,235",url:'/job',users:["admin",'visitor'] },
    { title: "Participator", iconClass: "bx-copy-alt", description: "$1,235",url:'/participator',users:[]  },
    {title: "Blog",iconClass: "bx-purchase-tag-alt",description: "$16.2",url:'/blog',users:[] },
    { title: "Consultancy", iconClass: "bx-copy-alt", description: "$1,232" ,url:'/professional',users:[] },
    {title: "Deit",iconClass: "bx-purchase-tag-alt",description: "$16.2",url:'/createDiet',users:[] },
    { title: "Trainer", iconClass: "bx-archive-in", description: "$35, 723",url:'/createplan' ,users:[] },
    { title: "Calculator", iconClass: "bx-archive-in", description: "$35, 723",url:'/calculator',users:[]  },
    { title: "Body Static", iconClass: "bx-archive-in", description: "$35, 723",url:'/body-static',users:[]  },
  
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Dashboard")} />
          <Row className="mb-3">
            <Col lg={12}>
              <Toast isOpen={toast} className="w-100 bg-info-subtle text-primary">
                <ToastHeader toggle={toggleToast} className="bg-info-subtle text-primary">Offer</ToastHeader>
                <ToastBody><h3 className="text-center">Advertisment Contents</h3></ToastBody>
              </Toast>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <WelcomeComp />
            </Col>

            <Col lg={6}>
              <Row>
                  
                <CardGroup>
                  {reports.map((data, key) => (
                    <>
                   
                    <Col xs="12" md="6" key={key}>
                      <Card className="mx-2" >
                        <CardBody>
                          <div className="d-flex justify-content-between align-item-center">
                            <CardTitle>{data.title} Status</CardTitle>
                            <button className="btn btn-sm btn-soft-success">
                              <i className="mdi mdi-run"></i>
                            </button>
                          </div>
                          <CardText className="pt-1">
                            <Row className="my-3">
                              <Col className="d-flex justify-content-between">
                                <span className="fw-bold">Total:</span>
                                <span>500</span>
                              </Col>
                            </Row>
                            <div className="d-lg-flex d-md-block d-flex justify-content-between">
                              <div className="d-lg-block d-md-flex d-block justify-content-between mb-1">
                                <span className="fw-bold">Total Avaiable:</span>
                                <span className="ms-2">480</span>
                              </div>
                              <div className="d-lg-block d-md-flex d-block justify-content-between">
                                <span className="fw-bold">Total Left:</span>
                                <span className="ms-2">20</span>
                              </div>
                            </div>
                          </CardText>
                          <CardFooter className="text-center" style={{ cursor: 'pointer' }}><Link to={data.url}><span>{data.title} Details</span></Link></CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    </>

                  ))}
                </CardGroup>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12}>
              <Toast isOpen={toast1} className="w-100 bg-info-subtle text-primary">
                <ToastHeader toggle={toggleToast1} className="bg-info-subtle text-primary">Offer</ToastHeader>
                <ToastBody><h3 className="text-center">Advertisment Contents</h3></ToastBody>
              </Toast>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
