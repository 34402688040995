import React from 'react'
import user1 from '../../../assets/images/users/avatar-1.jpg'
import user2 from '../../../assets/images/users/avatar-2.jpg'
import user3 from '../../../assets/images/users/avatar-3.jpg'
import user4 from '../../../assets/images/users/avatar-4.jpg'
import user5 from '../../../assets/images/users/avatar-5.jpg'
import user6 from '../../../assets/images/users/avatar-3.jpg'
import { Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row } from 'reactstrap'
import Header from '../Header'
import './Team.css'
export const Team = () => {
    const teamMembers = [
        { name: "Aditya Kedia", role: "Founder", image:user1 },
        { name: "Coach Richi", role: "Head of Training", image: user2},
        { name: "Mansi Rawal", role: "Growth Manager", image: user3 },
        { name: "Coach Sonam", role: "Kids Nutritionist", image: user4 },
        { name: "Coach Shilpa", role: "Fit Buds - Yoga", image: user5},
        { name: "Coach Aman", role: "Fit Buds - Dance", image: user6 },
      ];
    return (
        <React.Fragment>
            <Header />
            <div className="page-content mt-5">
            <Container className="__team-section">
                <h2 className="text-center __team-title">Our <span>Team</span></h2>
                <Row className='mt-5'>
                    {teamMembers.map((member, index) => (
                        <Col md="4" sm="6" xs="12" key={index} className="mb-4">
                            <Card className="__team-card">
                                <div className="__team-img-wrapper">
                                    <img src={member.image} alt={member.name} className="__team-img" />
                                </div>
                                <CardBody>
                                    <CardTitle tag="h5" className="text-center">{member.name}</CardTitle>
                                    <CardSubtitle tag="h6" className="text-center text-muted">{member.role}</CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            </div>
        </React.Fragment>
    )
}
