import React, { useState } from "react";
import LeftSlidecompetition from "./LeftSlideCompetition";
import CompetitionRightSide from "./CompetitionRightSide";
const Competition = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  return (
    <React.Fragment>
      <section className="py-4" style={{backgroundColor: "rgb(255 255 255)",color: "black",overflowX: "hidden",}}>
        <div className="container-fluid">  
          <div className="row mx-4">
            <div className="text-center py-3"><h3 className="small-title fs-1">Recent Activities</h3></div>
            <div className="col-lg-4"><LeftSlidecompetition activeTab={customActiveTab} /></div>
            <div className="col-lg-8"><CompetitionRightSide customActiveTab={customActiveTab} setcustomActiveTab={setcustomActiveTab}/></div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Competition;
