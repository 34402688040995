import React, { useState } from 'react';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Button, Input } from 'reactstrap';
import ReactSelect from 'react-select';

function PreganancyCalc() {

    const [method, setMethod] = useState("lmp");
    const [cycleLength, setCycleLength] = useState("");
    const [date, setDate] = useState("");
    const [estimatedDueDate, setEstimatedDueDate] = useState("");
    const [trimester, setTrimester] = useState("");

    const calculateDueDate = () => {
        if (method === "lmp" && cycleLength && date) {
            const lmpDate = new Date(date);
            const adjustedCycleLength = parseInt(cycleLength) - 28;
            lmpDate.setDate(lmpDate.getDate() + adjustedCycleLength);
            const dueDate = new Date(lmpDate.setDate(lmpDate.getDate() + 280));
            setEstimatedDueDate(dueDate.toDateString());
            setTrimester(getTrimester(dueDate));
        } else if (method === "conception" && date) {
            const conceptionDate = new Date(date);
            const dueDate = new Date(
                conceptionDate.setDate(conceptionDate.getDate() + 266)
            );
            setEstimatedDueDate(dueDate.toDateString());
            setTrimester(getTrimester(dueDate));
        } else if (method === "fpl" && cycleLength && date) {
            const fplDate = new Date(date);
            const adjustedCycleLength = parseInt(cycleLength) - 28;
            fplDate.setDate(fplDate.getDate() - adjustedCycleLength);
            const dueDate = new Date(fplDate.setDate(fplDate.getDate() + 280));
            setEstimatedDueDate(dueDate.toDateString());
            setTrimester(getTrimester(dueDate));
        } else if (method === "ivf" && date) {
            const ivfDate = new Date(date);
            const dueDate = new Date(ivfDate.setDate(ivfDate.getDate() + 266));
            setEstimatedDueDate(dueDate.toDateString());
            setTrimester(getTrimester(dueDate));
        } else if (method === "ultrasound" && date) {
            const ultrasoundDate = new Date(date);
            const dueDate = new Date(
                ultrasoundDate.setDate(ultrasoundDate.getDate() + 280)
            );
            setEstimatedDueDate(dueDate.toDateString());
            setTrimester(getTrimester(dueDate));
        }
    };

    const getTrimester = (dueDate) => {
        const currentDate = new Date();
        const weeksPregnant = Math.floor(
            (currentDate - dueDate) / (1000 * 60 * 60 * 24 * 7)
        );
        if (weeksPregnant < 13) {
            return "1st Trimester";
        } else if (weeksPregnant >= 13 && weeksPregnant < 27) {
            return "2nd Trimester";
        } else {
            return "3rd Trimester";
        }
    };


    return (
        <React.Fragment>
            <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader>
                                <h3>Estimated Due Date:</h3>
                        </CardHeader>
                            <CardTitle className='my-2'>
                                {estimatedDueDate && (
                                    <div>
                                        <p>{estimatedDueDate}</p>
                                        <h3>Trimester:</h3>
                                        <p>{trimester}</p>
                                    </div>)}
                            </CardTitle>

                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <CardBody>
                            <div>
                                <h2>Pregnancy Calculator</h2>
                                <label>Calculation Method:</label>
                                <ReactSelect Value={method} onChange={(e) => setMethod(e.value)}
                                    options={[
                                        { value: 'lmp', label: 'Last Menstrual Period (LMP)' },
                                        { value: 'conception', label: 'Conception Date' },
                                        { value: 'fpl', label: 'First Day of Last Period (FPL)' },
                                        { value: 'ivf', label: 'IVF Transfer Date' },
                                        { value: 'ultrasound', label: 'Ultrasound Date' },
                                    ]} />
                                {(method === "lmp" || method === "fpl") && (
                                    <div className='my-2'>
                                        <label>Cycle Length (in days):</label>
                                        <Input type="number" value={cycleLength} onChange={(e) => setCycleLength(e.target.value)} />
                                    </div>
                                )}
                                <div className='my-2'>
                                    <label>Date:</label>
                                    <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                                <Button className='btn btn-success' onClick={calculateDueDate}>Calculate Due Date</Button>

                            </div>
                        </CardBody>
                    </Card>
                    <br></br>
                    <Card>
                        <CardBody>
                            <h3>Pregnancy Term &amp; Due Date</h3>
                            <ul>
                                <li>Pregnancy is a state when a woman carries one or more developing offspring inside her body for a duration of approximately 9 months.</li>
                                <li>The birth of a child typically occurs about 38 weeks after conception, or 40 weeks after the last menstrual cycle.</li>
                                <li>The World Health Organization considers a normal pregnancy term to be between 37 and 42 weeks.</li>
                                <li>At the first prenatal visit with an obstetrician-gynecologist, an estimated due date is usually provided based on ultrasound or the last menstrual period.</li>
                                <li>However, the actual length of pregnancy may vary due to several factors such as the mother's age, length of previous pregnancies, and birth weight.</li>
                                <li>Some factors that contribute to natural variations in pregnancy duration are not fully understood.</li>
                                <li>Research suggests that only about 4% of births happen on the exact due date, around 60% occur within a week of the due date, and almost 90% happen within two weeks of the due date.</li>
                                <li>Hence, while it is possible to estimate a due date, it is not possible to predict the exact day of birth with absolute certainty.</li>
                            </ul>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h3>Pregnancy Detection</h3>
                            <ul>
                                <li>There are two ways to detect pregnancy: through symptoms or through pregnancy tests.</li>
                                <li>Some common symptoms of pregnancy include a missed period, elevated basal body temperature, fatigue, morning sickness, and increased urination frequency.</li>
                                <li>Pregnancy tests use specific biomarkers, such as hormones, to detect pregnancy.</li>
                                <li>Clinical blood and urine tests can detect pregnancy as early as six to eight days after fertilization.</li>
                                <li>Blood tests are more accurate and can detect lower levels of the hormone hCG, which is only present during pregnancy, but they are more expensive and take longer to evaluate than home urine tests.</li>
                                <li>Clinical urine tests are also available but are not necessarily more accurate and can be more expensive.</li>
                            </ul>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h3>Pregnancy Management</h3>
                            <p>
                                During pregnancy, various factors need to be taken into account, and the considerations vary depending on the individual's circumstances. Factors such as medication, weight gain, exercise, and nutrition are crucial during this time. It is important for expectant mothers to seek advice from healthcare professionals to ensure the health and well-being of both the mother and the developing baby.
                            </p>
                            <strong>Medication:</strong>
                            <ul>
                                <li>Certain medications taken during pregnancy can have lasting effects on the fetus.</li>
                                <li>The FDA categorizes drugs into categories A, B, C, D, and X based on potential benefits vs. fetal risks.</li>
                                <li>Drugs with low risk to the fetus and positive benefits for the mother are classified as category A.</li>
                                <li>Drugs with proven, significant fetal risks outweighing potential benefits to the mother are classified as category X.</li>
                                <li>Pregnant individuals should consult their doctor before taking any medication during pregnancy.</li>
                            </ul>

                            <strong>Weight Gain:</strong>
                            <ul >
                                <li>Weight gain is a natural and necessary part of pregnancy that varies between individuals.</li>
                                <li>It impacts fetal development, including the weight of the baby, placenta, circulatory fluid, and fat and protein stores.</li>
                                <li>Managing weight gain is important to avoid negative consequences such as C-sections and gestational hypertension.</li>
                                <li>The Institute of Medicine recommends specific weight gain ranges based on pre-pregnancy BMI.</li>
                                <li>The recommended weight gain for women with a "normal" weight (BMI 18.5-24.9) is 25-35 pounds.</li>
                                <li>For underweight women (BMI &lt; 18.5), the recommended weight gain is 28-40 pounds.</li>
                                <li>For overweight women (BMI 25-29.9), the recommended weight gain is 15-25 pounds.</li>
                                <li>For obese women (BMI &gt;30), the recommended weight gain is 11-20 pounds.</li>
                                <li>Our Pregnancy Weight Gain Calculator is based on the Institute of Medicine's guidelines.</li>
                            </ul>

                            <strong>Excercise</strong>
                            <ul>
                                <li>Studies have shown that aerobic exercise during pregnancy can improve or maintain physical fitness and reduce the risk of needing a C-section.</li>
                                <li>While the appropriate level of exercise will vary between individuals, regular aerobic and strength training exercises are typically recommended for pregnant women, particularly for those who were active before pregnancy and have uncomplicated pregnancies.</li>
                                <li>The American College of Obstetricians and Gynecologists states that the likelihood of fetal injury resulting from exercise is low, as long as the pregnancy is uncomplicated.</li>
                                <li>However, pregnant women should exercise caution and consult with their doctor if they experience symptoms such as vaginal bleeding, shortness of breath, dizziness, headaches, calf pain or swelling, amniotic fluid leakage, decreased fetal movement, preterm labor, muscle weakness, or chest pain.</li>
                            </ul>

                            <strong>Nutrition:</strong>
                            <p>Nutrition is of utmost importance during pregnancy as it directly impacts the health and wellbeing of both the mother and the developing fetus. The nutritional requirements during pregnancy differ from those in a non-pregnant state as there is an increased need for energy and specific micronutrients.</p>
                            <p>Certain nutrients such as folic acid, also known as Vitamin B9, can significantly reduce the risk of certain birth defects. On the other hand, DHA omega-3 is essential for the proper development of the brain and retina, but infants cannot produce it efficiently and hence it needs to be obtained from the placenta during pregnancy or breast milk after birth. There are several other micronutrients that play a crucial role in fetal development.</p>
                            <p>
                                It can be overwhelming to sift through the abundance of information available on what pregnant women should or shouldn't eat. Moreover, the dietary requirements and restrictions can vary from person to person. Therefore, it is highly recommended for pregnant women to consult their doctors and/or dietitians to devise a personalized nutrition plan that caters to their specific needs.
                            </p>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default PreganancyCalc;
