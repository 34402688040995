import React, { useState } from 'react'
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter } from "reactstrap";

function CalorieCalculator({publicdata=false}) {
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('male');
    const [activityLevel, setActivityLevel] = useState('sedentary');
    const [goal, setGoal] = useState('maintenance');
    const [tdee, setTDEE] = useState(null);
    const [calorieGoal, setCalorieGoal] = useState(null);

    const calculateTDEE = () => {
        // Harris-Benedict equation
        let bmr = gender === 'male' ? 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age : 447.593 + 9.247 * weight + 3.098 * height - 4.330 * age;
        // Adjust BMR based on activity level
        switch (activityLevel) {
          case 'sedentary': bmr *= 1.2; break;
          case 'lightlyActive': bmr *= 1.375; break;
          case 'moderatelyActive': bmr *= 1.55; break;
          case 'veryActive': bmr *= 1.725; break;
          case 'extraActive': bmr *= 1.9; break;
          default: break;
        }
        setTDEE(bmr.toFixed(2));
    };

const calculateCalorieGoal = () => {
    let adjustedTDEE = tdee;
    switch (goal) {
        case 'weightLoss':  
            adjustedTDEE *= 0.8; 
            break;
        case 'maintenance': 
            adjustedTDEE
            break;
        case 'weightGain': 
            adjustedTDEE *= 1.2; 
            break;
        default: 
            break;
    }
    adjustedTDEE = adjustedTDEE === null ? 0 : adjustedTDEE;
    setCalorieGoal(adjustedTDEE.toFixed(2));
};


    return (
        <React.Fragment>
            {
                publicdata==true?
                <>
                <Card className='my-3'>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Calorie Goal / TDEE Calculator</h3>
                                    </CardTitle>
                                    <ul>
                                    <h5>Result:</h5>
                                        <li>
                                           <Row>
                                                <Col><h4>TDEE</h4> </Col>
                                                <Col><h4>{tdee !== null ? tdee:"-"}</h4> </Col>
                                            </Row> 
                                        </li>
                                        <li>
                                           <Row>
                                                <Col><h4>Calorie Goal</h4> </Col> 
                                                <Col><h4>{calorieGoal !== null ? calorieGoal:"-"}</h4> </Col>
                                            </Row> 
                                        </li>
                                    </ul>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Weight (kg):</label>
                                                <input type="number" value={weight} className='form-control' onChange={(e) => setWeight(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Height (cm):</label>
                                                <input type="number" value={height} className='form-control' onChange={(e) => setHeight(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Age :</label>
                                                <input type="number" value={age} className='form-control' onChange={(e) => setAge(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Gender :</label>
                                                <select value={gender} className='form-control' onChange={(e) => setGender(e.target.value)}>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Activity Level :</label>
                                                <select value={activityLevel} className='form-control' onChange={(e) => setActivityLevel(e.target.value)}>
                                                    <option value="sedentary">Sedentary</option>
                                                    <option value="lightlyActive">Lightly Active</option>
                                                    <option value="moderatelyActive">Moderately Active</option>
                                                    <option value="veryActive">Very Active</option>
                                                    <option value="extraActive">Extra Active</option>
                                                </select>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Goal :</label>
                                                <select value={goal} className='form-control' onChange={(e) => setGoal(e.target.value)}>
                                                    <option value="weightLoss">Weight Loss</option>
                                                    <option value="maintenance">Maintenance</option>
                                                    <option value="weightGain">Weight Gain</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex justify-content-end py-2'>
                                        <button className='me-2 btn btn-success' onClick={calculateTDEE}>Calculate TDEE</button>
                                        <button className='me-2 btn btn-info' onClick={calculateCalorieGoal}>Calculate Calorie Goal</button>
                                    </div>
                                </CardFooter>
                                </Card>
                </>
                :
                <>
                
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardHeader><CardTitle><h3>Results</h3></CardTitle></CardHeader>
                                <CardBody>
                                    <ul>
                                        <li>
                                           <Row>
                                                <Col><h4>TDEE</h4> </Col>
                                                <Col><h4>{tdee !== null ? tdee:"-"}</h4> </Col>
                                            </Row> 
                                        </li>
                                        <li>
                                           <Row>
                                                <Col><h4>Calorie Goal</h4> </Col> 
                                                <Col><h4>{calorieGoal !== null ? calorieGoal:"-"}</h4> </Col>
                                            </Row> 
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                    <CardTitle>
                                        <h3>Calorie Goal / TDEE Calculator</h3>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <form>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Weight (kg):</label>
                                                <input type="number" value={weight} className='form-control' onChange={(e) => setWeight(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Height (cm):</label>
                                                <input type="number" value={height} className='form-control' onChange={(e) => setHeight(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Age :</label>
                                                <input type="number" value={age} className='form-control' onChange={(e) => setAge(e.target.value)} />
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Gender :</label>
                                                <select value={gender} className='form-control' onChange={(e) => setGender(e.target.value)}>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Activity Level :</label>
                                                <select value={activityLevel} className='form-control' onChange={(e) => setActivityLevel(e.target.value)}>
                                                    <option value="sedentary">Sedentary</option>
                                                    <option value="lightlyActive">Lightly Active</option>
                                                    <option value="moderatelyActive">Moderately Active</option>
                                                    <option value="veryActive">Very Active</option>
                                                    <option value="extraActive">Extra Active</option>
                                                </select>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <label className='label-control'>Goal :</label>
                                                <select value={goal} className='form-control' onChange={(e) => setGoal(e.target.value)}>
                                                    <option value="weightLoss">Weight Loss</option>
                                                    <option value="maintenance">Maintenance</option>
                                                    <option value="weightGain">Weight Gain</option>
                                                </select>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex justify-content-end py-2'>
                                        <button className='me-2 btn btn-success' onClick={calculateTDEE}>Calculate TDEE</button>
                                        <button className='me-2 btn btn-info' onClick={calculateCalorieGoal}>Calculate Calorie Goal</button>
                                    </div>
                                </CardFooter>
                                <CardBody>
                                <div>
                                    <p>A calorie calculator is a tool used to estimate the number of calories a person needs to consume in a day to maintain their current weight, 
                                        or to reach a desired weight goal. The calculator takes into account a person's age, gender, height, weight, activity level, and weight goal to calculate the number of calories needed. 
                                        The estimated calorie needs can be used to plan a balanced diet and determine appropriate portion sizes for meals and snacks. Calorie calculators are commonly used by individuals looking to lose weight, gain weight, or maintain their weight for overall health and wellness. 
                                        However, it's important to note that these calculators provide an estimate and should be used in conjunction with guidance from a healthcare professional for personalized and accurate nutrition advice.
                                    </p>
                                </div>
                                <div>
                                    <strong>Mifflin-St Jeor Equation:</strong>
                                        <p> <strong>For Men:</strong> BMR = 10W + 6.25H - 5A + 5</p>
                                        <p> <strong>For Women:</strong> BMR = 10W + 6.25H - 5A - 161</p>
                                    <strong>Revised Harris-Benedict Equation:</strong>
                                        <p> <strong>For Men:</strong> BMR = 13.397W + 4.799H - 5.677A + 88.362</p>
                                        <p> <strong>For Women:</strong> BMR = 9.247W + 3.098H - 4.330A + 447.593</p>
                                </div>
                                <div>
                                    <h3>Katch-McArdle Formula:</h3>
                                        <p> <strong>For Men:</strong> BMR = 370 + 21.6(1 - F)W</p>
                                        <p> <strong>For Women:</strong> For Women: BMR = 9.247W + 3.098H - 4.330A + 447.593</p>
                                        <ul>
                                            <li>W is body weight in kg</li>
                                            <li>H is body height in cm</li>
                                            <li>A is age</li>
                                            <li>F is body fat in percentage</li>
                                        </ul> 
                                        <p>The Katch-McArdle formula estimates the number of calories a person needs per day to maintain their body weight while at rest, based on their lean body mass. 
                                            This estimate is then multiplied by an activity factor, which takes into account the person's typical levels of exercise, to obtain a more realistic value for maintaining body weight throughout the day. 
                                            To lose weight, it is recommended to reduce calorie intake by 500 calories per day, which equates to losing 1 pound per week. It is not recommended to reduce calorie intake by more than 1,000 calories per day, as this can lead to unhealthy weight loss and muscle loss, which can lower BMR. Additionally, it's important to maintain a balanced and nutritious diet to support metabolic processes and replenish the body's nutrients.</p>
                                </div>
                                <div>
                                    <h3>Using calorie tracking for weight loss</h3>
                                        <ul>
                                            <li>Calorie counting can be an effective means for weight loss because it helps individuals understand the number of calories they consume and burn each day.</li>
                                            <li>When someone consumes more calories than they burn, the excess calories are stored as fat in the body, leading to weight gain.</li>
                                            <li>On the other hand, if they consume fewer calories than they burn, the body will use stored fat for energy, leading to weight loss.</li>
                                            <li>By tracking the number of calories they consume each day, individuals can develop a better understanding of their eating habits and make adjustments as needed to meet their weight loss goals.</li>
                                            <li>Calorie counting can be done using various methods, including keeping a food journal, using a calorie tracking app or website, or reading food labels to determine calorie content.</li>
                                            <li>However, it's important to note that while calorie counting can be an effective tool for weight loss, it's not the only factor to consider.</li>
                                            <li>Proper nutrition, physical activity, and overall lifestyle habits also play a significant role in achieving and maintaining a healthy weight.</li>
                                            <li>Additionally, it's important to avoid excessively restricting calories, as this can lead to nutrient deficiencies and other negative health effects.</li>
                                            <li>It's recommended to aim for a safe and sustainable rate of weight loss, typically 1-2 pounds per week.</li>
                                            
                                        </ul> 
                                </div>
                                <div>
                                    <h3>Zigzag Calorie Cycling</h3>
                                    <ul>
                                        <li>Zigzag Calorie Cycling is a method of varying daily calorie intake to prevent the body from adapting to a set caloric intake.</li>
                                        <li>It involves alternating high-calorie and low-calorie days throughout the week.</li>
                                        <li>On high-calorie days, individuals consume more calories than their maintenance level to provide the body with the energy it needs to perform high-intensity exercise and build muscle.</li>
                                        <li>On low-calorie days, individuals consume fewer calories than their maintenance level to create a caloric deficit and promote fat loss.</li>
                                        <li>Zigzag Calorie Cycling is thought to prevent the body from adapting to a set caloric intake, which can slow down weight loss progress and cause plateaus.</li>
                                        <li>It can also help prevent the negative effects of long-term caloric restriction, such as decreased metabolism and muscle loss.</li>
                                        <li>However, it's important to note that Zigzag Calorie Cycling may not be appropriate for everyone, and individuals should consult a healthcare professional before starting any new diet or exercise program.</li>
                                        <li>Proper nutrition, exercise, and lifestyle habits are still essential for achieving and maintaining a healthy weight.</li>
                                        <li>F is body fat in percentage</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Daily Calorie Requirements</h3>
                                    <ul>
                                        <li>Zigzag Calorie Cycling is a method of varying daily calorie intake to prevent the body from adapting to a set caloric intake.</li>
                                        <li>It involves alternating high-calorie and low-calorie days throughout the week.</li>
                                        <li>On high-calorie days, individuals consume more calories than their maintenance level to provide the body with the energy it needs to perform high-intensity exercise and build muscle.</li>
                                        <li>On low-calorie days, individuals consume fewer calories than their maintenance level to create a caloric deficit and promote fat loss.</li>
                                        <li>Zigzag Calorie Cycling is thought to prevent the body from adapting to a set caloric intake, which can slow down weight loss progress and cause plateaus.</li>
                                        <li>It can also help prevent the negative effects of long-term caloric restriction, such as decreased metabolism and muscle loss.</li>
                                        <li>However, it's important to note that Zigzag Calorie Cycling may not be appropriate for everyone, and individuals should consult a healthcare professional before starting any new diet or exercise program.</li>
                                        <li>Proper nutrition, exercise, and lifestyle habits are still essential for achieving and maintaining a healthy weight.</li>
                                        <li>F is body fat in percentage</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Various categories and their impacts of calories</h3>
                                    <ul>
                                        <li>Zigzag Calorie Cycling is a method of varying daily calorie intake to prevent the body from adapting to a set caloric intake.</li>
                                        <li>It involves alternating high-calorie and low-calorie days throughout the week.</li>
                                        <li>On high-calorie days, individuals consume more calories than their maintenance level to provide the body with the energy it needs to perform high-intensity exercise and build muscle.</li>
                                        <li>On low-calorie days, individuals consume fewer calories than their maintenance level to create a caloric deficit and promote fat loss.</li>
                                        <li>Zigzag Calorie Cycling is thought to prevent the body from adapting to a set caloric intake, which can slow down weight loss progress and cause plateaus.</li>
                                        <li>It can also help prevent the negative effects of long-term caloric restriction, such as decreased metabolism and muscle loss.</li>
                                        <li>However, it's important to note that Zigzag Calorie Cycling may not be appropriate for everyone, and individuals should consult a healthcare professional before starting any new diet or exercise program.</li>
                                        <li>Proper nutrition, exercise, and lifestyle habits are still essential for achieving and maintaining a healthy weight.</li>
                                        <li>F is body fat in percentage</li>
                                    </ul>
                                </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </React.Fragment>
    )
}

export default CalorieCalculator