import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardHeader, CardTitle, Button } from "reactstrap"
import DayCard from "./DayCard"
import AddDayModal from "./AddDayModal";
import { exercise } from "helpers/api_url";
import SearchUserModal from "components/SearchUserModal";

const Scheduler = ({scheduleData,setScheduleData}) => {
  const [exercises,setExercises] = useState([]);
  const handleClose = e => setScheduleData(null);
  useEffect(()=>{
    exercise.list().then(res=>setExercises(res.data.exercise)).catch(e=>console.error(e));
  },[]);
  return (
    <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <CardTitle>{scheduleData?.name}</CardTitle>
              <div>
                <SearchUserModal setSelectedUser={()=>{}}>
                  <Button className="btn btn-warning btn-sm me-2">Share</Button>
                </SearchUserModal>
                <AddDayModal currentSchedule={scheduleData} setCurrentSchedule={setScheduleData} exercises={exercises}>
                  <Button className="btn btn-success btn-sm me-2">+ Add Day</Button>
                </AddDayModal>
                <Button onClick={handleClose} className="btn btn-close p-2 border border-danger"></Button>
              </div>
            </CardHeader>
          </Card>
        </Col>
        {scheduleData?.schedule_days?.map((sch,index)=>(
          <Col key={index} xs={6}>
              <DayCard day={sch} setSchedule={setScheduleData} index={index} exercises={exercises} />
          </Col>
        ))}
    </Row>
  )
}

export default Scheduler
