import React from 'react';
import { Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import { useModalHandler } from 'helpers/customHook';

function ViewRecipeModal(prop) {
    const {Recipe,foodList} = prop;
    const {status, toggleModal}=useModalHandler();
   
    return (
        <>
            {prop.children?<span onClick={toggleModal}>{prop.children}</span>:(
                <button onClick={()=>toggleModal()} className="btn btn-sm btn-soft-info me-2"><i className="mdi mdi-eye-outline" /></button>
            )}
            <Modal isOpen={status} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={() => toggleModal()}>View Receipe</ModalHeader>
                <ModalBody>
                <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            <ul className="list-group">
                                <li className='list-group-item bg-light' >
                                    <Row>
                                        <Col xs={6}><span className='fw-bold'>Receipe Name </span> <br /> {Recipe?.name}</Col>
                                        <Col className='' xs={6}><span className='fw-bold'>Receipe Type</span><br />{Recipe?.type}</Col>
                                        {/* <Col xs={4}><span className='fw-bold text-start'> Total Quantity <br />{Recipe.quantity}</span></Col> */}
                                    </Row>
                                </li>
                            </ul>
                            <table className='table table-bordered mt-3'>
                            <thead>
                                <tr>
                                <th>SrNo</th>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Carbohydrate</th>
                                <th>Protein</th>
                                <th>Fat</th>
                                <th>Calorie</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Recipe?.foods?.map((item,idx)=>{
                                    const foodData=foodList?.filter(fd=>fd.id == item.id)[0];
                                    const carbs = parseFloat(foodData?.nutrients?.filter(nt=>nt.name == 'Carbohydrate')[0]?.quantity || 0) *(item.pivot.food_quantity/item.quantity);
                                    const protein = parseFloat(foodData?.nutrients?.filter(nt=>nt.name == 'Protein')[0]?.quantity || 0) *(item.pivot.food_quantity/item.quantity);
                                    const fat = parseFloat(foodData?.nutrients?.filter(nt=>nt.name == 'FAT')[0]?.quantity || 0) *(item.pivot.food_quantity/item.quantity);
                                    return (
                                        <tr key={idx}>
                                            <td>{idx+1}</td>
                                            <td>{foodData?.name}</td>
                                            <td>{item.pivot.food_quantity} gm</td>
                                            <td>{carbs.toFixed(2)} gm</td>
                                            <td>{protein.toFixed(2)} gm</td>
                                            <td>{fat.toFixed(2)} gm</td>
                                            <td>{((carbs*4)+(protein*4)+(fat*9)).toFixed(2)} kcal</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewRecipeModal