import React, { useState } from 'react';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';

function DueDate() {
    const [method, setMethod] = useState("LastMenstrualPeriod");
    const [lastPeriodDate, setLastPeriodDate] = useState("");
    const [cycleLength, setCycleLength] = useState("");
    const [pregnancyLength, setPregnancyLength] = useState("");
    const [conceptionDate, setConceptionDate] = useState("");
    const [ivfTransferDate, setIvfTransferDate] = useState("");
    const [dueDate, setDueDate] = useState("");


    const calculateDueDate = () => {
        let estimatedDueDate;
        if (method === "LastMenstrualPeriod") {
            const lastPeriod = new Date(lastPeriodDate);
            const cycle = parseInt(cycleLength);
            const pregnancy = parseInt(pregnancyLength);
            if (!isNaN(lastPeriod.getTime()) && cycle && pregnancy) {
                estimatedDueDate = new Date(
                    lastPeriod.getTime() +
                    (cycle + pregnancy) * 24 * 60 * 60 * 1000
                );
            } else {
                alert("Please fill in all the fields correctly.");
                return;
            }
        } else if (method === "Ultrasound") {
            const ultrasound = new Date(conceptionDate);
            if (!isNaN(ultrasound.getTime())) {
                estimatedDueDate = new Date(
                    ultrasound.getTime() + 280 * 24 * 60 * 60 * 1000
                );
            } else {
                alert("Please fill in the ultrasound date correctly.");
                return;
            }
        } else if (method === "ConceptionDate") {
            const conception = new Date(conceptionDate);
            if (!isNaN(conception.getTime())) {
                estimatedDueDate = new Date(
                    conception.getTime() + 266 * 24 * 60 * 60 * 1000
                );
            } else {
                alert("Please fill in the conception date correctly.");
                return;
            }
        } else if (method === "IVFTransferDate") {
            const ivfTransfer = new Date(ivfTransferDate);
            if (!isNaN(ivfTransfer.getTime())) {
                estimatedDueDate = new Date(
                    ivfTransfer.getTime() + 266 * 24 * 60 * 60 * 1000
                );
            } else {
                alert("Please fill in the IVF transfer date correctly.");
                return;
            }
        }

        setDueDate(estimatedDueDate.toDateString());
    };



    return (
        <React.Fragment>
                    <Breadcrumbs title="Fitness Calculator" breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardHeader><h3>Estimated Due Date:</h3></CardHeader>
                                {dueDate && (
                                    <div>
                                        <CardBody><p>{dueDate}</p></CardBody>
                                    </div>
                                )}
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <Card>
                                <CardHeader><h2>Due Date Calculator</h2></CardHeader>
                                <CardBody>
                                    <div>
                                        <Label>Select Calculation Method:</Label>
                                        <Select className="basic-single" classNamePrefix="select" defaultValue={method} options={[
                                            { label: 'Ultrasound', value: 'Ultrasound' },
                                            { label: 'Conception Date', value: 'ConceptionDate' },
                                            { label: 'IVF Transfer Date', value: 'IVFTransferDate' },
                                        ]} onChange={(e) => setMethod(e.value)} />
                                        {method !== "LastMenstrualPeriod" && (
                                            <div className='my-2'>
                                                <Label>Date:</Label>
                                                <Input type="date" className='form-control' value={conceptionDate} onChange={(e) => setConceptionDate(e.target.value)} />
                                            </div>

                                        )}
                                        {method === "LastMenstrualPeriod" && (
                                            <>
                                                <div className='my-2'>
                                                    <Label>Last Menstrual Period Date:</Label>
                                                    <Input type="date" value={lastPeriodDate} onChange={(e) => setLastPeriodDate(e.target.value)} />
                                                </div>
                                                <div className='my-2'>
                                                    <Label>Average Menstrual Cycle Length (days):</Label>
                                                    <Input type="number" value={cycleLength} onChange={(e) => setCycleLength(e.target.value)} />
                                                </div>

                                                <div className='my-2'>
                                                    <Label>Average Length of Pregnancy (days):</Label>
                                                    <Input type="number" value={pregnancyLength} onChange={(e) => setPregnancyLength(e.target.value)} />
                                                </div>
                                            </>
                                        )}
                                        <Button className='btn btn-success' onClick={calculateDueDate}>Calculate Due Date</Button>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Estimation of due date</h3>
                                    <ul>
                                        <li>The due date is an estimated date when a pregnant woman is expected to deliver her baby, also referred to as the estimated date of confinement.</li>
                                        <li>It's important to note that while a single date is often given, it's useful to consider a range of due dates, as only 4% of births actually occur on the estimated due date.</li>
                                        <li>There are several methods that can be used to estimate due dates, such as the last menstrual period, ultrasound, conception date, and IVF transfer date.</li>
                                        <li>Each of these methods takes different factors into account to provide an estimate for when the baby is likely to be born.</li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Menstrual Cycle Start Date</h3>
                                    <ul>
                                        <li>The due date is an estimated date when a pregnant woman is expected to deliver her baby, also referred to as the estimated date of confinement.</li>
                                        <li>It's important to note that while a single date is often given, it's useful to consider a range of due dates, as only 4% of births actually occur on the estimated due date.</li>
                                        <li>There are several methods that can be used to estimate due dates, such as the last menstrual period, ultrasound, conception date, and IVF transfer date.</li>
                                        <li>Each of these methods takes different factors into account to provide an estimate for when the baby is likely to be born.</li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Menstrual Cycle Start Date</h3>
                                    By default, this calculator estimates a woman's due date based on her last menstrual period (LMP), assuming that childbirth typically occurs at 280 days, or 40 weeks of gestational age. There may be differing opinions on when a pregnancy technically begins, but since gestational age is based on LMP, it remains constant regardless of the definition used. Most pregnancies last between 37 and 42 weeks, and 40 weeks is often used as a standard estimate for calculating due dates. Therefore, the due date is typically estimated by adding 40 weeks to the start of a woman's LMP.

                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <h3>Ultrasound</h3>
                                    <p>Ultrasound-based estimation of the due date is a non-invasive technique that utilizes sound waves to observe the fetus's growth and compare it with the growth rates of other babies worldwide. It's a straightforward and speedy procedure that poses no risk to the baby, and it can provide an accurate estimation of the due date in the early stages of pregnancy.</p>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Conception date</h3>
                                    <p>Estimating the due date using the conception date is a method similar to using the last menstrual period. However, there is a variation of approximately two weeks between these two methods, which is based on the timing between the last menstrual period and the actual date of conception.</p>
                                    <br />
                                    <p>When it comes to in vitro fertilization, estimating the due date is usually more accurate than with natural conception, because the exact transfer date is known. Although the average gestational age of 40 weeks from the last menstrual period is still used, IVF allows for due date estimation based on a variety of factors, such as the day of ovulation, egg retrieval, insemination, and the date of the 3-day or 5-day embryo transfer. This calculator, for instance, utilizes the embryo transfer date to estimate the due date.</p>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h3>Estimated time of delivery (EDT)</h3>
                                    <ul >
                                        <li>Typically, the timing of a baby's birth within the 37 to 42-week window is generally not a cause for concern.</li>
                                        <li>Doctors use three terms to describe the timing of a baby's birth: early term for babies born between 37-39 weeks, full-term for those born between 39-41 weeks, and late-term for those born between 41-42 weeks.</li>
                                        <li>While babies born within any of these ranges can be healthy, full-term babies tend to have better outcomes.</li>
                                        <li>However, if a baby is born before 37 weeks (premature) or after 42 weeks (postterm), there may be cause for concern.</li>
                                        <li>These ranges are important for doctors to determine if any action is necessary.</li>
                                        <li>For instance, if a woman begins labor too early, at say 33 weeks, doctors may need to stop labor to prevent a preterm baby who could have a host of health problems due to underdevelopment.</li>
                                        <li>Conversely, if a woman hasn't gone into labor after 42 weeks, doctors may induce labor.</li>
                                        <li>Allowing the pregnancy to continue beyond 42 weeks can lead to complications where the placenta, which supplies the baby with nutrients and oxygen, may stop functioning properly while the baby continues to grow, requiring more nutrients and oxygen.</li>
                                        <li>This can eventually lead to a point where the baby can no longer be adequately supported.</li>
                                    </ul>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
        </React.Fragment>
    );
}

export default DueDate;
