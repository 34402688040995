import PropTypes from "prop-types";
import React from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, CardFooter, CardHeader } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, Navigate, redirect, useLocation, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/fw-logoold.png";
import logo from "assets/images/logo.svg";
import authOverlay from "assets/images/crousle.jpeg"

const Login = props => {

  //meta title
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirect');
  document.title = "Login | Fittestwarrior";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "itsd1@3ree6ix.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      if (redirectUrl) values = {...values,redirect:redirectUrl}
      dispatch(loginUser(values, props.router.navigate));
    }
  });


  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(
    selectLoginState,
    (login) => ({
      error: login.error,
      success: login.success
    })
  );
  const { error, success } = useSelector(LoginProperties);


  return (
    <React.Fragment>
      <div className="account-pages bg-dark" style={{ overflow:'hidden',width:'100vw',height:'100vh' }} >
      {/* style={{
        backgroundImage: `url(${authOverlay})`, backgroundSize: "cover", backgroundPosition: "center",
        minHeight: "100vh", backgroundRepeat: 'no-repeat'
      }} */}
        <Container fluid>
          <Row>
            <Col lg={8} className="d-none d-lg-block"></Col>
            <Col lg={4} className="pe-2">
              <Card className="" style={{ height: "100vh"}}>
                <CardHeader>
                <div className="bg-light mt-5">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3 mt-5">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Fittest Warrior-Sign In (FW)</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end mb-3"><Link to="/"> <img src={profile} alt="" className="img-fluid" /></Link></Col>
                  </Row>
                </div>
                </CardHeader>
                <CardBody className="pt-0 d-flex flex-column justify-content-center">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light"><img src={logo}alt="" className="rounded-circle" height="34"/></span>
                      </div>
                    </Link>
                  </div>
                  <div className="">
                    <Form className="form-horizontal" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-1">
                        <Label className="form-label">Email</Label>
                        <Input name="email" className="form-control" placeholder="Enter email" type="email" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} invalid={validation.touched.email && validation.errors.email ? true : false} />
                        {validation.touched.email && validation.errors.email ? (<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>) : null}
                      </div>

                      <div className="mb-1">
                        <Label className="form-label">Password</Label>
                        <Input name="password" value={validation.values.password || ""} type="password" placeholder="Enter Password" onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.touched.password && validation.errors.password ? true : false} />
                        {validation.touched.password && validation.errors.password ? (<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>) : null}
                      <div className="mt-3 d-grid"><button className="btn btn-primary btn-block" type="submit">Log In</button></div>
                      <div className="mt-4 text-center"> <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1" />Forgot your password?</Link></div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="mt-1 text-center">
                    <p> Don&#39;t have an account ?{" "}<Link to="/register" className="fw-medium text-primary">{" "}Signup now{" "}</Link>{" "}</p>
                    <p> © {new Date().getFullYear()} Fittestwarrior . Crafted with{" "}<i className="mdi mdi-heart text-danger" /> by Our Team</p>
                  </div>
                  </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
