import React, { useEffect, useState } from 'react';
import {
    Button, Card, CardBody, CardImg, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { exercise } from 'helpers/api_url';
import { toast } from 'react-toastify';
import './Exercise.css';
import image from '../../../assets/images/Execise/ex2.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import { ExerciseLike } from './ExerciseLike';

export const ExerciseData = () => {
    const [tabledata, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [nameFilter, setNameFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [bodyPartFilter, setBodyPartFilter] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from API
        exercise.list()
            .then(res => {
                setTableData(res.data.exercise);
                setFilteredData(res.data.exercise); // Initially set all data
            })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message));
    }, []);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const toggleAdvancedSearch = () => setAdvancedSearch(!advancedSearch);

    const handleSearch = () => {
        // Filter data based on the search term
        const data = tabledata.filter(item =>
            item.exercise_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(data);
    };

    const handleAdvancedSearch = () => {
        // Filter data based on advanced filters
        const data = tabledata.filter(item => {
            return (
                (!nameFilter || item.exercise_name.toLowerCase().includes(nameFilter.toLowerCase())) &&
                (!categoryFilter || JSON.parse(item.category || '[]').includes(categoryFilter.toLowerCase())) &&
                (!bodyPartFilter || JSON.parse(item.area_of_body_part || '[]').includes(bodyPartFilter.toLowerCase()))
            );
        });
        setFilteredData(data);
        toggleAdvancedSearch();
    };
    const handleExerciseView = exercise_id => navigate('/view-excercise/' + exercise_id);
    return (
        <React.Fragment>
            <div className="page-content mt-5">
                <Container className="mt-5">
                <Row>
                        <Col lg={11} md={10} xs={10}>
                            <h1 className="text-center mb-4">Fittestwarrior Exercises</h1>
                            <h3>Your Go-To Hub for Health and Fitness Tools</h3>
                            <p className='font-format fs-4 '>
                                Explore our curated library of exercises tailored for every skill level. With detailed instructions, images, and videos, users can confidently incorporate these exercises into their routines. From strength and flexibility to endurance and agility, there’s something for everyone looking to enhance their physical fitness.
                                At FittestWarrior, we provide a wealth of resources to support our users in their health and fitness journeys. From exercise guides to nutrition tips, our platform is designed to offer practical, reliable tools that empower users to make positive lifestyle changes.
                            </p>
                        </Col>
                        <Col md="2" lg={1} xs={2} className="media-position">
                            <b>Follow Us</b>
                            <div className="justify-content-end mt-3">
                                    <Link to="#"><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link><br />
                                    <Link to="https://www.instagram.com/p/DCZFL_GTjNx/?igsh=MWU5Nnc3eWQzMGI3NA%3D%3D" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link><br />
                                    <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link><br />
                                </div>
                               
                            </Col>
                    </Row>
                    <div className="d-flex justify-content-center my-4 __search-bar-wrapper">
                        <Input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for exercises e.g., name..." className="__search-input"/>
                        <Button color="primary" onClick={handleSearch} className="ms-2">Search</Button>
                        <Button color="dark" onClick={toggleAdvancedSearch} className="ms-2">Advanced Search</Button>
                    </div>
                    <Modal isOpen={advancedSearch} toggle={toggleAdvancedSearch}>
                        <ModalHeader toggle={toggleAdvancedSearch}>Advanced Search</ModalHeader>
                        <ModalBody>
                            <Nav tabs>
                                <NavItem><NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggleTab('1')}>Name</NavLink></NavItem>
                                <NavItem><NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggleTab('2')}>Category</NavLink></NavItem><NavItem>
                                    <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => toggleTab('3')}>Body Part</NavLink></NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1"><Input type="text"value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}placeholder="Filter by name"/></TabPane>
                                <TabPane tabId="2"><Input type="text" value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}placeholder="Filter by category"/></TabPane>
                                <TabPane tabId="3"><Input type="text"value={bodyPartFilter}onChange={(e) => setBodyPartFilter(e.target.value)}placeholder="Filter by body part"/></TabPane>
                            </TabContent>
                            <Button color="success" onClick={handleAdvancedSearch} className="mt-3">Apply Filters</Button>
                        </ModalBody>
                    </Modal>
                    <Row>
                        {
                            filteredData.length ? filteredData.map((item, idx) => (
                                <Col md="3" sm="6" className="mb-4" key={idx}>
                                    <Card className="__exercise-card shadow">
                                        <div className="__image-wrapper position-relative">
                                            <ExerciseLike />
                                            <CardImg top src={image} alt="Exercise" className="__exercise-image" />
                                        </div>
                                        <CardBody className="text-center" style={{ height: '200px' }}>
                                            <h6 className="__exercise-category">{item.exercise_name}</h6>
                                            <p className="__exercise-date">{JSON.parse(item.area_of_body_part)}</p>
                                            <h5 className="__exercise-title">{JSON.parse(item.gravity)}</h5>
                                            <Button color="dark" onClick={() => handleExerciseView(item.id)} className="__show-button">Show Details</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )) : (
                                <div className="text-center">No exercises found.</div>
                            )
                        }
                    </Row>
                    <p className='font-format fs-4 '>
                    <b>Comprehensive Exercise Library for All Levels</b><br />
                        Our exercise library offers a diverse range of workouts and training routines tailored for everyone—from beginners to advanced professionals. This comprehensive database includes detailed exercise data, images, and instructional videos, enabling users to explore and master techniques at their own pace.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Visual and Easy-to-Follow Guides: </b>Each exercise includes clear images and videos, ensuring that users can easily understand and implement these exercises in their routines.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Suitable for All Skill Levels: </b>We offer workouts for every level, whether you're just starting out or seeking advanced routines to push your limits. Our exercises cover a variety of fitness goals, from strength and conditioning to flexibility and endurance.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Expert Insights, User-Driven Content: </b>FittestWarrior aggregates content from trusted experts and experienced users to create a valuable repository of fitness knowledge. Our platform offers these resources to users while acting as a third-party facilitator.
                    </p>
                    <p className='font-format fs-4 '>
                        <b>Disclaimer: </b>Please note, as a third-party platform, FittestWarrior provides this content as-is based on the expertise and experience of our contributors. While we curate these exercises with care, we encourage users to consult healthcare or fitness professionals before starting new routines. We do not assume responsibility for the outcomes of individual exercises.
                    </p>
                </Container>
            </div>
        </React.Fragment>
    );
};
