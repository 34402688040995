import React, { useEffect, useRef } from "react";
import BreadCrumb from "./Breadcrumb";
import { Modal, Card, CardBody, Row, Col, CardTitle, CardText, Container } from "reactstrap";
import { Player } from '@lordicon/react';
import ICON from '../../assets/JSON/lock.json'
import { Link } from "react-router-dom";

function ProfileLock({title="comming soon..",link="#"}) {
    const playerRef = useRef(null);
    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.playFromBeginning();
      }
    }, []);
    return (
        <>
           
                    <Card className="border border-primary">
                        <CardBody className="bg-soft-light">
                            <div style={{ height: '300px' }} className="d-flex flex-column align-items-center justify-content-center">  
                                <Player ref={playerRef} icon={ ICON }size={96}onComplete={() => playerRef.current?.playFromBeginning()}/>
                                <CardTitle className="mt-3"><span className="badge badge-soft-danger fs-16 p-3">This Module is Locked / {title} </span></CardTitle>
                                <CardText className="mt-3"><Link to={link} className="btn btn-soft-success"> <i className=" ri-lock-unlock-line me-2"></i>Unlock Now</Link></CardText>
                            </div>
                        </CardBody>
                    </Card>
              
        </>
    )
}

export default ProfileLock