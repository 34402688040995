import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody, Container, Badge, Button, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, CardText } from 'reactstrap';
import BreadCrumb from 'components/Common/Breadcrumb';
import classnames from 'classnames';
import { AssessmentForm } from '../AssessmentForm';
import { PhysicianInformation } from './PhysicianInformation';
import { LifeStyleDietary } from './LifeStyleDietary';
import { CardiovascularInformation } from './CardiovascularInformation';
import { PainHistory } from './PainHistory';
import { WorkEnvironment } from './WorkEnvironment';
import { ExerciseHistory } from './ExerciseHistory';
export const PersonalHealth = () => {
    const [activeTab1, setactiveTab1] = useState("1");
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };
    const [tableDataPhysician, setTableDataPhysician] = useState([]);
    const [tableDataLifeStyle, setTableDataLifeStyle] = useState([]);
    const [tableDataCardiovascular, setTableDataCardiovascular] = useState([]);
    const [tableDataPainHistory, setTableDataPainHistory] = useState([]);
    const [tableDataWorkEnvironment, setTableDataWorkEnvironment] = useState([]);
    const [tableDataExerciseHistory, setTableDataExerciseHistory] = useState([]);
    return (
        <React.Fragment>
            <CardBody>
                <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "1", })} onClick={() => { toggle1("1"); }}>Physician Information</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "2", })} onClick={() => { toggle1("2"); }}>LifeStyle And Dietary</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "3", })} onClick={() => { toggle1("3"); }}>Cardiovascular Information</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "4", })} onClick={() => { toggle1("4"); }} >Pain History Information</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "5", })} onClick={() => { toggle1("5"); }} >Work and Environment Information</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab1 === "6", })} onClick={() => { toggle1("6"); }} >Exercise History Information</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Physician Information</h5>
                                    <AssessmentForm type="physician_information" setTableData={setTableDataPhysician} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <PhysicianInformation type="physician_information" tableData={tableDataPhysician} setTableData={setTableDataPhysician} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">LifeStyle And Dietary</h5>
                                    <AssessmentForm type="lifestyle_dietary" setTableData={setTableDataLifeStyle} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <LifeStyleDietary type="lifestyle_dietary" tableData={tableDataLifeStyle} setTableData={setTableDataLifeStyle} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Cardiovascular Information</h5>
                                    <AssessmentForm type="cardiovascular_information" setTableData={setTableDataCardiovascular} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <CardiovascularInformation type="cardiovascular_information" tableData={tableDataCardiovascular} setTableData={setTableDataCardiovascular} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="4">
                        <Row>
                            <Col sm="12">
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Pain History Information</h5>
                                    <AssessmentForm type="pain_history_information" setTableData={setTableDataPainHistory} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <PainHistory type="pain_history_information" tableData={tableDataPainHistory} setTableData={setTableDataPainHistory} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="5">
                        <Row>
                            <Col sm="12">
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Work and Environment Information</h5>
                                    <AssessmentForm type="work_environment_information" setTableData={setTableDataWorkEnvironment} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <WorkEnvironment type="work_environment_information" tableData={tableDataWorkEnvironment} setTableData={setTableDataWorkEnvironment} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="6">
                        <Row>
                            <Col sm="12">
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                    <h5 className="card-title mb-0">Exercise History Information</h5>
                                    <AssessmentForm type="exercise_history_information" setTableData={setTableDataExerciseHistory} />
                                </CardHeader>
                                <CardText className="mb-0">
                                    <ExerciseHistory type="exercise_history_information" tableData={tableDataExerciseHistory} setTableData={setTableDataExerciseHistory} />
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </CardBody>
        </React.Fragment>
    )
}
