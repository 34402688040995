import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Container, Row, UncontrolledCarousel } from 'reactstrap'
import img1 from '../../../assets/images/Diet/img1.png'
import img2 from '../../../assets/images/Diet/img2.png'
import img3 from '../../../assets/images/Diet/img4.png'
import ReactPlayer from 'react-player'
import { Diet } from 'helpers/api_url'
import { Link } from 'react-router-dom'
export const DietView = () => {
    const [diets, setDiets] = useState([]);
    useEffect(() => {
        Diet.list().then(res => setDiets(res.data.diets)).catch(e => console.error(e)); 
    }, [])
    console.log(diets)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row>
                        <Col>
                            <Card>
                                <div className='row'>
                                    <div className='col-lg-4 text-start'>
                                        <CardTitle className='p-2 fs-3'>
                                            Page Scroll Down
                                        </CardTitle>

                                    </div>
                                    <div className='col-lg-2 text-start'> </div>
                                    <div className='col-lg-6 text-end p-2'>
                                        <div className="d-flex justify-content-center ">
                                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-facebook'></i></div></Link>
                                            <Link to="#" > <div className="fs-3 icon-button mx-2"><i className='bx bxl-instagram'></i></div></Link>
                                            <Link to="#" ><div className="fs-3 icon-button mx-2"><i className='bx bxl-youtube'></i></div></Link>
                                        </div>
                                    </div>

                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} xs={12}>
                            <Card>
                                    <CardTitle className='fs-4 px-2 text-center bg-light'>Diet</CardTitle>
                                <CardBody >
                                    <UncontrolledCarousel interval={4000} indicators={false} items={[{ altText: " ", caption: " ", key: 1, src: img1, }, { altText: " ", caption: " ", key: 1, src: img2, }, { altText: " ", caption: " ", key: 1, src: img3, }]} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} xs={12}>
                            <Card>
                                    <CardTitle className='fs-4 px-2 text-center bg-light'>Youtube </CardTitle>
                                <CardBody>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                        <div className='d-lg-block d-none'>
                                            <ReactPlayer url='https://youtube.com/shorts/Cbs6k8VUuNI?si=kh1EGn6z1-H9Xxgx' width='200px' height='200px' />
                                        </div>
                                        <div className='d-block d-lg-none'>
                                            <ReactPlayer url='https://youtube.com/shorts/Cbs6k8VUuNI?si=kh1EGn6z1-H9Xxgx' width='auto' height='200px' />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} xs={12}>
                            <Card>
                                    <CardTitle className='fs-4 px-2 text-center bg-light'>Filter</CardTitle>
                                <CardBody>
                                    <ul className="list-group">
                                        <li className='list-group-item text-center'>Bulk up preset</li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Bulk up preset</CardTitle>
                                    {/* <ul className="list-group">
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'></span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Gravity</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Muscle Size</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Intensity / Mobility</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Excercise Style</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Time</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Distance</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                        <li className='list-group-item'>
                                            <Row>
                                                <Col xs={5}><span className='fw-bold'>Sets | Reps | Weight</span></Col>
                                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                <Col className='text-start' xs={5}><span className='text-wrap'></span></Col>
                                            </Row>
                                        </li>
                                    </ul> */}
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg={6} xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className='text-center'>Do's</CardTitle>
                                    dos of this exercise
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} xs={12}>
                        <Card>
                                <CardBody>
                                    <CardTitle className='text-center'>Don't</CardTitle>
                                    donts of this exercise
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
