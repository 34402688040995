import React, { useState } from 'react'
import { Row } from 'reactstrap';

export const AddressDetail = () => {
    const [sameAddress, setSameAddress] = useState(false);
    const handleCheckboxChange = () => {
        setSameAddress(!sameAddress);
        if (!sameAddress) {
            document.getElementById('temporary_address').value = document.getElementById('permanent_address').value || '';
            document.getElementById('temporary_address2').value = document.getElementById('permanent_address2').value || '';
            document.getElementById('temporary_city').value = document.getElementById('permanent_city').value || '';
            document.getElementById('temporary_district').value = document.getElementById('permanent_district').value || '';
            document.getElementById('temporary_state').value = document.getElementById('permanent_state').value || '';
            document.getElementById('temporary_pincode').value = document.getElementById('permanent_pincode').value || '';
        } else {
            document.getElementById('temporary_address').value = '';
            document.getElementById('temporary_address2').value = '';
            document.getElementById('temporary_city').value = '';
            document.getElementById('temporary_district').value = '';
            document.getElementById('temporary_state').value = '';
            document.getElementById('temporary_pincode').value = '';
        }
    };
    return (
        <React.Fragment>
            <Row>
                <div className="mb-4"><h5 className="mb-1 text-center text-primary">Permanent Address</h5></div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_address" className="form-label">Address 1</label>
                        <textarea name='permanent_address' id='permanent_address' className='form-control' cols={1} rows={1} />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_address2" className="form-label">Address 2</label>
                        <textarea name='permanent_address2' id='permanent_address2' className='form-control'  rows={1} />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_city" className="form-label">City</label>
                        <input type='text' name='permanent_city' id='permanent_city' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_district" className="form-label">District</label>
                        <input type='text' name='permanent_district' id='permanent_district' className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_state" className="form-label">State</label>
                        <input type='text' name='permanent_state' id='permanent_state'  className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="permanent_pincode" className="form-label">Pin</label>
                        <input type='number' name='permanent_pincode' id='permanent_pincode'  className='form-control' />
                    </div>
                </div>
            </Row>
            <hr />
            <Row>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckboxChange} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Same Address
                    </label>
                </div>
                <div className="my-2"><h5 className="mb-1 text-center text-primary">Current Address</h5></div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_address" className="form-label">Address 1</label>
                        <textarea name='temporary_address' id='temporary_address' className='form-control'  cols={1} rows={1} />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_address2" className="form-label">Address 2</label>
                        <textarea name='temporary_address2' id='temporary_address2' className='form-control' rows={1} />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_city" className="form-label">City</label>
                        <input type='text' name='temporary_city' id='temporary_city'  className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_district" className="form-label">District</label>
                        <input type='text' name='temporary_district' id='temporary_district'  className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_state" className="form-label">State</label>
                        <input type='text' name='temporary_state' id='temporary_state'  className='form-control' />
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div>
                        <label htmlFor="temporary_pincode" className="form-label">Pin</label>
                        <input type='number' name='temporary_pincode' id='temporary_pincode'  className='form-control' />
                    </div>
                </div>

            </Row>
        </React.Fragment>
    )
}
