import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';

function AboutModel(props) {
    const {setData,user,current=null} = props;
    const { genders, toggleModal, status } = useModalHandler();
    const dispatch = useDispatch();
    const handleSubmit=(e)=>{
        dispatch(changePreloader({status:true,message:'Updating Address'}));
        e.preventDefault();
        const formData = new FormData(e.target);    
        users.updateAbout(formData).then(res=>{
            setData(state=>({...state,about:res.data.about}))
            toast.success("Updated Successfully !")
            toggleModal(false);
        }).catch(e=>toast.error(e.response.data.message))
        .finally(()=>dispatch(changePreloader({status:false,message:false})))

    }
    return (
        <>
            {props.children?(
                <span onClick={toggleModal}>
                    {props.children}
                </span>
            ):(
                <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            )}
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update About Us</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                        <div className="mb-3">
                            {/* <Label className="form-label">About</Label> */}
                            <textarea className='form-control' name="content" rows={4}>{current}</textarea>
                        </div>
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AboutModel