import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';
import { exercise } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';


function UpdateSubCategoryModal({ setCategories, data }) {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);
    const handleAddCategory = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get('sub_category_name') != '') {
            dispatch(changePreloader({ status: true, message: 'please wait' }));
            exercise.addCategory(formData)
                .then(res => { setCategories(state => state.map(s => s.id == res.data.category.id ? res.data.category : s)); toast.success(res.message) })
                .catch(err => toast.error(err.response ? err.response.data.message : err.message))
                .finally(() => dispatch(changePreloader({ status: false, message: '' })));
        }
        else toast.error('Sub Category name is required');
    }
    const handleDeleteSubCategory = (cat_id, sub_cat) => {
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        exercise.removeSubCategory(cat_id, sub_cat).then(res => setCategories(state => state.map(s => s.id == cat_id ? res.data.category : s)))
            .catch(err => toast.error(err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }
    return (
        <>
            <button onClick={() => toggleModal()} className="btn btn-sm btn-outline-success me-2"><i className="mdi mdi-pencil-outline"></i></button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Edit Sub Category</h5></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={6}>
                            <div className="mb-2">
                                <label className="control-label"> Category Title </label>
                                <Input disabled value={data?.category_title} className="form-control" name="category_title" />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="mb-2">
                                <label className="control-label">Category</label>
                                <Input disabled value={data?.category_name} className="form-control" name="category_name" />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <label className=''>Sub categories</label>
                        </Col>
                        <Col xs={12} className='my-1 justify-content-center d-flex'>
                            <div className='text-center' style={{ maxWidth: '800px' }}>
                                {data?.sub_category_name?.split(',')
                                    .map(sc => sc !== '' && (
                                        <div
                                            key={sc}
                                            className={'d-inline-flex justify-content-between align-items-center border border-secondary px-3 py-2 m-2'}
                                            style={{ borderRadius: '24px', whiteSpace: 'nowrap', position: 'relative', overflow: 'hidden' }}>
                                            <span className='me-4'>{sc}</span>
                                            <span
                                                onClick={e => handleDeleteSubCategory(data.id, sc)}
                                                className='text-white bg-danger d-flex justify-content-center align-items-center rounded-circle  top-0 bottom-0'
                                                style={{ position: 'absolute', right: 0, cursor: 'pointer',fontSize:'15px',padding:'5px 9px'}}
                                            >
                                                <i className='mdi mdi-close-circle-outline'></i>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <form onSubmit={handleAddCategory} className='w-100'>
                        <input type="hidden" value={data?.category_title} name="category_title" />
                        <input type='hidden' value={data?.category_name} name="category_name" />
                        <Row >
                            <Col xs={8}>
                                <Input className="form-control" name="sub_category_name" placeholder='Sub Category Name' />
                            </Col>
                            <Col xs={4}>
                                <Button color="primary" type="submit" className="w-100" > + Add New </Button>
                            </Col>
                        </Row>
                    </form>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default UpdateSubCategoryModal