import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import ReactSelect from 'react-select';
import { AssetUrl, appointments, users } from 'helpers/api_url';
import { useSelector } from 'react-redux';
import { format, isBefore, isSameDay, parse, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import MakeAppointmentModal from './MakeAppointmentModal';
import { changePreloader } from 'store/actions';
import { useDispatch } from 'react-redux';
import { generateEventFromAppointment } from 'helpers/event_helper';

function Myappointments() {
    const [appointmentModalData, setAppointmentModalData] = useState({ status: false, data: {} });
    const toggleAppointmentModal = () => setAppointmentModalData(state => ({ ...state, status: !appointmentModalData.status }))
    const dispatch = useDispatch();
    const [profUsers, setProfUsers] = useState([]);
    
    const [events, setEvents] = useState([]);
    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }))
        appointments.my_appointments().then(res => {
            const ev = [];
            res.data.appointment.map((appointment) => ev.push(generateEventFromAppointment(appointment)));
            setEvents(ev);
        }).catch(error => console.warn(error))
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
        users.proffessional().then(res => setProfUsers(res.data.users)).catch(error => console.warn(error));
    }, [])
    const handleApproveAppointmentSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get('time') && formData.get('date')) {
            const parsedDate = parse(`${formData.get('date')} ${formData.get('time')}`, 'yyyy-MM-dd HH:mm', new Date());
            const formattedDate = format(parsedDate, 'yyyy-MM-dd-HH:mm:ss');
            formData.append('time_of_appointment', formattedDate);
        }
        dispatch(changePreloader({ status: true, message: '' }))
        appointments.approve(formData).then(res => {
            toast.success(res.message);
            setEvents(state => state.map(s => {
                console.log(s.id,)
                return s.id === res.data.appointment.id ? generateEventFromAppointment(res.data.appointment) : s;
            }));
            toggleAppointmentModal();
        }).catch(e => toast.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }
    const handleRejectAppointment = id => {
        if (id) {
            dispatch(changePreloader({ status: true, message: '' }));
            appointments.reject(id).then(res => {
                toast.success(res.message);
                setEvents(state => state.map(s => s.id === id ? generateEventFromAppointment(res.data.appointment) : s));
                toggleAppointmentModal();
            }).catch(e => toast.error(e.response ? e.response.data.message : e.message))
                .finally(() => dispatch(changePreloader({ status: false, message: '' })));;
        }
    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <FullCalendar plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin,]}
                                slotDuration={"00:15:00"}
                                handleWindowResize={true}
                                themeSystem="bootstrap"
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                                }}
                                events={events}
                                eventClick={function (info) {
                                    setAppointmentModalData({ status: true, data: info.event._def.extendedProps?.data });
                                }}
                            />
                        </CardBody>
                    </Card>
                </Container>

                <Modal centered={true} isOpen={appointmentModalData.status} toggle={toggleAppointmentModal}>
                    <ModalHeader toggle={toggleAppointmentModal}>
                        {appointmentModalData?.data?.title} <small>({format(appointmentModalData?.data?.date_of_apointment || '2024-11-11', "PPP")})</small>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className='d-flex justify-content-between align-items-center'>
                                <div className="d-flex align-items-center" >
                                    <img
                                        src={AssetUrl + appointmentModalData?.data?.user?.other_details?.profile_image}
                                        style={{ height: '48px', aspectRatio: '1/1', objectFit: 'cover' }}
                                        className='border border-primary rounded-circle img-fluid object-fit-cover' alt='User'
                                    />
                                    <h5 className='ms-3 m-0'>{appointmentModalData?.data?.user?.first_name} {appointmentModalData?.data?.user?.last_name}</h5>
                                </div>
                                {appointmentModalData?.data?.is_approved ? (
                                    <div className='d-flex'>
                                        <button className='btn btn-soft-primary me-2'>Archive</button>
                                        {/* <button className='btn btn-danger'>Delete</button> */}
                                        <button onClick={e => handleRejectAppointment(appointmentModalData?.data?.id)} className='btn btn-soft-danger'>Reject</button>
                                    </div>
                                ) : (
                                    <button onClick={e => handleRejectAppointment(appointmentModalData?.data?.id)} className='btn btn-soft-danger'>Reject</button>
                                )}
                            </Col>
                            <Col xs={12}>
                                <textarea name="" rows={4} readOnly id="" value={appointmentModalData?.data?.description} className='form-control mt-3'></textarea>
                            </Col>
                        </Row>
                        {appointmentModalData?.data?.is_approved ? (
                            <form onSubmit={(e)=>{e.preventDefault();toast.error('something went wrong')}}>
                                <input type='hidden' value={appointmentModalData?.data?.id} name='appointment_id' />
                                <hr />
                                <Row className='my-2'>
                                    <Col xs={12}><u><h5>Schedule Appointment</h5></u></Col>
                                    <Col xs={12}>
                                        <div className='mb-2'>
                                            <Label htmlFor='prof_user_id' className="form-label">Notes</Label>
                                            <textarea name="" rows={4} id="" className='form-control'></textarea>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor='prof_user_id' className="form-label">Date</Label>
                                            <input type='time' className='form-control' name='time' />
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className='mb-2'>
                                            <Label htmlFor='prof_user_id' className="form-label">Time</Label>
                                            <input type='time' className='form-control' name='time' />
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="mb-3 center pb-2 h-100 d-flex align-items-end">
                                            <Button type='submit' className='btn btn-soft-success w-100' >Next Schedule</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        ) : (
                            <form onSubmit={handleApproveAppointmentSubmit}>
                                <input type='hidden' value={appointmentModalData?.data?.id} name='appointment_id' />
                                <input type='hidden' value={appointmentModalData?.data?.date_of_apointment} name='date' />
                                <Row className='my-2'>
                                    <Col xs={6}>
                                        <div className='mb-2'>
                                            <Label htmlFor='prof_user_id' className="form-label">Time of Appointment</Label>
                                            <input type='time' className='form-control' name='time' />
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="mb-3 center pb-2 h-100 d-flex align-items-end">
                                            <Button type='submit' className='btn btn-soft-success w-100' >Approve</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default Myappointments