import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Input, Row, Button, Card } from 'reactstrap';
import ReactSelect from "react-select";
import { exercise } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import { toast } from 'react-toastify';

function UpdateCategoryModal({ setCategories, data }) {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const toggleModal = () => setStatus(!status);

    const handleUpdateCategory = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        exercise.UpdateCategory(formData)
            .then(res => {
                setCategories(state => state.map(s => s.id == res.data.category.id ? res.data.category : s));
                toggleModal();
                toast.success(res.message);
            })
            .catch(err => toast.error(err.response ? err.response.data.message : err.message))
            .finally(() =>
                dispatch(changePreloader({ status: false, message: '' })));
    };

    const categoryTitles = [
        { value: "Muscle Group", label: "Muscle Group" },
        { value: "Experience Level", label: "Experience Level" },
        { value: "Exercise Type", label: "Exercise Type" },
        { value: "Equipments", label: "Equipments" },
    ];

    return (
        <>
            <button onClick={() => toggleModal()} className="btn btn-sm btn-success me-2"><i className="mdi mdi-pencil-outline"></i></button>
            <Modal isOpen={status} toggle={() => toggleModal()}>
                <ModalHeader toggle={() => toggleModal()}><h5>Edit Category</h5></ModalHeader>
                <form onSubmit={handleUpdateCategory}>
                    <ModalBody>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <input type="hidden" name='cat_id' value={data.id}/>
                                    <label className="control-label"> Category Title </label>
                                    <Card>
                                        <ReactSelect 
                                            id="specialty" 
                                            name="category_title" 
                                            defaultValue={categoryTitles.find(e => e.value === data.category_title)}  
                                            options={categoryTitles} 
                                        />
                                    </Card>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <label className="control-label">Category</label>
                                    <Input className="form-control" name="category_name" defaultValue={data.category_name} />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" className="mt-2"> Update </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
}

export default UpdateCategoryModal;
