import BreadCrumb from 'components/Common/Breadcrumb'
import Locked from 'components/Common/Locked';
import ProfileLock from 'components/Common/ProfileLock';
import { AssetUrl, kyc } from 'helpers/api_url';
import { formatDate } from 'helpers/formatDate';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { Form, Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Dropdown, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { changePreloader } from 'store/actions';
export const CompanyPro = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.Login.authUser);
    const [comProfileData, setComProfileData] = useState([]);
    useEffect(() => {
        dispatch(changePreloader({ status: true, message: '' }))
        kyc.companyread(id).then(res => setComProfileData(res.data.company))
            .catch(e => toast.error(e.response ? e.response.data.message : e.message))
            .finally(() => { dispatch(changePreloader({ status: false, message: '' })); });
    }, [id])
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePreloader({ status: true, message: 'please wait' }));
        const formData = new FormData(e.target);
        kyc.company(formData)
            .then((res) => { toast.success(res.message, { autoClose: 2000 }); e.target.reset(); })
            .catch((err) => { toast.error(err.response ? err.response.data.message : err.message); })
            .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Company Profile" prevPage="Dashboard" prevPath="/dashboard" />
                    {
                        comProfileData ? (
                            <>

                                <Row>
                                    <Col lg={4} xs={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className='mb-0'>
                                                    <div className="overflow-hidden position-relative">
                                                        <div className="" style={{ backgroundImage: `url(${AssetUrl + comProfileData?.logo})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}>
                                                            <Row>
                                                                <Col xs="7">
                                                                    <div className="text-light p-3">
                                                                        <h5 className="text-light">Welcome Back !</h5>
                                                                        <p>{comProfileData?.organization_name} (FW)</p>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                        <CardBody className="pt-0">
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="avatar-md profile-user-wid mb-4">
                                                                        <img
                                                                            src={comProfileData?.user?.other_details?.profile_image === "" ? avatar1 : AssetUrl + comProfileData?.user?.other_details?.profile_image}
                                                                            alt=""
                                                                            className="img-thumbnail rounded-circle" style={{ aspectRatio: 1 / 1, objectFit: 'cover' }}
                                                                        />
                                                                    </div>
                                                                </Col>

                                                                <Col sm="8" className='mt-3'>
                                                                    <h6> <span>{comProfileData?.user?.first_name} {comProfileData?.user?.last_name}</span>  </h6>

                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} lg={10} className='text-start'>Company Infomation</Col>
                                                </Row>
                                                <hr />

                                                <ul className="list-group">
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Type </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.business_type?.toUpperCase()}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Designation </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'> <Badge className="bg-success px-2">{comProfileData?.representator_designation?.toUpperCase()}</Badge></span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Brand Name </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.brand_name?.toUpperCase()}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Professional Name </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.profession}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Registration Date </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{formatDate(comProfileData?.registration_date) || '-'}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Phone </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.mobile}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Whatsapp </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.whatsapp}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>  Email </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.email}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                </ul>
                                            </CardBody>

                                        </Card>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} lg={10} className='text-start'>Company Address</Col>
                                                </Row>
                                                <hr />
                                                <ul className="list-group">
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>Address </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.address : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>City </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.city : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>District </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.district : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>State </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.state : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>PinCode </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.pincode : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>country </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'>{comProfileData?.address?.length ? comProfileData?.address[0]?.country : ''}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                </ul>
                                            </CardBody>
                                        </Card>

                                        <Card>
                                            <CardBody>
                                            <Form onSubmit={handleSubmit}>
                                                   
                                                        <CardHeader><CardTitle>Company Legal Document Upload</CardTitle></CardHeader>
                                                        <CardBody>
                                                            <Label htmlFor="type" className='mt-1'>Select</Label>
                                                           <ReactSelect name='type' id='type' options={
                                                            [
                                                                {label:'Registration/CIN', value:'registration_CIN'},
                                                                {label:'Prospectus ', value:'prospectus'},
                                                                {label:'Taxation', value:'taxation'},
                                                                {label:'Pan card', value:'pan_card'},
                                                                {label:'StartUp Docs', value:'startup_docs'},
                                                                {label:'MSME Docs', value:'MSME_docs'},
                                                                {label:'Muncipal/Shop Act', value:'muncipal_shop_act'},
                                                                {label:'ISO Certificate', value:'ISO_certificate'},
                                                                {label:'Importer-Exporter Code', value:'importer_exporter_code'},
                                                                {label:'ITR Acknowledgement , Balance Sheet , Computation, P&L', value:'ITR_acknowledgement_balance_sheet_computation_PandL'},
                                                                
                                                                
                                                                ]
                                                           } />
                                                            <Label htmlFor="heading" className='mt-1'>Document Number</Label>
                                                            <Input type="text" name="heading" id="heading" />
                                                            <Label htmlFor="document" className='mt-1'>Upload</Label>
                                                            <Input type="file" name="document" id="document" />
                                                        </CardBody>
                                                        <CardFooter>
                                                            <Button type="submit" color="primary" className="px-3 my-2"> Submit</Button>
                                                        </CardFooter>
                                                   

                                                </Form>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} lg={10} className='text-start'>Annual</Col>
                                                </Row>
                                                <hr />
                                                <ul className="list-group">
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>Gross Annual Income </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'><i className="bx bx-rupee"></i> {comProfileData?.gross_annual_income}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                    <li className='list-group-item'>
                                                        <Row>
                                                            <Col xs={5}><span className='fw-bold'>Latest Worth Rupees </span></Col>
                                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                            <Col className='text-start' xs={5}>
                                                                <span className='text-wrap'><i className="bx bx-rupee"></i> {comProfileData?.latest_worth_rupees}</span>
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                </ul>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <div className="col">
                                                    <b className='pt-3 text-center px-5 ' > <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.youtube_link : ''} className='fs-3 text-danger'><i className='bx bxl-youtube'></i></Link> </b>
                                                    <b className='pt-3 text-center px-5' > <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.instagram_link : ''} className='fs-3 text-danger'><i className='bx bxl-instagram'></i></Link> </b>
                                                    <b className='pt-3 text-center px-5'> <Link to={comProfileData?.socials?.length ? comProfileData?.socials[0]?.facebook_link : ''} className='fs-3 text-primary'><i className='bx bxl-facebook'></i></Link> </b>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={4} xs={12}></Col>
                                </Row>
                            </>
                        )
                            :
                            (
                                <>
                                    <ProfileLock />
                                </>
                            )
                    }

                </Container>
            </div>
        </React.Fragment>
    )
}
