import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom"; 
import { validate } from "helpers/api_url";
import { useDispatch,useSelector } from "react-redux";
import { loginSuccess } from "store/actions";
import { changePreloader as setPreloader } from "store/actions";
import Pages404 from "pages/Error/pages-404";
const useValidation = ()=>{
  const [validated,setValidated] = useState(null);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setPreloader({status:true,message:''}))
    validate()
    .then(r=>{
      dispatch(loginSuccess(r))
      setValidated(true)
    })
    .catch(err=>{console.log(err);setValidated(false)})
    .finally(r=>{
      dispatch(setPreloader({status:false,message:''}))
    })
  },[dispatch])
  return validated
}
export const Authmiddleware = (props) => {
  const validated = useValidation();
  const authUser = useSelector(state => state.Login.authUser);
  const currentUserRole = authUser?.role?.role_type?.toLowerCase();
  if(!props.route.users?.some(role => role?.toLowerCase() == currentUserRole)) return <Pages404 />
  if (validated !== null)
  return validated?<React.Fragment> {props.children} </React.Fragment>: <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
};
export const GuestMiddleware = (props) => {
  const validated = useValidation();
  if (validated !== null) 
  return validated?<Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />:<React.Fragment> {props.children} </React.Fragment>;
};


