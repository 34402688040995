import { useModalHandler } from 'helpers/customHook';
import React from 'react'
import { Button, Card, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { users } from 'helpers/api_url';
import { useDispatch } from 'react-redux';
import { changePreloader } from 'store/actions';
import ReactSelect from 'react-select';

function ActivityModel(props) {
    const { setData, user, current = null } = props;
    const { genders, toggleModal, status } = useModalHandler();
    const handleSubmit = (e) => { 
        e.preventDefault();
       toast.error("Under proccesing...........")
    }
    return (
        <>
            {props.children ? (
                <span onClick={toggleModal}>
                    {props.children}
                </span>
            ) : (
                <button onClick={toggleModal} className='btn btn-soft-primary add-btn waves-effect'><i className="bx bxs-edit align-item-center"></i></button>
            )}
            <Modal centered={true} isOpen={status} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Update skill</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} >
                        <input type='hidden' name='user_id' value={user?.id} />
                       
                        <div className="mb-3">
                            <Label className="form-label">Activitiy Date</Label>
                            <Input type='date' className='form-control' />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Description</Label>
                            <textarea className='form-control' name="" id=""></textarea>
                        </div>
                       
                       
                        <div className="mb-3 center">
                            <Button type='submit' className='form-control bg-primary' >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ActivityModel