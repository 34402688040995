import { proffessionals } from 'helpers/api_url';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select'
import { toast } from 'react-toastify';
import { Row, Col, Form, Input, Label, Button, Table } from 'reactstrap'
import { changePreloader } from 'store/actions';
export const CreateForm = () => {
    const Proffessions = [
        { value: 'Management Consulting', label: 'Management Consulting' },
        { value: 'Science/Medical Research Consulting', label: 'Science/Medical Research Consulting' },
        { value: 'Marketing Consulting', label: 'Marketing Consulting' },
        { value: 'Human Resources (HR) Consulting', label: 'Human Resources (HR) Consulting' },
        { value: 'Strategy Consulting', label: 'Strategy Consulting' },
        { value: 'Retail Consulting', label: 'Retail Consulting' },
        { value: 'Digital Transformation Consulting', label: 'Digital Transformation Consulting' },
        { value: 'Business Process Consulting', label: 'Business Process Consulting' },
        { value: 'Public Relations (PR) Consulting', label: 'Public Relations (PR) Consulting' },
    ];
    const Digrees = [
        { value: 'BTECH', label: 'BTECH' },
        { value: 'BCA', label: 'BCA' },
        { value: 'MBBS', label: 'MBBS' },
        { value: 'LLB', label: 'LLB' },
        { value: 'MBA', label: 'MBA' },
        { value: 'BPharma', label: 'BPharma' },
        { value: 'MCA', label: 'MCA' },
        { value: 'MTECH', label: 'MTECH' },
        { value: 'BA', label: 'BA' },
        { value: 'MA', label: 'MA' },
    ];
    const paymentopt = [{ value: 'online', label: 'online' }, { value: 'offline', label: 'offline' }]
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.Login.authUser);
    const [profileData,setProfileData] = useState();
    useEffect(()=>{
        dispatch(changePreloader({ status: true, message: '' }))
        proffessionals.myprofile()
        .then(res=>setProfileData(res?.data?.profile))
        .catch(e=>console.log(e)).finally(() => dispatch(changePreloader({ status: false, message: '' })));
    },[]);
    const handleUpdateProfile = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(changePreloader({ status: true, message: '' }))
        proffessionals.updateProfile(formData).then(res=>console.log(res))
        .catch(e=>toast.error(e.response?e.response.data.message:e.message))
        .finally(() => dispatch(changePreloader({ status: false, message: '' })));
    }
    return (
        <React.Fragment>
            <Form onSubmit={handleUpdateProfile}>
                <Row>
                    <Col xs={12}><blockquote><u><h5 className='my-3 '>Proffessional Details</h5></u></blockquote></Col>
                    <Col lg={6}>
                        <Label htmlFor='name'>Name</Label>
                        <Input 
                            defaultValue={
                                profileData? profileData?.name :
                                `${authUser?.first_name?.toUpperCase()} ${authUser?.last_name?.toUpperCase()}`
                            } 
                            type='text' 
                            id='name' 
                            name='name' 
                        />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='highest_degree'>Highest Degree</Label>
                        <ReactSelect 
                            key={Date.now()}
                            defaultValue={profileData? {label:profileData?.digree,value:profileData.digree}:null} 
                            className='border border-soft-secondary rounded' 
                            id='highest_degree' 
                            name='digree' 
                            options={Digrees} 
                        />
                    </Col>

                    <Col lg={5} className='mt-2'>
                        <Label htmlFor='proffession'>Proffession</Label>
                        <ReactSelect 
                            key={Date.now()}
                            defaultValue={profileData? {label:profileData?.profession,value:profileData.profession}:null} 
                            id='proffession' 
                            name='profession' 
                            className='border border-soft-secondary rounded' 
                            options={Proffessions} 
                        />
                    </Col>
                    <Col lg={5} className='mt-2'>
                        <Label htmlFor='speciality'>Speciality</Label>
                        <Input type='text' id='speciality' defaultValue={profileData?.speciality} name='speciality' />
                    </Col>
                    <Col lg={2} className='mt-2'>
                        <Label htmlFor='experience'>Experience in Years</Label>
                        <Input defaultValue={profileData?.experience} type='text' id='experience' name='experience' />
                    </Col>

                    <Col lg={6} className='mt-2'>
                        <Label htmlFor='fee'>Fees</Label>
                        <Input type='text' defaultValue={profileData?.fee} id='fee' name='fee' />
                    </Col>
                    <Col lg={6} className='mt-2'>
                        <Label htmlFor='payment'>Payment option</Label>
                        <ReactSelect 
                            key={Date.now()}
                            isMulti 
                            defaultValue={profileData? JSON.parse(profileData?.payment_options).map(o=>({label:o,value:o})):null} 
                            className='border border-soft-secondary rounded' 
                            id='payment' 
                            name='payment_options[]' 
                            options={paymentopt} 
                        />
                    </Col>

                    <Col lg={12} className='mt-2'>
                        <Label htmlFor='description'>Description</Label>
                        <textarea name='description' id='description' defaultValue={profileData?.description} className='form-control'></textarea>
                    </Col>
                    <Col xs={12}><blockquote><u><h5 className='my-3 underline'>Address</h5></u></blockquote></Col>
                    <Col lg={6}>
                        <Label htmlFor='address'>Office Address</Label>
                        <Input type='text' defaultValue={profileData?.address?.address} id='address' name='address' />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='city'>City</Label>
                        <Input type='text' defaultValue={profileData?.address?.city} id='city' name='city' />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='district'>District</Label>
                        <Input type='text' defaultValue={profileData?.address?.district} id='district' name='district' />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='state'>State</Label>
                        <Input type='text' id='state' defaultValue={profileData?.address?.state} name='state' />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='pincode'>Pin Code</Label>
                        <Input type='text' id='pincode' defaultValue={profileData?.address?.pincode} name='pincode' />
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor='country'>Country</Label>
                        <Input type='text' id='country' defaultValue={profileData?.address?.country} name='country' />
                    </Col>
                    <Col xs={12} className='mt-4 d-flex justify-content-center'>
                        <button type='submit' className='btn btn-primary btn-end w-md'>submit</button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}
