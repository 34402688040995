import React, { useEffect, useMemo, useState } from 'react'
import { Row,Col,Card, CardBody, Container,Badge,Button, CardHeader } from 'reactstrap';
import { AssessmentForm } from './AssessmentForm';
import { AssessmentApi } from 'helpers/api_url';
import { UpdateQuestion } from './UpdateQuestion';

export const Assessment = () => {  
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    AssessmentApi.allQuestion()
      .then(r => setTableData(r.data.questions))
      .catch(e => console.log(e))
  }, [])
  const handleDelete = (id) => {AssessmentApi.deleteQuestion(id).then(res => { setTableData([...tableData.filter(td => td.id != id)]) }).catch((err) => toast.error(err.response ? err.response.data.message : err.message))}
    return (
      <React.Fragment>
     
              <CardHeader className="d-flex align-items-center justify-content-between">
                <h5 className="card-title mb-0">Participator-intake</h5>
                <AssessmentForm  type="participator_intake" setTableData={setTableData}/>
              </CardHeader>
              <CardBody>
              <ul className="list-group">
                {tableData.filter(i=>i.type==="participator_intake").map((item, idx) => (
                  <li className='list-group-item' key={idx}>
                  <Row>
                      <Col xs={1}><span className='fw-bold'>{idx + 1}.</span></Col>
                      <Col xs={8}><span className='fw-bold'><b>{item.question}</b></span></Col>
                      <Col className='text-start' xs={3}>
                      <div>
                      <UpdateQuestion type="participator_intake" item={item.question} ques_id={item.id} tableData={tableData} setTableData={setTableData}/>
                      <Button onClick={()=>{handleDelete(item.id)}} className="btn btn-sm btn-soft-danger me-1" ><i className="mdi mdi-delete-outline" /></Button>
                      </div>
                      </Col>
                  </Row>
              </li>
                ))}
                </ul>
                </CardBody>
    
      </React.Fragment>
    )
}
